var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-page"},[(_vm.loading.services)?_c('d-loading'):[(!_vm.selectedService)?[_c('div',{staticClass:"home-page--documents mt-4 px-4"},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"4"}},[_c('router-link',{staticClass:"doc-card style-1",attrs:{"to":"/received-docs"}},[_c('div',{staticClass:"doc-card--text"},[_c('h2',{staticClass:"text-h6 gray--text text--darken-3 text-decoration-none",domProps:{"innerHTML":_vm._s(_vm.$t('dashboard.received-documents'))}})]),_c('div',{staticClass:"doc-card--icon d-flex justify-end"},[_c('img',{attrs:{"src":require("@/assets/images/document-icon.svg")}})])])],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"4"}},[_c('router-link',{staticClass:"doc-card style-1",attrs:{"to":"/my-uploads"}},[_c('div',{staticClass:"doc-card--text"},[_c('h2',{staticClass:"text-h6 gray--text text--darken-3 text-decoration-none",domProps:{"innerHTML":_vm._s(_vm.$t('dashboard.my-uploads'))}})]),_c('div',{staticClass:"doc-card--icon d-flex justify-end"},[_c('img',{attrs:{"src":require("@/assets/images/document-icon.svg")}})])])],1)],1)],1),(_vm.services && _vm.services.length > 0)?_c('div',{staticClass:"home-page--services"},[_c('div',{staticClass:"px-4 py-3 mb-4 border-bottom"},[_c('h3',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("dashboard.services")))])]),_c('div',{staticClass:"px-4"},[_c('v-row',_vm._l((_vm.services),function(service){return _c('v-col',{key:service.id,staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"service-card",style:({
                  'background-color': service.color
                    ? service.color
                    : _vm.defaults.serviceColor,
                }),attrs:{"to":{ path: '/', query: { service: service.id } }}},[_c('div',{staticClass:"service-card--icon"},[_c('img',{attrs:{"src":`data:image/png;base64,${
                      service.listIcon
                        ? service.listIcon
                        : _vm.defaults.serviceIcon
                    }`}})]),_c('div',{staticClass:"service-card--text"},[_c('span',{staticClass:"subtitle-1 white--text font-weight-bold"},[_vm._v(_vm._s(service.displayName))])])])],1)}),1)],1)]):_vm._e(),(_vm.authorizationUtils.isClient())?_c('div',{staticClass:"home-page--appointment"},[_c('div',{staticClass:"px-4 py-3 mb-4 border-bottom"},[_c('h3',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.make-an-appointment.title"))+" ")])]),_c('div',{staticClass:"px-4"},[_c('v-row',_vm._l((_vm.appointmentLinks),function(appointmentLink,index){return _c('v-col',{key:index,staticClass:"mb-4",attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"gray darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"service-card",style:({
                      'background-color': appointmentLink.color
                        ? appointmentLink.color
                        : _vm.defaults.serviceColor,
                    }),attrs:{"href":appointmentLink.url,"target":"_blank"}},'a',attrs,false),on),[_c('div',{staticClass:"service-card--icon"},[_c('img',{attrs:{"src":`data:image/png;base64,${
                          appointmentLink.listIcon
                            ? appointmentLink.listIcon
                            : _vm.defaults.serviceIcon
                        }`}})]),_c('div',{staticClass:"service-card--text"},[_c('span',{staticClass:"subtitle-1 white--text font-weight-bold"},[_vm._v(_vm._s(appointmentLink.name))])])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.make-an-appointment.tooltip", { department: appointmentLink.name, })))])])],1)}),1)],1)]):_vm._e()]:[(_vm.loading.tags)?_c('d-loading'):_c('div',{staticClass:"home-page--tags"},[_c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return _c('v-breadcrumbs-item',{class:{ 'primary--text cursor-pointer': !item.disabled },attrs:{"to":!item.disabled ? '/' : null,"exact":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}}])})],1),_c('div',{staticClass:"px-4"},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"tag-card",on:{"click":_vm.selectTag}},[_c('div',{staticClass:"tag-card--icon"},[_c('v-icon',{attrs:{"color":"gray darken-3","size":"20"}},[_vm._v("mdi-format-list-bulleted")])],1),_c('div',{staticClass:"tag-card--text"},[_c('span',{staticClass:"body-1 gray--text text--darken-3 font-weight-medium"},[_vm._v(_vm._s(_vm.$t("dashboard.all-tags")))])])])]),_vm._l((_vm.tags),function(tag){return _c('v-col',{key:tag.id,staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"tag-card",on:{"click":function($event){return _vm.selectTag(tag)}}},[_c('div',{staticClass:"tag-card--icon"},[(tag.listIcon)?_c('img',{attrs:{"src":`data:image/png;base64,${tag.listIcon}`}}):_vm._e()]),_c('div',{staticClass:"tag-card--text"},[_c('span',{staticClass:"body-1 gray--text text--darken-3 font-weight-medium"},[_vm._v(_vm._s(tag.displayName))])])])])})],2)],1)])]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }