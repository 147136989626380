<template>
  <router-link
    tag="button"
    :disabled="!canAccessTransactionList"
    :to="generatedLink"
    :event="canAccessTransactionList ? 'click' : ''"
    class="institution-account-item d-block text-decoration-none py-2 px-2 mb-1 rounded"
  >
    <v-row align="center">
      <v-col cols="8" md="9">
        <div class="d-flex align-center text-left">
          <v-avatar size="36" color="primary">
            <img
              v-if="item.institution.logo"
              :src="`data:image/png;base64,${item.institution.logo}`"
              :alt="item.institution.name"
            />
            <span class="white--text" v-else>{{
              item.institution.name.charAt(0)
            }}</span>
          </v-avatar>
          <div class="ml-2">
            <p class="body-2 text--primary mb-0">
              {{ item.institution.name }}
            </p>
            <p class="body-2 text--secondary mb-0">
              --{{ item.mask }} | {{ item.name }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="4" md="3">
        <p
          class="body-2 text--primary text-right mb-0 font-weight-medium"
          v-if="item.currentBalance || item.currentBalance == 0"
        >
          {{
            `${formatCurrency(
              item.currentBalance,
              item.isoCurrencyCode.toUpperCase()
            )}`
          }}
        </p>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
import isoCountryCurrency from "@/utils/isoCountryCurrency";
import authorizationUtils from "@/utils/authorization.js";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    generatedLink() {
      if (this.canAccessTransactionList) {
        if (authorizationUtils.isClient()) {
          return {
            name: "ReconciliationTransactions",
            params: { institutionAccountId: this.item.id },
          };
        }

        return {
          name: "ReconciliationTransactions",
          params: { institutionAccountId: this.item.id },
          query: { clientId: this.clientId },
        };
      }

      return {};
    },
    canAccessTransactionList() {
      return authorizationUtils.isGranted("reconciliation:transaction:list");
    },
  },
  methods: {
    formatCurrency(amount, isoCurrencyCode) {
      return isoCountryCurrency.formatCurrency(amount, isoCurrencyCode);
    },
  },
};
</script>

<style lang="scss">
.institution-account-item {
  width: 100%;
  background-color: var(--v-gray-lighten5);

  &:hover,
  &:active {
    background-color: var(--v-gray-lighten4);
  }
}
</style>
