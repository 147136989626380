export function flatArrayToGroupedArray(arr, groupingKeys) {
  return arr.reduce((result, curItem) => {
    const groupingKey = getPropValueWithKeys(curItem, groupingKeys);

    (result[groupingKey] = result[groupingKey] || []).push(curItem);

    return result;
  }, {});
}

export function getPropValueWithKeys(obj, keys) {
  return keys.reduce(
    (result, curKey) => (result && result[curKey] ? result[curKey] : null),
    obj
  );
}
