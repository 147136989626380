<template>
  <d-modal
    :title="modalTitle"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="prepend-title">
      <div class="mr-2">
        <v-tooltip right color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              {{ isBusiness ? "mdi-briefcase" : "mdi-account" }}
            </v-icon>
          </template>
          <span>
            {{
              isBusiness
                ? $t("accounts.client-type.business")
                : $t("accounts.client-type.personal")
            }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template slot="body">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitModal">
        <v-row>
          <template v-if="isBusiness">
            <v-col cols="12">
              <v-text-field
                :label="$t('accounts.owner.create.form.name')"
                v-model.trim="form.name"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
          </template>
          <template v-if="isPersonal">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :label="$t('accounts.owner.create.form.first-name')"
                v-model.trim="form.firstName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :label="$t('accounts.owner.create.form.last-name')"
                v-model.trim="form.lastName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.lastName"
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";

const initialForm = {
  clientType: null,
  name: null,
  firstName: null,
  lastName: null,
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    clientType: {
      type: Number || String,
      required: true,
      default: ClientTypes.PERSONAL,
    },
  },
  data() {
    return {
      ClientTypes,
      form: { ...initialForm },
      isSubmitting: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
      },
    };
  },
  computed: {
    isPersonal() {
      return this.clientType === ClientTypes.PERSONAL;
    },
    isBusiness() {
      return this.clientType === ClientTypes.BUSINESS;
    },
    modalTitle() {
      return this.isBusiness
        ? this.$t("accounts.owner.create.title.business")
        : this.$t("accounts.owner.create.title.personal");
    },
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      if (!this.$refs.form.validate()) return;

      this.isSubmitting = true;
      const newItem = { ...this.form, clientType: this.clientType };

      window.API.insertMyClient(newItem)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("accounts.owner.create.alert.created", {
              fullName: this.isPersonal
                ? `${newItem.firstName} ${newItem.lastName}`
                : `${newItem.name}`,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("accounts.owner.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
