export function fetchAllRoles() {
  return window.axios
    .get("/identity/roles/all")
    .then((response) => response.data);
}

export function fetchRoles({
  name,
  displayName,
  offset,
  limit,
  sortedBy,
  sortedDesc,
}) {
  return window.axios
    .get("/identity/roles", {
      params: {
        name,
        displayName,
        offset,
        limit,
        sortedBy,
        sortedDesc,
      },
    })
    .then((response) => response.data);
}

export function insertRole(data) {
  return window.axios
    .post("/identity/roles", data)
    .then((response) => response);
}

export function deleteRole(id) {
  return window.axios
    .delete(`/identity/roles/${id}`)
    .then((response) => response);
}

export function checkNameAvailability(name) {
  return window.axios
    .get(`/identity/roles/name/${name}/is-available`)
    .then((response) => response.data);
}

export function checkDisplayNameAvailability(displayName) {
  return window.axios
    .get(`/identity/roles/display-name/${displayName}/is-available`)
    .then((response) => response.data);
}

export function getRoleById(id) {
  return window.axios
    .get(`/identity/roles/${id}`)
    .then((response) => response.data);
}

export function editRole(id, data) {
  return window.axios
    .put(`/identity/roles/${id}`, data)
    .then((response) => response);
}
