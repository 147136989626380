<template>
  <d-modal
    :title="$t('my-uploads.upload.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
    :saveButtonText="$t('my-uploads.upload.button.save')"
    :isSaveButtonDisabled="files.length == 0"
  >
    <template slot="body">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <section
          class="docu-upload"
          :class="{ 'drag-over': isDragover }"
          @drop.prevent="handleAddFilesWithDrag"
          @dragover.prevent="isDragover = true"
          v-if="files.length == 0"
        >
          <v-row>
            <v-col cols="12" align="center" justify="center" class="mb-2">
              <v-icon size="32">mdi-file-plus</v-icon>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="subtitle-2 mb-0">
                {{ $t("my-uploads.upload.form.upload-text") }}
              </p>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <v-btn
                text
                depressed
                large
                color="blue"
                @click="handleAddFilesWithButton"
              >
                <v-icon left> mdi-plus </v-icon>
                <span class="subtitle-2 text-none">{{
                  $t("my-uploads.upload.button.add")
                }}</span>
              </v-btn>
              <input
                ref="fileUploader"
                class="d-none"
                type="file"
                multiple
                @change="handleChangeFileUploader"
              />
            </v-col>
          </v-row>
        </section>
        <template v-if="files.length > 0">
          <section class="border-all rounded">
            <v-data-table
              v-model="selectedItems"
              :single-select="false"
              show-select
              :headers="headers"
              :items="files"
              class="d-table border-none"
              hide-default-footer
              item-key="order"
              :height="files.length > 5 ? '285' : null"
              fixed-header
              @toggle-select-all="selectAll"
              :items-per-page="-1"
            >
              <template v-slot:[`item.order`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.actions`]="{ item, index }">
                <v-tooltip top color="gray darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="gray darken-2"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="removeFile(index, item)"
                    >
                      <v-icon size="18"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("my-uploads.upload.form.table.actions.delete")
                  }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="d-flex align-center justify-end pa-4 border-top">
              <v-btn
                dark
                depressed
                small
                color="primary"
                @click="handleAddFilesWithButton"
              >
                <v-icon left> mdi-plus </v-icon>
                <span class="text text-none">{{
                  $t("my-uploads.upload.button.add-more")
                }}</span>
              </v-btn>
              <input
                ref="fileUploader"
                class="d-none"
                type="file"
                multiple
                @change="handleChangeFileUploader"
              />
            </div>
          </section>

          <section class="mt-4">
            <v-row>
              <v-col cols="12" class="mb-3" v-if="selectedItems.length === 1">
                <v-text-field
                  filled
                  hide-details="auto"
                  :label="$t('my-uploads.upload.form.displayName')"
                  v-model="form.displayName"
                  :rules="rules.displayName"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="mb-3"
                v-if="!authorizationUtils.isClient()"
              >
                <v-autocomplete
                  filled
                  hide-details="auto"
                  :label="$t('my-uploads.upload.form.client')"
                  v-model="form.clientId"
                  :items="clients"
                  :rules="rules.client"
                  item-text="name"
                  item-value="id"
                  @change="clientChanged"
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                :md="authorizationUtils.isClient() ? 12 : 6"
                class="mb-3"
              >
                <v-autocomplete
                  filled
                  hide-details="auto"
                  :label="$t('my-uploads.upload.form.service')"
                  v-model="form.serviceId"
                  :items="services"
                  :rules="rules.service"
                  item-text="displayName"
                  item-value="id"
                  :loading="loading.services"
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :class="{ 'mb-3': form.serviceId }">
                <BaseTagsSelect
                  :title="$t('my-uploads.upload.form.tags')"
                  :serviceItDepends="form.serviceId"
                  v-model="form.tags"
                />
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-textarea
                  rows="1"
                  filled
                  hide-details="auto"
                  :label="$t('my-uploads.upload.form.description')"
                  v-model="form.description"
                >
                </v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="mb-3"
                v-if="!authorizationUtils.isClient()"
              >
                <v-checkbox
                  class="mt-0"
                  v-model="form.uploadedOnBehalfOfClient"
                  :label="$t('my-uploads.upload.form.behalf-of-client')"
                  filled
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <p class="caption mb-0 gray--text text--darken-1">
                  {{ $t("my-uploads.upload.form.info") }}
                </p>
              </v-col>
            </v-row>
          </section>
        </template>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import BaseTagsSelect from "@/components/BaseTagsSelect.vue";
import authorizationUtils from "@/utils/authorization.js";
import { mapGetters } from "vuex";

const initialForm = {
  displayName: null,
  clientId: null,
  serviceId: null,
  tags: null,
  description: null,
  uploadedOnBehalfOfClient: false,
};
export default {
  components: { BaseTagsSelect },
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
  },
  data(vm) {
    return {
      form: { ...initialForm },
      rules: {
        displayName: [(v) => !!v || this.$t("defaults.rule.required")],
        client: [(v) => !!v || this.$t("defaults.rule.required")],
        service: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      isSubmitting: false,
      files: [],
      isDragover: false,
      selectedItems: [],
      headers: [
        {
          text: vm.$t("my-uploads.upload.form.table.headers.order"),
          value: "order",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.upload.form.table.headers.file-name"),
          value: "displayName",
          sortable: false,
        },
        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
      clients: [],
      services: [],
      isFormValid: false,
      order: 0,
      loading: {
        services: false,
      },
      authorizationUtils,
    };
  },
  watch: {
    selectedItems: {
      handler(items) {
        if (items.length === 1) {
          this.form.displayName = items[0].displayName;
        }

        if (items.length === 0 && this.files.length > 0) {
          this.setFirstFileAsSelectedItem();
        }

        if (this.files.length === 0) {
          this.form = { ...initialForm };
        }
      },
    },
  },
  methods: {
    async handleSubmitModal() {
      if (this.$refs.form.validate() && this.isFormValid) {
        await this.uploadDocuments();
      }
    },
    handleCloseModal() {
      this.$emit("closeModal");
      this.form = { ...initialForm };
      this.$refs.form.reset();
    },
    handleAddFilesWithDrag(e) {
      this.isDragover = false;
      this.generateFiles(e.dataTransfer.files);
    },
    handleAddFilesWithButton() {
      this.$refs.fileUploader.click();
    },
    handleChangeFileUploader(e) {
      this.generateFiles(e.target.files);
    },
    setFirstFileAsSelectedItem() {
      this.selectedItems.push(this.files[0]);
    },
    generateFiles(files) {
      let index = 0;
      while (index < files.length) {
        const file = files[index++];
        this.files.push({
          order: this.order++,
          displayName: file.name,
          file,
        });
      }

      if (this.selectedItems.length === 0) {
        this.setFirstFileAsSelectedItem();
      }
    },
    removeFile(index, item) {
      this.files.splice(index, 1);
      this.selectedItems = this.selectedItems.filter(
        (selectedFile) => selectedFile.order != item.order
      );
    },
    selectAll({ value }) {
      if (!value) {
        this.selectedItems = [];
      }
    },
    getClientActiveServices(id) {
      this.loading.services = true;
      this.services = [];
      window.API.getClientActiveServices(id)
        .then((response) => {
          this.services = response;
        })
        .finally(() => (this.loading.services = false));
    },
    clientChanged() {
      this.form.serviceId = null;
      this.getClientActiveServices(this.form.clientId);
    },
    async fetchAllActiveClients() {
      this.clients = await window.API.fetchAllActiveClients();
    },
    generateFormData() {
      let formData = new FormData();

      if (this.selectedItems.length == 1) {
        formData.append("displayName", this.form.displayName);
      }

      if (authorizationUtils.isClient()) {
        formData.append("clientId", this.myCurrentClient.id);
      } else {
        formData.append("clientId", this.form.clientId);
      }

      formData.append("serviceId", this.form.serviceId);

      this.form.tags.forEach((service) => {
        formData.append("tags", service);
      });

      if (this.form.description) {
        formData.append("description", this.form.description);
      }
      if (!authorizationUtils.isClient()) {
        formData.append(
          "uploadedOnBehalfOfClient",
          this.form.uploadedOnBehalfOfClient
        );
      }

      this.selectedItems.forEach((selectedItem) => {
        formData.append("files", selectedItem.file);
      });

      return formData;
    },
    async uploadDocuments() {
      this.isSubmitting = true;
      const formData = await this.generateFormData();
      window.API.uploadDocuments(formData)
        .then(async () => {
          this.files = await this.files.filter(
            (file) =>
              !this.selectedItems.some((item) => item.order == file.order)
          );
          this.selectedItems = [];

          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("my-uploads.upload.alert.uploaded"),
          });

          this.$emit("submitModal");

          if (this.files.length == 0) {
            this.handleCloseModal();
          }
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("my-uploads.upload.alert.could-not-upload"),
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
  },
  mounted() {
    if (authorizationUtils.isClient()) {
      this.getClientActiveServices(this.myCurrentClient.id);
    } else {
      this.fetchAllActiveClients();
    }
  },
};
</script>

<style lang="scss">
.docu-upload {
  padding: 16px;
  border: 1px dashed var(--v-gray-lighten1);

  &.drag-over {
    border-color: var(--v-primary-base);
  }
}
</style>
