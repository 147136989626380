<template>
  <div class="pa-4">
    <v-row class="mb-6" v-if="loading.table || table.items.length > 0">
      <v-col cols="12">
        <v-text-field
          :label="$t('accounts.owner.list.search')"
          hide-details="auto"
          filled
          type="text"
          v-model="searchText"
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="loading.table"
      v-if="loading.table || filteredItems.length > 0"
      class="d-table"
      :items-per-page="table.itemsPerPage"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <div class="d-flex flex-nowrap align-center">
          <v-tooltip right color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                {{
                  item.clientType === ClientTypes.BUSINESS
                    ? "mdi-briefcase"
                    : "mdi-account"
                }}
              </v-icon>
            </template>
            <span>
              {{
                item.clientType === ClientTypes.BUSINESS
                  ? $t("accounts.client-type.business")
                  : $t("accounts.client-type.personal")
              }}
            </span>
          </v-tooltip>
          <span class="ml-2">{{ item.fullName }}</span>
        </div>
      </template>

      <template v-slot:[`item.socialSecurityNumber`]="{ item }">
        <template v-if="item.socialSecurityNumber">
          {{ item.socialSecurityNumber }}
        </template>
        <template v-else-if="item.taxpayerIdentificationNumber">
          {{ item.taxpayerIdentificationNumber }}
        </template>
      </template>
      <template v-slot:[`item.advisor`]="{ item }">
        <span v-if="item.advisor">{{ item.advisor }}</span>
        <span class="caption text--disabled" v-else>{{
          $t("accounts.owner.list.table.no-advisor")
        }}</span>
      </template>
      <template v-slot:[`item.collaborators`]="{ item }">
        <template v-for="collaborator in item.collaborators">
          <v-chip
            @click="selectCollaborator(collaborator)"
            :key="collaborator.id"
            small
            class="ma-1"
            :color="
              collaborator.isActive ? 'light-blue lighten-5' : 'gray lighten-3'
            "
          >
            <span
              :class="
                collaborator.isActive
                  ? 'blue--text'
                  : 'gray--text text--darken-1'
              "
              >{{ collaborator.collaboratorUser }}</span
            >

            <template
              v-if="
                selectedCollaborator &&
                selectedCollaborator.id === collaborator.id
              "
            >
              <template v-if="collaborator.isActive">
                <v-tooltip
                  top
                  small
                  color="gray darken-2"
                  v-granted="'account:list'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="changeCollaboratorStatus(item, collaborator)"
                    >
                      <v-icon x-small color="primary"> mdi-lock </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("accounts.owner.list.table.collaborator.deactivate")
                  }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip
                  top
                  small
                  color="gray darken-2"
                  v-granted="'account:list'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="changeCollaboratorStatus(item, collaborator)"
                    >
                      <v-icon x-small color="primary"> mdi-lock-open </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("accounts.owner.list.table.collaborator.activate")
                  }}</span>
                </v-tooltip>
              </template>
            </template>
          </v-chip>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'account:list'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="viewItem(item)"
              >
                <v-icon size="18"> mdi-information </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("accounts.owner.list.table.actions.details") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('accounts.owner.list.empty-page')"
      v-if="!searchText && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="searchText && filteredItems.length == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";
import tableMixins from "@/mixins/tableMixins";

export default {
  mixins: [tableMixins],
  components: {},
  data(vm) {
    return {
      ClientTypes,
      headers: [
        {
          text: vm.$t("accounts.owner.list.table.headers.name"),
          value: "fullName",
          sortable: false,
        },
        {
          text: vm.$t("accounts.owner.list.table.headers.tin-ssn"),
          value: "socialSecurityNumber",
          sortable: false,
        },
        {
          text: vm.$t("accounts.owner.list.table.headers.advisor"),
          value: "advisor",
          sortable: false,
        },
        {
          text: vm.$t("accounts.owner.list.table.headers.collaborators"),
          value: "collaborators",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      searchText: null,
      selectedCollaborator: null,
    };
  },
  computed: {
    filteredItems() {
      if (this.searchText) {
        return this.table.items.filter((item) => {
          if (
            item.fullName &&
            item.fullName.toLowerCase().includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.socialSecurityNumber &&
            item.socialSecurityNumber
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.taxpayerIdentificationNumber &&
            item.taxpayerIdentificationNumber
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.advisor &&
            item.advisor.toLowerCase().includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.collaborators.length > 0 &&
            item.collaborators.some((collaborator) =>
              collaborator.collaboratorUser
                .toLowerCase()
                .includes(this.searchText.toLowerCase())
            )
          ) {
            return true;
          }

          return false;
        });
      }
      return this.table.items;
    },
  },
  methods: {
    selectCollaborator(collaborator) {
      this.selectedCollaborator = collaborator;
    },
    changeCollaboratorStatus(item = null, collaborator = null) {
      this.$emit("changeCollaboratorStatus", item, collaborator);
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    fetchItems() {
      this.loading.table = true;

      window.API.getAllMyOwnerClients()
        .then((response) => {
          this.table.items = response;
          this.table.totalCount = response.length;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
  created() {
    this.$eventBus.$on("fetchOwnerClients", this.fetchItems);
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchOwnerClients", this.fetchItems);
  },
};
</script>

<style lang="scss">
.v-data-table__progress > th {
  padding: 0 !important;
}
</style>
