<template>
  <v-form
    v-if="form"
    ref="form"
    v-model="isValidForm"
    lazy-validation
    @submit.prevent="handleFormSubmit"
  >
    <v-row>
      <v-col cols="12" md="4" class="mb-3">
        <div
          class="border-all pa-4 rounded d-flex align-center justify-center h-100"
        >
          <d-image-upload
            @change="handleImageUploadChange"
            :value="hasImage ? `/identity/users/me/profile-picture` : null"
          ></d-image-upload>
        </div>
      </v-col>
      <v-col cols="12" md="8" class="mb-3">
        <div
          class="border-all pa-4 rounded d-flex align-center justify-center h-100"
        >
          <v-row>
            <v-col cols="12" sm="6" class="mb-3">
              <v-text-field
                :label="$t('profile.form.first-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.firstName"
                :rules="rules.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mb-3">
              <v-text-field
                :label="$t('profile.form.last-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.lastName"
                :rules="rules.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mb-3">
              <v-text-field
                :label="$t('profile.form.phone-number')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.phoneNumber"
                :rules="rules.phoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mb-3" v-if="!isClient">
              <v-text-field
                :label="$t('profile.form.extension')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.extension"
                :rules="rules.extension"
                v-mask="'####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="mb-3 mb-sm-0">
              <v-text-field
                :label="$t('profile.form.email')"
                hide-details="auto"
                filled
                type="text"
                disabled
                v-model="form.emailAddress"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('profile.form.username')"
                hide-details="auto"
                filled
                type="text"
                disabled
                v-model="form.username"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="12">
        <div class="d-flex align-center justify-end">
          <v-btn
            @click="cancelEditProfile"
            class="text-none body-2 font-weight-medium mr-3"
            depressed
          >
            {{ $t("profile.button.cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
            :loading="loading"
          >
            {{ $t("profile.button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import authorizationUtils from "@/utils/authorization.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    hasImage: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ loggedInUser: "Auth/getLoggedInUser" }),
    isClient() {
      return authorizationUtils.isClient();
    },
  },
  data() {
    return {
      isValidForm: true,
      form: null,
      isProfilePictureChanged: false,
      profilePicture: null,
      rules: {
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        phoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        extension: [
          (v) => {
            if (!v) return true;

            if (v && /^\d+$/.test(v)) return true;
            return this.$t("profile.rule.extension-only-number");
          },
          (v) => {
            if (!v) return true;

            if (v && v.length === 4) return true;

            return this.$t("profile.rule.extension");
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({ setLoggedInUser: "Auth/setLoggedInUser" }),
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.loading = true;

        let formData = new FormData();

        formData.append("firstName", this.form.firstName);
        formData.append("lastName", this.form.lastName);
        formData.append("phoneNumber", this.form.phoneNumber);
        if (this.form.extension && !this.isClient) {
          formData.append("extension", this.form.extension);
        }
        formData.append(
          "isProfilePictureChanged",
          this.isProfilePictureChanged
        );
        if (this.isProfilePictureChanged) {
          formData.append("profilePicture", this.profilePicture);
        }

        window.API.updateUserProfile(formData)
          .then(() => {
            this.$emit("saveEdit");

            let loggedInUser = this.loggedInUser;

            loggedInUser.firstName = this.form.firstName;
            loggedInUser.lastName = this.form.lastName;

            this.setLoggedInUser(loggedInUser);

            this.$eventBus.$emit("profileUpdated");

            this.$eventBus.$emit("notification", {
              type: "success",
              message: this.$t("profile.alert.save-success"),
            });
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("profile.alert.save-error"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    cancelEditProfile() {
      this.form = null;
      this.isProfilePictureChanged = false;
      this.profilePicture = null;
      this.$emit("cancelEdit");
    },
    handleImageUploadChange(image) {
      this.profilePicture = image;
      this.isProfilePictureChanged = true;
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>
