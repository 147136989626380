<template>
  <div class="organizer-item rounded my-3 border-all">
    <v-row>
      <v-col class="py-3" v-if="isCorporation" md="3">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.titles") }}:
        </span>
        <span class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
          >{{ getFormattedTitles(organizer.titles) }}
        </span>
      </v-col>
      <v-col class="py-3" :md="isCorporation || (isResident && isLLC) ? 3 : 4">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.first-name") }}:
        </span>
        <span class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
          >{{ organizer.firstName }}
        </span>
      </v-col>
      <v-col class="py-3" :md="isCorporation || (isResident && isLLC) ? 3 : 4">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.last-name") }}:
        </span>
        <span class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
          >{{ organizer.lastName }}
        </span>
      </v-col>
      <v-col class="py-3" :md="isCorporation || (isResident && isLLC) ? 3 : 4">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.middle-name") }}:
        </span>
        <span class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
          >{{ fillEmptyData(organizer.middleName) }}
        </span>
      </v-col>
      <v-col class="py-3" :md="sharedColumnWidth">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.share-ownership") }}:
        </span>
        <v-chip class="ml-4" small color="gray lighten-2">
          <span>{{ `${organizer.shareOwnership}%` }} </span>
        </v-chip>
      </v-col>
      <v-col class="py-3" v-if="isCorporation" :md="sharedColumnWidth">
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.is-director.label") }}:
        </span>
        <span class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
          >{{
            $t(
              `companies.details.organizers.is-director.${
                organizer.isDirector ? "yes" : "no"
              }`
            )
          }}
        </span>
      </v-col>
      <v-col
        class="py-3"
        v-if="!isResident && organizer.mainPageOfPassport"
        :md="sharedColumnWidth"
      >
        <span class="subtitle-2 font-weight-medium grey--text"
          >{{ $t("companies.details.organizers.uploaded-file") }}:
        </span>
        <v-chip
          small
          label
          class="ml-4 px-2"
          color="primary"
          :disabled="loading"
          @click="downloadFile"
        >
          <d-loading v-if="loading" :size="16" color="white" />
          <v-icon v-else size="16" color="white"> mdi-file-document </v-icon>
          <span class="white--text text-truncate ml-2">{{
            organizer.mainPageOfPassport.fileName
          }}</span>
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import fileDownload from "js-file-download";

import { getFilenameFromContentDispositionHeader } from "@/utils/getFilenameFromContentDispositionHeader";

export default {
  props: {
    organizer: {
      type: Object,
      require: true,
    },
    isCorporation: {
      type: Boolean,
      require: true,
    },
    isLLC: {
      type: Boolean,
      require: true,
    },
    isResident: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    sharedColumnWidth() {
      if (
        (this.isResident && this.isCorporation) ||
        (!this.isResident && !this.isCorporation)
      )
        return 6;

      if (!this.isResident && this.isCorporation) return 4;

      return 3;
    },
  },
  methods: {
    getFormattedTitles(selectedTitles) {
      if (!selectedTitles || selectedTitles.length == 0)
        return this.fillEmptyData();

      return selectedTitles.join(", ");
    },
    async downloadFile() {
      const companyId = this.$route.params.id;
      this.loading = true;

      try {
        const response = await window.API.downloadOrganizerMainPageOfPassport(
          companyId,
          this.organizer.id
        );

        if (response.data) {
          const fileName = getFilenameFromContentDispositionHeader(
            response.headers["content-disposition"]
          );
          fileDownload(response.data, `${fileName}`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
