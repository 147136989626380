<template>
  <d-modal
    :title="$t('users.reset-password.title')"
    :modal="modal"
    :isSubmitting="isSubmitting"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
  >
    <template slot="body">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitModal">
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('users.reset-password.form.password.label')"
              :hint="$t('users.reset-password.form.password.hint')"
              persistent-hint
              v-model.trim="form.password"
              hide-details="auto"
              filled
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordVisible)"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="rules.password"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :label="$t('users.reset-password.form.confirm-password')"
              v-model.trim="form.confirmPassword"
              hide-details="auto"
              filled
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isConfirmPasswordVisible)"
              @click:append="
                isConfirmPasswordVisible = !isConfirmPasswordVisible
              "
              :rules="rules.confirmPassword"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  id: null,
  password: null,
  confirmPassword: null,
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      form: { ...initialForm },
      rules: {
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("users.reset-password.rule.password.invalid"),
        ],
        confirmPassword: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => {
            if (!this.form.password) return true;

            if (v === this.form.password) return true;

            return this.$t(
              "users.reset-password.rule.confirm-password.not-match"
            );
          },
        ],
      },
    };
  },
  methods: {
    getPasswordInputIcon(isVisible) {
      return isVisible ? "mdi-eye-off" : "mdi-eye";
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      if (this.$refs.form.validate()) {
        this.isSubmitting = true;
        window.API.resetUserPassword(this.form.id, this.form)
          .then(() => {
            this.$eventBus.$emit("notification", {
              type: "success",
              message: this.$t("users.reset-password.alert.reset"),
            });
            this.handleCloseModal();
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("users.reset-password.alert.could-not-reset"),
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
  created() {
    this.form.id = this.item.id;
  },
};
</script>
