<template>
  <d-page
    :title="$t('contractors.page-heading')"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="append-heading">
      <v-row>
        <v-col lg="6" md="6" sm="12">
          <v-select
            filled
            v-model="selectedTaxYear"
            dense
            :items="taxYears"
            hide-details="auto"
            :label="$t('contractors.search.form.tax-year')"
            class="ms-4"
          ></v-select>
        </v-col>
      </v-row>
    </template>
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("contractors.button.search-toggle")
        }}</span>
      </v-btn>

      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        @click="exportContractors"
        :loading="loading.export"
        v-if="isExportGranted"
      >
        <v-icon left> mdi-file-excel </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("contractors.button.export-data")
        }}</span>
      </v-btn>

      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        :disabled="totalUnpaidServiceFee == 0 || hasAnyItemWithZeroTotalPayment"
        @click="handleOpenPaymentModal"
        v-granted="'contractor:pay'"
        :loading="loading.payAndSubmit"
        v-if="selectedTaxYear === $constants.CURRENT_TAX_YEAR"
      >
        <span class="subtitle-2 text-none"
          >{{ $t("contractors.button.pay-and-submit") }}
          <span v-if="totalUnpaidServiceFee > 0">
            ({{
              $t("defaults.currency", {
                amount: totalUnpaidServiceFee,
              })
            }})
          </span>
        </span>
      </v-btn>
      <v-menu
        bottom
        offset-y
        content-class="elevation-0 mt-1 rounded"
        v-granted="'contractor:add'"
        v-if="selectedTaxYear === $constants.CURRENT_TAX_YEAR"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            depressed
            large
            class="mb-1 mb-sm-0"
            v-bind="attrs"
            v-on="on"
          >
            <span class="subtitle-2 text-none">{{
              $t("contractors.button.create-item")
            }}</span>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list outlined class="rounded" dense>
          <v-list-item @click="createItem(ContractorTypes.BUSINESS)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-briefcase</v-icon>
                <span class="body-2">{{
                  $t("contractors.contractor-type.business")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="createItem(ContractorTypes.INDIVIDUAL)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-account</v-icon>
                <span class="body-2">{{
                  $t("contractors.contractor-type.individual")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template slot="search">
      <Search></Search>
    </template>
    <template slot="content">
      <List
        @updateTotalUnpaidServiceFee="handleUpdateTotalUnpaidServiceFee"
        @updateHasAnyItemWithZeroTotalPayment="handleUpdateHasAnyItemWithZeroTotalPayment"
        @editItem="handleEditItem"
        @deleteItem="handleOpenDeleteModal"
        @getTable="handleGetTable"
      ></List>
      <AddOrEdit
        v-if="openAddOrEditModal"
        :modal="openAddOrEditModal"
        @closeModal="handleCloseAddOrEditModal"
        @submitModal="handleSubmitAddOrEditModal"
        :contractorType="selectedContractorType"
        :item="selectedItem"
      ></AddOrEdit>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :item="selectedItem"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
      ></Delete>
      <Payment
        v-if="openPaymentModal && clientSecret"
        :modal="openPaymentModal"
        :paymentAmount="paymentAmount"
        :clientSecret="clientSecret"
        @closeModal="handleClosePaymentModal"
        @submitModal="handleSubmitPaymentModal"
      ></Payment>
    </template>
  </d-page>
</template>

<script>
import Search from "./Search.vue";
import List from "./List.vue";
import AddOrEdit from "./AddOrEdit.vue";
import Delete from "./Delete.vue";
import Payment from "./Payment.vue";
import ContractorTypes from "@/shared/enums/ContractorTypes";
import { mapActions, mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import CreateStripePaymentIntentForMyCurrentClientContractorsResultType from "@/shared/enums/CreateStripePaymentIntentForMyCurrentClientContractorsResultType";

export default {
  components: { Search, List, AddOrEdit, Delete, Payment },
  data() {
    return {
      selectedContractorType: null,
      openAddOrEditModal: false,
      openDeleteModal: false,
      openPaymentModal: false,
      ContractorTypes,
      searchToggle: false,
      totalUnpaidServiceFee: 0,
      table: null,
      loading: {
        export: false,
        payAndSubmit: false,
      },
      clientSecret: null,
      paymentAmount: null,
      selectedItem: null,
      selectedTaxYear: this.$constants.CURRENT_TAX_YEAR,
      hasAnyItemWithZeroTotalPayment: false,
    };
  },
  watch: {
    selectedTaxYear(val) {
      this.setSearchParams({
        ...this.searchParams,
        year: val
      });
      this.$eventBus.$emit("fetchContractors");
    }
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
      loggedInUser: "Auth/getLoggedInUser",
    }),
    isExportGranted() {
      return (
        authorizationUtils.isGranted("contractor:export") &&
        (!authorizationUtils.isEmployee() || this.loggedInUser.isTaxEmployee)
      );
    },
    taxYears() {
      return this.$constants.TAX_YEARS;
    }
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams"
    }),
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchContractors", true);
      this.selectedTaxYear = this.$constants.CURRENT_TAX_YEAR;
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchContractors");
    },
    createItem(contractorType) {
      this.selectedItem = null;
      this.selectedContractorType = contractorType;
      this.openAddOrEditModal = true;
    },
    handleEditItem(item = null) {
      this.selectedItem = item;
      this.selectedContractorType = item.contractorType.id;
      this.openAddOrEditModal = true;
    },
    handleCloseAddOrEditModal() {
      this.selectedItem = null;
      this.selectedContractorType = null;
      this.openAddOrEditModal = false;
    },
    handleSubmitAddOrEditModal() {
      this.$eventBus.$emit("fetchContractors");
    },
    handleUpdateTotalUnpaidServiceFee(fee = 0) {
      this.totalUnpaidServiceFee = fee;
    },
    handleUpdateHasAnyItemWithZeroTotalPayment(result) {
      this.hasAnyItemWithZeroTotalPayment = result;
    },
    handleOpenDeleteModal(item = null) {
      this.openDeleteModal = true;
      this.selectedItem = item;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchContractors");
    },
    handleGetTable(value) {
      this.table = value;
    },
    exportContractors() {
      this.loading.export = true;

      window.API.exportContractors({
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("contractors.export.alert.exported"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("contractors.export.alert.could-not-export"),
          });
        })
        .finally(() => {
          this.loading.export = false;
        });
    },
    handleOpenPaymentModal() {
      this.loading.payAndSubmit = true;

      window.API.createStripePaymentIntentForMyCurrentClientContractors()
        .then((response) => {
          if (
            response.resultType ===
            CreateStripePaymentIntentForMyCurrentClientContractorsResultType.SUCCESS
          ) {
            this.clientSecret = response.stripePaymentClientSecret;
            this.paymentAmount = response.paymentAmount;
            this.openPaymentModal = true;
          } else if (
            response.resultType ===
            CreateStripePaymentIntentForMyCurrentClientContractorsResultType.CLIENT_HAS_NO_UNPAID_CONTRACTOR
          ) {
            this.$eventBus.$emit("notification", {
              type: "info",
              message: this.$t(
                "contractors.payment.alert.client-has-no-unpaid-contractor"
              ),
            });
            this.$eventBus.$emit("fetchContractors");
          } else if (
            response.resultType ===
            CreateStripePaymentIntentForMyCurrentClientContractorsResultType.CLIENT_HAS_PROCESSING_OR_SUCCEED_PAYMENT_INTENT
          ) {
            this.$eventBus.$emit("notification", {
              type: "info",
              message: this.$t(
                "contractors.payment.alert.client-has-processing-or-succeed-payment-intent"
              ),
            });
          }
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "contractors.payment.alert.could-not-create-payment-intent"
            ),
          });
        })
        .finally(() => {
          this.loading.payAndSubmit = false;
        });
    },
    handleClosePaymentModal() {
      this.openPaymentModal = false;
    },
    handleSubmitPaymentModal() {
      this.$eventBus.$emit("fetchContractors");
    },
  },
};
</script>
