<template>
  <d-modal
    :title="$t('documents.share.title')"
    :saveButtonText="$t('documents.share.button.share')"
    :modal="modal"
    @closeModal="handleCloseModal"
    @submitModal="handleSubmitModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitModal">
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('documents.share.form.recipientEmailAddress')"
              v-model.trim="form.recipientEmailAddress"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.recipientEmailAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  recipientEmailAddress: null,
};
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      isSubmitting: false,
      rules: {
        recipientEmailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("documents.share.rule.email-address.invalid"),
        ],
      },
    };
  },
  methods: {
    async handleSubmitModal() {
      if (!this.$refs.form.validate()) return;
      this.isSubmitting = true;

      await window.API.shareDocument(this.item.id, this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("documents.share.alert.sent", {
              displayName: this.form.displayName,
            }),
          });
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("documents.share.alert.could-not-send"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
  },
};
</script>
