export default {
  data() {
    return {
      defaultIndustryList: {
        value: "other",
        displayText: "Other",
      },
    };
  },
};
