<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.contract.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.contract.date") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{
                item.contractDate
                  ? UTCToLocalTime(item.contractDate, "MM/DD/YYYY")
                  : fillEmptyData()
              }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.contract.document") }}:
            </span>
            <v-chip
              v-if="item.contract"
              color="primary"
              label
              @click="previewLeadContract"
            >
              <v-progress-circular
                v-if="loading.preview"
                indeterminate
                color="white"
                size="20"
                class="mr-2"
              ></v-progress-circular>
              <v-icon v-else left size="20"> mdi-file-document </v-icon>
              {{ item.contract.fileName }}
            </v-chip>
            <span
              class="body-2 font-weight-regular grey--text text--darken-3"
              v-else
              >{{ fillEmptyData() }}</span
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: {
        preview: false,
      },
    };
  },
  methods: {
    async previewLeadContract() {
      try {
        this.loading.preview = true;
        const response = await window.API.previewLeadContract(this.item.id);

        if (response.data) {
          this.previewFile(response);
        }
      } finally {
        this.loading.preview = false;
      }
    },
  },
};
</script>
