import StaticRoles from "@/shared/enums/StaticRoles";
import store from "@/store";

const AuthorizationUtils = {
  isGranted(askedPermission) {
    try {
      if (!askedPermission) return false;

      if (askedPermission === "*") return true;

      let result = false;
      const permissions = store.getters["Auth/getUserPermissions"];

      if (!permissions && permissions.length === 0) return result;

      const isOrCombination = askedPermission.includes("|");
      if (isOrCombination) {
        const askedPermissions = askedPermission.split("|").map((perm) => {
          return `${process.env.VUE_APP_ROOT_PERMISSION}:${perm}`;
        });

        result = !!permissions.find((perm) =>
          askedPermissions.some(
            (askedPerm) =>
              askedPerm.startsWith(`${perm}:`) || perm === askedPerm
          )
        );
      } else {
        const permission = `${process.env.VUE_APP_ROOT_PERMISSION}:${askedPermission}`;

        result = !!permissions.find(
          (perm) => permission.startsWith(`${perm}:`) || perm === permission
        );
      }

      return result;
    } catch (error) {
      return false;
    }
  },
  isInRole(roleName) {
    const loggedInUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_DATA_KEY)
    );

    if (!loggedInUser || !loggedInUser.roles) return false;

    return loggedInUser.roles.includes(roleName);
  },
  isAdmin() {
    return this.isInRole(StaticRoles.ADMIN);
  },
  isAdvisor() {
    return this.isInRole(StaticRoles.ADVISOR);
  },
  isSupervisor() {
    return this.isInRole(StaticRoles.SUPERVISOR);
  },
  isEmployee() {
    return this.isInRole(StaticRoles.EMPLOYEE);
  },
  isClient() {
    return this.isInRole(StaticRoles.CLIENT);
  },
};

export default AuthorizationUtils;
