<template>
  <d-modal
    :title="editMode ? $t('users.edit.title') : $t('users.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.create.form.first-name')"
              v-model.trim="form.firstName"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.create.form.last-name')"
              v-model.trim="form.lastName"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.lastName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.create.form.email-address')"
              v-model.trim="form.emailAddress"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.emailAddress"
              :error="errorMessages.emailAddress.length > 0"
              :error-messages="errorMessages.emailAddress"
              @blur="checkEmailAddressAvailability"
              :loading="loading.emailAddress"
            ></v-text-field>
          </v-col>
          <v-col v-if="editMode" cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.edit.form.username')"
              v-model.trim="form.username"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.username"
              :error="errorMessages.username.length > 0"
              :error-messages="errorMessages.username"
              @blur="checkUsernameAvailability"
              :loading="loading.username"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="!editMode || !isInRole(StaticRoles.CLIENT)"
            cols="12"
            sm="6"
            md="6"
            class="mb-3"
          >
            <v-autocomplete
              :label="$t('users.create.form.role')"
              v-model="form.roleId"
              :items="roles"
              item-text="displayName"
              item-value="id"
              filled
              hide-details="auto"
              :rules="rules.role"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="
              isInRole(StaticRoles.ADVISOR) ||
              isInRole(StaticRoles.CLIENT) ||
              isInRole(StaticRoles.EMPLOYEE)
            "
            :cols="editMode ? '6' : '12'"
            class="pa-0 ma-0 mb-3"
          >
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                :sm="editMode ? '12' : '6'"
                :md="editMode ? '12' : '6'"
                v-if="isInRole(StaticRoles.ADVISOR)"
              >
                <v-autocomplete
                  :label="$t('users.create.form.supervisor')"
                  v-model="form.supervisorUserId"
                  :items="supervisors"
                  item-text="displayText"
                  item-value="value"
                  filled
                  hide-details="auto"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                :sm="editMode ? '12' : '6'"
                :md="editMode ? '12' : '6'"
                v-if="isInRole(StaticRoles.CLIENT)"
              >
                <v-autocomplete
                  :label="$t('users.create.form.advisor')"
                  v-model="form.advisorUserId"
                  :items="advisors"
                  item-text="displayText"
                  item-value="value"
                  filled
                  hide-details="auto"
                  :rules="rules.advisorUser"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                :sm="editMode ? '12' : '6'"
                :md="editMode ? '12' : '6'"
                v-if="isInRole(StaticRoles.EMPLOYEE)"
              >
                <v-autocomplete
                  :label="$t('users.create.form.department')"
                  v-model="form.departmentId"
                  :items="departments"
                  item-text="displayText"
                  item-value="value"
                  filled
                  hide-details="auto"
                  :rules="rules.department"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.create.form.phone-number')"
              v-model.trim="form.phoneNumber"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.phoneNumber"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            class="mb-3"
            v-if="!isInRole(StaticRoles.CLIENT)"
          >
            <v-text-field
              :label="$t('users.create.form.extension')"
              v-model.trim="form.extension"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.extension"
              v-mask="'####'"
            ></v-text-field>
          </v-col>
          <v-col v-if="!editMode" cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('users.create.form.password')"
              v-model.trim="form.password"
              hide-details="auto"
              filled
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordVisible)"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="rules.password"
            ></v-text-field>
          </v-col>
          <v-col v-if="!editMode" cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('users.create.form.confirm-password')"
              v-model.trim="form.confirmPassword"
              hide-details="auto"
              filled
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isConfirmPasswordVisible)"
              @click:append="
                isConfirmPasswordVisible = !isConfirmPasswordVisible
              "
              :rules="rules.confirmPassword"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import StaticRoles from "@/shared/enums/StaticRoles";
const initialForm = {
  firstName: null,
  lastName: null,
  emailAddress: null,
  roleId: null,
  supervisorUserId: null,
  advisorUserId: null,
  departmentId: null,
  phoneNumber: null,
  extension: null,
};
const initialTempValues = {
  emailAddress: null,
  username: null,
};
const initialErrorMessages = {
  emailAddress: [],
  username: [],
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      StaticRoles,
      form: { ...initialForm },
      loading: {
        emailAddress: false,
        username: false,
        page: false,
      },
      errorMessages: { ...initialErrorMessages },
      tempValues: { ...initialTempValues },
      isFormValid: false,
      isSubmitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      rules: {
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        emailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("users.create.rule.email-address.invalid"),
        ],
        username: [(v) => !!v || this.$t("defaults.rule.required")],
        phoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        extension: [
          (v) => {
            if (!v) return true;

            if (v && /^\d+$/.test(v) && v.length === 4) return true;

            return this.$t("users.create.rule.extension.invalid");
          },
        ],
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("users.create.rule.password.invalid"),
        ],
        confirmPassword: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => {
            if (!this.form.password) return true;

            if (v === this.form.password) return true;

            return this.$t("users.create.rule.confirm-password.not-match");
          },
        ],
        advisorUser: [(v) => !!v || this.$t("defaults.rule.required")],
        department: [(v) => !!v || this.$t("defaults.rule.required")],
        role: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      editMode: false,
      roles: [],
      advisors: [],
      supervisors: [],
      departments: [],
    };
  },
  methods: {
    isInRole(roleName) {
      if (!this.form.roleId || !roleName) return false;

      return this.roles.some(
        (role) => role.id === this.form.roleId && role.name == roleName
      );
    },
    getPasswordInputIcon(isVisible) {
      return isVisible ? "mdi-eye-off" : "mdi-eye";
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.errorMessages = { ...initialErrorMessages };
      this.tempValues = { ...initialTempValues };
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    createItem() {
      this.isSubmitting = true;

      const newItem = {
        ...this.form,
        roles: [this.form.roleId],
      };

      window.API.insertUser(newItem)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("users.create.alert.created", {
              fullName: `${newItem.firstName} ${newItem.lastName}`,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("users.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    editItem() {
      this.isSubmitting = true;

      const editedItem = {
        ...this.form,
        roles: [this.form.roleId],
      };

      window.API.editUser(editedItem.id, editedItem)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("users.edit.alert.updated", {
              fullName: `${editedItem.firstName} ${editedItem.lastName}`,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("roles.edit.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async handleSubmitModal() {
      await this.checkEmailAddressAvailability();
      await this.checkUsernameAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.form.id) {
          await this.editItem();
        } else {
          await this.createItem();
        }
      }
    },
    async checkEmailAddressAvailability() {
      const { emailAddress } = this.form;

      if (!emailAddress) return;

      if (this.tempValues.emailAddress == emailAddress && this.editMode) {
        this.errorMessages.emailAddress = [];
        return;
      }

      this.loading.emailAddress = true;
      window.API.checkEmailAddressAvailability(emailAddress)
        .then((response) => {
          if (!response) {
            this.errorMessages.emailAddress = [
              this.$t("users.create.rule.email-address.is-taken", {
                emailAddress: emailAddress,
              }),
            ];
            return;
          }
          this.errorMessages.emailAddress = [];
        })
        .finally(() => (this.loading.emailAddress = false));
    },
    async checkUsernameAvailability() {
      const { username } = this.form;

      if (!username) return;

      if (this.tempValues.username == username && this.editMode) {
        this.errorMessages.username = [];
        return;
      }

      this.loading.username = true;
      window.API.checkUsernameAvailability(username)
        .then((response) => {
          if (!response) {
            this.errorMessages.username = [
              this.$t("users.edit.rule.username.is-taken", {
                username: username,
              }),
            ];
            return;
          }
          this.errorMessages.username = [];
        })
        .finally(() => (this.loading.username = false));
    },
    fetchAllRoles() {
      window.API.fetchAllRoles()
        .then((response) => {
          this.roles = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllAdvisors() {
      window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllSupervisors() {
      window.API.fetchAllSupervisors()
        .then((response) => {
          this.supervisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllDepartments() {
      window.API.fetchAllDepartments()
        .then((response) => {
          this.departments = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    async getItem(item) {
      if (item && item.id > 0) {
        this.loading.page = true;
        this.editMode = true;
        await window.API.getUserById(item.id)
          .then((response) => {
            const { roles, ...rest } = response;
            this.form = {
              ...rest,
              departmentId: rest.departmentId?.toString(),
              supervisorUserId: rest.supervisorUserId?.toString(),
              advisorUserId: rest.advisorUserId?.toString(),
              roleId: (roles || [])[0],
            };

            if (!this.isInRole(StaticRoles.CLIENT)) {
              this.roles = this.roles.filter(
                (role) => role.name != StaticRoles.CLIENT
              );
            }

            this.tempValues.emailAddress = rest.emailAddress;
            this.tempValues.username = rest.username;
          })
          .catch(() => this.showFetchRequestErrorMessage())
          .finally(() => (this.loading.page = false));
      }
    },
    resetRoleRelatedFields() {
      this.form = {
        ...this.form,
        advisorUserId: this.isInRole(StaticRoles.CLIENT)
          ? this.form.advisorUserId
          : null,
        supervisorUserId: this.isInRole(StaticRoles.ADVISOR)
          ? this.form.supervisorUserId
          : null,
        departmentId: this.isInRole(StaticRoles.EMPLOYEE)
          ? this.form.departmentId
          : null,
        extension: !this.isInRole(StaticRoles.CLIENT)
          ? this.form.extension
          : null,
      };
    },
  },
  watch: {
    "form.roleId": {
      handler() {
        this.resetRoleRelatedFields();
      },
    },
  },
  async mounted() {
    await this.fetchAllRoles();
    await this.fetchAllAdvisors();
    await this.fetchAllSupervisors();
    await this.fetchAllDepartments();
    await this.getItem(this.item);
  },
};
</script>
