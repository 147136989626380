<template>
  <div class="customer-onboarding-page--stepper">
    <v-stepper elevation="0" v-model="currentStep" flat alt-labels>
      <v-stepper-header class="elevation-0">
        <v-stepper-step
          :complete="currentStep > 1"
          step="1"
          class="text-center"
        >
          <p class="ma-0 text-center font-weight-medium">
            {{ $t(`onboarding.general-info.title`) }}
          </p>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 2"
          step="2"
          class="text-center"
        >
          <p class="ma-0 text-center font-weight-medium">
            {{ $t(`onboarding.intake-questions.title`) }}
          </p>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 3"
          step="3"
          class="text-center"
        >
          <p class="ma-0 text-center font-weight-medium">
            {{ $t(`onboarding.quote.title`) }}
          </p>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 4"
          step="4"
          class="text-center"
        >
          <p class="ma-0 text-center font-weight-medium">
            {{ $t(`onboarding.contract.title`) }}
          </p>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 5"
          step="5"
          class="text-center"
        >
          <p class="ma-0 text-center font-weight-medium">
            {{ $t(`onboarding.create-account.title`) }}
          </p>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items class="mt-3">
        <v-stepper-content step="1" class="ma-0 pa-0">
          <GeneralInfo
            v-if="currentStep == 1"
            @submitStep="handleSubmitGeneralInfo"
            :isSubmitting="isSubmitting.generalInfo"
            :editMode="editMode"
            :formData="form"
          />
        </v-stepper-content>

        <v-stepper-content step="2" class="ma-0 pa-0">
          <IntakeQuestions
            v-if="currentStep == 2"
            @submitStep="handleSubmitIntakeQuestions"
            @previousStep="currentStep--"
            :isSubmitting="isSubmitting.intakeQuestions"
            :formData="form"
          />
        </v-stepper-content>

        <v-stepper-content step="3" class="ma-0 pa-0">
          <Quote
            v-if="currentStep == 3"
            @submitStep="handleSubmitQuote"
            @previousStep="currentStep--"
            :idHash="this.idHash"
            :isSubmitting="isSubmitting.quote"
          />
        </v-stepper-content>

        <v-stepper-content step="4" class="ma-0 pa-0">
          <Contract
            v-if="currentStep == 4"
            @submitStep="handleSubmitContract"
            @previousStep="currentStep--"
            :idHash="this.idHash"
            :formData="form"
            :isSubmitting="isSubmitting.contract"
          />
        </v-stepper-content>

        <v-stepper-content step="5" class="ma-0 pa-0">
          <CreateAccount
            v-if="currentStep == 5"
            @submitStep="handleSubmitCreateAccount"
            :isSubmitting="isSubmitting.createAccount"
            :formData="form"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import GeneralInfo from "./GeneralInfo.vue";
import IntakeQuestions from "./IntakeQuestions.vue";
import Quote from "./Quote.vue";
import Contract from "./Contract.vue";
import CreateAccount from "./CreateAccount.vue";
import LeadStatuses from "@/shared/enums/LeadStatuses";
import { mapGetters } from "vuex";
import authenticationMixins from "@/mixins/authenticationMixins";

export default {
  mixins: [authenticationMixins],
  components: {
    GeneralInfo,
    IntakeQuestions,
    Quote,
    Contract,
    CreateAccount,
  },
  data() {
    return {
      currentStep: 1,
      form: {},
      isSubmitting: {
        generalInfo: false,
        intakeQuestions: false,
        quote: false,
        contract: false,
        createAccount: false,
      },
      idHash: null,
      editMode: false,
      LeadStatuses,
    };
  },
  watch: {
    "$route.params.idHash": {
      handler() {
        this.idHash = this.$route.params.idHash
          ? encodeURIComponent(this.$route.params.idHash)
          : null;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "Auth/isLoggedIn",
    }),
  },
  methods: {
    async handleSubmitGeneralInfo(form) {
      this.isSubmitting.generalInfo = true;
      if (this.editMode) {
        await window.API.updateLeadGeneralInfo(this.idHash, form)
          .then(async () => {
            await this.getLead(this.idHash);
            this.currentStep++;
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("onboarding.general-info.alert.could-not-post"),
            });
          })
          .finally(() => (this.isSubmitting.generalInfo = false));
      } else {
        await window.API.insertLead(form)
          .then(async (response) => {
            this.$router.push({
              name: "ContinueCustomerOnboarding",
              params: {
                idHash: response,
              },
            });
            await this.getLead(encodeURIComponent(response));
            this.currentStep++;
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("onboarding.general-info.alert.could-not-post"),
            });
          })
          .finally(() => (this.isSubmitting.generalInfo = false));
      }
    },
    async handleSubmitIntakeQuestions(form) {
      this.isSubmitting.intakeQuestions = true;

      await window.API.updateLeadIntakeInterviewAnswers(this.idHash, form)
        .then(async () => {
          await this.getLead(this.idHash);
          this.currentStep++;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "onboarding.intake-questions.alert.could-not-post"
            ),
          });
        })
        .finally(() => (this.isSubmitting.intakeQuestions = false));
    },
    async handleSubmitQuote() {
      this.isSubmitting.quote = true;

      await window.API.updateLeadQuote(this.idHash)
        .then(async () => {
          await this.getLead(this.idHash);
          this.currentStep++;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("onboarding.quote.alert.could-not-post"),
          });
        })
        .finally(() => (this.isSubmitting.quote = false));
    },
    async handleSubmitContract() {
      this.isSubmitting.contract = true;

      await window.API.updateLeadContract(this.idHash)
        .then(async () => {
          await this.getLead(this.idHash);
          this.currentStep++;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("onboarding.contract.alert.could-not-post"),
          });
        })
        .finally(() => (this.isSubmitting.contract = false));
    },
    async handleSubmitCreateAccount(form) {
      this.isSubmitting.createAccount = true;

      await window.API.updateLeadClientInformation(this.idHash, form)
        .then(async () => {
          if (this.isLoggedIn) {
            await this.globalLogOut();
          }

          const credentials = {
            username: form.emailAddress,
            password: form.password,
          };

          await this.globalLogin(credentials);
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "onboarding.client-information.alert.could-not-post"
            ),
          });
        })
        .finally(() => (this.isSubmitting.createAccount = false));
    },
    getCurrentStep(status) {
      switch (status) {
        case LeadStatuses.GENERAL_INFORMATION:
          this.currentStep = 2;
          break;
        case LeadStatuses.INTAKE_QUESTIONS:
          this.currentStep = 3;
          break;
        case LeadStatuses.QUOTE:
          this.currentStep = 4;
          break;
        case LeadStatuses.CONTRACT:
          this.currentStep = 5;
          break;
        case LeadStatuses.CREATE_ACCOUNT:
          this.currentStep = 6;
          break;
        default:
          this.currentStep = 1;
      }
    },
    async getLead(idHash) {
      await window.API.getLeadByIdHash(idHash)
        .then((response) => {
          if (response.status >= LeadStatuses.CREATE_ACCOUNT) {
            this.$router
              .push({
                name: "Login",
              })
              .catch(() => {});
          }
          this.form = response;
          this.editMode = true;
        })
        .catch(() => {
          this.$router.push({
            name: "StartCustomerOnboarding",
          });
        });
    },
  },
  async created() {
    if (this.idHash) {
      await this.getLead(this.idHash);
      await this.getCurrentStep(this.form.status);
    }
  },
};
</script>
