<template>
  <v-btn
    depressed
    small
    :loading="loading"
    :disabled="isDisabled"
    @click="getPlaidLinkToken"
  >
    <span class="subtitle-2 text-none">
      {{ $t("reconciliations.list.actions.add") }}
    </span>
    <v-icon right> mdi-plus </v-icon>
  </v-btn>
</template>

<script>
import PlaidAccountSubtypes from "@/shared/enums/PlaidAccountSubtypes.js";

export default {
  props: {
    subtype: {
      type: [String, Number],
      required: true,
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      plaid: null,
      linkHandler: null,
      linkToken: null,
      loading: false,
      products: ["transactions"],
    };
  },
  watch: {
    loading: {
      handler(val) {
        this.$emit("isLoading", val);
      },
    },
  },
  computed: {
    isLoading() {
      if (this.subtype == PlaidAccountSubtypes.CHECKING) {
        return this.loading.checkingAccount;
      }

      if (this.subtype == PlaidAccountSubtypes.CREDIT_CARD) {
        return this.loading.creditCardAccount;
      }

      return false;
    },
  },
  methods: {
    async getPlaidLinkToken() {
      try {
        this.loading = true;

        if (this.subtype == PlaidAccountSubtypes.CHECKING) {
          const { linkToken } = await this.createLinkTokenForCheckingAccount();
          this.linkToken = linkToken;
        } else if (this.subtype == PlaidAccountSubtypes.CREDIT_CARD) {
          const { linkToken } =
            await this.createLinkTokenForCreditCardAccount();
          this.linkToken = linkToken;
        }

        await this.openPlaidLink();
      } finally {
        this.loading = false;
      }
    },
    async openPlaidLink() {
      if (this.plaid != null) {
        // destroy link handler to prevent stacking of iframes
        if (this.linkHandler != null) {
          this.linkHandler.destroy();
          this.linkHandler = null;
        }

        this.linkHandler = await this.plaid.create({
          env: process.env.VUE_APP_PLAID_ENV,
          token: this.linkToken,
          products: this.products,
          onSuccess: (public_token, metadata) => {
            this.$emit("onSuccess", public_token, metadata);
          },
          onLoad: () => {
            this.$emit("onLoad");
          },
          onExit: (err, metadata) => {
            this.$emit("onExit", err, metadata);
          },
          onEvent: (eventName, metadata) => {
            this.$emit("onEvent", eventName, metadata);
          },
        });
        this.linkHandler.open();
      }
    },
    async createLinkTokenForCheckingAccount() {
      return await window.API.createLinkTokenForCheckingAccount({
        clientId: this.clientId,
      });
    },
    async createLinkTokenForCreditCardAccount() {
      return await window.API.createLinkTokenForCreditCardAccount({
        clientId: this.clientId,
      });
    },
  },
  mounted() {
    // Only download the script if not already done
    if (window.Plaid) {
      this.plaid = window.Plaid;
      return;
    }
    let linkScript = document.createElement("script");
    linkScript.async = true;
    linkScript.setAttribute(
      "src",
      "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
    );
    document.head.appendChild(linkScript);
    linkScript.onload = () => {
      this.plaid = window.Plaid;
    };
  },
};
</script>
