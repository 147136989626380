<template>
  <v-menu
    offset-y
    v-if="myCurrentClient"
    bottom
    nudge-bottom="4"
    content-class="elevation-0"
    max-height="326"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        depressed
        outlined
        color="gray lighten-3"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <div class="d-flex align-center">
          <v-avatar color="primary" size="20" class="mr-2">
            <v-icon x-small dark>
              {{
                myCurrentClient.clientType === ClientTypes.BUSINESS
                  ? "mdi-briefcase"
                  : "mdi-account"
              }}
            </v-icon>
          </v-avatar>

          <span
            class="gray--text text--darken-3 subtitle-2 font-weight-medium text-none"
          >
            {{ myCurrentClient.name }}
          </span>
          <v-icon color="gray darken-2" class="ml-2"> mdi-chevron-down </v-icon>
        </div>
      </v-btn>
    </template>
    <v-card outlined>
      <v-list>
        <v-list-item
          v-for="item in myClients"
          :key="item.id"
          @click="updateMyCurrentClient(item)"
          :disabled="myCurrentClient.id == item.id"
          :class="myCurrentClient.id == item.id ? 'light-blue lighten-5' : ''"
        >
          <div class="d-flex align-center">
            <v-icon
              size="24"
              :color="myCurrentClient.id == item.id ? 'primary' : 'gray'"
              class="mr-3"
            >
              {{
                item.clientType === ClientTypes.BUSINESS
                  ? "mdi-briefcase"
                  : "mdi-account"
              }}
            </v-icon>
            <span
              class="body-2"
              :class="
                myCurrentClient.id == item.id
                  ? 'primary--text'
                  : 'gray--text text--darken-3'
              "
            >
              {{ item.name }}
            </span>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  data() {
    return {
      myClients: [],
      ClientTypes,
    };
  },
  computed: {
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
  },
  methods: {
    ...mapActions({
      setMyCurrentClient: "Auth/setMyCurrentClient",
    }),
    getAllMyClients() {
      window.API.getAllMyClients()
        .then((response) => {
          this.myClients = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    updateMyCurrentClient(item) {
      window.API.updateMyCurrentClient({ clientId: item.id }).then(() => {
        this.$eventBus.$emit("myCurrentClientUpdated");
        this.setMyCurrentClient(item);
        window.location.reload();
      });
    },
  },
  mounted() {
    this.getAllMyClients();
  },
};
</script>
