<template>
  <CompanyLayout>
    <template slot="sidebar">
      <OrderSummary
        :states="states"
        :businessEntityType="form.businessEntityType"
        :selectedStateId="form.stateId"
        :type="CompanyFormationTypes.RESIDENT"
        @totalPayment="handleTotalPayment"
        v-model="form.couponCode"
      />
    </template>
    <template slot="content">
      <div class="border-bottom px-6 py-4">
        <h1 class="text-h6 gray--text text--darken-3">
          {{ $t("company-formation.resident.page-heading") }}
        </h1>
      </div>

      <div class="pa-4 company-formation__page--stepper">
        <v-stepper elevation="0" v-model="currentStep" flat alt-labels>
          <v-stepper-header class="elevation-0">
            <template v-for="(n, index) in 4">
              <template v-if="n > 0">
                <v-stepper-step
                  :key="n + '_step_' + index"
                  class="text-center"
                  :complete="currentStep > n"
                  :step="n"
                >
                  <p class="ma-0 text-center font-weight-medium">
                    {{ $t(`company-formation.resident.step-${n}.title`) }}
                  </p>
                </v-stepper-step>

                <v-divider
                  v-if="n < 4"
                  :key="n + '_divider_' + index"
                ></v-divider>
              </template>
            </template>
          </v-stepper-header>

          <v-stepper-items class="mt-3">
            <v-stepper-content step="1" class="ma-0 pa-0">
              <div class="px-3">
                <Step1
                  :states="states"
                  @submitStep="handleSubmitStep1"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <div class="px-3">
                <Step2
                  :selectedStateId="form.stateId"
                  @submitStep="handleSubmitStep2"
                  @cancelStep="handleCancelStep2"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="ma-0 pa-0">
              <div class="px-3">
                <Step3
                  :isLLC="isLLC"
                  :isCorporation="isCorporation"
                  :isSubmitting="loading.step3"
                  @submitStep="handleSubmitStep3"
                  @cancelStep="handleCancelStep3"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="4" class="ma-0 pa-0">
              <div class="px-3" v-if="clientSecret">
                <Payment
                  :fee="totalPayment"
                  :clientSecret="clientSecret"
                  @submitPayment="handleSubmitPayment"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="5" class="ma-0 pa-0">
              <div class="px-3">
                <Success />
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </template>
  </CompanyLayout>
</template>

<script>
import companyFormationMixins from "@/mixins/companyFormationMixins";
import CompanyLayout from "../layout.vue";
import CompanyFormationTypes from "@/shared/enums/CompanyFormationTypes";
import BusinessEntityTypes from "@/shared/enums/BusinessEntityTypes";
import OrderSummary from "./OrderSummary.vue";
import Step1 from "./Step-1.vue";
import Step2 from "./Step-2.vue";
import Step3 from "./Step-3.vue";
import Payment from "../Payment.vue";
import Success from "../Success.vue";

export default {
  components: {
    CompanyLayout,
    OrderSummary,
    Step1,
    Step2,
    Step3,
    Payment,
    Success,
  },
  data() {
    return {
      CompanyFormationTypes,
      BusinessEntityTypes,
      currentStep: 1,
      loading: {
        step3: false,
      },
      form: {
        businessEntityType: "",
        stateId: null,
        couponCode: null,
      },
      states: [],
      totalPayment: 0,
      clientSecret: null,
    };
  },
  mixins: [companyFormationMixins],
  computed: {
    isLLC() {
      return this.form.businessEntityType == BusinessEntityTypes.LLC;
    },
    isCorporation() {
      return this.form.businessEntityType == BusinessEntityTypes.Corporation;
    },
  },
  methods: {
    handleSubmitStep1() {
      this.currentStep = 2;
    },
    handleFormUpdate(form) {
      this.form = { ...this.form, ...form };
    },
    handleSubmitStep2() {
      this.currentStep = 3;
    },
    handleCancelStep2() {
      this.currentStep = 1;
    },
    handleSubmitStep3() {
      if (this.form.industryId == this.defaultIndustryList.value) {
        this.form.industryId = null;
      }

      this.postCompanyResident(this.form);
    },
    handleCancelStep3() {
      this.currentStep = 2;
    },
    handleTotalPayment(total) {
      this.totalPayment = total;
    },
    postCompanyResident(form) {
      this.loading.step3 = true;

      window.API.postCompanyResident(form)
        .then((res) => {
          this.clientSecret = res.stripePaymentClientSecret;
          this.currentStep = 4;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("company-formation.resident.alert.could-not-post"),
          });
        })
        .finally(() => (this.loading.step3 = false));
    },
    handleSubmitPayment() {
      this.currentStep = 5;
    },
    async fetchAllCompanyStates() {
      this.states = await window.API.fetchAllCompanyStates();
    },
  },
  async created() {
    await this.fetchAllCompanyStates();
  },
};
</script>
