<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="filteredHeaders"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small class="my-3" color="primary" outlined>
          {{ getInvitationTypeText(item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.creationTime`]="{ item }">
        <span>
          {{ UTCToLocalTime(item.creationTime, "hh:mm, dddd, MMMM D, YYYY") }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="getInvitationStatusText(item.status)"
          small
          class="my-3"
          :color="getInvitationStatus(item.status).color"
          :text-color="getInvitationStatus(item.status).textColor"
        >
          {{ getInvitationStatusText(item.status) }}
        </v-chip>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('invitations.list.empty-page')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import InvitationStatuses from "@/shared/enums/InvitationStatuses";
import InvitationTypes from "@/shared/enums/InvitationTypes";
import authorizationUtils from "@/utils/authorization.js";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("invitations.list.table.headers.sender"),
          value: "sender",
          sortable: false,
        },
        {
          text: vm.$t("invitations.list.table.headers.recipient"),
          value: "recipient",
          sortable: false,
        },
        {
          text: vm.$t("invitations.list.table.headers.type"),
          value: "type",
          sortable: false,
        },
        {
          text: vm.$t("invitations.list.table.headers.client"),
          value: "client",
          sortable: false,
        },
        {
          text: vm.$t("invitations.list.table.headers.creation-time"),
          value: "creationTime",
          sortable: false,
        },
        {
          text: vm.$t("invitations.list.table.headers.status"),
          value: "status",
          sortable: false,
        },
      ],
      initialSearchParams: {
        type: null,
        status: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    filteredHeaders() {
      if (authorizationUtils.isAdvisor())
        return this.headers.filter(
          (header) => !["sender", "type", "client"].includes(header.value)
        );
      if (authorizationUtils.isClient())
        return this.headers.filter(
          (header) => !["sender", "type"].includes(header.value)
        );
      return this.headers;
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;

      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchInvitations({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getInvitationStatus(status) {
      switch (status) {
        case InvitationStatuses.SENT:
          return {
            color: "light-blue lighten-5",
            textColor: "primary",
          };
        case InvitationStatuses.RECEIVED:
          return {
            color: "orange lighten-5",
            textColor: "warning",
          };
        case InvitationStatuses.REGISTERED:
          return {
            color: "teal lighten-5",
            textColor: "success",
          };
        default:
          return null;
      }
    },
    getInvitationStatusText(status) {
      switch (status) {
        case InvitationStatuses.SENT:
          return this.$t("invitations.list.table.status.sent");
        case InvitationStatuses.RECEIVED:
          return this.$t("invitations.list.table.status.received");
        case InvitationStatuses.REGISTERED:
          return this.$t("invitations.list.table.status.registered");
        default:
          return null;
      }
    },
    getInvitationTypeText(type) {
      switch (type) {
        case InvitationTypes.ADVISOR_TO_CLIENT:
          return this.$t("invitations.list.table.type.advisor-to-client");
        case InvitationTypes.CLIENT_TO_COLLABORATOR:
          return this.$t("invitations.list.table.type.client-to-collaborator");
        default:
          return null;
      }
    },
  },
  created() {
    this.$eventBus.$on("fetchInvitations", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchInvitations", this.handleFetchItems);
  },
};
</script>
