<template>
  <v-main>
    <v-container fluid class="pa-0 h-100">
      <section class="tax-planning-layout h-100">
        <v-row no-gutters class="h-md-100">
          <v-col md="4" lg="3" cols="12">
            <div class="tax-planning-layout--left h-md-100">
              <div class="tax-planning-layout--logo">
                <a href="https://numbersquad.com/">
                  <img src="@/assets/images/logo-numbersquad.svg"
                /></a>
              </div>
              <div class="tax-planning-layout--cover">
                <img src="@/assets/images/tax-planning-intro.svg" />
              </div>
            </div>
          </v-col>
          <v-col md="8" lg="9" cols="12">
            <div class="tax-planning-layout--right h-md-100">
              <div class="border-bottom pa-6">
                <h1 class="text-h6 font-weight-bold">
                  {{ $t("tax-planning.page-heading") }}
                </h1>
              </div>
              <div class="px-6">
                <router-view></router-view>
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </v-main>
</template>
<script>
export default {};
</script>
