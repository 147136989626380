export function getUserProfile() {
  return window.axios
    .get("/identity/users/me")
    .then((response) => response.data);
}

export function updateUserProfile(data) {
  return window.axios
    .put("/identity/users/me", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response.data);
}

export function updateUserPassword(data) {
  return window.axios
    .patch("/identity/users/me/password", data)
    .then((response) => response.data);
}
