<template>
  <div class="login-layout__form">
    <h1 class="text-h5 font-weight-medium mb-6">
      {{ $t("register.page-heading") }}
    </h1>

    <v-form
      ref="form"
      v-model="isValidForm"
      lazy-validation
      @submit.prevent="handleFormSubmit"
    >
      <template v-if="!loading.registrationToken && form.registrationToken">
        <v-row>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.first-name')"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.firstName"
              v-model.trim="form.firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.last-name')"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.lastName"
              v-model.trim="form.lastName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.email-address')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.emailAddress"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.phone-number')"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.phoneNumber"
              v-model="form.phoneNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.password')"
              hide-details="auto"
              filled
              type="password"
              :rules="rules.password"
              :hint="$t('register.hint.password')"
              persistent-hint
              v-model.trim="form.password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('register.form.confirm-password')"
              hide-details="auto"
              filled
              type="password"
              :rules="rules[`confirm-password`]"
              persistent-hint
              v-model.trim="form.confirmPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <div class="text-left">
              <p
                class="body-2 text-decoration-none secondary--text"
                v-html="$t('register.form.terms-conditions')"
              ></p>
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="primary"
              class="text-none body-2 font-weight-medium"
              depressed
              block
              large
              :loading="loading.register"
            >
              {{ $t("register.button.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <d-loading v-else></d-loading>
    </v-form>
  </div>
</template>

<script>
import authenticationMixins from "@/mixins/authenticationMixins";

export default {
  mixins: [authenticationMixins],
  data() {
    return {
      isValidForm: true,
      rules: {
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        phoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => (!!v && v.length >= 6) || this.$t("register.rule.password"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("register.rule.password"),
        ],
        "confirm-password": [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            v === this.form.password ||
            this.$t("register.rule.confirm-password"),
        ],
      },
      form: {
        registrationToken: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        phoneNumber: null,
        password: null,
        confirmPassword: null,
      },
      loading: {
        registrationToken: false,
        register: false,
      },
    };
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.loading.register = true;

        window.API.registerClient({
          ...this.form,
        })
          .then(async () => {
            const credentials = {
              username: this.form.emailAddress,
              password: this.form.password,
            };
            await this.globalLogin(credentials);

            this.$eventBus.$emit("notification", {
              type: "success",
              message: this.$t("register.alert.created"),
            });
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("register.alert.system-error"),
            });
          })
          .finally(() => {
            this.loading.register = false;
          });
      }
    },
    isRegisterTokenUsable() {
      this.loading.registrationToken = true;
      this.form.registrationToken = null;

      window.API.receivedInvitation({
        registrationToken: this.$route.query.token,
      })
        .then((res) => {
          if (res.isValidRegistrationToken) {
            this.form.registrationToken = this.$route.query.token;
            this.form.emailAddress = res.recipientEmailAddress;
            return;
          }

          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("register.alert.used-registration-token"),
          });

          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("register.alert.system-error"),
          });

          this.$router.push({ name: "Login" });
        })
        .finally(() => {
          this.loading.registrationToken = false;
        });
    },
  },
  created() {
    this.isRegisterTokenUsable();
  },
};
</script>
