<template>
  <div class="mx-2 my-2">
    <v-row v-if="client && !loading">
      <v-col cols="12" class="mb-3">
        <v-avatar color="gray lighten-1" size="64">
          <d-auth-image
            :url="`/document/clients/${item.id}/profile-picture`"
            :width="128"
            :height="128"
            @error="handleImageError"
          ></d-auth-image>
          <span class="body-1 gray--text text--lighten-4" v-if="imageError">{{
            profileAvatar
          }}</span>
        </v-avatar>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters align="center">
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.name") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <div class="d-flex flex-nowrap align-center">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="primary" dark v-bind="attrs" v-on="on">
                    {{
                      item.clientType === ClientTypes.BUSINESS
                        ? "mdi-briefcase"
                        : "mdi-account"
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    item.clientType === ClientTypes.BUSINESS
                      ? $t("clients.client-type.business")
                      : $t("clients.client-type.personal")
                  }}
                </span>
              </v-tooltip>
              <span class="caption black--text ml-1">
                {{
                  item.clientType === ClientTypes.BUSINESS
                    ? fillEmptyData(client.name)
                    : fillEmptyData(client.fullName)
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.ssn") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.socialSecurityNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.trade-name") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.tradeName) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.tin") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.taxpayerIdentificationNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.website") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.website) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.birth-date") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.birthDate) | moment("MM.DD.YYYY") }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.email-address") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.emailAddress) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.phone-number") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.phoneNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.advisor") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.advisor) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.services") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <template v-if="client.services && client.services.length > 0">
              <v-chip
                v-for="(service, index) in client.services"
                small
                :key="index"
                class="ma-1"
                color="primary"
                outlined
              >
                {{ service }}
              </v-chip>
            </template>
            <template v-else>
              <span class="caption black--text"> — </span>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.owner") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(client.owner) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <div class="border-bottom">
          <v-row no-gutters class="mb-3">
            <span class="caption black--text font-weight-medium">
              {{ $t("clients.details.collaborators") }}
            </span>
          </v-row>
          <template
            v-if="client.collaborators && client.collaborators.length > 0"
          >
            <v-row
              no-gutters
              v-for="(item, index) in client.collaborators"
              :key="index"
              class="mb-3"
            >
              <v-col cols="12" md="4">
                <span class="caption gray--text text--darken-1">
                  {{ item.collaborator }}
                </span>
              </v-col>
              <v-col cols="12" md="8">
                <template v-if="item.services && item.services.length > 0">
                  <v-chip
                    v-for="(service, index) in item.services"
                    small
                    :key="index"
                    class="ma-1"
                    color="primary"
                    outlined
                  >
                    {{ service }}
                  </v-chip>
                </template>
                <template v-else>
                  <span class="caption black--text"> — </span>
                </template>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span class="caption black--text"> — </span>
          </template>
        </div>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("clients.details.address") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text" v-if="client.address">
              {{ generateAddress(client.address) }}
            </span>
            <span class="caption black--text" v-else>—</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <div class="border-bottom">
          <v-row no-gutters class="mb-3">
            <span class="caption black--text font-weight-medium">
              {{ $t("clients.details.marriage-info") }}
            </span>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <span class="caption gray--text text--darken-1">
                    {{ $t("clients.details.marital-status") }}
                  </span>
                </v-col>
                <v-col cols="12" md="8">
                  <span class="caption black--text">
                    {{
                      client.maritalStatus === MaritalStatuses.MARRIED
                        ? $t("clients.marital-status.married")
                        : $t("clients.marital-status.single")
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="client.maritalStatus === MaritalStatuses.MARRIED">
            <v-row no-gutters>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.first-name") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="caption black--text">
                      {{ fillEmptyData(client.spouseInfo.firstName) }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.last-name") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="caption black--text">
                      {{ fillEmptyData(client.spouseInfo.lastName) }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.ssn") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="caption black--text">
                      {{
                        fillEmptyData(client.spouseInfo.socialSecurityNumber)
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.birth-date") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="caption black--text">
                      {{
                        fillEmptyData(client.spouseInfo.birthDate)
                          | moment("MM.DD.YYYY")
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.phone-number") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="caption black--text">
                      {{ fillEmptyData(client.spouseInfo.phoneNumber) }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("clients.details.spouse.address") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span
                      class="caption black--text"
                      v-if="client.spouseInfo.address"
                    >
                      {{ generateAddress(client.spouseInfo.address) }}
                    </span>
                    <span class="caption black--text" v-else>—</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-col>
    </v-row>
    <d-loading v-else></d-loading>
  </div>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";
import MaritalStatuses from "@/shared/enums/MaritalStatuses";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      client: null,
      imageError: false,
      ClientTypes,
      MaritalStatuses,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.getItem(val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    profileAvatar() {
      if (this.isPersonal) {
        return (
          this.client.firstName.charAt(0).toUpperCase() +
          this.client.lastName.charAt(0).toUpperCase()
        );
      } else {
        return this.client.name.charAt(0).toUpperCase();
      }
    },
    isPersonal() {
      return this.item.clientType === ClientTypes.PERSONAL;
    },
    isBusiness() {
      return this.item.clientType === ClientTypes.BUSINESS;
    },
  },
  methods: {
    getPersonalClientDetailsById(id) {
      this.loading = true;
      window.API.getPersonalClientDetailsById(id)
        .then((response) => {
          this.client = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
    getBusinessClientDetailsById(id) {
      this.loading = true;
      window.API.getBusinessClientDetailsById(id)
        .then((response) => {
          this.client = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
    getItem(item) {
      if (item) {
        if (item.clientType === ClientTypes.PERSONAL) {
          this.getPersonalClientDetailsById(item.id);
        } else if (item.clientType === ClientTypes.BUSINESS) {
          this.getBusinessClientDetailsById(item.id);
        }
      }
    },
    handleImageError(error) {
      this.imageError = error;
    },
    generateAddress(adress) {
      return `${adress.addressLine1 ? adress.addressLine1 + ", " : ""}
      ${adress.addressLine2 ? adress.addressLine2 + ", " : ""}
      ${adress.city ? adress.city + ", " : ""}
      ${adress.state ? adress.state + ", " : ""}
      ${adress.zipCode ? adress.zipCode : ""}`
        .trim()
        .replace(/(^,)|(,$)/g, "");
    },
  },
};
</script>
