<template>
  <d-modal-confirmation
    :title="$t('documents.delete.title')"
    :text="$t('documents.delete.text')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
  </d-modal-confirmation>
</template>

<script>
import DocumentTypes from "@/shared/enums/DocumentTypes";

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      default: () => DocumentTypes.MY_UPLOAD
    }
  },
  data() {
    return {
      DocumentTypes,
      isSubmitting: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.isSubmitting = true;

      (this.type === this.DocumentTypes.RECEIVED
        ? window.API.deleteReceivedDocs(this.selectedItems)
        : window.API.deleteMyUploads(this.selectedItems)
      )
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("documents.delete.alert.deleted"),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("documents.delete.alert.could-not-delete"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
