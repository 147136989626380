<template>
  <v-autocomplete
    filled
    :value="selectedItems"
    :items="convertedItems"
    :item-text="textProp"
    :item-value="valueProp"
    hide-details="auto"
    :loading="loading"
    :search-input.sync="searchText"
    multiple
    return-object
    clearable
    :label="label"
    @input="handleChangeItems"
  >
    <template v-slot:item="{ item, attrs }">
      <v-chip
        v-if="!item.disabled"
        v-bind="attrs"
        :key="item[valueProp]"
        small
        label
        :color="item.tagGroup.color"
      >
        <span class="white--text">{{ item[textProp] }}</span>
      </v-chip>
      <span v-else class="body-2 gray--text text--darken-3">{{
        item[textProp]
      }}</span>
    </template>
    <template v-slot:selection="{ attrs, selected, select, item, index }">
      <v-chip
        small
        :color="item.tagGroup.color"
        v-bind="attrs"
        :input-value="selected"
        close
        label
        dark
        @click="select"
        @click:close="handleRemoveItem(index)"
      >
        <span>{{ item[textProp] }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { flatArrayToGroupedArray } from "@/utils/converter.js";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => null,
    },
    textProp: {
      type: String,
      default: () => "displayName",
    },
    valueProp: {
      type: String,
      default: () => "id",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      searchText: null,
      convertedItems: [],
      selectedItems: [],
    };
  },
  watch: {
    value: {
      handler(ids) {
        if (ids.length === 0) {
          this.selectedItems = [];
        } else {
          this.searchText = null;
          this.prepareSelectedItems(ids);
        }
      },
      immediate: true,
    },
    items: {
      handler() {
        this.prepareItems();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleChangeItems(items) {
      this.$emit(
        "input",
        items.map((item) => item[this.valueProp])
      );
    },
    handleRemoveItem(index) {
      this.selectedItems.splice(index, 1);

      this.$emit(
        "input",
        this.selectedItems.map((item) => item[this.valueProp])
      );
    },
    prepareItems() {
      var grouppedItems = flatArrayToGroupedArray(this.items, [
        "tagGroup",
        "displayName",
      ]);

      const items = this.convertGroupedArrToFlatArr(grouppedItems);
      this.convertedItems = [...items];

      if (this.value.length > 0) {
        this.prepareSelectedItems(this.value);
      }
    },
    convertGroupedArrToFlatArr(grouppedItems) {
      const items = [];
      for (const key in grouppedItems) {
        items.push({
          [this.valueProp]: key,
          [this.textProp]: key,
          disabled: true,
        });

        grouppedItems[key].forEach((tag) => {
          items.push(tag);
        });
      }

      return items;
    },
    prepareSelectedItems(ids) {
      this.selectedItems = [
        ...this.convertedItems.filter((item) =>
          ids.includes(item[this.valueProp])
        ),
      ];
    },
  },
};
</script>
