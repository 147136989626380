<template>
  <d-modal-confirmation
    :title="modalTitle"
    :text="modalConfirmationText"
    :modal="modal"
    :submitButtonText="modalSubmitButtonText"
    @closeModal="handleCloseModal"
    @submitModal="handleSubmitModal"
    :isSubmitting="isSubmitting"
  >
  </d-modal-confirmation>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    modalTitle() {
      return this.$t("clients.change-status.title", {
        action: this.item.isActive
          ? this.$t(`clients.change-status.button.deactivate`)
          : this.$t(`clients.change-status.button.activate`),
      });
    },
    modalConfirmationText() {
      return this.$t("clients.change-status.text", {
        action: this.item.isActive
          ? this.$t(`clients.change-status.button.deactivate`).toLowerCase()
          : this.$t(`clients.change-status.button.activate`).toLowerCase(),
        fullName: this.item.fullName,
      });
    },
    modalSubmitButtonText() {
      return this.item.isActive
        ? this.$t(`clients.change-status.button.deactivate`)
        : this.$t(`clients.change-status.button.activate`);
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.item.isActive ? this.deactivateClient() : this.activateClient();
    },
    activateClient() {
      this.isSubmitting = true;
      window.API.activateClient(this.item.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.change-status.alert.activated", {
              fullName: this.item.fullName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.change-status.alert.could-not-activate"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    deactivateClient() {
      this.isSubmitting = true;
      window.API.deactivateClient(this.item.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.change-status.alert.deactivated", {
              fullName: this.item.fullName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "clients.change-status.alert.could-not-deactivate"
            ),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
