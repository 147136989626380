<template>
  <div>
    <v-app-bar outlined app flat color="#FAFAFA" height="56">
      <v-row align="center">
        <v-col cols="4">
          <v-app-bar-nav-icon
            @click="setNavigationDrawer()"
          ></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="4" class="d-flex justify-center">
          <ChangeAccount v-if="canChangeAccount" />
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center justify-end">
            <v-menu
              offset-y
              content-class="elevation-0 mt-1 rounded"
              v-if="loggedInUser"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="gray"
                  text
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  class="pa-0"
                >
                  <div class="d-flex align-center">
                    <v-avatar color="primary" size="32">
                      <d-auth-image
                        v-if="displayProfilePicture"
                        :url="
                          displayProfilePicture
                            ? `/identity/users/me/profile-picture`
                            : ''
                        "
                        :width="64"
                        :height="64"
                        @error="handleProfilePictureError"
                      ></d-auth-image>

                      <span
                        class="body-1 white--text"
                        v-if="profilePictureError"
                        >{{ loggedInUserAvatar }}</span
                      >
                    </v-avatar>
                    <span
                      class="subtitle-2 text-none pl-2 pr-3 gray--text text--darken-3"
                      >{{ loggedInUserFullName }}</span
                    >
                  </div>
                </v-btn>
              </template>
              <v-list outlined class="rounded" dense>
                <v-list-item to="/profile">
                  <v-list-item-title>
                    <span class="body-2">{{ $t("header.menu.profile") }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item to="/accounts" v-granted="'account:list'">
                  <v-list-item-title>
                    <span class="body-2">{{ $t("header.menu.accounts") }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="openChangePasswordModal = true">
                  <v-list-item-title>
                    <span class="body-2">{{
                      $t("header.menu.change-password")
                    }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="openSendInvitationModal = true"
                  v-if="canSendInvitation"
                >
                  <v-list-item-title>
                    <span class="body-2">{{
                      $t("header.menu.send-invitation")
                    }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logOut">
                  <v-list-item-title>
                    <span class="body-2">{{ $t("header.menu.log-out") }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <v-toolbar-title></v-toolbar-title>
    </v-app-bar>
    <ChangePassword
      v-if="openChangePasswordModal"
      :modal="openChangePasswordModal"
      @closeModal="handleCloseChangePasswordModal"
    ></ChangePassword>
    <SendInvitation
      v-if="openSendInvitationModal"
      :modal="openSendInvitationModal"
      @closeModal="handleCloseSendInvitationModal"
    ></SendInvitation>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChangePassword from "@/views/profile/ChangePassword.vue";
import SendInvitation from "@/views/profile/SendInvitation.vue";
import ChangeAccount from "@/views/profile/ChangeAccount.vue";
import authorizationUtils from "@/utils/authorization.js";
import authenticationMixins from "@/mixins/authenticationMixins";

export default {
  mixins: [authenticationMixins],
  components: {
    ChangePassword,
    SendInvitation,
    ChangeAccount,
  },
  data: () => ({
    drawer: null,
    profilePictureError: false,
    displayProfilePicture: true,
    openChangePasswordModal: false,
    openSendInvitationModal: false,
  }),
  computed: {
    ...mapGetters({
      loggedInUser: "Auth/getLoggedInUser",
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
    loggedInUserAvatar() {
      return (
        this.loggedInUser.firstName.charAt(0).toUpperCase() +
        this.loggedInUser.lastName.charAt(0).toUpperCase()
      );
    },
    loggedInUserFullName() {
      return `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    },
    canSendInvitation() {
      if (authorizationUtils.isGranted("invitation:send")) {
        return (
          !authorizationUtils.isClient() ||
          (authorizationUtils.isClient() && this.myCurrentClient)
        );
      }
      return false;
    },
    canChangeAccount() {
      return authorizationUtils.isGranted("account:list");
    },
  },
  methods: {
    ...mapActions({
      setNavigationDrawer: "Common/setNavigationDrawer",
    }),
    async logOut() {
      await this.globalLogOut(() => {
        this.$router.push("/login");
      });
    },
    handleProfilePictureError(error) {
      this.profilePictureError = error;
    },
    handleProfileUpdated() {
      this.displayProfilePicture = false;
      this.$nextTick(() => {
        this.displayProfilePicture = true;
        this.profilePictureError = false;
      });
    },
    handleCloseChangePasswordModal() {
      this.openChangePasswordModal = false;
    },
    handleCloseSendInvitationModal() {
      this.openSendInvitationModal = false;
    },
  },
  created() {
    this.$eventBus.$on("profileUpdated", this.handleProfileUpdated);
  },
  beforeDestroy() {
    this.$eventBus.$off("profileUpdated", this.handleProfileUpdated);
  },
};
</script>

<style lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  border-bottom: 1px solid var(--v-gray-lighten2) !important;
}
</style>
