<template>
  <d-modal
    :title="$t('profile.choose-account.page-heading')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    :hasCancelButton="false"
    :isSubmitting="isSubmitting"
    :saveButtonText="$t('profile.choose-account.button.continue')"
  >
    <template slot="body">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-autocomplete
          :items="myClients"
          v-model="myCurrentClient"
          item-text="name"
          item-value="id"
          filled
          hide-details="auto"
          :rules="rules.myCurrentClient"
          return-object
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center">
              <v-icon size="24" class="mr-3">
                {{
                  item.clientType === ClientTypes.BUSINESS
                    ? "mdi-briefcase"
                    : "mdi-account"
                }}
              </v-icon>
              <span class="body-2">
                {{ item.name }}
              </span>
            </div>
          </template>
        </v-autocomplete>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    myClients: {
      type: Array,
      default: () => [],
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ClientTypes,
      myCurrentClient: null,
      rules: {
        myCurrentClient: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      isFormValid: false,
    };
  },
  watch: {
    myClients: {
      handler() {
        if (this.myClients && this.myClients.length > 0) {
          this.myCurrentClient = this.myClients[0];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleSubmitModal() {
      if (this.$refs.form.validate() && this.isFormValid) {
        this.$emit("submitModal", this.myCurrentClient);
      }
    },
  },
};
</script>
