<template>
  <d-page v-if="form.id > 0 && !loading.page" :title="$t('clients.edit.title')">
    <template slot="heading">
      <v-tooltip right color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-2" color="primary" dark v-bind="attrs" v-on="on">
            {{ isBusiness ? "mdi-briefcase" : "mdi-account" }}
          </v-icon>
        </template>
        <span>
          {{
            isBusiness
              ? $t("clients.client-type.business")
              : $t("clients.client-type.personal")
          }}
        </span>
      </v-tooltip>
    </template>
    <template slot="content">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12" md="4" class="mb-3">
            <div
              class="border-all pa-4 rounded d-flex align-center justify-center h-100"
            >
              <d-image-upload
                @change="handleImageUploadChange"
                :value="
                  form.hasProfilePicture
                    ? `/document/clients/${id}/profile-picture`
                    : null
                "
              ></d-image-upload>
            </div>
          </v-col>
          <v-col cols="12" md="8" class="mb-3">
            <v-row>
              <template v-if="isBusiness">
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.name"
                    :rules="rules.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.trade-name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.tradeName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.tin')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.taxpayerIdentificationNumber"
                    v-mask="'##-#######'"
                    placeholder="##-#######"
                    :rules="rules.taxpayerIdentificationNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.website')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.website"
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="isPersonal">
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.first-name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.firstName"
                    :rules="rules.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.last-name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.lastName"
                    :rules="rules.lastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-text-field
                    :label="$t('clients.edit.form.ssn')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.socialSecurityNumber"
                    v-mask="'###-##-####'"
                    placeholder="###-##-####"
                    :rules="rules.socialSecurityNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="mb-3">
                  <v-menu
                    v-model="menu.birthDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedBirthdate"
                        :label="$t('clients.edit.form.birth-date')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.birthDate"
                      @input="menu.birthDate = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </template>
              <v-col cols="12" sm="6" class="mb-3 mb-sm-0">
                <v-text-field
                  :label="$t('clients.edit.form.email')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.emailAddress"
                  :rules="rules.emailAddress"
                  :error="errorMessages.emailAddress.length > 0"
                  :error-messages="errorMessages.emailAddress"
                  :loading="loading.emailAddress"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('clients.edit.form.phone-number')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.phoneNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-autocomplete
              filled
              :items="advisors"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              :label="$t('clients.edit.form.advisor')"
              v-model="form.advisorUserId"
              :rules="rules.advisorUser"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-autocomplete
              filled
              :items="services"
              item-text="displayName"
              item-value="id"
              hide-details="auto"
              :label="$t('clients.edit.form.services')"
              multiple
              v-model="form.services"
              :rules="rules.services"
              @change="handleServicesChanged"
            >
              <template
                v-slot:selection="{ attrs, selected, select, item, index }"
              >
                <v-chip
                  small
                  color="primary"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="removeService(index)"
                >
                  {{ item.displayName }}
                </v-chip>
              </template></v-autocomplete
            >
          </v-col>
          <v-col cols="12" class="mb-3">
            <div class="w-100 border-bottom my-2">
              <h3 class="subtitle-1 font-weight-medium">
                {{ $t("clients.edit.form.owner-collaborator") }}
              </h3>
            </div>
            <v-row>
              <v-col cols="12" md="4" class="mb-3">
                <v-autocomplete
                  filled
                  :items="owners"
                  item-text="displayText"
                  item-value="value"
                  hide-details="auto"
                  clearable
                  :label="$t('clients.edit.form.owner')"
                  v-model="selectedOwner"
                  :loading="loading.owners"
                  :search-input.sync="search.owners"
                  :placeholder="$t('defaults.remote-dropdown-placeholder')"
                  hide-no-data
                  :rules="rules.ownerUser"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="mb-3">
                <v-autocomplete
                  filled
                  :items="collaborators"
                  item-text="displayText"
                  item-value="value"
                  hide-details="auto"
                  :label="$t('clients.edit.form.collaborators.title')"
                  multiple
                  v-model="selectedCollaborators"
                  :loading="loading.collaborators"
                  :search-input.sync="search.collaborators"
                  :placeholder="$t('defaults.remote-dropdown-placeholder')"
                  hide-no-data
                  small-chips
                  deletable-chips
                  @change="formCollaboratorsUpdated"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                class="mb-3"
                v-if="form.collaborators.length > 0"
              >
                <v-data-table
                  hide-default-footer
                  :headers="collaboratorListHeaders"
                  :items="form.collaborators"
                  disable-filtering
                  disable-pagination
                  class="d-table"
                >
                  <template v-slot:[`item.services`]="{ item, index }">
                    <v-autocomplete
                      filled
                      :items="filteredServices"
                      item-text="displayName"
                      item-value="id"
                      hide-details="auto"
                      multiple
                      v-model="item.services"
                      dense
                    >
                      <template
                        v-slot:selection="{ attrs, selected, select, item }"
                      >
                        <v-chip
                          small
                          color="primary"
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeCollaboratorService(item, index)"
                        >
                          {{ item.displayName }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mb-3">
            <div class="w-100 border-bottom my-2">
              <h3 class="subtitle-1 font-weight-medium">
                {{ $t("clients.edit.form.address-info") }}
              </h3>
            </div>
            <v-row>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :label="$t('clients.edit.form.address.address-line-1')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.address.addressLine1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="mb-3">
                <v-text-field
                  :label="$t('clients.edit.form.address.address-line-2')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.address.addressLine2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="mb-3">
                <v-text-field
                  :label="$t('clients.edit.form.address.city')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.address.city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="mb-3">
                <v-autocomplete
                  filled
                  :items="states"
                  item-text="displayText"
                  item-value="value"
                  hide-details="auto"
                  clearable
                  :label="$t('clients.edit.form.address.state')"
                  v-model="form.address.stateId"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="mb-3">
                <v-text-field
                  :label="$t('clients.edit.form.address.zip-code')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.address.zipCode"
                  v-mask="'#####'"
                  :rules="rules.zipCode"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <template v-if="isPersonal">
            <v-col cols="12" class="mb-3">
              <div class="w-100 border-bottom my-2">
                <h3 class="subtitle-1 font-weight-medium">
                  {{ $t("clients.edit.form.marriage-info") }}
                </h3>
              </div>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-radio-group
                    row
                    filled
                    v-model="form.maritalStatus"
                    hide-details="auto"
                    class="mt-0"
                  >
                    <v-radio
                      :label="$t('clients.marital-status.single')"
                      :value="MaritalStatuses.SINGLE"
                    ></v-radio>
                    <v-radio
                      :label="$t('clients.marital-status.married')"
                      :value="MaritalStatuses.MARRIED"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <template v-if="form.maritalStatus === MaritalStatuses.MARRIED">
              <v-col cols="12" class="mb-3">
                <div class="w-100 border-bottom my-2">
                  <h3 class="subtitle-1 font-weight-medium">
                    {{ $t("clients.edit.form.spouse-info") }}
                  </h3>
                </div>
                <v-row>
                  <v-col cols="12" sm="6" class="mb-3">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse.first-name')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.firstName"
                      :rules="rules.spouseFirstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="mb-3">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse.last-name')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.lastName"
                      :rules="rules.spouseLastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="mb-3">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse.ssn')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.socialSecurityNumber"
                      v-mask="'###-##-####'"
                      placeholder="###-##-####"
                      :rules="rules.socialSecurityNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="mb-3">
                    <v-menu
                      v-model="menu.spouseBirthDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedSpouseBirthdate"
                          :label="$t('clients.edit.form.spouse.birth-date')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          filled
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.spouseInfo.birthDate"
                        @input="menu.spouseBirthDate = false"
                        no-title
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse.phone-number')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.phoneNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="mb-3">
                <div class="w-100 border-bottom my-2">
                  <h3 class="subtitle-1 font-weight-medium">
                    {{ $t("clients.edit.form.spouse-address-info") }}
                  </h3>
                </div>
                <v-row>
                  <v-col cols="12" md="6" class="mb-3">
                    <v-text-field
                      :label="
                        $t('clients.edit.form.spouse-address.address-line-1')
                      "
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.address.addressLine1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="mb-3">
                    <v-text-field
                      :label="
                        $t('clients.edit.form.spouse-address.address-line-2')
                      "
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.address.addressLine2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="mb-3">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse-address.city')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.address.city"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="mb-3">
                    <v-autocomplete
                      filled
                      :items="states"
                      item-text="displayText"
                      item-value="value"
                      hide-details="auto"
                      clearable
                      :label="$t('clients.edit.form.spouse-address.state')"
                      v-model="form.spouseInfo.address.stateId"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="mb-3">
                    <v-text-field
                      :label="$t('clients.edit.form.spouse-address.zip-code')"
                      hide-details="auto"
                      filled
                      type="text"
                      v-model="form.spouseInfo.address.zipCode"
                      v-mask="'#####'"
                      :rules="rules.zipCode"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </template>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <v-btn
                :loading="isSubmitting"
                class="text-none body-2 font-weight-medium mr-3"
                depressed
                @click="handleFormClose"
              >
                {{ $t("defaults.modal-cancel") }}
              </v-btn>
              <v-btn
                type="submit"
                color="success"
                class="text-none body-2 font-weight-medium"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("defaults.modal-save") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-page>
  <d-loading v-else :padding="5"></d-loading>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";
import MaritalStatuses from "@/shared/enums/MaritalStatuses";

const initialForm = {
  id: 0,
  clientType: "",
  name: "",
  tradeName: "",
  taxpayerIdentificationNumber: "",
  hasProfilePicture: false,
  firstName: "",
  lastName: "",
  socialSecurityNumber: "",
  birthDate: "",
  website: "",
  emailAddress: "",
  phoneNumber: "",
  advisorUserId: "",
  maritalStatus: MaritalStatuses.SINGLE,
  ownerUserId: "",
  ownerUser: "",
  address: {},
  services: [],
  collaborators: [],
  spouseInfo: {},
};

const initialAddressValues = {
  id: 0,
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateId: "",
  zipCode: "",
};

const initialSpouseInfoValues = {
  id: 0,
  firstName: "",
  lastName: "",
  socialSecurityNumber: "",
  birthDate: "",
  phoneNumber: "",
  address: {
    id: 0,
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: "",
    zipCode: "",
  },
};

const initialErrorMessages = {
  emailAddress: [],
};
const initialTempValues = {
  emailAddress: "",
};

export default {
  data(vm) {
    return {
      id: null,
      ClientTypes,
      MaritalStatuses,
      isFormValid: false,
      isProfilePictureChanged: false,
      profilePicture: null,
      advisors: [],
      services: [],
      owners: [],
      collaborators: [],
      selectedCollaborators: [],
      states: [],
      loading: {
        page: false,
        emailAddress: false,
        owners: false,
        collaborators: false,
      },
      isSubmitting: false,
      tempPreviousServices: [],
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        advisorUser: [(v) => !!v || this.$t("defaults.rule.required")],
        services: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => (!!v && v.length >= 1) || this.$t("defaults.rule.required"),
        ],
        emailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("clients.edit.rule.email-address.invalid"),
        ],
        ownerUser: [(v) => !!v || this.$t("defaults.rule.required")],
        socialSecurityNumber: [
          (v) => {
            if (!v) return true;

            if (v && v.length === 11) return true;

            return this.$t("clients.edit.rule.ssn");
          },
        ],
        zipCode: [
          (v) => {
            if (!v) return true;

            if (v && /^\d+$/.test(v)) return true;
            return this.$t("clients.edit.rule.zip-code-only-number");
          },
          (v) => {
            if (!v) return true;

            if (v && v.length === 5) return true;

            return this.$t("clients.edit.rule.zip-code");
          },
        ],
        spouseFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        spouseLastName: [(v) => !!v || this.$t("defaults.rule.required")],
        taxpayerIdentificationNumber: [
          (v) => {
            if (!v) return true;

            if (v && v.length === 10) return true;

            return this.$t("clients.edit.rule.tin");
          },
        ],
      },
      errorMessages: { ...initialErrorMessages },
      tempValues: { ...initialTempValues },
      form: { ...initialForm },
      search: {
        owners: null,
        collaborators: null,
      },
      selectedOwner: null,
      collaboratorListHeaders: [
        {
          text: vm.$t("clients.edit.form.collaborators.table.headers.name"),
          value: "collaboratorUser",
          width: "25%",
          sortable: false,
        },
        {
          text: vm.$t("clients.edit.form.collaborators.table.headers.services"),
          value: "services",
          width: "75%",
          sortable: false,
        },
      ],
      menu: {
        birthDate: false,
        spouseBirthDate: false,
      },
    };
  },
  watch: {
    "search.owners": {
      handler(val) {
        if (!this.selectedOwner && val && val.length > 2) {
          this.owners = [];
          this.fetchOwners(val);
        }
      },
    },
    "search.collaborators": {
      handler(val) {
        if (val && val.length > 2) {
          this.fetchCollaborators(val);
        }
      },
    },
  },
  computed: {
    isPersonalPage() {
      return this.$route.params.clientType === "personal";
    },
    isBusinessPage() {
      return this.$route.params.clientType === "business";
    },
    isPersonal() {
      return this.form.clientType === ClientTypes.PERSONAL;
    },
    isBusiness() {
      return this.form.clientType === ClientTypes.BUSINESS;
    },
    filteredServices() {
      return this.services.filter((service) =>
        this.form.services.includes(service.id)
      );
    },
    formattedBirthdate() {
      return this.form.birthDate
        ? this.$moment(this.form.birthDate).format("MM.DD.YYYY")
        : null;
    },
    formattedSpouseBirthdate() {
      return this.form.spouseInfo.birthDate
        ? this.$moment(this.form.spouseInfo.birthDate).format("MM.DD.YYYY")
        : null;
    },
  },
  methods: {
    handleServicesChanged(services) {
      const removedServices = this.tempPreviousServices.filter(
        (x) => !services.includes(x)
      );

      if (removedServices.length > 0) {
        this.form.collaborators.forEach((collaborator) => {
          collaborator.services = collaborator.services.filter(
            (service) => service != removedServices[0]
          );
        });
      } else {
        this.form.collaborators.forEach((collaborator) => {
          collaborator.services.push(services[services.length - 1]);
        });
      }

      this.tempPreviousServices = [...services];
    },
    handleImageUploadChange(image) {
      this.profilePicture = image;
      this.isProfilePictureChanged = true;
    },
    handleFormClose() {
      this.$router.push({ name: "Clients" });
    },
    async handleFormSubmit() {
      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.isPersonalPage) {
          this.editPersonalClient(this.form);
        } else if (this.isBusinessPage) {
          this.editBusinessClient(this.form);
        }
      }
    },
    editPersonalClient(form) {
      this.isSubmitting = true;
      let data = this.generateFormData(form);
      window.API.editPersonalClient(form.id, data)
        .then(() => {
          this.$router.push({ name: "Clients" });
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.edit.alert.updated", {
              fullName: `${this.form.firstName} ${this.form.lastName}`,
            }),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.edit.alert.could-not-update"),
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    editBusinessClient(form) {
      this.isSubmitting = true;
      let data = this.generateFormData(form);
      window.API.editBusinessClient(form.id, data)
        .then(() => {
          this.$router.push({ name: "Clients" });
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.edit.alert.updated", {
              fullName: `${this.form.name}`,
            }),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.edit.alert.could-not-update"),
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    getPersonalClientById(id) {
      this.loading.page = true;
      window.API.getPersonalClientById(id)
        .then((response) => {
          this.setInitialValues(response);
        })
        .catch(({ response }) => {
          this.showFetchRequestErrorMessage();
          if (response.data.statusCode === 404) this.redirectClientsPage();
        })
        .finally(() => (this.loading.page = false));
    },
    getBusinessClientById(id) {
      this.loading.page = true;
      window.API.getBusinessClientById(id)
        .then((response) => {
          this.setInitialValues(response);
        })
        .catch(({ response }) => {
          this.showFetchRequestErrorMessage();
          if (response.data.statusCode === 404) this.redirectClientsPage();
        })
        .finally(() => (this.loading.page = false));
    },
    redirectClientsPage() {
      this.$router.push({
        name: "Clients",
      });
    },
    async setInitialValues(response) {
      const {
        advisorUserId,
        ownerUser,
        ownerUserId,
        collaborators,
        services,
        maritalStatus,
        ...rest
      } = response;

      this.form = {
        ...rest,
        ownerUserId,
        ownerUser,
        services,
        collaborators,
        advisorUserId,
        maritalStatus,
      };

      if (this.isPersonalPage) {
        this.form.maritalStatus = maritalStatus
          ? maritalStatus
          : MaritalStatuses.SINGLE;

        this.form.birthDate = this.form.birthDate
          ? this.$moment(this.form.birthDate).format("YYYY-MM-DD")
          : "";

        if (this.form.spouseInfo) {
          this.form.spouseInfo.birthDate = this.form.spouseInfo.birthDate
            ? this.$moment(this.form.spouseInfo.birthDate).format("YYYY-MM-DD")
            : "";

          if (this.form.spouseInfo.address) {
            this.form.spouseInfo.address.stateId =
              this.form.spouseInfo.address.stateId.toString();
          } else {
            this.form.spouseInfo.address = { ...initialAddressValues };
          }
        } else {
          this.form.spouseInfo = { ...initialSpouseInfoValues };
        }
      }

      if (this.form.address) {
        this.form.address.stateId = this.form.address.stateId
          ? this.form.address.stateId.toString()
          : "";
      } else {
        this.form.address = { ...initialAddressValues };
      }

      this.selectedOwner = {
        displayText: ownerUser,
        value: ownerUserId,
      };

      this.owners = [];

      this.owners.push(this.selectedOwner);
      this.tempValues.emailAddress = rest.emailAddress || "";

      if (services && services.length > 0) {
        this.form.services = await services.map((service) => {
          return service.id;
        });
        this.tempPreviousServices = [...this.form.services];
      }

      if (advisorUserId) {
        this.form.advisorUserId = advisorUserId.toString();
      }

      if (collaborators && collaborators.length > 0) {
        this.form.collaborators = [];
        this.selectedCollaborators = [];
        this.collaborators = [];

        await collaborators.forEach((item) => {
          this.form.collaborators.push({
            collaboratorUserId: item.collaboratorUserId,
            collaboratorUser: item.collaboratorUser,
            services:
              item.services && item.services.length > 0
                ? item.services.map((service) => service)
                : [],
          });

          this.selectedCollaborators.push({
            displayText: item.collaboratorUser,
            value: item.collaboratorUserId,
          });

          this.collaborators.push({
            value: item.collaboratorUserId,
            displayText: item.collaboratorUser,
          });
        });
      }
    },
    generateFormData(form) {
      let formData = new FormData();

      formData.append("id", form.id);
      formData.append("emailAddress", form.emailAddress);
      if (form.phoneNumber) {
        formData.append("phoneNumber", form.phoneNumber);
      }
      formData.append("advisorUserId", form.advisorUserId);
      formData.append("ownerUserId", this.selectedOwner.value);
      formData.append("isProfilePictureChanged", this.isProfilePictureChanged);

      if (this.isProfilePictureChanged) {
        formData.append("profilePicture", this.profilePicture);
      }

      if (form.address.id) {
        formData.append("address.id", form.address.id);
      }

      if (form.address.addressLine1) {
        formData.append("address.addressLine1", form.address.addressLine1);
      }

      if (form.address.addressLine2) {
        formData.append("address.addressLine2", form.address.addressLine2);
      }

      if (form.address.city) {
        formData.append("address.city", form.address.city);
      }

      if (form.address.stateId) {
        formData.append("address.stateId", form.address.stateId);
      }

      if (form.address.zipCode) {
        formData.append("address.zipCode", form.address.zipCode);
      }

      form.services.forEach((service) => {
        formData.append("services", service);
      });

      form.collaborators.forEach((collaborator, index) => {
        formData.append(`Collaborators[${index}].ClientId`, form.id);

        formData.append(
          `Collaborators[${index}].CollaboratorUserId`,
          collaborator.collaboratorUserId
        );

        collaborator.services.forEach((service) => {
          formData.append(`Collaborators[${index}].Services`, service);
        });
      });

      if (this.isPersonalPage) {
        formData.append("firstName", form.firstName);
        formData.append("lastName", form.lastName);
        formData.append("socialSecurityNumber", form.socialSecurityNumber);
        if (form.birthDate) {
          formData.append("birthDate", form.birthDate);
        }
        formData.append("maritalStatus", form.maritalStatus);

        if (form.maritalStatus === MaritalStatuses.MARRIED) {
          formData.append("spouseInfo.id", form.spouseInfo.id);
          formData.append("spouseInfo.firstName", form.spouseInfo.firstName);
          formData.append("spouseInfo.lastName", form.spouseInfo.lastName);

          if (form.spouseInfo.birthDate) {
            formData.append("spouseInfo.birthDate", form.spouseInfo.birthDate);
          }

          if (form.spouseInfo.phoneNumber) {
            formData.append(
              "spouseInfo.phoneNumber",
              form.spouseInfo.phoneNumber
            );
          }

          formData.append(
            "spouseInfo.socialSecurityNumber",
            form.spouseInfo.socialSecurityNumber
          );

          if (form.spouseInfo.address.id) {
            formData.append(
              "spouseInfo.address.id",
              form.spouseInfo.address.id
            );
          }

          if (form.spouseInfo.address.addressLine1) {
            formData.append(
              "spouseInfo.address.addressLine1",
              form.spouseInfo.address.addressLine1
            );
          }

          if (form.spouseInfo.address.addressLine2) {
            formData.append(
              "spouseInfo.address.addressLine2",
              form.spouseInfo.address.addressLine2
            );
          }

          if (form.spouseInfo.address.city) {
            formData.append(
              "spouseInfo.address.city",
              form.spouseInfo.address.city
            );
          }

          if (form.spouseInfo.address.stateId) {
            formData.append(
              "spouseInfo.address.stateId",
              form.spouseInfo.address.stateId
            );
          }

          if (form.spouseInfo.address.zipCode) {
            formData.append(
              "spouseInfo.address.zipCode",
              form.spouseInfo.address.zipCode
            );
          }
        }
      }

      if (this.isBusinessPage) {
        formData.append("name", form.name);
        if (form.tradeName) {
          formData.append("tradeName", form.tradeName);
        }

        if (form.website) {
          formData.append("website", form.website);
        }

        formData.append(
          "taxpayerIdentificationNumber",
          form.taxpayerIdentificationNumber
        );
      }

      return formData;
    },
    removeService(index) {
      const removedServices = this.form.services.splice(index, 1);
      this.tempPreviousServices = JSON.parse(
        JSON.stringify(this.form.services)
      );

      this.form.collaborators.forEach((collaborator) => {
        collaborator.services = collaborator.services.filter(
          (service) => service != removedServices[0]
        );
      });
    },
    removeCollaboratorService(item, id) {
      const index = this.form.collaborators[id].services.indexOf(item.id);
      if (index >= 0) this.form.collaborators[id].services.splice(index, 1);
    },
    getItem() {
      this.id = this.$route.params.id;
      if (this.isPersonalPage) {
        this.getPersonalClientById(this.id);
      } else if (this.isBusinessPage) {
        this.getBusinessClientById(this.id);
      }
    },
    fetchAllAdvisors() {
      window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllServices() {
      window.API.fetchAllServices()
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllStates() {
      window.API.fetchAllStates()
        .then((response) => {
          this.states = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchOwners(searchTerm) {
      this.loading.owners = true;

      window.API.fetchClientUsers(searchTerm)
        .then((response) => {
          this.owners = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.owners = false));
    },
    fetchCollaborators(searchTerm) {
      this.loading.collaborators = true;

      window.API.fetchClientUsers(searchTerm)
        .then((response) => {
          response.forEach((element) => {
            this.collaborators.push(element);
          });
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.collaborators = false));
    },
    formCollaboratorsUpdated() {
      this.selectedCollaborators.forEach((selectedCollaborator) => {
        if (
          !this.form.collaborators.some(
            (collaborator) =>
              selectedCollaborator.value.toString() ===
              collaborator.collaboratorUserId.toString()
          )
        ) {
          this.form.collaborators.push({
            collaboratorUser: selectedCollaborator.displayText,
            collaboratorUserId: selectedCollaborator.value,
            services: [...this.form.services],
          });
        }
      });

      this.form.collaborators.forEach((collaborator, index) => {
        if (
          !this.selectedCollaborators.some(
            (selectedCollaborator) =>
              selectedCollaborator.value.toString() ===
              collaborator.collaboratorUserId.toString()
          )
        ) {
          this.form.collaborators.splice(index, 1);
        }
      });
    },
  },
  async created() {
    await this.fetchAllAdvisors();
    await this.fetchAllServices();
    await this.fetchAllStates();
    await this.getItem();
  },
};
</script>
