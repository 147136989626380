import { mapActions } from "vuex";
import AuthenticateUserResultTypes from "@/shared/enums/AuthenticateUserResultTypes";

export default {
  methods: {
    ...mapActions({
      setAccessToken: "Auth/setAccessToken",
      setRefreshToken: "Auth/setRefreshToken",
      setUserPermissions: "Auth/setUserPermissions",
      setLoggedInUser: "Auth/setLoggedInUser",
      clearState: "Auth/logOut",
      setSearchParams: "SearchParams/setSearchParams",
    }),
    async globalLogin(credentials, errorCallback = () => {}) {
      try {
        const response = await window.API.logIn(
          credentials.username,
          credentials.password
        );

        if (response.resultType === AuthenticateUserResultTypes.SUCCESS) {
          this.setLoginInfo(response);

          const { query } = this.$router.currentRoute;
          this.$router.push(query.redirect ? query.redirect : "/");
        } else if (
          response.resultType ===
            AuthenticateUserResultTypes.INVALID_USERNAME_OR_EMAIL_ADDRESS ||
          response.resultType === AuthenticateUserResultTypes.INVALID_PASSWORD
        ) {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("login.alert.wrong-credentials"),
          });
          errorCallback();
        } else if (
          response.resultType === AuthenticateUserResultTypes.USER_IS_NOT_ACTIVE
        ) {
          this.$eventBus.$emit("notification", {
            type: "warning",
            message: this.$t("login.alert.deactive-user"),
          });
          errorCallback();
        }
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("login.alert.wrong-credentials"),
        });
        errorCallback();
      }
    },
    setLoginInfo(response) {
      let {
        id,
        departmentId,
        firstName,
        lastName,
        username,
        emailAddress,
        roles,
        permissions,
        jwtToken,
        isTaxEmployee,
        isLeadOnboardingCompleted,
        leadIdHash,
      } = { ...response.user };

      this.setAccessToken(jwtToken.accessToken);
      this.setRefreshToken(jwtToken.refreshToken);
      this.setUserPermissions(permissions);
      this.setLoggedInUser({
        id,
        departmentId,
        firstName,
        lastName,
        username,
        emailAddress,
        roles,
        isTaxEmployee,
        isLeadOnboardingCompleted,
        leadIdHash,
      });
    },
    async globalLogOut(finalCallback = () => {}) {
      try {
        await window.API.logOut();
      } catch {
        return;
      } finally {
        this.clearState();
        this.setSearchParams({});
        finalCallback();
      }
    },
  },
};
