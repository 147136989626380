var qs = require("qs");

export function fetchMyUploads(params) {
  return window.axios
    .get("/document/documents/my-upload", {
      params: { ...params },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNulls: true });
      },
    })
    .then((response) => response.data);
}

export function uploadDocuments(data) {
  return window.axios
    .post("/document/documents/my-upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response.data);
}

export function deleteMyUploads(ids) {
  return window.axios
    .delete("/document/documents/my-upload", {
      params: { ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNulls: true });
      },
    })
    .then((response) => response);
}

export function editMyUploadDocument(id, data) {
  return window.axios
    .put(`/document/documents/my-upload/${id}`, data)
    .then((response) => response);
}
