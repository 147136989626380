<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.management-structure") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.management-structure") }}:
            </span>
            <v-chip class="ml-4" small color="gray lighten-2">
              <span>{{ company.managementStructure }} </span>
            </v-chip>
          </v-col>
          <v-col md="6"></v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
    },
  },
};
</script>
