<template>
  <v-row justify="center">
    <v-dialog
      v-model="modal"
      persistent
      :maxWidth="700"
      :retain-focus="false"
      eager
    >
      <div class="d-modal onboarding-modal">
        <div class="d-modal__header border-bottom">
          <div class="d-flex align-center">
            <h3 class="text-h6">{{ $t("onboarding.page-heading") }}</h3>
          </div>
        </div>
        <div class="onboarding-modal--stepper">
          <v-stepper elevation="0" color="primary" v-model="currentStep" flat>
            <v-stepper-header class="elevation-0">
              <v-stepper-step
                :complete="currentStep > 1"
                step="1"
                class="text-center"
              >
                <p class="ma-0 text-center font-weight-medium">
                  {{ $t(`onboarding.billing-info.title`) }}
                </p>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="currentStep > 2"
                step="2"
                class="text-center"
              >
                <p class="ma-0 text-center font-weight-medium">
                  {{ $t(`onboarding.document-uploads.title`) }}
                </p>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="mx-0 pa-0">
                <BillingInfo
                  :formData="formData"
                  :isSubmitting="isSubmitting.billingInfo"
                  @submitStep="handleSubmitBillingInfo"
                />
              </v-stepper-content>

              <v-stepper-content step="2" class="ma-0 pa-0">
                <UploadDocuments
                  :isSubmitting="isSubmitting.uploadDocuments"
                  @previousStep="currentStep--"
                  @submitStep="handleSubmitUploadDocuments"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import BillingInfo from "./BillingInfo";
import UploadDocuments from "./UploadDocuments";
import LeadStatuses from "@/shared/enums/LeadStatuses";

export default {
  components: { BillingInfo, UploadDocuments },
  props: {
    modal: Boolean,
    formData: {
      type: Object,
    },
  },
  data() {
    return {
      currentStep: 1,
      isSubmitting: {
        billingInfo: false,
        uploadDocuments: false,
      },
    };
  },
  methods: {
    async handleSubmitBillingInfo(form) {
      this.isSubmitting.billingInfo = true;

      await window.API.updateMyLinkedLeadBillingInfo(form)
        .then(() => {
          this.currentStep++;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("onboarding.billing-info.alert.could-not-post"),
          });
        })
        .finally(() => (this.isSubmitting.billingInfo = false));
    },
    async handleSubmitUploadDocuments(form) {
      this.isSubmitting.uploadDocuments = true;

      let formData = new FormData();

      if (!form.uploadLaterTaxReturnAttachment && form.taxReturnAttachment) {
        formData.append("taxReturnAttachment", form.taxReturnAttachment);
      }

      if (
        !form.uploadLaterEmployerIdentificationNumberAttachment &&
        form.employerIdentificationNumberAttachment
      ) {
        formData.append(
          "employerIdentificationNumberAttachment",
          form.employerIdentificationNumberAttachment
        );
      }

      if (
        !form.uploadLaterArticleOfIncorporationAttachment &&
        form.articleOfIncorporationAttachment
      ) {
        formData.append(
          "articleOfIncorporationAttachment",
          form.articleOfIncorporationAttachment
        );
      }

      await window.API.updateMyLinkedLeadDocuments(formData)
        .then(() => {
          this.$emit("closeModal");

          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("onboarding.document-uploads.alert.completed"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "onboarding.document-uploads.alert.could-not-complete"
            ),
          });
        })
        .finally(() => (this.isSubmitting.uploadDocuments = false));
    },
  },
  async created() {
    if (this.formData.status === LeadStatuses.BILLING_INFO) {
      this.currentStep = 2;
    }
  },
};
</script>
