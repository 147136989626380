<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.registered-agent.section-title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t("companies.details.registered-agent.registered-agent-type")
              }}:
            </span>
            <v-chip class="ml-4" small color="gray lighten-2">
              <span>{{ registeredAgent.registeredAgentType.name }} </span>
            </v-chip>
          </v-col>
          <v-col v-if="isCompany" md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.company-name") }}:
            </span>
            <v-chip class="ml-4" small color="gray lighten-2">
              <span>{{ fillEmptyData(registeredAgent.companyName) }} </span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
      <div v-if="!isCompany" class="border-bottom py-3">
        <v-row>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.first-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(registeredAgent.firstName) }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.last-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(registeredAgent.lastName) }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.middle-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(registeredAgent.middleName) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.address-line1") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ registeredAgent.addressLine1 }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.address-line2") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(registeredAgent.addressLine2) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.city") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ registeredAgent.city }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.jurisdiction") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ registeredAgent.county }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.zip-code") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ registeredAgent.zipCode }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.email-address") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(registeredAgent.emailAddress) }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.registered-agent.phone-number") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ registeredAgent.phoneNumber }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RegisteredAgentTypes from "@/shared/enums/RegisteredAgentTypes.js";

export default {
  props: {
    registeredAgent: {
      type: Object,
      require: true,
    },
  },
  computed: {
    isCompany() {
      return (
        this.registeredAgent.registeredAgentType.id ==
        RegisteredAgentTypes.COMPANY
      );
    },
  },
};
</script>
