<template>
  <div class="border-bottom pb-2">
    <v-row>
      <v-col cols="12">
        <div class="pt-6">
          <h4 class="subtitle-2 font-weight-medium black--text">
            {{
              isCorporation
                ? $t("companies.details.organizers.section-title-officer")
                : $t("companies.details.organizers.section-title-organizer")
            }}
          </h4>
        </div>
      </v-col>
      <v-col cols="12">
        <OrganizerItem
          v-for="(organizer, index) in organizers"
          :key="index"
          :organizer="organizer"
          :isCorporation="isCorporation"
          :isResident="isResident"
          :isLLC="isLLC"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrganizerItem from "./OrganizerItem.vue";
export default {
  components: {
    OrganizerItem,
  },
  props: {
    organizers: {
      type: Array,
      require: true,
    },
    isCorporation: {
      type: Boolean,
      require: true,
    },
    isLLC: {
      type: Boolean,
      require: true,
    },
    isResident: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
