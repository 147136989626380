<template>
  <div>
    <v-data-table
      v-model="selectedItems"
      :single-select="false"
      show-select
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.services`]="{ item }">
        <v-chip
          v-for="(service, index) in item.services"
          small
          :key="index"
          class="ma-1"
          color="primary"
          outlined
        >
          {{ service }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'lead:view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                :to="{ name: 'LeadDetails', params: { id: item.id } }"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-information-outline </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("leads.list.table.actions.view") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('leads.list.empty-page')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("leads.list.table.headers.owner-full-name"),
          value: "ownerFullName",
          sortable: false,
        },
        {
          text: vm.$t("leads.list.table.headers.contact-email-address"),
          value: "contactEmailAddress",
          sortable: false,
        },
        {
          text: vm.$t("leads.list.table.headers.contact-phone-number"),
          value: "contactPhoneNumber",
          sortable: false,
        },
        {
          text: vm.$t("leads.list.table.headers.services"),
          value: "services",
          sortable: false,
        },
        {
          text: vm.$t("leads.list.table.headers.status"),
          value: "status",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {},
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    selectedItems: {
      handler(val) {
        this.updateSelectedItems(val.map((item) => item.id));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;
      this.clearSelectedItems();
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchLeads({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    clearSelectedItems() {
      this.selectedItems = [];
    },
    updateSelectedItems(selectedItems) {
      this.$emit("updateSelectedItems", selectedItems);
    },
  },
  created() {
    this.$eventBus.$on("fetchLeads", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchLeads", this.handleFetchItems);
  },
};
</script>
