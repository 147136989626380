<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.business-address.section-title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div v-if="!businessAddress" class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.business-address.section-title") }}:
            </span>
            <v-chip class="ml-4" small color="gray lighten-2">
              <span
                >{{
                  $t(
                    `companies.details.business-address.type.${
                      isResident ? "resident" : "non-resident"
                    }`
                  )
                }}
              </span>
            </v-chip>
          </v-col>
          <v-col md="6"></v-col>
        </v-row>
      </div>
      <template v-if="businessAddress">
        <div class="border-bottom py-3">
          <v-row>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.address-line1") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ businessAddress.addressLine1 }}
              </span>
            </v-col>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.address-line2") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(businessAddress.addressLine2) }}
              </span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3">
          <v-row>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.city") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ businessAddress.city }}
              </span>
            </v-col>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.zip-code") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(businessAddress.zipCode) }}
              </span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3">
          <v-row>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.email-address") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(businessAddress.emailAddress) }}
              </span>
            </v-col>
            <v-col md="6">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.business-address.phone-number") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(businessAddress.phoneNumber) }}
              </span>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    businessAddress: {
      type: Object,
    },
    isResident: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
