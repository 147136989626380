export function fetchServices(params) {
  return window.axios
    .get("/document/services", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchAllServices(params) {
  return window.axios
    .get("/document/services/all", { params })
    .then((response) => response.data);
}

export function getServiceById(id) {
  return window.axios
    .get(`/document/services/${id}`)
    .then((response) => response.data);
}

export function insertService(data) {
  return window.axios
    .post("/document/services", data)
    .then((response) => response);
}

export function editService(id, data) {
  return window.axios
    .put(`/document/services/${id}`, data)
    .then((response) => response);
}

export function checkNameAvailability(name) {
  return window.axios
    .get(`/document/services/name/${name}/is-available`)
    .then((response) => response.data);
}

export function checkDisplayNameAvailability(displayName) {
  return window.axios
    .get(`/document/services/display-name/${displayName}/is-available`)
    .then((response) => response.data);
}

export function deleteService(id) {
  return window.axios
    .delete(`/document/services/${id}`)
    .then((response) => response);
}

export function activateService(id) {
  return window.axios
    .patch(`/document/services/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateService(id) {
  return window.axios
    .patch(`/document/services/${id}/deactivate`)
    .then((response) => response.data);
}
