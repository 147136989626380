<template>
  <d-page
    :title="$t('accounts.page-heading')"
    :contentClass="'pa-0'"
    :openDetails="openOwnerDetails"
    @closeDetails="handleCloseDetails"
  >
    <template slot="actions">
      <v-menu
        v-if="isOwnerTab"
        v-granted="'account:list'"
        bottom
        offset-y
        content-class="elevation-0 mt-1 rounded"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            depressed
            large
            class="mb-1 mb-sm-0"
            v-bind="attrs"
            v-on="on"
          >
            <span class="subtitle-2 text-none">{{
              $t("accounts.button.create-item")
            }}</span>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list outlined class="rounded" dense>
          <v-list-item @click="handleOpenAddModal(ClientTypes.BUSINESS)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-briefcase</v-icon>
                <span class="body-2">{{
                  $t("accounts.client-type.business")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleOpenAddModal(ClientTypes.PERSONAL)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-account</v-icon>
                <span class="body-2">{{
                  $t("accounts.client-type.personal")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template slot="content">
      <v-tabs background-color="gray lighten-5" v-model="tab">
        <v-tab class="text-none" href="#owner">
          {{ $t("accounts.owner.title") }}
        </v-tab>
        <v-tab class="text-none" href="#collaborator">
          {{ $t("accounts.collaborator.title") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item id="owner">
          <OwnerList
            @changeCollaboratorStatus="handleOpenChangeCollaboratorStatusModal"
            @viewItem="handleOpenOwnerDetails"
          />
        </v-tab-item>
        <v-tab-item id="collaborator">
          <CollaboratorList @deleteItem="handleOpenDeleteCollaborationModal" />
          <DeleteCollaboration
            v-if="openDeleteCollaborationModal"
            :modal="openDeleteCollaborationModal"
            :item="selectedItem"
            @closeModal="handleCloseDeleteCollaborationModal"
            @submitModal="handleSubmitDeleteCollaborationModal"
          ></DeleteCollaboration>
        </v-tab-item>
      </v-tabs-items>
      <Add
        v-if="openAddModal"
        :modal="openAddModal"
        @closeModal="handleCloseAddModal"
        @submitModal="handleSubmitAddModal"
        :clientType="selectedClientType"
      ></Add>
      <ChangeCollaboratorStatus
        v-if="openChangeCollaboratorStatusModal"
        :modal="openChangeCollaboratorStatusModal"
        :item="selectedItem"
        :collaborator="selectedCollaborator"
        @closeModal="handleCloseChangeCollaboratorStatusModal"
        @submitModal="handleSubmitChangeCollaboratorStatusModal"
      ></ChangeCollaboratorStatus>
    </template>
    <template slot="details">
      <OwnerDetails v-if="openOwnerDetails" :item="selectedItem"></OwnerDetails>
    </template>
  </d-page>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";
import OwnerList from "./owner/OwnerList.vue";
import Add from "./owner/Add.vue";
import ChangeCollaboratorStatus from "./owner/ChangeCollaboratorStatus.vue";
import OwnerDetails from "./owner/Details.vue";
import CollaboratorList from "./collaborator/CollaboratorList.vue";
import DeleteCollaboration from "./collaborator/Delete.vue";

export default {
  components: {
    OwnerList,
    Add,
    ChangeCollaboratorStatus,
    OwnerDetails,
    CollaboratorList,
    DeleteCollaboration,
  },
  data() {
    return {
      ClientTypes,
      tab: "owner",
      openAddModal: false,
      openOwnerDetails: false,
      openChangeCollaboratorStatusModal: false,
      openDeleteCollaborationModal: false,
      selectedItem: null,
      selectedClientType: null,
      selectedCollaborator: null,
    };
  },
  computed: {
    isOwnerTab() {
      return this.tab == "owner";
    },
  },
  methods: {
    handleOpenOwnerDetails(item = null) {
      this.selectedItem = item;
      this.openOwnerDetails = true;
    },
    handleCloseDetails() {
      this.openOwnerDetails = false;
    },
    handleOpenAddModal(clientType) {
      this.openAddModal = true;
      this.selectedClientType = clientType;
    },
    handleCloseAddModal() {
      this.openAddModal = false;
    },
    handleSubmitAddModal() {
      this.$eventBus.$emit("fetchOwnerClients");
    },
    handleOpenChangeCollaboratorStatusModal(item = null, collaborator = null) {
      this.selectedItem = item;
      this.selectedCollaborator = collaborator;
      this.openChangeCollaboratorStatusModal = true;
    },
    handleCloseChangeCollaboratorStatusModal() {
      this.openChangeCollaboratorStatusModal = false;
    },
    async handleSubmitChangeCollaboratorStatusModal() {
      this.$eventBus.$emit("fetchOwnerClients");
    },
    handleOpenDeleteCollaborationModal(item = null) {
      this.openDeleteCollaborationModal = true;
      this.selectedItem = item;
    },
    handleCloseDeleteCollaborationModal() {
      this.openDeleteCollaborationModal = false;
    },
    handleSubmitDeleteCollaborationModal() {
      this.$eventBus.$emit("fetchCollaboratorClients");
    },
  },
};
</script>
