<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <div class="mb-4">
              <h4 class="subtitle-1 font-weight-medium">
                {{ $t("onboarding.quote.subtitle") }}
              </h4>
            </div>

            <v-row>
              <v-col
                cols="12"
                class="mb-4"
                v-for="servicePlan in servicePlans"
                :key="servicePlan.id"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="border-all px-4 py-2 rounded gray lighten-4">
                      <div class="border-bottom py-1">
                        <div class="d-flex align-items-center">
                          <span class="body-2 font-weight-bold text--primary">{{
                            servicePlan.isPackage
                              ? $t("onboarding.quote.package")
                              : servicePlan.services[0].displayName
                          }}</span>
                          <span
                            v-if="servicePlan.fee"
                            class="body-2 font-weight-bold text--primary pl-4"
                            >{{
                              $t("defaults.currency", {
                                amount: servicePlan.fee,
                              })
                            }}
                            /
                            {{
                              getServicePlanPaymentFrequencyText(
                                servicePlan.paymentFrequency
                              )
                            }}
                          </span>
                          <span v-else class="body-2 text--primary pl-4">
                            {{ $t("onboarding.quote.custom-price") }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-for="service in servicePlan.services"
                        :key="service.name"
                      >
                        <h6
                          v-if="servicePlan.isPackage"
                          class="body-2 py-1 mt-1 mb-0 text--primary font-weight-bold"
                        >
                          {{ service.displayName }}
                        </h6>
                        <div>
                          <p
                            class="body-2 py-1 mb-0"
                            v-for="(item, index) in providedServices[
                              service.name
                            ]"
                            :key="index"
                          >
                            {{ item }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <v-btn
                @click="previousStep"
                type="button"
                class="text-none body-2 font-weight-medium mr-2"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.previous") }}
              </v-btn>
              <v-btn
                type="submit"
                color="success"
                class="text-none body-2 font-weight-medium"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.next") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ServicePlanPaymentFrequency from "@/shared/enums/ServicePlanPaymentFrequency";

export default {
  data() {
    return {
      loading: false,
      isFormValid: true,
      providedServices: {
        tax: [
          "Tax credit discovery",
          "Tax deduction review",
          "Company structure review",
          "Business tax return preparation",
          "E-filing",
        ],
        bookkeeping: [
          "Free mobile and web app",
          "Dedicated finance expert",
          "Monthly Profit & Loss",
          "Monthly Balance Sheet",
          "Transaction entry",
          "Categorizing expenses",
          "Bank account reconciliation",
          "Credit card statement reconciliation",
          "Journal entries",
          "Chart of accounts",
        ],
        payroll: [
          "Payroll processing",
          "Paycheck",
          "Direct deposit",
          "Payroll summary",
          "Payroll tax payments",
          "Payroll tax filing",
        ],
      },
      servicePlans: [],
      ServicePlanPaymentFrequency,
    };
  },
  props: {
    idHash: {
      type: String,
      required: true,
    },
    isSubmitting: Boolean,
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitStep");
      }
    },
    previousStep() {
      this.$emit("previousStep");
    },
    async getLeadServicePlans() {
      const response = await window.API.getLeadServicePlans(this.idHash);
      this.servicePlans = response;
    },
    getServicePlanPaymentFrequencyText(paymentFrequency) {
      switch (paymentFrequency) {
        case ServicePlanPaymentFrequency.MONTHLY:
          return this.$t("onboarding.quote.payment-frequency.monthly");
        case ServicePlanPaymentFrequency.ANNUAL:
          return this.$t("onboarding.quote.payment-frequency.annual");
        default:
          return "";
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getLeadServicePlans();
    } finally {
      this.loading = false;
    }
  },
};
</script>
