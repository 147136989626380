<template>
  <d-modal
    :title="editMode ? $t('roles.edit.title') : $t('roles.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('roles.create.form.name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.name"
              :error="errorMessages.name.length > 0"
              :error-messages="errorMessages.name"
              @blur="checkNameAvailability"
              :loading="loading.name"
              :rules="rules.name"
              :disabled="editMode && form.isStatic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mb-3">
            <v-text-field
              :label="$t('roles.create.form.display-name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.displayName"
              :rules="rules.displayName"
              :error="errorMessages.displayName.length > 0"
              :error-messages="errorMessages.displayName"
              @blur="checkDisplayNameAvailability"
              :loading="loading.displayName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-textarea
              :label="$t('roles.create.form.description')"
              hide-details="auto"
              filled
              rows="2"
              v-model="form.description"
            ></v-textarea>
          </v-col>
          <v-col cols="12" v-if="editMode && form.isStatic">
            <div class="border-all pa-3 rounded">
              <h1
                class="caption font-weight-regular mb-1 gray--text text--darken-1"
              >
                {{ $t("roles.create.form.permissions") }}
              </h1>
              <v-chip
                small
                class="ma-1"
                v-for="(permission, index) in selectedPermissions"
                :key="index"
                >{{ permission.displayName }}</v-chip
              >
            </div>
          </v-col>
          <v-col cols="12" v-else>
            <v-autocomplete
              :items="permissions"
              v-model="form.permissions"
              item-text="displayName"
              item-value="id"
              :label="$t('roles.create.form.permissions')"
              multiple
              filled
              hide-details="auto"
              :rules="rules.permissions"
              clearable
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.displayName }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{
                    `${form.permissions.length - 3} ${$t("defaults.others")}`
                  }}
                  )
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  name: null,
  displayName: null,
  description: null,
  permissions: [],
};
const initialTempValues = {
  name: null,
  displayName: null,
};
const initialErrorMessages = {
  name: [],
  displayName: [],
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      loading: {
        name: false,
        displayName: false,
        page: false,
      },
      errorMessages: { ...initialErrorMessages },
      tempValues: { ...initialTempValues },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        displayName: [(v) => !!v || this.$t("defaults.rule.required")],
        permissions: [
          (v) => v.length > 0 || this.$t("roles.create.rule.permissions"),
        ],
      },
      editMode: false,
      permissions: [],
    };
  },
  computed: {
    selectedPermissions() {
      return this.permissions.filter((permission) =>
        this.form.permissions.some(
          (permissionId) => permissionId == permission.id
        )
      );
    },
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.errorMessages = { ...initialErrorMessages };
      this.tempValues = { ...initialTempValues };
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    createItem() {
      this.isSubmitting = true;
      window.API.insertRole(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("roles.create.alert.created", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("roles.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    editItem() {
      this.isSubmitting = true;
      window.API.editRole(this.form.id, this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("roles.edit.alert.updated", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("roles.edit.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async handleSubmitModal() {
      await this.checkNameAvailability();
      await this.checkDisplayNameAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.form.id) {
          await this.editItem();
        } else {
          await this.createItem();
        }
      }
    },
    async checkNameAvailability() {
      const { name } = this.form;

      if (!name) return;

      if (this.tempValues.name == name && this.editMode) {
        this.errorMessages.name = [];
        return;
      }

      this.loading.name = true;
      await window.API.checkNameAvailability(name)
        .then((response) => {
          if (!response) {
            this.errorMessages.name = [
              this.$t("roles.create.rule.name-is-taken", {
                name: this.form.name,
              }),
            ];
            return;
          }
          this.errorMessages.name = [];
        })
        .finally(() => (this.loading.name = false));
    },
    async checkDisplayNameAvailability() {
      const { displayName } = this.form;

      if (!displayName) return;

      if (this.tempValues.displayName == displayName && this.editMode) {
        this.errorMessages.displayName = [];
        return;
      }

      this.loading.displayName = true;
      await window.API.checkDisplayNameAvailability(displayName)
        .then((response) => {
          if (!response) {
            this.errorMessages.displayName = [
              this.$t("roles.create.rule.name-is-taken", {
                name: this.form.displayName,
              }),
            ];
            return;
          }
          this.errorMessages.displayName = [];
        })
        .finally(() => (this.loading.displayName = false));
    },
    fetchAllPermissions() {
      window.API.fetchAllPermissions()
        .then((response) => {
          this.permissions = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    async getItem(item) {
      if (item && item.id > 0) {
        this.loading.page = true;
        this.editMode = true;
        await window.API.getRoleById(item.id)
          .then((response) => {
            this.form = response;
            this.tempValues.name = response.name;
            this.tempValues.displayName = response.displayName;
          })
          .catch(() => this.showFetchRequestErrorMessage())
          .finally(() => (this.loading.page = false));
      }
    },
  },
  async mounted() {
    await this.fetchAllPermissions();
    await this.getItem(this.item);
  },
};
</script>
