<template>
  <div v-if="serviceItDepends && mainTagGroup">
    <h6 class="subtitle-1 mb-1" v-if="title">
      {{ title }}
    </h6>
    <div
      class="border-all rounded pt-4 px-3 pb-1"
      :class="{ 'is-required': invalid }"
    >
      <v-row>
        <template>
          <v-col cols="12" class="mb-1">
            <h6 class="body-2 pl-1">
              {{ mainTagGroup.displayName }}
            </h6>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-chip
              :outlined="!tag.selected"
              small
              label
              class="ma-1"
              :color="mainTagGroup.color"
              v-for="tag in mainTagGroup.subTags"
              :key="tag.id"
              dark
              @click="selectMainTagGroup(tag)"
            >
              {{ tag.displayName }}
            </v-chip>
          </v-col>
        </template>
        <template v-for="(tagGroup, index) in mainTagActivatedTagGroups">
          <v-col cols="12" class="mb-1" :key="tagGroup.displayName">
            <h6 class="body-2 pl-1">
              {{ tagGroup.displayName }}
            </h6>
          </v-col>
          <v-col cols="12" class="mb-3" :key="index">
            <v-chip
              :outlined="!tag.selected"
              small
              label
              class="ma-1"
              :color="tagGroup.color"
              v-for="tag in tagGroup.subTags"
              :key="tag.id"
              dark
              @click="selectSubTags(tag, tagGroup)"
            >
              {{ tag.displayName }}
            </v-chip>
          </v-col>
        </template>
      </v-row>
    </div>

    <v-input
      :value="selectedTags"
      hide-details="auto"
      :rules="[validateTags]"
      class="pl-3"
    >
    </v-input>
  </div>
</template>

<script>
export default {
  props: {
    serviceItDepends: Number,
    value: Array,
    title: String,
    initialValues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mainTagGroup: null,
      mainTagActivatedTagGroups: [],
      tags: [],
      invalid: false,
      ruleFirstTime: true,
      selectedTags: [],
    };
  },
  watch: {
    serviceItDepends: {
      handler() {
        this.ruleFirstTime = true;
        this.findMainTagGroup();
      },
    },
    selectedTags: {
      handler() {
        this.$emit("input", this.selectedTags);
      },
      deep: true,
    },
  },
  methods: {
    validateTags() {
      if (this.ruleFirstTime) {
        this.ruleFirstTime = false;
        return true;
      }
      if (
        this.mainTagGroup &&
        !this.mainTagGroup.subTags.some((tag) => tag.selected)
      ) {
        this.invalid = true;
        return this.$t("defaults.rule.required");
      }
      const result = this.mainTagActivatedTagGroups.filter((tagGroup) =>
        tagGroup.subTags.some((subTag) => subTag.selected)
      );
      if (result.length != this.mainTagActivatedTagGroups.length) {
        this.invalid = true;
        return this.$t("defaults.rule.required");
      }
      this.invalid = false;
      return true;
    },
    async getAllTags() {
      const response = await window.API.getAllTags();
      this.tags = response.filter((tag) => tag.isActive === true);
      this.findMainTagGroup();
    },
    selectSubTags(tag, tagGroup) {
      this.mainTagActivatedTagGroups
        .find((_tagGroup) => _tagGroup.id == tagGroup.id)
        ?.subTags.forEach((subTag) => (subTag.selected = tag.id == subTag.id));

      this.selectedTags = [
        ...this.mainTagGroup.subTags
          .filter((subTag) => subTag.selected)
          .map((subTag) => subTag.id),
        ...this.mainTagActivatedTagGroups.map(({ subTags }) =>
          subTags.filter((subTag) => subTag.selected).map((subTag) => subTag.id)
        ),
      ].flat();
    },
    selectMainTagGroup(tag) {
      this.mainTagGroup.subTags.map(
        (subTag) => (subTag.selected = subTag.id == tag.id)
      );
      this.selectedTags = this.mainTagGroup.subTags
        .filter((subTag) => subTag.selected)
        .map((subTag) => subTag.id)
        .flat();

      this.mainTagActivatedTagGroups = [];
      tag.tagGroupsItActivates.forEach((tagGroupId) => {
        const tagGroup = this.tags.find(
          (tag) => tag.tagGroup.id === tagGroupId
        )?.tagGroup;
        if (tagGroup && !tagGroup.isMainTagGroup) {
          this.mainTagActivatedTagGroups.push({
            id: tagGroup.id,
            displayName: tagGroup.displayName,
            color: tagGroup.color,
            subTags: this.tags
              .filter((tag) => tag.tagGroup.id === tagGroupId)
              .map((tag) => ({
                id: tag.id,
                displayName: tag.displayName,
                selected: false,
              })),
          });
        }
      });
    },
    findMainTagGroup() {
      this.mainTagActivatedTagGroups = [];
      this.mainTagGroup = null;
      this.selectedTags = [];

      if (this.tags.length > 0 && this.serviceItDepends) {
        let serviceTags = this.tags.filter((tag) => {
          return (
            tag.servicesItDepends.some((id) => id == this.serviceItDepends) &&
            tag.tagGroup.isMainTagGroup
          );
        });

        let group = {
          id: serviceTags[0].tagGroup.id,
          displayName: serviceTags[0].tagGroup.displayName,
          color: serviceTags[0].tagGroup.color,
        };
        let subTags = [];

        serviceTags.forEach((item) => {
          subTags.push({
            id: item.id,
            displayName: item.displayName,
            tagGroupsItActivates: item.tagGroupsItActivates,
            selected: false,
          });
        });

        this.mainTagGroup = {
          ...group,
          subTags,
        };

        if (this.initialValues.length > 0) {
          let mainTag = this.mainTagGroup.subTags.find((subTag) =>
            this.initialValues.some((tempTag) => tempTag.id == subTag.id)
          );

          this.selectMainTagGroup(mainTag);

          if (mainTag) {
            this.initialValues
              .filter((tempTag) => tempTag.id != mainTag.id)
              .forEach((subTag) => {
                this.selectSubTags(subTag, subTag.tagGroup);
              });
          }
        }
      }
    },
  },
  created() {
    this.getAllTags().catch(console.error);
  },
};
</script>
