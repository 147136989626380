<template>
  <d-modal
    :title="editMode ? $t('tags.edit.title') : $t('tags.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-text-field
              :label="$t('tags.create.form.name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.name"
              :error="errorMessages.name.length > 0"
              :error-messages="errorMessages.name"
              @blur="checkNameAvailability"
              :loading="loading.name"
              :rules="rules.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-text-field
              :label="$t('tags.create.form.display-name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.displayName"
              :rules="rules.displayName"
              :error="errorMessages.displayName.length > 0"
              :error-messages="errorMessages.displayName"
              @blur="checkDisplayNameAvailability"
              :loading="loading.displayName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <d-color-picker
              :label="$t('tags.create.form.color')"
              v-model="form.color"
              :rules="rules.color"
            ></d-color-picker>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-checkbox
              v-model="form.isMainTagGroup"
              :label="$t('tags.create.form.main-tag-group.label')"
              hide-details="auto"
              :hint="$t('tags.create.form.main-tag-group.hint')"
              persistent-hint
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  name: null,
  displayName: null,
  isMainTagGroup: false,
  color: "",
};
const initialTempValues = {
  name: null,
  displayName: null,
};
const initialErrorMessages = {
  name: [],
  displayName: [],
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      loading: {
        name: false,
        displayName: false,
        page: false,
      },
      errorMessages: { ...initialErrorMessages },
      tempValues: { ...initialTempValues },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        displayName: [(v) => !!v || this.$t("defaults.rule.required")],
        color: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      editMode: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.errorMessages = { ...initialErrorMessages };
      this.tempValues = { ...initialTempValues };
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    createItem() {
      this.isSubmitting = true;
      window.API.insertTagGroup(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("tags.create.alert.created", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("tags.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    editItem() {
      this.isSubmitting = true;
      window.API.editTagGroup(this.form.id, this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("tags.edit.alert.updated", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("tags.edit.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async handleSubmitModal() {
      await this.checkNameAvailability();
      await this.checkDisplayNameAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.form.id) {
          await this.editItem();
        } else {
          await this.createItem();
        }
      }
    },
    async checkNameAvailability() {
      const { name } = this.form;

      if (!name) return;

      if (this.tempValues.name == name && this.editMode) {
        this.errorMessages.name = [];
        return;
      }

      this.loading.name = true;
      await window.API.checkTagGroupNameAvailability(name)
        .then((response) => {
          if (!response) {
            this.errorMessages.name = [
              this.$t("tags.create.rule.name-is-taken", {
                name: this.form.name,
              }),
            ];
            return;
          }
          this.errorMessages.name = [];
        })
        .finally(() => (this.loading.name = false));
    },
    async checkDisplayNameAvailability() {
      const { displayName } = this.form;

      if (!displayName) return;

      if (this.tempValues.displayName == displayName && this.editMode) {
        this.errorMessages.displayName = [];
        return;
      }

      this.loading.displayName = true;
      await window.API.checkTagGroupDisplayNameAvailability(displayName)
        .then((response) => {
          if (!response) {
            this.errorMessages.displayName = [
              this.$t("tags.create.rule.name-is-taken", {
                name: this.form.displayName,
              }),
            ];
            return;
          }
          this.errorMessages.displayName = [];
        })
        .finally(() => (this.loading.displayName = false));
    },
    async getItem(item) {
      if (item && item.id > 0) {
        this.loading.page = true;
        this.editMode = true;
        await window.API.getTagGroupById(item.id)
          .then((response) => {
            this.form = response;
            this.tempValues.name = response.name;
            this.tempValues.displayName = response.displayName;
          })
          .catch(() => this.showFetchRequestErrorMessage())
          .finally(() => (this.loading.page = false));
      }
    },
  },
  async mounted() {
    await this.getItem(this.item);
  },
};
</script>
