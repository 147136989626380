<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('roles.search.form.name')"
        hide-details="auto"
        filled
        type="text"
        v-model="searchParams.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('roles.search.form.display-name')"
        hide-details="auto"
        filled
        type="text"
        v-model="searchParams.displayName"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
  },
};
</script>
