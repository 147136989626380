<template>
  <d-page :title="$t('companies.page-heading')">
    <template slot="content">
      <List />
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";

export default {
  components: {
    List,
  },
  data() {
    return {
      selectedItem: null,
    };
  },
};
</script>
