var qs = require("qs");

export function fetchReceivedDocs(params) {
  return window.axios
    .get("/document/documents/received", {
      params: { ...params },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNulls: true });
      },
    })
    .then((response) => response.data);
}

export function deleteReceivedDocs(ids) {
  return window.axios
    .delete("/document/documents/received", {
      params: { ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNulls: true });
      },
    })
    .then((response) => response);
}

export function editRecievedDocument(id, data) {
  return window.axios.put(`/document/documents/received/${id}`, data);
}

export function changeDocumentStatus(data) {
  return window.axios
    .post(`/document/documents/received/change-status`, data)
    .then((response) => response);
}
