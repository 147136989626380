var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.table.items.length > 0 || _vm.loading.table)?_c('v-data-table',{staticClass:"d-table",attrs:{"page":_vm.table.page,"headers":_vm.headers,"items":_vm.table.items,"items-per-page":_vm.table.itemsPerPage,"loading":_vm.loading.table,"server-items-length":_vm.table.totalCount,"footer-props":{
      'items-per-page-options': _vm.table.itemsPerPageOptions,
    },"options":_vm.table.options},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:`item.fullName`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-tooltip',{attrs:{"right":"","color":item.isActive ? 'success' : 'error'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":item.isActive ? 'success' : 'error',"size":"12","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isActive ? _vm.$t("clients.list.table.status.active") : _vm.$t("clients.list.table.status.inactive")))])]),_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.isBusiness(item) ? "mdi-briefcase" : "mdi-account")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isBusiness(item) ? _vm.$t("clients.client-type.business") : _vm.$t("clients.client-type.personal")))])]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.fullName))])],1)]}},{key:`item.advisor`,fn:function({ item }){return [(item.advisor)?_c('span',[_vm._v(_vm._s(item.advisor))]):_c('span',{staticClass:"caption text--disabled"},[_vm._v(_vm._s(_vm.$t("clients.list.table.no-advisor")))])]}},{key:`item.services`,fn:function({ item }){return [_vm._l((item.services),function(service,index){return [_c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(service)+" ")])]})]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-menu',{directives:[{name:"granted",rawName:"v-granted",value:(
            'client:edit|client:view|client:change-status|client:delete'
          ),expression:"\n            'client:edit|client:view|client:change-status|client:delete'\n          "}],attrs:{"bottom":"","offset-y":"","content-class":"elevation-0 mt-1 rounded"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray darken-2","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"rounded",attrs:{"outlined":"","dense":""}},[_c('v-list-item',{directives:[{name:"granted",rawName:"v-granted",value:('client:edit'),expression:"'client:edit'"}],attrs:{"to":{
                name: 'EditClient',
                params: {
                  id: item.id,
                  clientType: _vm.isBusiness(item) ? 'business' : 'personal',
                },
              }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("clients.list.table.actions.edit"))+" ")])],1),_c('v-list-item',{directives:[{name:"granted",rawName:"v-granted",value:('client:view'),expression:"'client:view'"}],on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("clients.list.table.actions.details"))+" ")])],1),_c('v-list-item',{directives:[{name:"granted",rawName:"v-granted",value:('client:change-status'),expression:"'client:change-status'"}],on:{"click":function($event){return _vm.changeStatus(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.isActive ? _vm.$t("clients.list.table.actions.deactivate") : _vm.$t("clients.list.table.actions.activate"))+" ")])],1),_c('v-list-item',{directives:[{name:"granted",rawName:"v-granted",value:('client:delete'),expression:"'client:delete'"}],on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("clients.list.table.actions.delete"))+" ")])],1)],1)],1)],1)]}}],null,true)}):_vm._e(),(!_vm.table.isSearched && _vm.table.totalCount == 0 && !_vm.loading.table)?_c('d-page-empty',{attrs:{"createButtonPermission":'client:add',"text":_vm.$t('clients.list.empty-page'),"createButtonText":_vm.$t('clients.button.create-item')},on:{"actionClick":function($event){return _vm.addItem()}}}):_vm._e(),(_vm.table.isSearched && _vm.table.totalCount == 0 && !_vm.loading.table)?_c('d-no-result'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }