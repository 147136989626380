<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.intake-questions.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.intake-questions.industry") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{
                item.industry
                  ? fillEmptyData(item.industry?.name)
                  : fillEmptyData(item.otherIndustryName)
              }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.intake-questions.annual-sales-range") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.annualSalesRange) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.intake-questions.employee-count-range") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.employeeCountRange) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.intake-questions.services") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ item.services ? item.services.join(", ") : fillEmptyData() }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.intake-questions.ready-financial-report.label"
                )
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ hasAnyReadyFinancialReport }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t("leads.details.intake-questions.year-to-get-tax-service")
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.yearToGetTaxService) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.intake-questions.currently-doing-bookkeeping-service.label"
                )
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ currentlyDoingBookkeeping }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.intake-questions.year-to-get-bookkeeping-service"
                )
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.yearToGetBookkeepingService) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t("leads.details.intake-questions.accounting-software-tool")
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.accountingSoftwareTool) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.intake-questions.currently-getting-payroll-service.label"
                )
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ currentlyGettingPayrollService }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.intake-questions.acquisition-channel") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.acquisitionChannel) }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    currentlyDoingBookkeeping() {
      if (this.item.currentlyDoingBookkeeping === false) {
        return this.$t(
          "leads.details.intake-questions.ready-financial-report.no"
        );
      }

      if (this.item.currentlyDoingBookkeeping === true) {
        return this.$t(
          "leads.details.intake-questions.ready-financial-report.yes"
        );
      }

      return this.fillEmptyData();
    },
    currentlyGettingPayrollService() {
      if (this.item.currentlyGettingPayrollService === false) {
        return this.$t(
          "leads.details.intake-questions.currently-getting-payroll-service.no"
        );
      }

      if (this.item.currentlyGettingPayrollService === true) {
        return this.$t(
          "leads.details.intake-questions.currently-getting-payroll-service.yes"
        );
      }

      return this.fillEmptyData();
    },
    hasAnyReadyFinancialReport() {
      if (this.item.hasAnyReadyFinancialReport === false) {
        return this.$t(
          "leads.details.intake-questions.ready-financial-report.no"
        );
      }

      if (this.item.hasAnyReadyFinancialReport === true) {
        return this.$t(
          "leads.details.intake-questions.ready-financial-report.yes"
        );
      }

      return this.fillEmptyData();
    },
  },
};
</script>
