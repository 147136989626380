<template>
  <d-page
    :title="$t('received-docs.page-heading')"
    :detailsTitle="$t('documents.comments.title')"
    :openDetails="openComments"
    @closeDetails="handleCloseComments"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="error"
        v-granted="'received:delete'"
        @click="handleOpenDeleteModal"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-delete </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("received-docs.button.delete")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="grey lighten-4"
        @click="handleDownloadSelectedDocuments"
        :loading="loading.download"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-file-download </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("received-docs.button.download")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="grey lighten-4"
        v-granted="'received:change-status'"
        @click="handleOpenChangeStatus"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-cached</v-icon>
        <span class="subtitle-2 text-none">{{
          $t("received-docs.button.change-status")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("received-docs.button.search-toggle")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search :services="services" />
    </template>
    <template slot="content">
      <List
        @viewDocument="handleOpenViewDocument"
        @shareDocument="handleOpenShareDocument"
        @viewComments="handleOpenComments"
        @updateSelectedItems="handleUpdateSelectedItems"
        @editDocument="handleOpenEditDocument"
        :selectedItem="selectedItem"
        :isEditing="openEditModal"
        :isViewing="loading.preview"
        :isSharing="openShareModal"
        :isCommenting="openComments"
        :services="services"
        v-if="!loading.page"
      ></List>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :selectedItems="selectedItems"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
        :type="DocumentTypes.RECEIVED"
      ></Delete>
      <Share
        v-if="openShareModal"
        :modal="openShareModal"
        @closeModal="handleCloseShareModal"
        :item="selectedItem"
      ></Share>
      <Edit
        v-if="openEditModal"
        :modal="openEditModal"
        @closeModal="handleCloseEditDocumentModal"
        @submitModal="handleSubmitEditDocumentModal"
        :item="selectedItem"
        :type="DocumentTypes.RECEIVED"
      >
      </Edit>
      <ChangeStatus
        v-if="openChangeStatusModal"
        :modal="openChangeStatusModal"
        @closeModal="handleCloseChangeStatusModal"
        :selectedItems="selectedItems"
      ></ChangeStatus>
    </template>
    <template slot="details">
      <Comment v-if="openComments" :item="selectedItem"></Comment>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import Search from "./Search.vue";
import Delete from "@/components/documents/Delete.vue";
import Comment from "@/components/documents/Comment.vue";
import documentMixin from "@/mixins/documentMixins";
import Share from "@/components/documents/Share.vue";
import Edit from "@/components/documents/Edit.vue";
import DocumentTypes from "@/shared/enums/DocumentTypes";
import ChangeStatus from "./ChangeStatus.vue";
import { mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";

export default {
  mixins: [documentMixin],
  components: {
    List,
    Search,
    Delete,
    Comment,
    Share,
    Edit,
    ChangeStatus,
  },
  data() {
    return {
      DocumentTypes,
      openDeleteModal: false,
      searchToggle: false,
      selectedItem: null,
      selectedItems: [],
      openComments: false,
      openShareModal: false,
      openEditModal: false,
      openChangeStatusModal: false,
      services: [],
      loading: {
        page: false,
      },
    };
  },
  computed: {
    hasSelectedItems() {
      return this.selectedItems && this.selectedItems.length > 0;
    },
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
  },
  methods: {
    handleCloseSearch() {
      this.$eventBus.$emit("fetchReceivedDocs", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchReceivedDocs");
    },
    handleUpdateSelectedItems(ids = []) {
      this.selectedItems = ids;
    },
    handleOpenComments(item = null) {
      this.selectedItem = item;
      this.openComments = true;
    },
    handleCloseComments() {
      this.openComments = false;
    },
    handleOpenDeleteModal() {
      this.openDeleteModal = true;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchReceivedDocs");
    },
    handleOpenViewDocument(item = null) {
      this.selectedItem = item;
      this.previewDocument(item.id);
    },
    handleDownloadSelectedDocuments() {
      this.downloadSelectedDocuments(this.selectedItems);
    },
    handleOpenShareDocument(item = null) {
      this.selectedItem = item;
      this.openShareModal = true;
    },
    handleCloseShareModal() {
      this.openShareModal = false;
    },
    handleOpenEditDocument(item = null) {
      this.selectedItem = item;
      this.openEditModal = true;
    },
    handleCloseEditDocumentModal() {
      this.openEditModal = false;
    },
    handleSubmitEditDocumentModal() {
      this.$eventBus.$emit("fetchReceivedDocs");
    },
    handleOpenChangeStatus() {
      this.openChangeStatusModal = true;
    },
    handleCloseChangeStatusModal() {
      this.openChangeStatusModal = false;
    },
    async fetchAllServices() {
      return await window.API.fetchAllServices()
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    async fetchClientAllServices(clientId) {
      return await window.API.fetchClientAllServices(clientId)
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async created() {
    try {
      this.loading.page = true;
      if (authorizationUtils.isClient()) {
        await this.fetchClientAllServices(this.myCurrentClient.id);
      } else {
        await this.fetchAllServices();
      }
    } finally {
      this.loading.page = false;
    }
  },
};
</script>
