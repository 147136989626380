<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.order-summary.section-title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="py-3">
        <v-row>
          <v-col cols="12" class="mb-1" v-if="company.isResident">
            <span class="subtitle-2 font-weight-medium grey--text">
              {{
                $t("companies.details.order-summary.state-registration-fee")
              }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
            >
              {{
                company.registrationPayment.stateFilingFee
                  ? $t("defaults.currency", {
                      amount: company.registrationPayment.stateFilingFee,
                    })
                  : fillEmptyData(company.registrationPayment.stateFilingFee)
              }}
            </span>
          </v-col>
          <v-col cols="12" class="mb-1" v-if="company.isResident">
            <span class="subtitle-2 font-weight-medium grey--text">
              {{ $t("companies.details.order-summary.payment-processing") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
            >
              {{
                company.registrationPayment.processingFee
                  ? $t("defaults.currency", {
                      amount: company.registrationPayment.processingFee,
                    })
                  : fillEmptyData(company.registrationPayment.processingFee)
              }}
            </span>
          </v-col>
          <v-col cols="12" class="mb-1" v-if="company.isResident">
            <span class="subtitle-2 font-weight-medium grey--text">
              {{ $t("companies.details.order-summary.service-fee") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
            >
              <template v-if="company.registrationPayment.coupon">
                <span class="d-inline-flex align-center">
                  <span class="mr-1">
                    {{
                      `(${company.registrationPayment.coupon.code} ${
                        company.registrationPayment.coupon.discountType ==
                        DiscountTypes.FIXED
                          ? "$"
                          : "%"
                      }${company.registrationPayment.coupon.discountValue} ${$t(
                        "companies.details.order-summary.off"
                      )})`
                    }}
                  </span>

                  <span class="text-decoration-line-through mr-1">
                    {{
                      $t("defaults.currency", {
                        amount: company.registrationPayment.serviceFee,
                      })
                    }}
                  </span>

                  <span>
                    {{
                      $t("defaults.currency", {
                        amount: discountedServiceFee,
                      })
                    }}
                  </span>
                </span>
              </template>
              <template v-else>
                {{
                  company.registrationPayment.serviceFee
                    ? $t("defaults.currency", {
                        amount: company.registrationPayment.serviceFee,
                      })
                    : fillEmptyData(company.registrationPayment.serviceFee)
                }}
              </template>
            </span>
          </v-col>
          <v-col cols="12" class="mb-1">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.order-summary.total") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{
                company.registrationPayment.totalAmount
                  ? $t("defaults.currency", {
                      amount: company.registrationPayment.totalAmount,
                    })
                  : fillEmptyData(company.registrationPayment.totalAmount)
              }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import DiscountTypes from "@/shared/enums/DiscountTypes";
export default {
  props: {
    company: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      DiscountTypes,
    };
  },
  computed: {
    discountedServiceFee() {
      if (this.company.registrationPayment.coupon) {
        return (
          this.company.registrationPayment.serviceFee -
          (this.company.registrationPayment.coupon.discountType ==
          DiscountTypes.PERCENTAGE
            ? (this.company.registrationPayment.serviceFee *
                this.company.registrationPayment.coupon.discountValue) /
              100
            : this.company.registrationPayment.coupon.discountValue)
        );
      }

      return this.company.registrationPayment.serviceFee;
    },
  },
};
</script>
