<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
  >
    <div class="onboarding-modal--body px-4">
      <v-row>
        <v-col cols="12" class="mb-4">
          <v-text-field
            class="d-required"
            :label="$t('onboarding.billing-info.form.company-bank-name')"
            hide-details="auto"
            filled
            type="text"
            v-model="form.companyBankName"
            :rules="rules.companyBankName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-4">
          <v-text-field
            class="d-required"
            :label="
              $t('onboarding.billing-info.form.company-bank-routing-number')
            "
            hide-details="auto"
            filled
            type="text"
            v-model="form.companyBankRoutingNumber"
            :rules="rules.companyBankRoutingNumber"
            v-mask="'#########'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-4">
          <v-text-field
            class="d-required"
            :label="
              $t('onboarding.billing-info.form.company-bank-accounting-number')
            "
            hide-details="auto"
            filled
            type="text"
            v-model="form.companyBankAccountingNumber"
            :rules="rules.companyBankAccountingNumber"
            @keypress="allowOnlyNumbers()"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="onboarding--modal--footer">
      <div class="border-top py-3 px-4">
        <div class="d-flex align-center justify-end">
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
            :loading="isSubmitting"
          >
            {{ $t("onboarding.step-actions.next") }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
    },
    isSubmitting: Boolean,
  },
  data() {
    return {
      form: null,
      isFormValid: true,
      rules: {
        companyBankName: [(v) => !!v || this.$t("defaults.rule.required")],
        companyBankRoutingNumber: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /\b\d{9}\b/g.test(v) ||
            this.$t(
              "onboarding.billing-info.rule.company-bank-routing-number.invalid"
            ),
        ],
        companyBankAccountingNumber: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
      },
    };
  },
  methods: {
    async submitStep() {
      if (this.$refs.form.validate() && this.isFormValid) {
        this.$emit("submitStep", this.form);
      }
    },
    setInitialForm() {
      this.form = {
        companyBankName: this.formData.companyBankName,
        companyBankRoutingNumber: this.formData.companyBankRoutingNumber,
        companyBankAccountingNumber: this.formData.companyBankAccountingNumber,
      };
    },
  },
  async created() {
    await this.setInitialForm();
  },
};
</script>
