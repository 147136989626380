<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.document-uploads.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="py-3">
        <v-row>
          <v-col cols="12" md="2" class="mb-2">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.document-uploads.tax-return-attachment") }}:
            </span>
          </v-col>
          <v-col cols="12" md="10" class="mb-2">
            <v-chip
              v-if="item.taxReturnAttachment"
              color="primary"
              label
              @click="previewTaxReturnAttachment"
            >
              <v-progress-circular
                v-if="loading.taxReturnAttachmentPreview"
                indeterminate
                color="white"
                size="20"
                class="mr-2"
              ></v-progress-circular>
              <v-icon v-else left size="20"> mdi-file-document </v-icon>
              {{ item.taxReturnAttachment.fileName }}
            </v-chip>
            <span
              class="body-2 font-weight-regular grey--text text--darken-3"
              v-else
              >{{ fillEmptyData() }}</span
            >
          </v-col>
          <v-col cols="12" md="2" class="mb-2">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.document-uploads.employer-identification-number-attachment"
                )
              }}:
            </span>
          </v-col>
          <v-col cols="12" md="10" class="mb-2">
            <v-chip
              v-if="item.employerIdentificationNumberAttachment"
              color="primary"
              label
              @click="previewEINAttachment"
            >
              <v-progress-circular
                v-if="loading.einAttachmentPreview"
                indeterminate
                color="white"
                size="20"
                class="mr-2"
              ></v-progress-circular>
              <v-icon v-else left size="20"> mdi-file-document </v-icon>
              {{ item.employerIdentificationNumberAttachment.fileName }}
            </v-chip>
            <span
              class="body-2 font-weight-regular grey--text text--darken-3"
              v-else
              >{{ fillEmptyData() }}</span
            >
          </v-col>
          <v-col cols="12" md="2" class="mb-2">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.document-uploads.article-of-incorporation-attachment"
                )
              }}:
            </span>
          </v-col>
          <v-col cols="12" md="10" class="mb-2">
            <v-chip
              v-if="item.articleOfIncorporationAttachment"
              color="primary"
              label
              @click="previewArticleOfIncorporationAttachment"
            >
              <v-progress-circular
                v-if="loading.articleOfIncorporationPreview"
                indeterminate
                color="white"
                size="20"
                class="mr-2"
              ></v-progress-circular>
              <v-icon v-else left size="20"> mdi-file-document </v-icon>
              {{ item.articleOfIncorporationAttachment.fileName }}
            </v-chip>
            <span
              class="body-2 font-weight-regular grey--text text--darken-3"
              v-else
              >{{ fillEmptyData() }}</span
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: {
        taxReturnAttachmentPreview: false,
        einAttachmentPreview: false,
        articleOfIncorporationPreview: false,
      },
    };
  },
  methods: {
    async previewTaxReturnAttachment() {
      try {
        this.loading.taxReturnAttachmentPreview = true;
        const response = await window.API.previewTaxReturnAttachment(
          this.item.id
        );

        if (response.data) {
          this.previewFile(response);
        }
      } finally {
        this.loading.taxReturnAttachmentPreview = false;
      }
    },
    async previewEINAttachment() {
      try {
        this.loading.einAttachmentPreview = true;
        const response = await window.API.previewEINAttachment(this.item.id);

        if (response.data) {
          this.previewFile(response);
        }
      } finally {
        this.loading.einAttachmentPreview = false;
      }
    },
    async previewArticleOfIncorporationAttachment() {
      try {
        this.loading.articleOfIncorporationPreview = true;
        const response =
          await window.API.previewArticleOfIncorporationAttachment(
            this.item.id
          );

        if (response.data) {
          this.previewFile(response);
        }
      } finally {
        this.loading.articleOfIncorporationPreview = false;
      }
    },
  },
};
</script>
