var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('d-loading'):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitStep.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('div',{staticClass:"py-3 border-bottom mb-4"},[_c('h4',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t( "company-formation.resident.step-2.form.registered-agent.registered-agent-type" ))+" ")])]),_c('div',[_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","filled":"","hide-details":"auto","rules":_vm.rules.registeredAgentType},model:{value:(_vm.form.registeredAgent.registeredAgentType),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "registeredAgentType", $$v)},expression:"form.registeredAgent.registeredAgentType"}},_vm._l((_vm.registeredAgentTypes),function(registeredAgentType){return _c('v-radio',{key:registeredAgentType.value,attrs:{"label":registeredAgentType.displayText,"value":registeredAgentType.value}})}),1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[(
            _vm.form.registeredAgent.registeredAgentType ==
            _vm.RegisteredAgentTypes.INDIVIDUAL
          )?[_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.registered-agent.first-name'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentFirstName},model:{value:(_vm.form.registeredAgent.firstName),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "firstName", $$v)},expression:"form.registeredAgent.firstName"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.registered-agent.last-name'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentLastName},model:{value:(_vm.form.registeredAgent.lastName),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "lastName", $$v)},expression:"form.registeredAgent.lastName"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.registered-agent.middle-name'
                ),"hide-details":"auto","filled":"","type":"text"},model:{value:(_vm.form.registeredAgent.middleName),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "middleName", $$v)},expression:"form.registeredAgent.middleName"}})],1)]:_vm._e(),(
            _vm.form.registeredAgent.registeredAgentType ==
            _vm.RegisteredAgentTypes.COMPANY
          )?[_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.registered-agent.company-name'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentCompanyName},model:{value:(_vm.form.registeredAgent.companyName),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "companyName", $$v)},expression:"form.registeredAgent.companyName"}})],1)]:_vm._e()],2)],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
            'company-formation.resident.step-2.form.registered-agent.address-line-1'
          ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentAddressLine1},model:{value:(_vm.form.registeredAgent.addressLine1),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "addressLine1", $$v)},expression:"form.registeredAgent.addressLine1"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
            'company-formation.resident.step-2.form.registered-agent.address-line-2'
          ),"hide-details":"auto","filled":"","type":"text"},model:{value:(_vm.form.registeredAgent.addressLine2),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "addressLine2", $$v)},expression:"form.registeredAgent.addressLine2"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('company-formation.resident.step-2.form.registered-agent.city'),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentCity},model:{value:(_vm.form.registeredAgent.city),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "city", $$v)},expression:"form.registeredAgent.city"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-autocomplete',{attrs:{"filled":"","items":_vm.filteredCounties,"item-text":"name","item-value":"id","hide-details":"auto","clearable":"","label":_vm.$t('company-formation.resident.step-2.form.registered-agent.county'),"rules":_vm.rules.registeredAgentCounty},model:{value:(_vm.form.registeredAgent.countyId),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "countyId", $$v)},expression:"form.registeredAgent.countyId"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"label":_vm.$t(
            'company-formation.resident.step-2.form.registered-agent.zip-code'
          ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentZipCode},model:{value:(_vm.form.registeredAgent.zipCode),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "zipCode", $$v)},expression:"form.registeredAgent.zipCode"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
            'company-formation.resident.step-2.form.registered-agent.email-address'
          ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentEmailAddress},model:{value:(_vm.form.registeredAgent.emailAddress),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "emailAddress", $$v)},expression:"form.registeredAgent.emailAddress"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
            'company-formation.resident.step-2.form.registered-agent.phone-number'
          ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.registeredAgentPhoneNumber},model:{value:(_vm.form.registeredAgent.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form.registeredAgent, "phoneNumber", $$v)},expression:"form.registeredAgent.phoneNumber"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('div',{staticClass:"py-3 border-bottom mb-4"},[_c('h4',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t( "company-formation.resident.step-2.form.business-address.section-heading" ))+" ")])]),_c('div',{staticClass:"mb-4"},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","filled":"","hide-details":"auto"},model:{value:(_vm.form.businessAddressType),callback:function ($$v) {_vm.$set(_vm.form, "businessAddressType", $$v)},expression:"form.businessAddressType"}},[_c('v-radio',{attrs:{"label":_vm.$t(
                'company-formation.resident.step-2.business-address-types.predefined-address'
              ),"value":_vm.BusinessAddressTypes.PREDEFINED_ADDRESS}}),_c('v-radio',{attrs:{"label":_vm.$t(
                'company-formation.resident.step-2.business-address-types.new-address'
              ),"value":_vm.BusinessAddressTypes.NEW_ADDRESS}})],1)],1),(_vm.form.businessAddressType == _vm.BusinessAddressTypes.NEW_ADDRESS)?[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.address-line-1'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.businessAddressAddressLine1},model:{value:(_vm.businessAddress.addressLine1),callback:function ($$v) {_vm.$set(_vm.businessAddress, "addressLine1", $$v)},expression:"businessAddress.addressLine1"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.address-line-2'
                ),"hide-details":"auto","filled":"","type":"text"},model:{value:(_vm.businessAddress.addressLine2),callback:function ($$v) {_vm.$set(_vm.businessAddress, "addressLine2", $$v)},expression:"businessAddress.addressLine2"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.city'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.businessAddressCity},model:{value:(_vm.businessAddress.city),callback:function ($$v) {_vm.$set(_vm.businessAddress, "city", $$v)},expression:"businessAddress.city"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.zip-code'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.businessAddressZipCode},model:{value:(_vm.businessAddress.zipCode),callback:function ($$v) {_vm.$set(_vm.businessAddress, "zipCode", $$v)},expression:"businessAddress.zipCode"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.email-address'
                ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.businessAddressEmailAddress},model:{value:(_vm.businessAddress.emailAddress),callback:function ($$v) {_vm.$set(_vm.businessAddress, "emailAddress", $$v)},expression:"businessAddress.emailAddress"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                  'company-formation.resident.step-2.form.business-address.phone-number'
                ),"hide-details":"auto","filled":"","type":"text"},model:{value:(_vm.businessAddress.phoneNumber),callback:function ($$v) {_vm.$set(_vm.businessAddress, "phoneNumber", $$v)},expression:"businessAddress.phoneNumber"}})],1)],1)]:_vm._e()],2),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"text-none body-2 font-weight-medium mr-2",attrs:{"type":"button","depressed":""},on:{"click":_vm.cancelStep}},[_vm._v(" "+_vm._s(_vm.$t("company-formation.resident.step-2.actions.cancel"))+" ")]),_c('v-btn',{staticClass:"text-none body-2 font-weight-medium",attrs:{"type":"submit","color":"success","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("company-formation.resident.step-2.actions.submit"))+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }