<template>
  <d-modal
    :title="
      editMode
        ? $t('contractors.edit.title', {
            contractorType: contractorTypeText,
          })
        : $t('contractors.create.title', {
            contractorType: contractorTypeText,
          })
    "
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="prepend-title">
      <div class="mr-2">
        <v-tooltip right color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              {{ isBusiness ? "mdi-briefcase" : "mdi-account" }}
            </v-icon>
          </template>
          <span>
            {{
              isBusiness
                ? $t("contractors.contractor-type.business")
                : $t("contractors.contractor-type.individual")
            }}
          </span>
        </v-tooltip>
      </div>
    </template>

    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <template v-if="isBusiness">
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                :label="$t('contractors.create.form.business-name')"
                v-model.trim="form.businessName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.businessName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                :label="
                  $t('contractors.create.form.employer-identification-number')
                "
                v-model.trim="form.employerIdentificationNumber"
                hide-details="auto"
                filled
                type="text"
                v-mask="'##-#######'"
                :rules="rules.employerIdentificationNumber"
              ></v-text-field>
            </v-col>
          </template>
          <template v-if="isIndividual">
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                :label="$t('contractors.create.form.first-name')"
                v-model.trim="form.firstName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                :label="$t('contractors.create.form.last-name')"
                v-model.trim="form.lastName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="mb-3">
              <v-text-field
                :label="$t('contractors.create.form.ssn-or-tin')"
                v-model.trim="
                  form.socialSecurityNumberOrTaxpayerIdentificationNumber
                "
                hide-details="auto"
                filled
                type="text"
                :rules="
                  rules.socialSecurityNumberOrTaxpayerIdentificationNumber
                "
                v-mask="'#########'"
              ></v-text-field>
            </v-col>
          </template>
          <v-col cols="12" md="6" class="mb-3">
            <v-text-field
              :label="$t('contractors.create.form.address-line1')"
              v-model.trim="form.addressLine1"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.addressLine1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-text-field
              :label="$t('contractors.create.form.address-line2')"
              v-model.trim="form.addressLine2"
              hide-details="auto"
              filled
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-text-field
              :label="$t('contractors.create.form.city')"
              v-model.trim="form.city"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.city"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-autocomplete
              filled
              :items="states"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              :label="$t('contractors.create.form.state')"
              v-model="form.stateId"
              :rules="rules.state"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-text-field
              :label="$t('contractors.create.form.zip-code')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.zipCode"
              v-mask="'#####'"
              :rules="rules.zipCode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="mb-3">
            <v-currency-field
              :label="$t('contractors.create.form.total-payment')"
              filled
              :rules="rules.totalPayment"
              v-model="form.totalPayment"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  contractorType: ContractorTypes.INDIVIDUAL,
  businessName: null,
  firstName: null,
  lastName: null,
  employerIdentificationNumber: null,
  socialSecurityNumberOrTaxpayerIdentificationNumber: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  stateId: null,
  zipCode: null,
  totalPayment: null,
};

import ContractorTypes from "@/shared/enums/ContractorTypes";
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    contractorType: {
      type: Number || String,
      required: true,
      default: ContractorTypes.INDIVIDUAL,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ContractorTypes,
      form: { ...initialForm },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        businessName: [(v) => !!v || this.$t("defaults.rule.required")],
        employerIdentificationNumber: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /\b\d{2}-\d{7}\b/g.test(v) ||
            this.$t(
              "contractors.create.rule.employer-identification-number.invalid"
            ),
        ],
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        socialSecurityNumberOrTaxpayerIdentificationNumber: [
          (v) => {
            if (!v) return this.$t("defaults.rule.required");

            if (v && /\b\d{9}\b/g.test(v)) return true;
            return this.$t("contractors.create.rule.ssn-or-tin.invalid");
          },
        ],
        addressLine1: [(v) => !!v || this.$t("defaults.rule.required")],
        city: [(v) => !!v || this.$t("defaults.rule.required")],
        state: [(v) => !!v || this.$t("defaults.rule.required")],
        zipCode: [
          (v) => {
            if (!v) return this.$t("defaults.rule.required");

            if (v && /\b\d{5}\b/g.test(v)) return true;
            return this.$t("contractors.create.rule.zip-code.invalid");
          },
        ],
        totalPayment: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => v !== "0.00" || this.$t("contractors.create.rule.total-payment.invalid"),
        ],
      },
      states: [],
      loading: {
        page: false,
      },
      editMode: false,
    };
  },
  computed: {
    isIndividual() {
      return this.contractorType === ContractorTypes.INDIVIDUAL;
    },
    isBusiness() {
      return this.contractorType === ContractorTypes.BUSINESS;
    },
    contractorTypeText() {
      return this.isBusiness
        ? this.$t("contractors.contractor-type.business")
        : this.$t("contractors.contractor-type.individual");
    },
    businessNameOrFullName() {
      return (
        this.form.businessName ?? `${this.form.firstName} ${this.form.lastName}`
      );
    },
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      this.form.contractorType = this.contractorType;

      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.form.id) {
          await this.editItem();
        } else {
          await this.createItem();
        }
      }
    },
    createItem() {
      this.isSubmitting = true;
      window.API.insertContractor(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("contractors.create.alert.created"),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("contractors.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    editContractor(form) {
      if (this.isBusiness) {
        return window.API.editBusinessContractor(form);
      } else if (this.isIndividual) {
        return window.API.editIndividualContractor(form);
      }
    },
    editItem() {
      this.isSubmitting = true;
      this.editContractor(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("contractors.edit.alert.updated", {
              businessNameOrFullName: this.businessNameOrFullName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("contractors.edit.could-not-update"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    fetchAllStates() {
      window.API.fetchAllStates()
        .then((response) => {
          this.states = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    getContractor(id) {
      if (this.isBusiness) {
        return window.API.getBusinessContractorById(id);
      } else if (this.isIndividual) {
        return window.API.getIndividualContractorById(id);
      }
    },
    async getItem(item) {
      if (item && item.id > 0) {
        this.loading.page = true;
        this.editMode = true;

        await this.getContractor(item.id)
          .then((response) => {
            this.form = response;
            this.form.stateId = response.stateId.toString();
          })
          .catch(() => this.showFetchRequestErrorMessage())
          .finally(() => (this.loading.page = false));
      }
    },
  },
  async mounted() {
    await this.fetchAllStates();
    await this.getItem(this.item);
  },
};
</script>
