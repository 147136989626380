<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.billing-info.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.billing-info.company-bank-name") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyBankName) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t("leads.details.billing-info.company-bank-routing-number")
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyBankRoutingNumber) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t("leads.details.billing-info.company-bank-accounting-number")
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyBankAccountingNumber) }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
