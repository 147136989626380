export function fetchCoupons(params) {
  return window.axios
    .get("/company/coupons", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function insertCoupon(data) {
  return window.axios
    .post("/company/coupons", data)
    .then((response) => response);
}

export function activateCoupon(id) {
  return window.axios
    .patch(`/company/coupons/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateCoupon(id) {
  return window.axios
    .patch(`/company/coupons/${id}/deactivate`)
    .then((response) => response.data);
}

export function checkCouponCodeAvailability(code) {
  return window.axios
    .get(`/company/coupons/code/${code}/is-available`)
    .then((response) => response.data);
}

export function verifyCoupon(code) {
  return window.axios
    .get(`/company/coupons/${encodeURIComponent(code)}/verify`)
    .then((response) => response.data);
}
