<template>
  <d-loading v-if="loading.page"></d-loading>
  <d-page
    v-else
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="heading">
      <div class="d-flex align-center">
        <v-btn exact icon class="mr-2" :to="backToReconciliations">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <h4 class="body-1 font-weight-bold mr-2">{{ clientName }}</h4>
        <h4 class="body-1 font-weight-normal gray--text mr-2">/</h4>
        <h4 class="body-1 font-weight-bold">
          {{
            `${account.institution.name} | --${account.mask} | ${account.name}`
          }}
        </h4>
      </div>
    </template>

    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        @click="exportTransactions"
        :loading="loading.export"
        v-granted="'reconciliation:transaction:export'"
      >
        <v-icon left> mdi-file-excel </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("reconciliations.transactions.button.export-data")
        }}</span>
      </v-btn>

      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("reconciliations.transactions.button.search-toggle")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search :months="months" :years="years"></Search>
    </template>
    <template slot="content">
      <List
        :months="months"
        :years="years"
        :startedYear="startedYear"
        :clientId="clientId"
        :institutionAccountId="institutionAccountId"
      ></List>
    </template>
  </d-page>
</template>

<script>
import List from "./TransactionsList.vue";
import Search from "./TransactionsSearch.vue";
import Months from "@/shared/enums/Months.js";
import authorizationUtils from "@/utils/authorization.js";
import { mapGetters } from "vuex";

export default {
  components: {
    List,
    Search,
  },
  data() {
    return {
      searchToggle: false,
      months: [...Months],
      startedYear: 2022,
      loading: { page: true, export: false },
      clientId: null,
      institutionAccountId: null,
    };
  },
  computed: {
    years() {
      const currentYear = this.$moment().format("YYYY");

      let years = [];

      for (let i = this.startedYear; i <= currentYear; i++) {
        years.push({ value: i.toString(), displayText: i.toString() });
      }

      return years;
    },
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
      searchParams: "SearchParams/getSearchParams",
    }),

    clientName() {
      return authorizationUtils.isClient()
        ? this.myCurrentClient.name
        : this.account.client.name;
    },
    backToReconciliations() {
      return authorizationUtils.isClient()
        ? {
            name: "Reconciliations",
          }
        : {
            name: "Reconciliations",
            query: { clientId: this.clientId },
          };
    },
  },
  methods: {
    handleCloseSearch() {
      this.$eventBus.$emit("fetchTransactions", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchTransactions");
    },
    async getPlaidInstitutionAccount() {
      try {
        this.loading.page = true;

        this.account = await window.API.getPlaidInstitutionAccount(
          this.clientId,
          this.institutionAccountId
        );
      } catch {
        this.$router.push(this.backToReconciliations);
      } finally {
        this.loading.page = false;
      }
    },
    async exportTransactions() {
      try {
        this.loading.export = true;
        await window.API.exportPlaidInstitutionAccountTransactions(
          this.clientId,
          this.institutionAccountId,
          this.searchParams
        );
        this.$eventBus.$emit("notification", {
          type: "success",
          message: this.$t(
            "reconciliations.transactions.export.alert.exported"
          ),
        });
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t(
            "reconciliations.transactions.export.alert.could-not-export"
          ),
        });
      } finally {
        this.loading.export = false;
      }
    },
    handleMyCurrentClientUpdated() {
      this.$router.push({
        name: "Reconciliations",
      });
    },
  },
  async mounted() {
    this.institutionAccountId = this.$route.params.institutionAccountId;

    this.clientId = authorizationUtils.isClient()
      ? this.myCurrentClient.id
      : this.$route.query.clientId;

    await this.getPlaidInstitutionAccount();
  },
  created() {
    this.$eventBus.$on(
      "myCurrentClientUpdated",
      this.handleMyCurrentClientUpdated
    );
  },
  beforeDestroy() {
    this.$eventBus.$off(
      "myCurrentClientUpdated",
      this.handleMyCurrentClientUpdated
    );
  },
};
</script>
