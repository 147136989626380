<template>
  <div class="company-formation__summary mt-8">
    <h3 class="mb-2 subtitle-1 font-weight-medium">
      {{ $t("company-formation.resident.order-summary.title") }}
    </h3>

    <div class="company-formation__summary-list">
      <div class="company-formation__summary-list--item">
        <p class="mb-0 gray--text subtitle-2">
          {{
            $t(
              "company-formation.resident.order-summary.state-registration-fee"
            )
          }}
        </p>
        <p
          class="mb-0 text-right body-2 gray--text text--darken-3"
          v-if="stateRegistrationFee"
        >
          {{
            $t("defaults.currency", {
              amount: stateRegistrationFee,
            })
          }}
        </p>
      </div>
      <div class="company-formation__summary-list--item">
        <p class="mb-0 gray--text subtitle-2">
          {{
            $t("company-formation.resident.order-summary.payment-processing")
          }}
        </p>
        <p
          class="mb-0 text-right body-2 gray--text text--darken-3"
          v-if="paymentProcessingFee"
        >
          {{
            $t("defaults.currency", {
              amount: paymentProcessingFee,
            })
          }}
        </p>
      </div>
      <div class="company-formation__summary-list--item">
        <p class="mb-0 gray--text subtitle-2">
          {{ $t("company-formation.resident.order-summary.service-fee") }}
        </p>

        <p
          class="mb-0 text-right body-2 gray--text text--darken-3"
          v-if="selectedState"
        >
          <span class="d-flex align-center justify-end">
            <template v-if="appliedCoupon">
              <span class="mr-1">
                {{
                  `(${appliedCoupon.code} ${
                    appliedCoupon.discountType == DiscountTypes.FIXED
                      ? "$"
                      : "%"
                  }${appliedCoupon.discountValue} ${$t(
                    "company-formation.resident.order-summary.coupon.off"
                  )})`
                }}
              </span>

              <span class="text-decoration-line-through mr-1">
                {{
                  $t("defaults.currency", {
                    amount: serviceFee,
                  })
                }}
              </span>

              <span>
                {{
                  $t("defaults.currency", {
                    amount: discountedServiceFee,
                  })
                }}
              </span>
            </template>
            <template v-else>
              <span>
                {{
                  $t("defaults.currency", {
                    amount: serviceFee,
                  })
                }}
              </span>
            </template>
          </span>
        </p>
      </div>
      <div class="company-formation__summary-list--item">
        <v-row>
          <v-col cols="8">
            <p
              class="mb-0 gray--text text--darken-3 subtitle-2 font-weight-medium"
            >
              {{ $t("company-formation.resident.order-summary.total") }}
            </p>
          </v-col>
          <v-col cols="4">
            <p
              class="mb-0 text-right body-2 gray--text text--darken-3 font-weight-medium"
              v-if="totalAmount"
            >
              {{
                $t("defaults.currency", {
                  amount: totalAmount,
                })
              }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="company-formation__summary-coupon"
      v-if="selectedState && !appliedCoupon"
    >
      <div class="text-center mt-3">
        <v-btn
          v-if="showEnterCoupon"
          depressed
          small
          outlined
          block
          color="primary"
          @click="showEnterCoupon = false"
        >
          <v-icon left> mdi-plus </v-icon>
          <span class="subtitle-2 text-none">
            {{
              $t(
                "company-formation.resident.order-summary.coupon.button.enter-coupon"
              )
            }}
          </span>
        </v-btn>

        <div class="border-all rounded pa-3" v-else>
          <v-form
            ref="form"
            v-model="isFormValid"
            lazy-validation
            @submit.prevent="submitCouponCode"
          >
            <v-text-field
              :label="
                $t(
                  'company-formation.resident.order-summary.coupon.form.coupon'
                )
              "
              hide-details
              filled
              type="text"
              v-model="form.coupon"
              :rules="rules.coupon"
            ></v-text-field>
            <v-btn
              block
              class="mt-2 text-none body-2 font-weight-medium"
              depressed
              color="primary"
              type="submit"
              :loading="loading.coupon"
            >
              {{
                $t(
                  "company-formation.resident.order-summary.coupon.button.apply"
                )
              }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BusinessEntityTypes from "@/shared/enums/BusinessEntityTypes";
import CompanyFormationTypes from "@/shared/enums/CompanyFormationTypes";
import DiscountTypes from "@/shared/enums/DiscountTypes";

export default {
  props: {
    businessEntityType: {
      type: [String, Number],
      required: true,
    },
    selectedStateId: {
      type: [String, Number],
    },
    states: {
      type: Array,
      required: true,
    },
    type: {
      type: [String, Number],
      required: true,
    },
    value: [String, null],
  },
  data() {
    return {
      BusinessEntityTypes,
      CompanyFormationTypes,
      DiscountTypes,
      paymentProcessingFeePercent: 0.06,
      serviceFee: 150,
      showEnterCoupon: true,
      isFormValid: true,
      form: {
        coupon: null,
      },
      appliedCoupon: null,
      rules: {
        coupon: [
          (v) => (!!v && !!v.trim()) || this.$t("defaults.rule.required"),
        ],
      },
      loading: {
        coupon: false,
      },
    };
  },
  computed: {
    selectedState() {
      return this.states.find((state) => state.id == this.selectedStateId);
    },
    stateRegistrationFee() {
      if (this.selectedState) {
        if (this.businessEntityType == BusinessEntityTypes.LLC) {
          return this.selectedState.llcFilingFeeForResidents;
        }

        if (this.businessEntityType == BusinessEntityTypes.Corporation) {
          return this.selectedState.corporationFilingFeeForResidents;
        }
      }

      return null;
    },
    paymentProcessingFee() {
      if (this.selectedState) {
        if (this.businessEntityType == BusinessEntityTypes.LLC) {
          return this.calculatePaymentProcessingFee(
            this.selectedState.llcFilingFeeForResidents
          );
        }

        if (this.businessEntityType == BusinessEntityTypes.Corporation) {
          return this.calculatePaymentProcessingFee(
            this.selectedState.corporationFilingFeeForResidents
          );
        }
      }

      return null;
    },
    totalAmount() {
      if (this.selectedState) {
        return this.formatPrice(
          Number(this.stateRegistrationFee) +
            Number(this.paymentProcessingFee) +
            Number(this.discountedServiceFee)
        );
      }

      return null;
    },
    discountedServiceFee() {
      if (this.appliedCoupon) {
        return (
          this.serviceFee -
          (this.appliedCoupon.discountType == DiscountTypes.PERCENTAGE
            ? (this.serviceFee * this.appliedCoupon.discountValue) / 100
            : this.appliedCoupon.discountValue)
        );
      }

      return this.serviceFee;
    },
  },
  watch: {
    totalAmount: {
      handler() {
        this.$emit("totalPayment", this.totalAmount);
      },
    },
    appliedCoupon: {
      handler(coupon) {
        this.$emit("input", coupon ? coupon.code : null);
      },
      deep: true,
    },
  },
  methods: {
    calculatePaymentProcessingFee(amount) {
      return this.formatPrice(amount * this.paymentProcessingFeePercent);
    },
    submitCouponCode() {
      if (this.$refs.form.validate() && this.isFormValid) {
        this.verifyCoupon(this.form.coupon);
      }
    },
    async verifyCoupon(code) {
      try {
        this.loading.coupon = true;
        const response = await window.API.verifyCoupon(code);
        this.appliedCoupon = response.coupon;
        this.$eventBus.$emit("notification", {
          type: response.coupon ? "success" : "error",
          message: response.coupon
            ? this.$t(
                "company-formation.resident.order-summary.coupon.alert.applied"
              )
            : response.resultMessage,
        });

        if (response.coupon) {
          this.form.coupon = null;
          this.showEnterCoupon = true;
        }
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t(
            "company-formation.resident.order-summary.coupon.alert.could-not-apply"
          ),
        });
      } finally {
        this.loading.coupon = false;
      }
    },
  },
  created() {
    this.form.coupon = this.value;
  },
};
</script>
