<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.quote.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.quote.subtitle") }}:
            </span>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="servicePlan in item.servicePlans"
              :key="servicePlan.id"
            >
              <v-col cols="12">
                <span class="body-2 font-weight-bold text--primary">
                  {{
                    servicePlan.isPackage
                      ? $t("leads.details.quote.package")
                      : servicePlan.services[0].displayName
                  }}
                </span>
                <div class="d-inline">
                  <span v-if="servicePlan.fee" class="body-2 text--primary pl-4"
                    >{{ $t("defaults.currency", { amount: servicePlan.fee }) }}
                    /
                    {{
                      getServicePlanPaymentFrequencyText(
                        servicePlan.paymentFrequency
                      )
                    }}
                  </span>
                  <span v-else class="body-2 text--primary pl-4">
                    {{ $t("leads.details.quote.custom-price") }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ServicePlanPaymentFrequency from "@/shared/enums/ServicePlanPaymentFrequency";

export default {
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    getServicePlanPaymentFrequencyText(paymentFrequency) {
      switch (paymentFrequency) {
        case ServicePlanPaymentFrequency.MONTHLY:
          return this.$t("leads.details.quote.payment-frequency.monthly");
        case ServicePlanPaymentFrequency.ANNUAL:
          return this.$t("leads.details.quote.payment-frequency.annual");
        default:
          return "";
      }
    },
  },
};
</script>
