<template>
  <d-modal-confirmation
    :title="$t('roles.delete.title')"
    :text="modalConfirmationText"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
  </d-modal-confirmation>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    modalConfirmationText() {
      return this.$t("roles.delete.text", {
        displayName: this.item.displayName,
      });
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.isSubmitting = true;
      window.API.deleteRole(this.item.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("roles.delete.alert.deleted", {
              displayName: this.item.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("roles.delete.alert.could-not-delete"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
