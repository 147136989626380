export function fetchCompanies({ offset, limit }) {
  return window.axios
    .get("/company/companies", {
      params: {
        offset,
        limit,
      },
    })
    .then((response) => response.data);
}

export function getCompanyDetailsById(id) {
  return window.axios
    .get(`/company/companies/${id}/details`)
    .then((response) => response.data);
}

export function fetchAllCompanyBusinessEntityTypes() {
  return window.axios
    .get("/company/business-entity-types/all")
    .then((response) => response.data);
}

export function fetchAllCompanyStates() {
  return window.axios
    .get("/company/states/all")
    .then((response) => response.data);
}

export function fetchAllCompanyIndustries() {
  return window.axios
    .get("/company/industries/all")
    .then((response) => response.data);
}

export function fetchAllCompanyRegisteredAgentTypes() {
  return window.axios
    .get("/company/registered-agent-types/all")
    .then((response) => response.data);
}

export function fetchAllCompanyManagementStructures() {
  return window.axios
    .get("/company/management-structures/all")
    .then((response) => response.data);
}

export function fetchAllCompanyOrganizerTitles() {
  return window.axios
    .get("/company/organizer-titles/all")
    .then((response) => response.data);
}

export function fetchAllCompanyCounties() {
  return window.axios
    .get("/company/counties/all")
    .then((response) => response.data);
}

export function postCompanyResident(data) {
  return window.axios
    .post(`/company/companies/resident`, data)
    .then((response) => response.data);
}

export function postCompanyNonResident(data) {
  return window.axios
    .post(`/company/companies/non-resident`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response.data);
}

export function downloadOrganizerMainPageOfPassport(companyId, organizerId) {
  return window.axios
    .get(
      `/company/companies/${companyId}/organizers/${organizerId}/main-page-of-passport/download`,
      {
        responseType: "blob",
        timeout: 10 * 60 * 1000, // 10 minutes
      }
    )
    .then((response) => response);
}
