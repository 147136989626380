import { downloadFile } from "@/utils/file.js";

export function fetchContractors(params) {
  return window.axios
    .get("/document/contractors", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function getBusinessContractorById(id) {
  return window.axios
    .get(`/document/contractors/business/${id}`)
    .then((response) => response.data);
}

export function getIndividualContractorById(id) {
  return window.axios
    .get(`/document/contractors/individual/${id}`)
    .then((response) => response.data);
}

export function insertContractor(data) {
  return window.axios
    .post("/document/contractors", data)
    .then((response) => response);
}

export function editBusinessContractor(data) {
  return window.axios
    .put(`/document/contractors/business/${data.id}`, data)
    .then((response) => response);
}

export function editIndividualContractor(data) {
  return window.axios
    .put(`/document/contractors/individual/${data.id}`, data)
    .then((response) => response);
}

export function deleteContractor(id) {
  return window.axios
    .delete(`/document/contractors/${id}`)
    .then((response) => response);
}

export function exportContractors(params) {
  return window.axios
    .get("/document/contractors/export", {
      responseType: "blob",
      params: params,
    })
    .then((response) => downloadFile(response));
}

export function completeContractor(id) {
  return window.axios
    .patch(`/document/contractors/${id}/complete`)
    .then((response) => response);
}

export function openContractor(id) {
  return window.axios
    .patch(`/document/contractors/${id}/open`)
    .then((response) => response);
}

export function createStripePaymentIntentForMyCurrentClientContractors() {
  return window.axios
    .post("/document/users/me/clients/current/contractors/checkout")
    .then((response) => response.data);
}
