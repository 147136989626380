<template>
  <d-page
    :title="$t('my-uploads.page-heading')"
    :detailsTitle="$t('documents.comments.title')"
    :openDetails="openComments"
    @closeDetails="handleCloseComments"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="error"
        v-granted="'my-upload:delete'"
        @click="handleOpenDeleteModal"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-delete </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("my-uploads.button.delete")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="grey lighten-4"
        @click="handleDownloadSelectedDocuments"
        :loading="loading.download"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-file-download </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("my-uploads.button.download")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0 white--text"
        color="#0093FF"
        v-granted="'my-upload:add'"
        @click="setOpenUploadDocumentModal(true)"
      >
        <v-icon left> mdi-tray-arrow-up </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("my-uploads.button.upload-documents")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("my-uploads.button.search-toggle")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search :services="services" />
    </template>
    <template slot="content">
      <List
        @viewDocument="handleOpenViewDocument"
        @shareDocument="handleOpenShareDocument"
        @viewComments="handleOpenComments"
        @updateSelectedItems="handleUpdateSelectedItems"
        @editDocument="handleOpenEditDocument"
        :selectedItem="selectedItem"
        :isEditing="openEditModal"
        :isViewing="loading.preview"
        :isSharing="openShareModal"
        :isCommenting="openComments"
        :services="services"
        v-if="!loading.page"
      ></List>
      <UploadDocument
        v-if="openUploadDocumentModal"
        :modal="openUploadDocumentModal"
        @closeModal="handleCloseUploadDocumentModal"
        @submitModal="handleSubmitUploadDocumentModal"
      >
      </UploadDocument>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :selectedItems="selectedItems"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
      ></Delete>
      <Share
        v-if="openShareModal"
        :modal="openShareModal"
        @closeModal="handleCloseShareModal"
        :item="selectedItem"
      ></Share>
      <Edit
        v-if="openEditModal"
        :modal="openEditModal"
        @closeModal="handleCloseEditDocumentModal"
        @submitModal="handleSubmitEditDocumentModal"
        :item="selectedItem"
      >
      </Edit>
    </template>
    <template slot="details">
      <Comment v-if="openComments" :item="selectedItem"></Comment>
    </template>
  </d-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Search from "./Search.vue";
import List from "./List.vue";
import UploadDocument from "./UploadDocument.vue";
import Delete from "@/components/documents/Delete.vue";
import Comment from "@/components/documents/Comment.vue";
import Share from "@/components/documents/Share.vue";
import Edit from "@/components/documents/Edit.vue";
import documentMixin from "@/mixins/documentMixins";
import authorizationUtils from "@/utils/authorization.js";

export default {
  mixins: [documentMixin],
  components: {
    Search,
    List,
    UploadDocument,
    Delete,
    Comment,
    Share,
    Edit,
  },
  data() {
    return {
      searchToggle: false,
      openDeleteModal: false,
      selectedItem: null,
      selectedItems: [],
      openComments: false,
      openShareModal: false,
      openEditModal: false,
      services: [],
      loading: { page: false },
    };
  },
  computed: {
    ...mapGetters({
      openUploadDocumentModal: "MyUpload/getOpenUploadDocumentModal",
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
    hasSelectedItems() {
      return this.selectedItems && this.selectedItems.length > 0;
    },
  },
  methods: {
    ...mapActions({
      setOpenUploadDocumentModal: "MyUpload/setOpenUploadDocumentModal",
    }),
    handleCloseSearch() {
      this.$eventBus.$emit("fetchMyUploads", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchMyUploads");
    },
    handleUpdateSelectedItems(ids = []) {
      this.selectedItems = ids;
    },
    handleOpenDeleteModal() {
      this.openDeleteModal = true;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchMyUploads");
    },
    handleCloseUploadDocumentModal() {
      this.setOpenUploadDocumentModal(false);
    },
    handleSubmitUploadDocumentModal() {
      this.$eventBus.$emit("fetchMyUploads");
    },
    handleOpenComments(item = null) {
      this.selectedItem = item;
      this.openComments = true;
    },
    handleCloseComments() {
      this.openComments = false;
    },
    handleOpenShareDocument(item = null) {
      this.selectedItem = item;
      this.openShareModal = true;
    },
    handleCloseShareModal() {
      this.openShareModal = false;
    },
    handleOpenViewDocument(item = null) {
      this.selectedItem = item;
      this.previewDocument(item.id);
    },
    handleDownloadSelectedDocuments() {
      this.downloadSelectedDocuments(this.selectedItems);
    },
    handleOpenEditDocument(item = null) {
      this.selectedItem = item;
      this.openEditModal = true;
    },
    handleCloseEditDocumentModal() {
      this.openEditModal = false;
    },
    handleSubmitEditDocumentModal() {
      this.$eventBus.$emit("fetchMyUploads");
    },
    fetchAllServices() {
      return window.API.fetchAllServices()
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchClientAllServices(clientId) {
      return window.API.fetchClientAllServices(clientId)
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async created() {
    try {
      this.loading.page = true;
      if (authorizationUtils.isClient()) {
        await this.fetchClientAllServices(this.myCurrentClient.id);
      } else {
        await this.fetchAllServices();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading.page = false;
    }
  },
};
</script>
