<template>
  <v-row>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.name')"
        v-model.trim="searchParams.fullName"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.tin')"
        v-model.trim="searchParams.taxpayerIdentificationNumber"
        hide-details="auto"
        filled
        type="text"
        v-mask="'##-#######'"
        placeholder="##-#######"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.ssn')"
        v-model.trim="searchParams.socialSecurityNumber"
        hide-details="auto"
        filled
        type="text"
        v-mask="'###-##-####'"
        placeholder="###-##-####"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.phone-number')"
        v-model.trim="searchParams.phoneNumber"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.city')"
        v-model.trim="searchParams.city"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.stateId"
        :items="states"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :label="$t('clients.search.form.state')"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.serviceId"
        :items="services"
        item-text="displayName"
        item-value="id"
        hide-details="auto"
        clearable
        :label="$t('clients.search.form.service')"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.ownerOrCollaboratorUserId"
        :items="clientUsers"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :loading="loading.clientUsers"
        :search-input.sync="search.clientUsers"
        :placeholder="$t('defaults.remote-dropdown-placeholder')"
        hide-no-data
        :label="$t('clients.search.form.owner')"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.advisorUserId"
        :items="advisors"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :label="$t('clients.search.form.advisor')"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('clients.search.form.email-address')"
        v-model.trim="searchParams.emailAddress"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-radio-group
        row
        filled
        v-model="searchParams.isActive"
        hide-details="auto"
      >
        <v-radio
          :label="$t('clients.search.form.status.active')"
          :value="true"
        ></v-radio>
        <v-radio
          :label="$t('clients.search.form.status.inactive')"
          :value="false"
        ></v-radio>
        <v-radio
          :label="$t('clients.search.form.status.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-radio-group
        row
        filled
        v-model="searchParams.clientType"
        hide-details="auto"
        class="mt-0"
      >
        <v-radio
          :label="$t('clients.search.form.client-type.business')"
          :value="ClientTypes.BUSINESS"
        ></v-radio>
        <v-radio
          :label="$t('clients.search.form.client-type.personal')"
          :value="ClientTypes.PERSONAL"
        ></v-radio>
        <v-radio
          :label="$t('clients.search.form.client-type.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  props: {
    advisors: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: {
        clientUsers: null,
      },
      loading: {
        clientUsers: false,
      },
      clientUsers: [],
      ClientTypes,
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
    "search.clientUsers": {
      handler(val) {
        if (val.length > 2) {
          this.fetchClientUsers(val);
        } else {
          this.clientUsers = [];
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    fetchClientUsers(searchTerm) {
      this.loading.clientUsers = true;

      window.API.fetchClientUsers(searchTerm)
        .then((response) => {
          this.clientUsers = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.clientUsers = false));
    },
  },
};
</script>
