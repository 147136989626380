export function fetchAnnouncements(params) {
  return window.axios
    .get("/document/announcements", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function getAnnouncementDetailsById(id) {
  return window.axios
    .get(`/document/announcements/${id}/details`)
    .then((response) => response.data);
}

export function insertAnnouncement(data) {
  return window.axios
    .post("/document/announcements", data)
    .then((response) => response);
}

export function activateAnnouncement(id) {
  return window.axios
    .patch(`/document/announcements/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateAnnouncement(id) {
  return window.axios
    .patch(`/document/announcements/${id}/deactivate`)
    .then((response) => response.data);
}

export function getMyUnconfirmedAnnouncements() {
  return window.axios
    .get(`/document/users/me/announcements/unconfirmed`)
    .then((response) => response.data);
}

export function confirmMyAnnouncement(announcementId, data) {
  return window.axios
    .post(`/document/users/me/announcements/${announcementId}/confirm`, data)
    .then((response) => response);
}
