<template>
  <v-text-field
    readonly
    :value="value"
    hide-details="auto"
    filled
    :label="label"
    :rules="rules"
  >
    <template v-slot:append>
      <v-menu top :offset-y="true" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon :color="color ? color : null">{{
              color ? "mdi-square" : "mdi-square-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <v-row>
          <v-col cols="12">
            <v-color-picker
              v-model="color"
              elevation="15"
              @update:color="updateColor"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data() {
    return {
      color: "",
    };
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
      default: "",
      required: true,
    },
    rules: {
      type: Array,
    },
  },
  methods: {
    updateColor(color) {
      this.$emit("input", color.hex);
    },
  },
  mounted() {
    this.color = this.value;
  },
};
</script>
