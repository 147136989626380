<template>
  <div class="login-layout__form">
    <h1 class="text-h5 font-weight-medium mb-6">
      {{ $t("reset-password.page-heading") }}
    </h1>

    <v-form
      ref="form"
      v-model="isValidForm"
      lazy-validation
      @submit.prevent="handleFormSubmit"
    >
      <v-row>
        <v-col cols="12" v-if="!isUsableToken">
          <v-alert outlined type="error" text icon="mdi-alert-octagon-outline">
            {{ $t("change-password.alert.session-expired") }}
          </v-alert>
        </v-col>

        <v-col cols="12" class="mb-4">
          <v-text-field
            :label="$t('reset-password.form.email')"
            hide-details="auto"
            filled
            type="email"
            :rules="rules.email"
            :hint="$t('reset-password.hint.email')"
            persistent-hint
            v-model="emailAddress"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center justify-end">
            <v-btn
              :to="{ name: 'Login' }"
              type="button"
              class="text-none body-2 font-weight-medium mr-3"
              depressed
              large
              :disabled="isSendingEmail"
            >
              {{ $t("reset-password.button.cancel") }}
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              class="text-none body-2 font-weight-medium"
              depressed
              large
              :loading="isSendingEmail"
            >
              {{ $t("reset-password.button.send") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ResetPasswordResultTypes from "@/shared/enums/ResetPasswordResultTypes";

export default {
  data() {
    return {
      isValidForm: true,
      rules: {
        email: [
          (v) => {
            if (v) {
              const pattern =
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
              return pattern.test(v) || this.$t("reset-password.rule.email");
            } else {
              return !!v || this.$t("reset-password.rule.email");
            }
          },
        ],
      },
      emailAddress: null,
      isSendingEmail: false,
      isUsableToken: true,
    };
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.isSendingEmail = true;
        this.isUsableToken = true;

        window.API.sendResetPasswordEmail(this.emailAddress)
          .then((response) => {
            switch (response.resultType) {
              case ResetPasswordResultTypes.INVALID_EMAIL_ADDRESS:
                this.$eventBus.$emit("notification", {
                  type: "error",
                  message: this.$t("reset-password.alert.non-registered-email"),
                });
                break;
              case ResetPasswordResultTypes.DEACTIVE_USER:
                this.$eventBus.$emit("notification", {
                  type: "error",
                  message: this.$t("reset-password.alert.deactive-user"),
                });
                break;
              default:
                this.$eventBus.$emit("notification", {
                  type: "success",
                  message: this.$t("reset-password.alert.email-sent"),
                });
            }
          })
          .finally(() => {
            this.isSendingEmail = false;
            this.$refs.form.reset();
          });
      }
    },
    checkIsUsableToken() {
      if (this.$route.params.isUsableToken === false) {
        this.isUsableToken = this.$route.params.isUsableToken;
      }
    },
  },
  created() {
    this.checkIsUsableToken();
  },
};
</script>
