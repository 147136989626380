export function sendResetPasswordEmail(emailAddress) {
  return window.axios
    .post("/identity/account/send-reset-password-email", {
      emailAddress,
    })
    .then((response) => response.data);
}

export function isForgotPasswordTokenUsable(token, userId) {
  return window.axios
    .get(
      `/identity/account/is-forgot-password-token-usable/token/${token}user/${userId}`
    )
    .then((response) => response.data);
}

export function resetPassword(data) {
  return window.axios
    .post(`/identity/account/reset-password`, data)
    .then((response) => response.data);
}

export function registerClient(data) {
  return window.axios
    .post(`/identity/account/register/client`, data)
    .then((response) => response.data);
}
