<template>
  <CompanyLayout>
    <template slot="sidebar">
      <OrderSummary />
    </template>
    <template slot="content">
      <div class="border-bottom px-6 py-4">
        <h1 class="text-h6 gray--text text--darken-3">
          {{ $t("company-formation.non-resident.page-heading") }}
        </h1>
      </div>

      <div class="pa-4 company-formation__page--stepper">
        <v-stepper elevation="0" v-model="currentStep" flat alt-labels>
          <v-stepper-header class="elevation-0">
            <template v-for="(n, index) in 4">
              <template v-if="n > 0">
                <v-stepper-step
                  :key="n + '_step_' + index"
                  class="text-center"
                  :complete="currentStep > n"
                  :step="n"
                >
                  <p class="ma-0 text-center font-weight-medium">
                    {{ $t(`company-formation.non-resident.step-${n}.title`) }}
                  </p>
                </v-stepper-step>

                <v-divider
                  v-if="n < 4"
                  :key="n + '_divider_' + index"
                ></v-divider>
              </template>
            </template>
          </v-stepper-header>

          <v-stepper-items class="mt-3">
            <v-stepper-content step="1" class="ma-0 pa-0">
              <div class="px-3">
                <Step1
                  :states="states"
                  @submitStep="handleSubmitStep1"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <div class="px-3">
                <Step2
                  @submitStep="handleSubmitStep2"
                  @cancelStep="handleCancelStep2"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="ma-0 pa-0">
              <div class="px-3">
                <Step3
                  :isLLC="isLLC"
                  :isCorporation="isCorporation"
                  :isSubmitting="loading.step3"
                  @submitStep="handleSubmitStep3"
                  @cancelStep="handleCancelStep3"
                  @formUpdated="handleFormUpdate"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="4" class="ma-0 pa-0">
              <div class="px-3" v-if="clientSecret">
                <Payment
                  :fee="totalPayment"
                  :clientSecret="clientSecret"
                  @submitPayment="handleSubmitPayment"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="5" class="ma-0 pa-0">
              <div class="px-3">
                <Success />
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </template>
  </CompanyLayout>
</template>

<script>
import companyFormationMixins from "@/mixins/companyFormationMixins";
import CompanyLayout from "../layout.vue";
import CompanyFormationTypes from "@/shared/enums/CompanyFormationTypes";
import BusinessEntityTypes from "@/shared/enums/BusinessEntityTypes";
import OrderSummary from "./OrderSummary.vue";
import Step1 from "./Step-1.vue";
import Step2 from "./Step-2.vue";
import Step3 from "./Step-3.vue";
import Payment from "../Payment.vue";
import Success from "../Success.vue";

export default {
  components: {
    CompanyLayout,
    OrderSummary,
    Step1,
    Step2,
    Step3,
    Payment,
    Success,
  },
  data() {
    return {
      CompanyFormationTypes,
      BusinessEntityTypes,
      currentStep: 1,
      loading: {
        step3: false,
      },
      form: {
        businessEntityType: "",
        stateId: null,
      },
      states: [],
      totalPayment: 500,
      clientSecret: null,
    };
  },
  mixins: [companyFormationMixins],
  computed: {
    isLLC() {
      return this.form.businessEntityType == BusinessEntityTypes.LLC;
    },
    isCorporation() {
      return this.form.businessEntityType == BusinessEntityTypes.Corporation;
    },
  },
  methods: {
    handleSubmitStep1() {
      this.currentStep = 2;
    },
    handleFormUpdate(form) {
      this.form = { ...this.form, ...form };
    },
    handleSubmitStep2() {
      this.currentStep = 3;
    },
    handleCancelStep2() {
      this.currentStep = 1;
    },
    handleSubmitStep3() {
      if (this.form.industryId == this.defaultIndustryList.value) {
        this.form.industryId = null;
      }

      this.postCompanyNonResident(this.form);
    },
    handleCancelStep3() {
      this.currentStep = 2;
    },
    generateFormData(form) {
      let formData = new FormData();

      if (form.businessEntityType) {
        formData.append("businessEntityType", form.businessEntityType);
      }

      if (form.stateId) {
        formData.append("stateId", form.stateId);
      }

      if (form.legalName) {
        formData.append("legalName", form.legalName);
      }

      if (form.doingBusinessAs) {
        formData.append("doingBusinessAs", form.doingBusinessAs);
      }

      if (form.industryId) {
        formData.append("industryId", form.industryId);
      } else {
        formData.append("otherIndustryName", form.otherIndustryName);
      }

      if (form.contactFirstName) {
        formData.append("contactFirstName", form.contactFirstName);
      }

      if (form.contactLastName) {
        formData.append("contactLastName", form.contactLastName);
      }

      if (form.contactEmailAddress) {
        formData.append("contactEmailAddress", form.contactEmailAddress);
      }

      if (form.contactPhoneNumber) {
        formData.append("contactPhoneNumber", form.contactPhoneNumber);
      }

      if (form.contactFax) {
        formData.append("contactFax", form.contactFax);
      }

      if (form.businessAddress) {
        if (form.businessAddress.addressLine1) {
          formData.append(
            "businessAddress.addressLine1",
            form.businessAddress.addressLine1
          );
        }

        if (form.businessAddress.addressLine2) {
          formData.append(
            "businessAddress.addressLine2",
            form.businessAddress.addressLine2
          );
        }

        if (form.businessAddress.city) {
          formData.append("businessAddress.city", form.businessAddress.city);
        }

        if (form.businessAddress.zipCode) {
          formData.append(
            "businessAddress.zipCode",
            form.businessAddress.zipCode
          );
        }

        if (form.businessAddress.emailAddress) {
          formData.append(
            "businessAddress.emailAddress",
            form.businessAddress.emailAddress
          );
        }

        if (form.businessAddress.phoneNumber) {
          formData.append(
            "businessAddress.phoneNumber",
            form.businessAddress.phoneNumber
          );
        }
      }

      if (form.socialSecurityNumber) {
        formData.append("socialSecurityNumber", form.socialSecurityNumber);
      }

      if (form.socialSecurityFirstName) {
        formData.append(
          "socialSecurityFirstName",
          form.socialSecurityFirstName
        );
      }

      if (form.socialSecurityLastName) {
        formData.append("socialSecurityLastName", form.socialSecurityLastName);
      }

      if (form.organizers && form.organizers.length > 0) {
        form.organizers.forEach((organizer, index) => {
          if (this.isCorporation) {
            if (organizer.titles && organizer.titles.length > 0) {
              organizer.titles.forEach((title, i) => {
                formData.append(`organizers[${index}].titles[${i}]`, title);
              });
            }

            formData.append(
              `organizers[${index}].isDirector`,
              organizer.isDirector
            );
          }

          if (organizer.firstName) {
            formData.append(
              `organizers[${index}].firstName`,
              organizer.firstName
            );
          }

          if (organizer.lastName) {
            formData.append(
              `organizers[${index}].lastName`,
              organizer.lastName
            );
          }

          if (organizer.middleName) {
            formData.append(
              `organizers[${index}].middleName`,
              organizer.middleName
            );
          }

          if (organizer.shareOwnership) {
            formData.append(
              `organizers[${index}].shareOwnership`,
              organizer.shareOwnership
            );
          }

          if (organizer.mainPageOfPassport) {
            formData.append(
              `organizers[${index}].mainPageOfPassport`,
              organizer.mainPageOfPassport
            );
          }
        });
      }

      return formData;
    },
    postCompanyNonResident(form) {
      this.loading.step3 = true;

      let data = this.generateFormData(form);

      window.API.postCompanyNonResident(data)
        .then((res) => {
          this.clientSecret = res.stripePaymentClientSecret;
          this.currentStep = 4;
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "company-formation.non-resident.alert.could-not-post"
            ),
          });
        })
        .finally(() => (this.loading.step3 = false));
    },
    handleSubmitPayment() {
      this.currentStep = 5;
    },
    async fetchAllCompanyStates() {
      this.states = await window.API.fetchAllCompanyStates();
    },
  },
  async created() {
    await this.fetchAllCompanyStates();
  },
};
</script>
