<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.clientId"
        :items="clients"
        item-text="name"
        item-value="id"
        hide-details="auto"
        clearable
        :loading="loading.clients"
        :search-input.sync="search.clients"
        :placeholder="$t('defaults.remote-dropdown-placeholder')"
        hide-no-data
        :label="$t('bookkeeping.search.form.client')"
      >
        <template v-slot:item="data">
          <v-icon
            :color="data.item.isActive ? 'success' : 'error'"
            size="12"
          >
            mdi-circle
          </v-icon>
          <span class="ms-2">
            {{ data.item.name }}
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-select
        filled
        v-model="searchParams.advisorUserId"
        :items="advisors"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :label="$t('bookkeeping.search.form.advisor')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="2" class="mb-3">
      <v-select
        filled
        v-model="searchParams.year"
        :items="years"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        :label="$t('bookkeeping.search.form.year')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="2" class="mb-3">
      <v-select
        filled
        v-model="searchParams.month"
        :items="months"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        :label="$t('bookkeeping.search.form.month')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-select
        filled
        v-model="searchParams.isActiveClient"
        :items="statuses"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        :label="$t('bookkeeping.search.form.status.label')"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    months: {
      type: Array,
      required: true
    },
    years: {
      type: Array,
      required: true
    }
  },
  data(vm) {
    return {
      clients: [],
      advisors: [],
      statuses: [
        {
          value: null,
          displayText: vm.$t("bookkeeping.search.form.status.all")
        },
        {
          value: true,
          displayText: vm.$t("bookkeeping.search.form.status.active")
        },
        {
          value: false,
          displayText: vm.$t("bookkeeping.search.form.status.inactive")
        }
      ],
      search: {
        clients: null
      },
      loading: {
        clients: false
      }
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams"
    })
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true
    },
    "search.clients": {
      handler(val) {
        if (val?.length > 2) {
          this.fetchAllClients(val);
        } else {
          this.clients = [];
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams"
    }),
    fetchAllClients(searchTerm) {
      this.loading.clients = true;

      window.API.fetchAllClients(searchTerm)
        .then((response) => {
          this.clients = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.clients = false));
    },
    fetchAllAdvisors() {
      window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    }
  },
  created() {
    this.fetchAllAdvisors();
  }
};
</script>
