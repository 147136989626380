export function fetchInvitations(params) {
  return window.axios
    .get("/document/invitations", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function sendInvitation(data) {
  return window.axios
    .post(`/document/invitations/send`, data)
    .then((response) => response.data);
}

export function receivedInvitation(data) {
  return window.axios
    .post(`/document/invitations/received`, data)
    .then((response) => response.data);
}
