<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row>
      <v-col cols="12" class="mb-4">
        <div class="py-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{
              isCorporation
                ? $t(
                    "company-formation.non-resident.step-3.form.organizer.section-heading-officer"
                  )
                : $t(
                    "company-formation.non-resident.step-3.form.organizer.section-heading-organizer"
                  )
            }}
          </h4>
        </div>

        <v-row v-for="(organizer, index) in form.organizers" :key="index">
          <v-col cols="11">
            <v-row>
              <v-col v-if="isCorporation" md="4" class="mb-4">
                <v-autocomplete
                  filled
                  :items="organizerTitles"
                  item-text="displayText"
                  item-value="value"
                  hide-details="auto"
                  clearable
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.title'
                    )
                  "
                  v-model="organizer.titles"
                  :rules="rules.organizerTitle"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col :md="isCorporation ? 4 : 3" class="mb-4">
                <v-text-field
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.first-name'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="organizer.firstName"
                  :rules="rules.organizerFirstName"
                ></v-text-field>
              </v-col>

              <v-col :md="isCorporation ? 4 : 3" class="mb-4">
                <v-text-field
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.last-name'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="organizer.lastName"
                  :rules="rules.organizerLastName"
                ></v-text-field>
              </v-col>

              <v-col :md="isCorporation ? 4 : 3" class="mb-4">
                <v-text-field
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.middle-name'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="organizer.middleName"
                ></v-text-field>
              </v-col>
              <v-col :md="isCorporation ? 4 : 3" class="mb-4">
                <v-text-field
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.share-ownership'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="number"
                  v-model="organizer.shareOwnership"
                  :rules="rules.organizerShareOwnership"
                ></v-text-field>
              </v-col>
              <v-col md="4" class="mb-4" v-if="isCorporation">
                <v-checkbox
                  v-model="organizer.isDirector"
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.is-director'
                    )
                  "
                  filled
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col md="6" class="mb-4">
                <v-file-input
                  hide-details="auto"
                  filled
                  accept="image/png,image/jpeg,image/jpg,application/pdf,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  :label="
                    $t(
                      'company-formation.non-resident.step-3.form.organizer.main-page-of-passport'
                    )
                  "
                  :hint="
                    $t(
                      'company-formation.non-resident.step-3.hint.main-page-of-passport'
                    )
                  "
                  persistent-hint
                  prepend-icon
                  prepend-inner-icon="mdi-paperclip"
                  v-model="organizer.mainPageOfPassport"
                  :rules="rules.organizerMainPageOfPassport"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="1">
            <div class="d-flex justify-end pt-3 mb-4">
              <v-btn color="gray darken-2" icon @click="removeOrganizer(index)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex justify-end">
          <v-btn color="primary" depressed outlined @click="addOrganizer">
            <v-icon left> mdi-plus </v-icon>
            <span class="subtitle-2 text-none">{{
              isCorporation
                ? $t(
                    "company-formation.non-resident.step-3.actions.add-officer"
                  )
                : $t(
                    "company-formation.non-resident.step-3.actions.add-organizer"
                  )
            }}</span>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12">
        <p class="body-2 gray--text text-darken-2">
          {{
            $t("company-formation.non-resident.step-3.form.ssn.section-heading")
          }}
        </p>

        <v-row>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.non-resident.step-3.form.ssn.number')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.socialSecurityNumber"
              :rules="rules.socialSecurityNumber"
              v-mask="'###-##-####'"
              placeholder="###-##-####"
            ></v-text-field>
          </v-col>

          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.non-resident.step-3.form.ssn.first-name')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.socialSecurityFirstName"
            ></v-text-field>
          </v-col>

          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.non-resident.step-3.form.ssn.last-name')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.socialSecurityLastName"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mb-4">
        <p
          class="mb-0 body-2 gray--text text-darken-2 text-right"
          v-html="
            $t('company-formation.non-resident.step-3.form.agreement-text')
          "
        ></p>
      </v-col>

      <v-col cols="12">
        <div class="d-flex align-center justify-end">
          <v-btn
            @click="cancelStep"
            type="button"
            class="text-none body-2 font-weight-medium mr-2"
            depressed
          >
            {{ $t("company-formation.non-resident.step-3.actions.cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
            :loading="isSubmitting"
          >
            {{ $t("company-formation.non-resident.step-3.actions.submit") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const initialOrganizerForm = {
  titles: [],
  firstName: null,
  lastName: null,
  middleName: null,
  shareOwnership: null,
  isDirector: false,
  mainPageOfPassport: null,
};

const initialForm = {
  organizers: [{ ...initialOrganizerForm }],
  socialSecurityNumber: null,
  socialSecurityFirstName: null,
  socialSecurityLastName: null,
};

export default {
  props: {
    isSubmitting: {
      type: Boolean,
    },
    isLLC: {
      type: Boolean,
    },
    isCorporation: {
      type: Boolean,
    },
  },
  data() {
    return {
      organizerTitles: [],
      loading: false,
      form: { ...initialForm },
      isFormValid: true,
      rules: {
        organizerTitle: [
          (v) => !!v.length > 0 || this.$t("defaults.rule.required"),
        ],
        organizerFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        organizerLastName: [(v) => !!v || this.$t("defaults.rule.required")],
        organizerShareOwnership: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => {
            if (v && /^\d+$/.test(v)) return true;
            return this.$t(
              "company-formation.non-resident.step-3.rule.share-ownership-only-number"
            );
          },
        ],
        organizerMainPageOfPassport: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            !v ||
            v.size < 20000000 ||
            this.$t(
              "company-formation.non-resident.step-3.rule.main-page-of-passport"
            ),
        ],
        socialSecurityNumber: [
          (v) => {
            if (!v) return true;

            if (/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(v))
              return true;

            return this.$t("company-formation.non-resident.step-3.rule.ssn");
          },
        ],
        socialSecurityFirstName: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        socialSecurityLastName: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit("formUpdated", this.form);
      },
      deep: true,
    },
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitStep");
      }
    },
    cancelStep() {
      this.$emit("cancelStep");
    },
    async fetchAllCompanyOrganizerTitles() {
      this.organizerTitles = await window.API.fetchAllCompanyOrganizerTitles();
    },
    addOrganizer() {
      this.form.organizers.push({ ...initialOrganizerForm });
    },
    removeOrganizer(index) {
      this.form.organizers.splice(index, 1);

      if (this.form.organizers.length == 0) {
        this.form.organizers.push({ ...initialOrganizerForm });
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchAllCompanyOrganizerTitles();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.row + .row {
  margin: 0 -8px !important;
}
</style>
