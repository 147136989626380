<template>
  <div class="pa-4">
    <v-row class="mb-6" v-if="loading.table || table.items.length > 0">
      <v-col cols="12">
        <v-text-field
          :label="$t('accounts.collaborator.list.search')"
          hide-details="auto"
          filled
          type="text"
          v-model="searchText"
          clearable
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="loading.table"
      v-if="loading.table || filteredItems.length > 0"
      class="d-table"
      :items-per-page="table.itemsPerPage"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <div class="d-flex flex-nowrap align-center">
          <v-tooltip right color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                {{
                  item.clientType === ClientTypes.BUSINESS
                    ? "mdi-briefcase"
                    : "mdi-account"
                }}
              </v-icon>
            </template>
            <span>{{
              item.clientType === ClientTypes.BUSINESS
                ? $t("accounts.client-type.business")
                : $t("accounts.client-type.personal")
            }}</span>
          </v-tooltip>
          <span class="ml-2">{{ item.fullName }}</span>
        </div>
      </template>

      <template v-slot:[`item.services`]="{ item }">
        <template v-if="item.services.length > 0">
          <v-chip
            v-for="(service, index) in item.services"
            small
            :key="index"
            class="ma-1"
            color="primary"
            outlined
          >
            {{ service }}
          </v-chip>
        </template>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'account:list'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon size="18"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{
              $t("accounts.collaborator.list.table.actions.delete")
            }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('accounts.collaborator.list.empty-page')"
      v-if="!searchText && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="searchText && filteredItems.length == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  mixins: [tableMixins],
  components: {},
  data(vm) {
    return {
      ClientTypes,
      headers: [
        {
          text: vm.$t("accounts.collaborator.list.table.headers.name"),
          value: "fullName",
          sortable: false,
        },
        {
          text: vm.$t("accounts.collaborator.list.table.headers.owner"),
          value: "owner",
          sortable: false,
        },
        {
          text: vm.$t("accounts.collaborator.list.table.headers.services"),
          value: "services",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      searchText: null,
    };
  },
  computed: {
    filteredItems() {
      if (this.searchText) {
        return this.table.items.filter((item) => {
          if (
            item.fullName &&
            item.fullName.toLowerCase().includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.owner &&
            item.owner.toLowerCase().includes(this.searchText.toLowerCase())
          ) {
            return true;
          } else if (
            item.services.length > 0 &&
            item.services.some((service) =>
              service.toLowerCase().includes(this.searchText.toLowerCase())
            )
          ) {
            return true;
          }
          return false;
        });
      }

      return this.table.items;
    },
  },
  methods: {
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    fetchItems() {
      this.loading.table = true;

      window.API.getAllMyCollaboratorClients()
        .then((response) => {
          this.table.items = response;
          this.table.totalCount = response.length;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
  created() {
    this.$eventBus.$on("fetchCollaboratorClients", this.fetchItems);
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchCollaboratorClients", this.fetchItems);
  },
};
</script>

<style lang="scss">
.v-data-table__progress > th {
  padding: 0 !important;
}
</style>
