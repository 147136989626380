import Vue from "vue";
let instance;
export const useConstants = () => {
  if (instance) return instance;
  instance = new Vue({
    data() {
      const CURRENT_TAX_YEAR = 2023;
      return {
        CURRENT_TAX_YEAR,
        TAX_YEARS: [
          {
            text: `Current year (${CURRENT_TAX_YEAR})`,
            value: CURRENT_TAX_YEAR
          },
          {
            text: '2022',
            value: 2022
          }
        ],
        PASSWORD_REGEX:
          /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|\\:;""'<,>.?/]{6,}$/,
      };
    },
  });
  return instance;
};
export const Constants = {
  install(Vue) {
    Vue.prototype.$constants = useConstants();
  },
};
