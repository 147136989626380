var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading.page)?_c('d-loading'):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitStep.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##-#######'),expression:"'##-#######'"}],staticClass:"d-required",attrs:{"label":_vm.$t(
                'onboarding.create-account.form.company-employer-identification-number'
              ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.companyEmployerIdentificationNumber,"placeholder":"##-#######"},model:{value:(_vm.form.companyEmployerIdentificationNumber),callback:function ($$v) {_vm.$set(_vm.form, "companyEmployerIdentificationNumber", $$v)},expression:"form.companyEmployerIdentificationNumber"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('onboarding.create-account.form.company-website'),"hide-details":"auto","filled":"","type":"text"},model:{value:(_vm.form.companyWebsite),callback:function ($$v) {_vm.$set(_vm.form, "companyWebsite", $$v)},expression:"form.companyWebsite"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"d-required",attrs:{"label":_vm.$t('onboarding.create-account.form.company-phone-number'),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.companyPhoneNumber},model:{value:(_vm.form.companyPhoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "companyPhoneNumber", $$v)},expression:"form.companyPhoneNumber"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"d-required",attrs:{"label":_vm.$t('onboarding.create-account.form.company-address'),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.companyAddress},model:{value:(_vm.form.companyAddress),callback:function ($$v) {_vm.$set(_vm.form, "companyAddress", $$v)},expression:"form.companyAddress"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-4"},[_c('h4',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("onboarding.create-account.form.login-credentials.heading"))+" ")])]),_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"d-required",attrs:{"label":_vm.$t(
                    'onboarding.create-account.form.login-credentials.email-address'
                  ),"hide-details":"auto","filled":"","type":"text","rules":_vm.rules.emailAddress,"error":_vm.errorMessages.emailAddress.length > 0,"error-messages":_vm.errorMessages.emailAddress,"loading":_vm.loading.emailAddress},on:{"blur":_vm.checkEmailAddressAvailability},model:{value:(_vm.form.emailAddress),callback:function ($$v) {_vm.$set(_vm.form, "emailAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.emailAddress"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"d-required",attrs:{"label":_vm.$t(
                    'onboarding.create-account.form.login-credentials.password'
                  ),"hide-details":"auto","filled":"","type":"password","rules":_vm.rules.password},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"d-required",attrs:{"label":_vm.$t(
                    'onboarding.create-account.form.login-credentials.confirm-password'
                  ),"hide-details":"auto","filled":"","type":"password","rules":_vm.rules.confirmPassword},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"text-none body-2 font-weight-medium",attrs:{"type":"submit","color":"success","depressed":"","loading":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.$t("onboarding.step-actions.submit"))+" ")])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }