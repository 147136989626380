<template>
  <d-modal
    :title="$t('contractors.payment.title')"
    :subtitle="$t('contractors.payment.subtitle')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
    :saveButtonText="saveButtonText"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-row v-else>
        <v-col cols="12" class="mb-3">
          <div class="stripe--input" id="card-number"></div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <div class="stripe--input" id="card-expiry"></div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <div class="stripe--input" id="card-cvc"></div>
        </v-col>
      </v-row>
    </template>
  </d-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    paymentAmount: {
      type: [Number, String],
      required: true,
    },
    clientSecret: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      loading: {
        page: false,
      },
      stripe: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    };
  },
  computed: {
    saveButtonText() {
      return `${this.$t("contractors.payment.button.pay")} (${this.$t(
        "defaults.currency",
        {
          amount: this.paymentAmount,
        }
      )})`;
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      this.isSubmitting = true;

      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {},
          },
        })
        .then((result) => {
          if (result.error) {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: result.error.message,
            });
            this.isSubmitting = false;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              setTimeout(() => {
                this.$eventBus.$emit("notification", {
                  type: "success",
                  message: this.$t(
                    "contractors.payment.alert.payment-succeeded"
                  ),
                });
                this.$emit("submitModal");
                this.handleCloseModal();
                this.isSubmitting = false;
              }, 2000);
            }
          }
        });
    },
    initializeStripe() {
      const style = {
        base: {
          color: "#424242",
          fontFamily: '"Roboto", sans-serif',
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.54)",
          },
        },
        invalid: {
          color: "#FF5252",
          iconColor: "#fa755a",
        },
      };

      this.stripe = window.Stripe(process.env.VUE_APP_CONTRACTOR_STRIPE_PUBLISHABLE_KEY);

      const elements = this.stripe.elements();

      this.cardNumber = elements.create("cardNumber", {
        style,
        showIcon: true,
        placeholder: this.$t("contractors.payment.form.card-number"),
      });

      this.cardNumber.mount("#card-number");

      this.cardExpiry = elements.create("cardExpiry", {
        style,
        placeholder: this.$t("contractors.payment.form.card-expiry"),
      });

      this.cardExpiry.mount("#card-expiry");

      this.cardCvc = elements.create("cardCvc", {
        style,
        placeholder: this.$t("contractors.payment.form.card-cvc"),
      });
      
      this.cardCvc.mount("#card-cvc");
    },
    destroyStripeElements() {
      if (this.cardNumber) {
        this.cardNumber.destroy();
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy();
      }
      if (this.cardCvc) {
        this.cardCvc.destroy();
      }
    },
  },
  mounted() {
    this.initializeStripe();
  },
  beforeDestroy() {
    this.destroyStripeElements();
  },
};
</script>
