var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.renderComponent)?_c('div',[_c('div',{staticClass:"editor",class:{ 'editor--invalid': !_vm.isValid }},[_c('editor',{staticClass:"tinymce-editor",attrs:{"api-key":_vm.apiKey,"tag-name":"textarea","output-format":"html","id":_vm.id,"init":{
        selector: `#${_vm.id}`,
        height: _vm.height,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
        ],
        toolbar:
          'undo redo | styleselect  | bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat  | \
      alignleft aligncenter alignright | \
      bullist numlist outdent indent | image link table',
        paste_data_images: true,
        images_upload_handler: function (blobInfo, success, failure) {
          _vm.uploadImage(blobInfo, success, failure);
        },
        image_description: false,
      }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),(!_vm.isValid)?_c('v-input',{staticClass:"pl-3",attrs:{"error":"","messages":_vm.$t('defaults.rule.required')}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }