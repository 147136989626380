<template>
  <d-modal-confirmation
    :title="$t('leads.delete.title')"
    :text="$t('leads.delete.text')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
  </d-modal-confirmation>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.isSubmitting = true;
      window.API.deleteLeads(this.selectedItems)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("leads.delete.alert.deleted"),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("leads.delete.alert.could-not-delete"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
