<template>
  <v-row justify="center">
    <v-dialog
      v-model="modal"
      persistent
      :maxWidth="maxWidth"
      :retain-focus="false"
      eager
    >
      <div class="d-modal">
        <div class="d-modal__header">
          <div class="d-flex align-center">
            <slot name="prepend-title"></slot>
            <h3 class="text-h6">{{ title }}</h3>
          </div>
          <p class="mb-0 body-2 gray--text text--darken-1" v-if="subtitle">
            {{ subtitle }}
          </p>
        </div>
        <div class="d-modal__body">
          <slot name="body"></slot>
        </div>
        <div class="d-modal__footer">
          <div class="d-flex align-center justify-end">
            <v-btn
              v-if="hasCancelButton"
              class="text-none body-2 font-weight-medium"
              :class="{ 'mr-3': hasSaveButton }"
              depressed
              @click="closeModal"
            >
              {{ cancelButtonText }}
            </v-btn>
            <v-btn
              v-if="hasSaveButton"
              type="submit"
              color="success"
              class="text-none body-2 font-weight-medium"
              depressed
              :loading="isSubmitting"
              :disabled="isSubmitting || isSaveButtonDisabled"
              @click="submitModal"
            >
              {{ saveButtonText }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    saveButtonText: {
      type: String,
      default: function () {
        return this.$t("defaults.modal-save");
      },
    },
    cancelButtonText: {
      type: String,
      default: function () {
        return this.$t("defaults.modal-cancel");
      },
    },
    title: String,
    isSubmitting: {
      type: Boolean,
      default: () => false,
    },
    hasCancelButton: {
      type: Boolean,
      default: () => true,
    },
    hasSaveButton: {
      type: Boolean,
      default: () => true,
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
    isSaveButtonDisabled: {
      type: Boolean,
      default: false,
    },
    subtitle: String,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    submitModal() {
      this.$emit("submitModal");
    },
  },
  data() {
    return {};
  },
};
</script>
