import fileDownload from "js-file-download";
import { getFilenameFromContentDispositionHeader } from "../utils/getFilenameFromContentDispositionHeader";

export function getAllContactPositions() {
  return window.axios
    .get(`/document/contact-positions/all`)
    .then((response) => response.data);
}

export function getAllLeadIndustries() {
  return window.axios
    .get("/document/industries/all")
    .then((response) => response.data);
}

export function getAllAnnualSalesRanges() {
  return window.axios
    .get(`/document/annual-sales-ranges/all`)
    .then((response) => response.data);
}

export function getAllEmployeeCountRanges() {
  return window.axios
    .get(`/document/employee-count-ranges/all`)
    .then((response) => response.data);
}

export function getAllClientOnboardingProcessRelatedServices() {
  return window.axios
    .get(`/document/services/client-onboarding-process-related`)
    .then((response) => response.data);
}

export function getAllAcquisitionChannels() {
  return window.axios
    .get(`/document/acquisition-channels/all`)
    .then((response) => response.data);
}

export function getAllAccountingSoftwareTools() {
  return window.axios
    .get(`/document/accounting-software-tools/all`)
    .then((response) => response.data);
}

export function getLeadServicePlans(idHash) {
  return window.axios
    .get(`/document/leads/${idHash}/service-plans`)
    .then((response) => response.data);
}

export function getLeadByIdHash(idHash) {
  return window.axios
    .get(`document/leads/${idHash}`)
    .then((response) => response.data);
}

export function insertLead(data) {
  return window.axios
    .post(`/document/leads`, data)
    .then((response) => response.data);
}

export function updateLeadGeneralInfo(idHash, data) {
  return window.axios
    .put(`/document/leads/${idHash}/general-info`, data)
    .then((response) => response);
}

export function updateLeadIntakeInterviewAnswers(idHash, data) {
  return window.axios
    .put(`/document/leads/${idHash}/intake-interview-answers`, data)
    .then((response) => response);
}

export function updateLeadQuote(idHash) {
  return window.axios
    .put(`/document/leads/${idHash}/quote`, { idHash })
    .then((response) => response);
}

export function updateLeadContract(idHash) {
  return window.axios
    .put(`/document/leads/${idHash}/contract`, { idHash })
    .then((response) => response);
}

export function updateLeadClientInformation(idHash, data) {
  return window.axios
    .put(`/document/leads/${idHash}/client-information`, data)
    .then((response) => response);
}

export function updateMyLinkedLeadBillingInfo(data) {
  return window.axios
    .put(`/document/users/me/linked-lead/billing-info`, data)
    .then((response) => response);
}

export function updateMyLinkedLeadDocuments(data) {
  return window.axios
    .put(`/document/users/me/linked-lead/documents`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response);
}

export function downloadLeadContract(idHash) {
  return window.axios
    .get(`/document/leads/${idHash}/contract/download`, {
      responseType: "blob",
    })
    .then((response) => {
      if (response.data) {
        const fileName = getFilenameFromContentDispositionHeader(
          response.headers["content-disposition"]
        );
        fileDownload(response.data, `${fileName}`);
      }
    });
}

export function previewLeadContract(id) {
  return window.axios
    .get(`/document/leads/${id}/contract/preview`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000,
    })
    .then((response) => response);
}

export function previewTaxReturnAttachment(id) {
  return window.axios
    .get(`/document/leads/${id}/tax-return-attachment/preview`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000,
    })
    .then((response) => response);
}

export function previewEINAttachment(id) {
  return window.axios
    .get(`/document/leads/${id}/ein-attachment/preview`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000,
    })
    .then((response) => response);
}

export function previewArticleOfIncorporationAttachment(id) {
  return window.axios
    .get(`/document/leads/${id}/article-of-incorporation-attachment/preview`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000,
    })
    .then((response) => response);
}
