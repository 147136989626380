<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.general-info.contact-section-heading") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.general-info.full-name") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.contactFullName) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.general-info.contact-email-address") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.contactEmailAddress) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.general-info.contact-phone-number") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.contactPhoneNumber) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.general-info.contact-position") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.contactPosition?.displayName) }}
            </span>
          </v-col>
          <template v-if="ContactPositions.OWNER != item.contactPosition?.id">
            <v-col cols="12" md="4">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("leads.details.general-info.owner-first-name") }}:
              </span>
              <span class="body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(item.ownerFirstName) }}
              </span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("leads.details.general-info.owner-last-name") }}:
              </span>
              <span class="body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(item.ownerLastName) }}
              </span>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ContactPositions from "@/shared/enums/ContactPositions";
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      ContactPositions,
    };
  },
};
</script>
