<template>
  <d-loading v-if="loading"></d-loading>
  <div v-else>
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @submit.prevent="submitStep"
    >
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="mb-4">
              <v-chip color="primary" label @click="downloadLeadContract">
                <v-progress-circular
                  v-if="isDownloading"
                  indeterminate
                  color="white"
                  size="20"
                  class="mr-2"
                ></v-progress-circular>
                <v-icon v-else left size="20"> mdi-file-document </v-icon>
                {{ generatedFileName }}
              </v-chip>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                :label="$t('onboarding.contract.form.company-legal-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.legalCompanyName"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                :label="$t('onboarding.contract.form.date')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.date"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                :label="$t('onboarding.contract.form.owner-first-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.ownerFirstName"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                :label="$t('onboarding.contract.form.owner-last-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.ownerLastName"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center justify-end">
                <v-btn
                  @click="previousStep"
                  type="button"
                  class="text-none body-2 font-weight-medium mr-2"
                  depressed
                  :loading="isSubmitting"
                >
                  {{ $t("onboarding.step-actions.previous") }}
                </v-btn>
                <v-btn
                  type="submit"
                  color="success"
                  class="text-none body-2 font-weight-medium"
                  depressed
                  :loading="isSubmitting"
                >
                  {{ $t("onboarding.step-actions.next") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const initialForm = {
  legalCompanyName: null,
  date: null,
  ownerFirstName: null,
  ownerLastName: null,
};

export default {
  data() {
    return {
      loading: false,
      isDownloading: false,
      form: { ...initialForm },
      isFormValid: true,
    };
  },
  props: {
    isSubmitting: Boolean,
    formData: Object,
    idHash: String,
  },
  computed: {
    generatedFileName() {
      return `${this.$t("onboarding.contract.form.contract-name-prefix")}_${
        this.formData.legalCompanyName
      }_${this.$moment().format("MMDDYYYY")}`;
    },
  },
  methods: {
    submitStep() {
      if (this.isFormValid && this.$refs.form.validate()) {
        this.$emit("submitStep");
      }
    },
    previousStep() {
      this.$emit("previousStep");
    },
    setInitialForm() {
      this.form = {
        legalCompanyName: this.formData.legalCompanyName,
        date: this.$moment().format("MM/DD/YYYY"),
        ownerFirstName: this.formData.ownerFirstName,
        ownerLastName: this.formData.ownerLastName,
      };
    },
    downloadLeadContract() {
      this.isDownloading = true;
      window.API.downloadLeadContract(this.idHash)
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("onboarding.contract.alert.could-not-download"),
          });
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.setInitialForm();
    } finally {
      this.loading = false;
    }
  },
};
</script>
