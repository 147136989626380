<template>
  <d-page :title="$t('tags.page-heading')">
    <template slot="actions">
      <v-btn
        color="success"
        depressed
        large
        @click="handleOpenAddOrEditModal()"
        v-granted="'tag:add'"
        class="mb-1 mb-sm-0"
      >
        <v-icon left> mdi-plus </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("tags.button.create-item")
        }}</span>
      </v-btn>
    </template>
    <template slot="content">
      <List
        @editItem="handleOpenAddOrEditModal"
        @deleteItem="handleOpenDeleteModal"
        @addTag="handleOpenAddOrEditTagModal"
        @editTag="handleOpenAddOrEditTagModal"
        @deleteTag="handleOpenDeleteTagModal"
        @changeTagStatus="handleOpenChangeTagStatusModal"
      ></List>
      <AddOrEdit
        v-if="openAddOrEditModal"
        :modal="openAddOrEditModal"
        :item="selectedItem"
        @closeModal="handleCloseAddOrEditModal"
        @submitModal="handleSubmitAddOrEditModal"
      ></AddOrEdit>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :item="selectedItem"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
      ></Delete>
      <AddOrEditTag
        v-if="openAddOrEditTagModal"
        :modal="openAddOrEditTagModal"
        :item="selectedTag"
        :tagGroup="selectedItem"
        @closeModal="handleCloseAddOrEditTagModal"
        @submitModal="handleSubmitAddOrEditTagModal"
      ></AddOrEditTag>
      <DeleteTag
        v-if="openDeleteTagModal"
        :modal="openDeleteTagModal"
        :item="selectedTag"
        @closeModal="handleCloseDeleteTagModal"
        @submitModal="handleSubmitDeleteTagModal"
      ></DeleteTag>
      <ChangeTagStatus
        v-if="openChangeTagStatusModal"
        :modal="openChangeTagStatusModal"
        :item="selectedTag"
        @closeModal="handleCloseChangeTagStatusModal"
        @submitModal="handleSubmitChangeTagStatusModal"
      ></ChangeTagStatus>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import AddOrEdit from "./AddOrEdit.vue";
import Delete from "./Delete.vue";
import AddOrEditTag from "./tag/AddOrEdit.vue";
import DeleteTag from "./tag/Delete.vue";
import ChangeTagStatus from "./tag/ChangeStatus.vue";

export default {
  components: {
    List,
    AddOrEdit,
    Delete,
    AddOrEditTag,
    DeleteTag,
    ChangeTagStatus,
  },
  data() {
    return {
      openAddOrEditModal: false,
      openDeleteModal: false,
      openAddOrEditTagModal: false,
      openDeleteTagModal: false,
      openChangeTagStatusModal: false,
      selectedItem: null,
      selectedTag: null,
    };
  },
  methods: {
    handleOpenAddOrEditModal(item = null) {
      this.openAddOrEditModal = true;
      this.selectedItem = item;
    },
    handleCloseAddOrEditModal() {
      this.openAddOrEditModal = false;
    },
    handleSubmitAddOrEditModal() {
      this.$eventBus.$emit("fetchTagGroups");
    },
    handleOpenDeleteModal(item = null) {
      this.selectedItem = item;
      this.openDeleteModal = true;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchTagGroups");
    },
    handleOpenAddOrEditTagModal(tag = null, tagGroup = null) {
      this.openAddOrEditTagModal = true;
      this.selectedTag = tag;
      this.selectedItem = tagGroup;
    },
    handleCloseAddOrEditTagModal() {
      this.openAddOrEditTagModal = false;
    },
    handleSubmitAddOrEditTagModal() {
      this.$eventBus.$emit("fetchTagGroups");
    },
    handleOpenDeleteTagModal(tag = null) {
      this.selectedTag = tag;
      this.openDeleteTagModal = true;
    },
    handleCloseDeleteTagModal() {
      this.openDeleteTagModal = false;
    },
    handleSubmitDeleteTagModal() {
      this.$eventBus.$emit("fetchTagGroups");
    },
    handleOpenChangeTagStatusModal(tag = null) {
      this.openChangeTagStatusModal = true;
      this.selectedTag = tag;
    },
    handleCloseChangeTagStatusModal() {
      this.openChangeTagStatusModal = false;
    },
    handleSubmitChangeTagStatusModal() {
      this.$eventBus.$emit("fetchTagGroups");
    },
  },
};
</script>
