<template>
  <main class="h-100">
    <TheSidebar />
    <TheHeader />
    <v-main class="h-100">
      <d-loading v-if="loading.page"></d-loading>
      <router-view v-if="showPage" />
      <ChooseAccount
        v-if="openChooseAccountModal"
        :modal="openChooseAccountModal"
        :myClients="myClients"
        :isSubmitting="isSubmitting"
        @submitModal="handleSubmitChooseAccountModal"
      />
      <LeadOnboardingCompletionPartModal
        v-if="openLeadOnboardingCompletionPartModal"
        :modal="openLeadOnboardingCompletionPartModal"
        :formData="leadFormData"
        @closeModal="handleCloseLeadOnboardingCompletionPartModal"
      />
      <ConfirmAnnouncement
        v-if="currentAnnouncement && openConfirmAnnouncementModal"
        :modal="openConfirmAnnouncementModal"
        :item="currentAnnouncement"
        @submitModal="handleSubmitConfirmAnnouncementModal"
      ></ConfirmAnnouncement>
    </v-main>
  </main>
</template>

<script>
import TheSidebar from "@/components/TheSidebar.vue";
import TheHeader from "@/components/TheHeader.vue";
import ChooseAccount from "@/views/profile/ChooseAccount.vue";
import LeadOnboardingCompletionPartModal from "@/views/customer-onboarding/CompletionPartModal.vue";
import { mapGetters, mapActions } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import LeadStatuses from "@/shared/enums/LeadStatuses";
import ConfirmAnnouncement from "@/views/profile/ConfirmAnnouncement.vue";

export default {
  components: {
    TheSidebar,
    TheHeader,
    ChooseAccount,
    LeadOnboardingCompletionPartModal,
    ConfirmAnnouncement,
  },
  data() {
    return {
      openChooseAccountModal: false,
      loading: {
        page: true,
      },
      isSubmitting: false,
      myClients: [],
      leadFormData: null,
      openLeadOnboardingCompletionPartModal: false,
      openConfirmAnnouncementModal: true,
      myUnconfirmedAnnouncements: [],
      currentAnnouncement: null,
      currentAnnouncementIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
      loggedInUser: "Auth/getLoggedInUser",
      isLeadOnboardingCompleted: "Auth/isLeadOnboardingCompleted",
      isRedirectedFromLogin: "Common/isRedirectedFromLogin",
    }),
    showPage() {
      if (authorizationUtils.isClient()) {
        return (
          this.myCurrentClient &&
          this.isLeadOnboardingCompleted !== false &&
          !this.loading.page
        );
      } else {
        return !this.loading.page;
      }
    },
  },
  methods: {
    ...mapActions({
      setMyCurrentClient: "Auth/setMyCurrentClient",
      setIsLeadOnboardingCompleted: "Auth/setIsLeadOnboardingCompleted",
      setIsRedirectedFromLogin: "Common/setIsRedirectedFromLogin",
    }),
    async checkIsMyLeadOnboardingCompleted() {
      if (
        this.loggedInUser.leadIdHash &&
        this.isLeadOnboardingCompleted === false
      ) {
        await window.API.getLeadByIdHash(
          encodeURIComponent(this.loggedInUser.leadIdHash)
        ).then(async (response) => {
          if (
            response.status === LeadStatuses.CREATE_ACCOUNT ||
            response.status === LeadStatuses.BILLING_INFO
          ) {
            this.leadFormData = response;
            this.openLeadOnboardingCompletionPartModal = true;
            this.loading.page = false;
          } else if (response.status === LeadStatuses.COMPLETED) {
            this.setIsLeadOnboardingCompleted(true);
          }
        });
      }
    },
    async checkMyClients() {
      if (!this.myCurrentClient && this.isLeadOnboardingCompleted !== false) {
        await this.getAllMyClients();
      }
    },
    async checkAnnouncements() {
      if (
        this.isRedirectedFromLogin &&
        this.isLeadOnboardingCompleted !== false
      ) {
        this.myUnconfirmedAnnouncements =
          await window.API.getMyUnconfirmedAnnouncements();

        if (this.myUnconfirmedAnnouncements.length) {
          this.loading.page = false;
          await this.setCurrentAnnouncement();
        } else {
          this.setIsRedirectedFromLogin(false);
        }
      }
    },
    async getAllMyClients() {
      await window.API.getAllMyClients()
        .then((response) => {
          this.myClients = response;

          if (this.myClients.length == 1) {
            this.updateMyCurrentClient(this.myClients[0]);
          } else if (this.myClients.length > 1) {
            this.openChooseAccountModal = true;
          } else {
            this.loading.page = false;
          }
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    updateMyCurrentClient(item) {
      this.isSubmitting = true;
      window.API.updateMyCurrentClient({ clientId: item.id })
        .then(() => {
          this.setMyCurrentClient(item);
          this.openChooseAccountModal = false;
          this.loading.page = false;
        })
        .finally(() => (this.isSubmitting = false));
    },
    async setCurrentAnnouncement() {
      if (
        this.myUnconfirmedAnnouncements.length > this.currentAnnouncementIndex
      ) {
        this.currentAnnouncement =
          this.myUnconfirmedAnnouncements[this.currentAnnouncementIndex];
        return;
      }

      this.openConfirmAnnouncementModal = false;
      this.setIsRedirectedFromLogin(false);
      await this.checkMyClients();
    },
    async handleCloseLeadOnboardingCompletionPartModal() {
      this.openLeadOnboardingCompletionPartModal = false;
      await this.setIsLeadOnboardingCompleted(true);
      await this.checkAnnouncements();

      if (!this.isRedirectedFromLogin) {
        await this.checkMyClients();
      }
    },
    handleSubmitChooseAccountModal(item) {
      this.updateMyCurrentClient(item);
    },
    async handleSubmitConfirmAnnouncementModal() {
      this.currentAnnouncementIndex++;
      await this.setCurrentAnnouncement();
    },
  },
  async mounted() {
    if (authorizationUtils.isClient()) {
      await this.checkIsMyLeadOnboardingCompleted();
      await this.checkAnnouncements();

      if (!this.isRedirectedFromLogin) {
        await this.checkMyClients();
      }

      if (this.myCurrentClient && this.isLeadOnboardingCompleted !== false) {
        this.loading.page = false;
      }
    } else {
      this.loading.page = false;
    }
  },
};
</script>
