<template>
  <div v-if="renderComponent">
    <div class="editor" :class="{ 'editor--invalid': !isValid }">
      <editor
        :api-key="apiKey"
        v-model="content"
        tag-name="textarea"
        output-format="html"
        class="tinymce-editor"
        :id="id"
        :init="{
          selector: `#${id}`,
          height: height,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap',
            'searchreplace visualblocks code fullscreen',
            'print preview anchor insertdatetime media',
            'paste code help wordcount table',
          ],
          toolbar:
            'undo redo | styleselect  | bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat  | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | image link table',
          paste_data_images: true,
          images_upload_handler: function (blobInfo, success, failure) {
            uploadImage(blobInfo, success, failure);
          },
          image_description: false,
        }"
      />
    </div>
    <v-input
      v-if="!isValid"
      error
      :messages="$t('defaults.rule.required')"
      class="pl-3"
    >
    </v-input>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
      default: "tinymceEditor",
    },
    height: {
      type: Number,
      default: 500,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: null,
      renderComponent: true,
      apiKey: null,
    };
  },
  watch: {
    content: {
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    uploadImage(blobInfo, success) {
      var reader = new FileReader();
      reader.readAsDataURL(blobInfo.blob());
      reader.onload = function () {
        success(reader.result);
      };
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
  created() {
    this.apiKey = process.env.VUE_APP_TINYMCE_API_KEY;

    this.forceRerender();
    if (this.value) {
      this.content = this.value;
    }
  },
};
</script>
