import axios from "axios";
import store from "@/store";
import router from "@/router";
import API from "@/services";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + "/api/v1",
  timeout: 60 * 1000,
});

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

function redirectToLogin() {
  store.dispatch("Auth/logOut");
  router.push("/login");
}

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = store.getters["Auth/getRefreshToken"];

    // If error, process all the requests in the queue and logout the user.
    const handleError = (error) => {
      processQueue(error);
      redirectToLogin();
      throw error;
    };

    // Refresh token conditions
    if (
      refreshToken &&
      error.response?.status === 401 &&
      originalRequest?.url !== "/authentication/account/refresh-jwt-tokens" &&
      originalRequest?.url !== "/authentication/account/logout" &&
      originalRequest?._retry !== true
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject }); // failedQueue = [Promise(then, catch), ...]
        })
          .then(() => {
            return axiosClient(originalRequest);
          })
          .catch((err) => {
            throw err;
          });
      }

      isRefreshing = true;
      originalRequest._retry = true;

      try {
        const response = await API.refreshToken(refreshToken);
        if (!response) {
          return redirectToLogin();
        }
        store.dispatch("Auth/updateTokens", {
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
        });
        store.dispatch("Auth/setUserPermissions", response.permissions);

        processQueue(null);
        return axiosClient(originalRequest);
      } catch (err) {
        return handleError(err);
      } finally {
        isRefreshing = false;
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    throw error;
  }
);

export default axiosClient;
