import Vue from "vue";
import components from "./components/components";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "@/assets/sass/main.scss";
import { i18n } from "./plugins/i18n";
import axios from "./plugins/axios";
import API from "./services";
import globalMixin from "./mixins/globalMixins";
import VueMask from "v-mask";
import { Constants } from "./plugins/constants";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";
import VueGtag from "vue-gtag";

window.axios = axios;
window.API = API;

Vue.config.productionTip = false;

export const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

components.forEach(({ name, component }) => {
  Vue.component(name, component);
});

Vue.component("v-granted", require("@/directives/authorization.js"));

Vue.mixin(globalMixin);
Vue.use(VueMask);
Vue.use(require("vue-moment"));
Vue.use(Constants);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
}, router);

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "en-US",
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
