import { downloadFile } from "@/utils/file.js";

export function fetchUsers(params) {
  return window.axios
    .get("/identity/users", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchAllAdvisors() {
  return window.axios
    .get("/identity/users/advisor")
    .then((response) => response.data);
}

export function fetchAllSupervisors() {
  return window.axios
    .get("/identity/users/supervisor")
    .then((response) => response.data);
}

export function fetchClientUsers(searchTerm) {
  return window.axios
    .get("/identity/users/client", { params: { searchTerm } })
    .then((response) => response.data);
}

export function fetchAllNonClientUsers() {
  return window.axios
    .get("/identity/users/non-client")
    .then((response) => response.data);
}

export function getUserById(id) {
  return window.axios
    .get(`/identity/users/${id}`)
    .then((response) => response.data);
}

export function insertUser(data) {
  return window.axios
    .post("/identity/users", data)
    .then((response) => response.data);
}

export function insertClientUser(data) {
  return window.axios
    .post("/identity/users/client", data)
    .then((response) => response.data);
}

export function editUser(id, data) {
  return window.axios
    .put(`/identity/users/${id}`, data)
    .then((response) => response.data);
}

export function resetUserPassword(id, data) {
  return window.axios
    .patch(`/identity/users/${id}/password`, data)
    .then((response) => response.data);
}

export function checkEmailAddressAvailability(emailAddress) {
  return window.axios
    .get(`/identity/users/email-address/${emailAddress}/is-available`)
    .then((response) => response.data);
}

export function checkUsernameAvailability(username) {
  return window.axios
    .get(`/identity/users/username/${username}/is-available`)
    .then((response) => response.data);
}

export function activateUser(id) {
  return window.axios
    .patch(`identity/users/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateUser(id) {
  return window.axios
    .patch(`identity/users/${id}/deactivate`)
    .then((response) => response.data);
}

export function exportUsers(params) {
  return window.axios
    .get("/identity/users/export", {
      responseType: "blob",
      params: params,
    })
    .then((response) => downloadFile(response));
}
