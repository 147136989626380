<template>
  <d-page :title="$t('leads.details.title')">
    <template slot="append-heading">
      <v-chip v-if="lead" class="ml-6" small color="primary" outlined
        >{{ lead.status.displayName }}
      </v-chip>
    </template>
    <template slot="actions" v-if="showCompleteOnboardingLink">
      <div class="ml-2 d-flex align-center w-100">
        <v-text-field
          filled
          :label="$t('leads.details.complete-onboarding-link')"
          @focus="$event.target.select()"
          ref="myinput"
          readonly
          :value="completeOnboardingLink"
          hide-details
          dense
        >
        </v-text-field>

        <v-tooltip top color="gray darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="copyCompleteOnboardingLink"
              class="ml-2"
            >
              <v-icon size="18"> mdi-content-copy </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("leads.details.button.copy") }}</span>
        </v-tooltip>
      </div>
    </template>

    <template slot="content">
      <d-loading v-if="loading"></d-loading>
      <div v-else class="company-details border-all rounded">
        <GeneralInfo :item="lead" />
        <ContactPersonInfo :item="lead" />
        <IntakeQuestions :item="lead" />
        <Quote :item="lead" />
        <Contract :item="lead" />
        <CreateAccount :item="lead" />
        <BillingInfo :item="lead" />
        <DocumentUploads :item="lead" />
      </div>
    </template>
  </d-page>
</template>

<script>
import GeneralInfo from "./GeneralInfo.vue";
import ContactPersonInfo from "./ContactPersonInfo.vue";
import IntakeQuestions from "./IntakeQuestions.vue";
import Quote from "./Quote.vue";
import Contract from "./Contract.vue";
import CreateAccount from "./CreateAccount.vue";
import BillingInfo from "./BillingInfo.vue";
import DocumentUploads from "./DocumentUploads.vue";
import LeadStatuses from "@/shared/enums/LeadStatuses";

export default {
  components: {
    GeneralInfo,
    ContactPersonInfo,
    IntakeQuestions,
    Quote,
    Contract,
    CreateAccount,
    BillingInfo,
    DocumentUploads,
  },
  data() {
    return {
      loading: true,
      lead: null,
    };
  },
  computed: {
    completeOnboardingLink() {
      return `${process.env.VUE_APP_BACKEND_URL}/customer-onboarding/${this.lead.idHash}`;
    },
    showCompleteOnboardingLink() {
      return (
        this.lead &&
        (this.lead.status.id == LeadStatuses.GENERAL_INFORMATION ||
          this.lead.status.id == LeadStatuses.INTAKE_QUESTIONS ||
          this.lead.status.id == LeadStatuses.QUOTE ||
          this.lead.status.id == LeadStatuses.CONTRACT)
      );
    },
  },
  methods: {
    async getLeadDetailsById(id) {
      try {
        this.loading = true;
        this.lead = await window.API.getLeadDetailsById(id);
      } catch {
        this.showFetchRequestErrorMessage();
      } finally {
        this.loading = false;
      }
    },
    copyCompleteOnboardingLink() {
      this.$refs.myinput.focus();
      document.execCommand("copy");
    },
  },
  mounted() {
    this.getLeadDetailsById(this.$route.params.id);
  },
};
</script>
