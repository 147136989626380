<template>
  <div class="login-layout__form">
    <h1 class="text-h5 font-weight-medium mb-6">
      {{ $t("login.page-heading") }}
    </h1>

    <v-form
      ref="form"
      v-model="isValidForm"
      lazy-validation
      @submit.prevent="handleFormSubmit"
    >
      <v-row>
        <v-col cols="12" class="mb-4">
          <v-text-field
            :label="$t('login.form.username')"
            hide-details="auto"
            filled
            type="text"
            :rules="rules.username"
            v-model.trim="form.username"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-4">
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('login.form.password')"
            hide-details="auto"
            filled
            @click:append="showPassword = !showPassword"
            :rules="rules.password"
            v-model.trim="form.password"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-4">
          <div class="text-right">
            <router-link
              :to="{ name: 'ResetPassword' }"
              class="body-2 text-decoration-none primary--text"
              >{{ $t("login.form.forgot-password") }}</router-link
            >
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
            type="submit"
            color="primary"
            class="text-none body-2 font-weight-medium"
            depressed
            block
            large
            :loading="loading"
          >
            {{ $t("login.button.submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import authenticationMixins from "@/mixins/authenticationMixins";

export default {
  mixins: [authenticationMixins],
  data() {
    return {
      showPassword: false,
      isValidForm: true,
      loading: false,
      rules: {
        username: [(v) => !!v || this.$t("login.rule.username")],
        password: [(v) => !!v || this.$t("login.rule.password")],
      },
      form: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    async handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        try {
          this.loading = true;
          await this.globalLogin(this.form, () => {
            this.form.password = null;
            this.$refs.form.resetValidation();
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
