<template>
  <v-row>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('reconciliations.transactions.search.form.name')"
        v-model.trim="searchParams.name"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="mb-3">
      <v-text-field
        :label="$t('reconciliations.transactions.search.form.amount')"
        v-model.trim="searchParams.amount"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="6" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.categoryId"
        :items="categories"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :label="$t('reconciliations.transactions.search.form.category')"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="2" class="mb-3">
      <v-select
        filled
        v-model="searchParams.year"
        :items="years"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        :label="$t('reconciliations.transactions.search.form.year')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="2" class="mb-3">
      <v-select
        filled
        v-model="searchParams.month"
        :items="months"
        item-text="displayText"
        item-value="id"
        hide-details="auto"
        :label="$t('reconciliations.transactions.search.form.month')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="6" class="mb-3">
      <v-radio-group
        row
        filled
        v-model="searchParams.isDeposit"
        hide-details="auto"
      >
        <v-radio
          :label="$t('reconciliations.transactions.search.types.deposit')"
          :value="true"
        ></v-radio>
        <v-radio
          :label="$t('reconciliations.transactions.search.types.withdrawal')"
          :value="false"
        ></v-radio>
        <v-radio
          :label="$t('reconciliations.transactions.search.types.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    months: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    async getAllPlaidTransactionCategories() {
      const response = await window.API.getAllPlaidTransactionCategories();
      this.categories = response;
    },
  },
  async created() {
    await this.getAllPlaidTransactionCategories();
  },
};
</script>
