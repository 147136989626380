<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="filteredHeaders"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.contractorType`]="{ item }">
        {{ item.contractorType.name }}
      </template>
      <template v-slot:[`item.businessName`]="{ item }">
        {{ item.businessName ? item.businessName : item.fullName }}
      </template>
      <template v-slot:[`item.employerIdentificationNumber`]="{ item }">
        {{
          item.employerIdentificationNumber
            ? item.employerIdentificationNumber
            : item.socialSecurityNumberOrTaxpayerIdentificationNumber
        }}
      </template>
      <template v-slot:[`item.totalPayment`]="{ item }">
        <template v-if="authorizationUtils.isClient() && item.totalPayment === 0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" small class="ma-1" color="red" outlined>
                {{ item.totalPayment }}
              </v-chip>
            </template>
            <span>{{ $t("contractors.list.table.info.item-needs-action") }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          {{ $t("defaults.currency", { amount: formatPrice(item.totalPayment) }) }}
        </template>
      </template>
      <template v-slot:[`item.serviceFee`]="{ item }">
        <template v-if="item.paymentStatus === PaymentStatuses.PAID">
          <v-chip small class="ma-1" color="primary" outlined>
            {{ $t("contractors.list.table.payment-status.paid") }}
          </v-chip>
        </template>
        <template v-else>
          {{
            $t("defaults.currency", {
              amount: item.serviceFee,
            })
          }}
        </template>
      </template>
      <template v-slot:[`item.isCompleted`]="{ item }">
        <v-checkbox
          @change="completeOrOpenItem(item)"
          v-model="item.isCompleted"
          :disabled="
            !authorizationUtils.isGranted('contractor:complete-or-open') ||
            (loading.completeOrOpen && selectedItem.id === item.id)
          "
        ></v-checkbox>
      </template>
      <template v-if="!searchParams.year || searchParams.year === $constants.CURRENT_TAX_YEAR" v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          top
          color="gray darken-2"
          v-granted="'contractor:edit'"
          v-if="item.paymentStatus != PaymentStatuses.PAID"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              <v-icon size="18"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("contractors.list.table.actions.edit") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          color="gray darken-2"
          v-granted="'contractor:delete'"
          v-if="item.paymentStatus != PaymentStatuses.PAID"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
            >
              <v-icon size="18"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("contractors.list.table.actions.delete") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('contractors.list.empty-page')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import PaymentStatuses from "@/shared/enums/PaymentStatuses";
import authorizationUtils from "@/utils/authorization.js";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      PaymentStatuses,
      authorizationUtils,
      headers: [
        {
          text: vm.$t("contractors.list.table.headers.client"),
          value: "client",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.contractor-type"),
          value: "contractorType",
          sortable: false,
        },
        {
          text: vm.$t(
            "contractors.list.table.headers.business-name-or-full-name"
          ),
          value: "businessName",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.identification-number"),
          value: "employerIdentificationNumber",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.address"),
          value: "address",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.total-payment"),
          value: "totalPayment",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.service-fee"),
          value: "serviceFee",
          sortable: false,
        },
        {
          text: vm.$t("contractors.list.table.headers.complete"),
          value: "isCompleted",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {
        isPaid: null,
        isCompleted: null,
        contractorType: null,
        year: this.$constants.CURRENT_TAX_YEAR
      },
      loading: {
        completeOrOpen: false,
      },
      selectedItem: null,
    };
  },
  computed: {
    filteredHeaders() {
      if (authorizationUtils.isClient())
        return this.headers.filter(
          (header) => !["client", "isCompleted"].includes(header.value)
        );
      return this.headers;
    },
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({
        ...this.initialSearchParams,
      });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );

      window.API.fetchContractors({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          this.$emit(
            "updateTotalUnpaidServiceFee",
            response.totalUnpaidServiceFee
          );

          this.$emit(
            "updateHasAnyItemWithZeroTotalPayment",
            response.hasAnyItemWithZeroTotalPayment
          );

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    editItem(item = null) {
      this.$emit("editItem", item);
    },
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    completeOrOpenItem(item = null) {
      this.selectedItem = item;

      if (item.isCompleted) {
        this.completeContractor(item.id);
        return;
      }

      this.openContractor(item.id);
    },
    completeContractor(id) {
      this.loading.completeOrOpen = true;
      window.API.completeContractor(id)
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("contractors.complete.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.loading.completeOrOpen = false;
          this.fetchItems();
          this.selectedItem = null;
        });
    },
    openContractor(id) {
      this.loading.completeOrOpen = true;
      window.API.openContractor(id)
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("contractors.open.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.loading.completeOrOpen = false;
          this.fetchItems();
          this.selectedItem = null;
        });
    },
  },
  created() {
    this.$eventBus.$on("fetchContractors", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchContractors", this.handleFetchItems);
  },
};
</script>
