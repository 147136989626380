<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.contact-details.section-title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.contact-details.first-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.contactFirstName }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.contact-details.last-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.contactLastName }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.contact-details.email-address") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.contactEmailAddress }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.contact-details.phone-number") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.contactPhoneNumber }}
            </span>
          </v-col>
          <v-col md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.contact-details.fax") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(company.contactFax) }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      require: true,
    },
  },
};
</script>
