import fileDownload from "js-file-download";
import { getFilenameFromContentDispositionHeader } from "../utils/getFilenameFromContentDispositionHeader";

export function getAllBookkeepingBusinessClients(params) {
  return window.axios
    .get(`/document/clients/business/bookkeeping`, {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function getClientPlaidInstitutionAccounts(clientId) {
  return window.axios
    .get(`/document/clients/${clientId}/institution-accounts`)
    .then((response) => response.data);
}

export function getAllPlaidInstitutionAccountTransactions(
  clientId,
  institutionAccountId,
  params
) {
  return window.axios
    .get(
      `/document/clients/${clientId}/institution-accounts/${institutionAccountId}/transactions`,
      { params }
    )
    .then((response) => response.data);
}

export function getAllPlaidTransactionCategories() {
  return window.axios
    .get(`/document/transaction-categories`)
    .then((response) => response.data);
}

export function getPlaidInstitutionAccount(clientId, institutionAccountId) {
  return window.axios
    .get(
      `/document/clients/${clientId}/institution-accounts/${institutionAccountId}`
    )
    .then((response) => response.data);
}

export function getPlaidInstitutionAccountBalance(
  clientId,
  institutionAccountId,
  params
) {
  return window.axios
    .get(
      `/document/clients/${clientId}/institution-accounts/${institutionAccountId}/balance`,
      { params }
    )
    .then((response) => response.data);
}

export function createLinkTokenForCreditCardAccount(data) {
  return window.axios
    .post(`/document/reconciliations/accounts/credit-card/link-token`, data)
    .then((response) => response.data);
}

export function createLinkTokenForCheckingAccount(data) {
  return window.axios
    .post(`/document/reconciliations/accounts/checking/link-token`, data)
    .then((response) => response.data);
}

export function createInstitutionAccountsByPublicToken(data) {
  return window.axios
    .post(`/document/reconciliations/accounts`, data)
    .then((response) => response.data);
}

export function exportPlaidInstitutionAccountTransactions(
  clientId,
  institutionAccountId,
  params
) {
  return window.axios
    .get(
      `/document/clients/${clientId}/institution-accounts/${institutionAccountId}/transactions/export`,
      {
        params,
        responseType: "blob",
        timeout: 10 * 60 * 1000, // 10 minutes
      }
    )
    .then((response) => {
      if (response.data) {
        const fileName = getFilenameFromContentDispositionHeader(
          response.headers["content-disposition"]
        );
        fileDownload(response.data, `${fileName}`);
      }
      return;
    });
}

export function synchronizeClientPlaidInstitutionAccounts(clientId) {
  return window.axios
    .post(`/document/clients/${clientId}/institution-accounts/sync`)
    .then((response) => response.data);
}
