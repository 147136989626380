const state = {
  openUploadDocumentModal: false,
};

const getters = {
  getOpenUploadDocumentModal: (state) => state.openUploadDocumentModal,
};

const actions = {
  setOpenUploadDocumentModal({ commit }, openUploadDocumentModal) {
    commit("SET_OPEN_UPLOAD_DOCUMENT_MODAL", openUploadDocumentModal);
  },
};

const mutations = {
  SET_OPEN_UPLOAD_DOCUMENT_MODAL(state, openUploadDocumentModal) {
    state.openUploadDocumentModal = openUploadDocumentModal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
