<template>
  <div class="company-formation__summary mt-8">
    <h3 class="mb-2 subtitle-1 font-weight-medium">
      {{ $t("company-formation.resident.order-summary.title") }}
    </h3>

    <div class="company-formation__summary-list">
      <div class="company-formation__summary-list--item">
        <v-row>
          <v-col cols="12">
            <p class="mb-0 gray--text subtitle-2">
              {{
                $t("company-formation.non-resident.order-summary.goverment-fee")
              }}
            </p>
          </v-col>
        </v-row>
      </div>

      <div class="company-formation__summary-list--item">
        <v-row>
          <v-col cols="8">
            <p
              class="mb-0 gray--text text--darken-3 subtitle-2 font-weight-medium"
            >
              {{ $t("company-formation.non-resident.order-summary.total") }}
            </p>
          </v-col>
          <v-col cols="4">
            <p
              class="mb-0 text-right body-2 gray--text text--darken-3 font-weight-medium"
            >
              {{
                $t("defaults.currency", {
                  amount: "500",
                })
              }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
