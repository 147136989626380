<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <div class="d-flex flex-nowrap">
          <v-tooltip right :color="item.isActive ? 'success' : 'error'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.isActive ? 'success' : 'error'"
                size="12"
                dark
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                mdi-circle
              </v-icon>
            </template>
            <span>{{
              item.isActive
                ? $t("clients.list.table.status.active")
                : $t("clients.list.table.status.inactive")
            }}</span>
          </v-tooltip>
          <v-tooltip right color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                {{ isBusiness(item) ? "mdi-briefcase" : "mdi-account" }}
              </v-icon>
            </template>
            <span>{{
              isBusiness(item)
                ? $t("clients.client-type.business")
                : $t("clients.client-type.personal")
            }}</span>
          </v-tooltip>
          <span class="ml-2">{{ item.fullName }}</span>
        </div>
      </template>
      <template v-slot:[`item.advisor`]="{ item }">
        <span v-if="item.advisor">{{ item.advisor }}</span>
        <span class="caption text--disabled" v-else>{{
          $t("clients.list.table.no-advisor")
        }}</span>
      </template>

      <template v-slot:[`item.services`]="{ item }">
        <template v-for="(service, index) in item.services">
          <v-chip small :key="index" class="ma-1" color="primary" outlined>
            {{ service }}
          </v-chip>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-menu
            v-granted="
              'client:edit|client:view|client:change-status|client:delete'
            "
            bottom
            offset-y
            content-class="elevation-0 mt-1 rounded"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="gray darken-2" icon small v-bind="attrs" v-on="on">
                <v-icon size="18"> mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list outlined class="rounded" dense>
              <v-list-item
                :to="{
                  name: 'EditClient',
                  params: {
                    id: item.id,
                    clientType: isBusiness(item) ? 'business' : 'personal',
                  },
                }"
                v-granted="'client:edit'"
              >
                <v-list-item-title>
                  {{ $t("clients.list.table.actions.edit") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-granted="'client:view'" @click="viewItem(item)">
                <v-list-item-title>
                  {{ $t("clients.list.table.actions.details") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="changeStatus(item)"
                v-granted="'client:change-status'"
              >
                <v-list-item-title>
                  {{
                    item.isActive
                      ? $t("clients.list.table.actions.deactivate")
                      : $t("clients.list.table.actions.activate")
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-granted="'client:delete'"
                @click="deleteItem(item)"
              >
                <v-list-item-title>
                  {{ $t("clients.list.table.actions.delete") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :createButtonPermission="'client:add'"
      @actionClick="addItem()"
      :text="$t('clients.list.empty-page')"
      :createButtonText="$t('clients.button.create-item')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import ClientTypes from "@/shared/enums/ClientTypes";
import authorizationUtils from "@/utils/authorization.js";

export default {
  mixins: [tableMixins],
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("clients.list.table.headers.name"),
          value: "fullName",
          width: "25%",
        },
        {
          text: vm.$t("clients.list.table.headers.phone-number"),
          value: "phoneNumber",
        },
        {
          text: vm.$t("clients.list.table.headers.email-address"),
          value: "emailAddress",
        },
        {
          text: vm.$t("clients.list.table.headers.owner"),
          value: "owner",
        },
        {
          text: vm.$t("clients.list.table.headers.advisor"),
          value: "advisor",
        },
        {
          text: vm.$t("clients.list.table.headers.services"),
          value: "services",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {
        isActive: true,
        clientType: null,
      },
      ClientTypes,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
      loggedInUser: "Auth/getLoggedInUser",
    }),
  },
  methods: {
    isPersonal(item) {
      return item.clientType === ClientTypes.PERSONAL;
    },
    isBusiness(item) {
      return item.clientType === ClientTypes.BUSINESS;
    },
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      let serviceId = null;

      if (authorizationUtils.isEmployee()) {
        const selectedServices = this.services
          .filter((item) => item.departmentId == this.loggedInUser.departmentId)
          .sort(function (a, b) {
            return a.id - b.id;
          });

        if (selectedServices && selectedServices.length > 0) {
          serviceId = selectedServices[0]?.id;
        }
      }

      this.setSearchParams({
        ...this.initialSearchParams,
        serviceId: serviceId,
      });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    addItem(item = null) {
      this.$emit("addItem", item);
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    changeStatus(item = null) {
      this.$emit("changeStatus", item);
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchClients({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    handleFetchClientsAfterStatusChange() {
      if (
        Math.ceil(this.table.totalCount / this.table.itemsPerPage) ===
          this.table.page &&
        this.table.totalCount % this.table.itemsPerPage === 1 &&
        this.table.page != 1 &&
        this.searchParams.isActive !== null
      ) {
        this.table.options.page = this.table.options.page - 1;
        this.table.page = this.table.page - 1;
      }

      this.fetchItems();
    },
    handleFetchClientsAfterDelete() {
      if (
        Math.ceil(this.table.totalCount / this.table.itemsPerPage) ===
          this.table.page &&
        this.table.totalCount % this.table.itemsPerPage === 1 &&
        this.table.page != 1
      ) {
        this.table.options.page = this.table.options.page - 1;
        this.table.page = this.table.page - 1;
      }

      this.fetchItems();
    },
  },
  created() {
    this.$eventBus.$on("fetchClients", this.handleFetchItems);
    this.$eventBus.$on(
      "fetchClientsAfterStatusChange",
      this.handleFetchClientsAfterStatusChange
    );
    this.$eventBus.$on(
      "fetchClientsAfterDelete",
      this.handleFetchClientsAfterDelete
    );
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchClients", this.handleFetchItems);
    this.$eventBus.$off(
      "fetchClientsAfterStatusChange",
      this.handleFetchClientsAfterStatusChange
    );
    this.$eventBus.$off(
      "fetchClientsAfterDelete",
      this.handleFetchClientsAfterDelete
    );
  },
};
</script>
