export function getTagById(id) {
  return window.axios
    .get(`/document/tags/${id}`)
    .then((response) => response.data);
}

export function fetchAllTags() {
  return window.axios
    .get("/document/tags/all")
    .then((response) => response.data);
}

export function insertTag(data) {
  return window.axios
    .post("/document/tags", data)
    .then((response) => response.data);
}

export function editTag(id, data) {
  return window.axios
    .put(`/document/tags/${id}`, data)
    .then((response) => response.data);
}

export function checkTagNameAvailability(name, tagGroupId) {
  return window.axios
    .get(`document/tags/name/${name}/is-available`, { params: { tagGroupId } })
    .then((response) => response.data);
}

export function checkTagDisplayNameAvailability(displayName, tagGroupId) {
  return window.axios
    .get(`document/tags/display-name/${displayName}/is-available`, {
      params: { tagGroupId },
    })
    .then((response) => response.data);
}

export function deleteTag(id) {
  return window.axios
    .delete(`/document/tags/${id}`)
    .then((response) => response);
}

export function activateTag(id) {
  return window.axios
    .patch(`/document/tags/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateTag(id) {
  return window.axios
    .patch(`/document/tags/${id}/deactivate`)
    .then((response) => response.data);
}

export function getAllTags() {
  return window.axios
    .get(`/document/tags/all`)
    .then((response) => response.data);
}

export function getMainTagGroupTagsByServiceId(serviceId, includeIcons) {
  return window.axios
    .get(`document/services/${serviceId}/main-tag-group-tags`, {
      params: {
        includeIcons,
      },
    })
    .then((response) => response.data);
}
