<template>
  <d-page
    :title="$t('invitations.page-heading')"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("invitations.button.search-toggle")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search></Search>
    </template>
    <template slot="content">
      <List></List>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import Search from "./Search.vue";

export default {
  components: {
    List,
    Search,
  },
  data() {
    return {
      searchToggle: false,
    };
  },
  methods: {
    handleCloseSearch() {
      this.$eventBus.$emit("fetchInvitations", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchInvitations");
    },
  },
};
</script>
