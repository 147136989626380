<template>
  <d-modal
    :title="$t('announcements.details.title')"
    :modal="modal"
    :saveButtonText="$t('defaults.modal-confirm')"
    :isSaveButtonDisabled="!form.isAcknowledged"
    :hasCancelButton="false"
    @submitModal="handleSubmitModal"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <div v-else class="announcement-details">
        <v-row>
          <v-col cols="12" class="mb-3">
            <h4 v-text="announcement.title"></h4>
          </v-col>
          <v-col cols="12">
            <div v-html="announcement.body"></div>
          </v-col>
          <v-col cols="12">
            <v-checkbox hide-details="auto" v-model="form.isAcknowledged">
              <template v-slot:label>
                <div v-html="announcement.acknowledgmentText"></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
    </template>
  </d-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      announcement: null,
      form: {
        isAcknowledged: false,
      },
      loading: {
        page: false,
      },
    };
  },
  methods: {
    handleSubmitModal() {
      this.$emit("submitModal");
    },
    async getAnnouncementDetailsById() {
      this.loading.page = true;
      await window.API.getAnnouncementDetailsById(this.item.id)
        .then((response) => {
          this.announcement = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.page = false));
    },
  },
  async created() {
    await this.getAnnouncementDetailsById();
  },
};
</script>
