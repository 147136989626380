<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex flex-nowrap">
          <v-tooltip right :color="item.isActive ? 'success' : 'error'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.isActive ? 'success' : 'error'"
                size="12"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-circle
              </v-icon>
            </template>
            <span>{{
              item.isActive
                ? $t("services.list.table.status.active")
                : $t("services.list.table.status.inactive")
            }}</span>
          </v-tooltip>
          <span class="ml-2">{{ item.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.departmentDisplayName`]="{ item }">
        <v-chip small class="ma-1" color="primary" outlined>
          {{ item.departmentDisplayName }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip
            top
            color="gray darken-2"
            v-granted="'service:change-status'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                @click="changeStatus(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ item.isActive ? "mdi-lock" : "mdi-lock-open" }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              item.isActive
                ? $t("services.list.table.actions.deactivate")
                : $t("services.list.table.actions.activate")
            }}</span>
          </v-tooltip>
          <v-tooltip top color="gray darken-2" v-granted="'service:edit'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                @click="addOrEditItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("services.list.table.actions.edit") }}</span>
          </v-tooltip>
          <v-tooltip top color="gray darken-2" v-granted="'service:delete'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("services.list.table.actions.delete") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :createButtonPermission="'service:add'"
      @actionClick="addOrEditItem()"
      :text="$t('services.list.empty-page')"
      :createButtonText="$t('services.button.create-item')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("services.list.table.headers.name"),
          value: "name",
        },
        {
          text: vm.$t("services.list.table.headers.display-name"),
          value: "displayName",
        },
        {
          text: vm.$t("services.list.table.headers.department"),
          value: "departmentDisplayName",
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {
        isActive: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    changeStatus(item) {
      this.$emit("changeStatus", item);
    },
    addOrEditItem(item = null) {
      this.$emit("editItem", item);
    },
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchServices({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  created() {
    this.$eventBus.$on("fetchServices", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchServices", this.handleFetchItems);
  },
};
</script>
