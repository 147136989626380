<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row>
      <v-col cols="12" class="mb-4">
        <h4 class="subtitle-1 font-weight-medium">
          {{
            $t("company-formation.resident.step-1.form.business-entity-type")
          }}
        </h4>
        <v-radio-group
          row
          filled
          hide-details="auto"
          class="mt-0"
          v-model="form.businessEntityType"
        >
          <v-radio
            v-for="businessEntityType in businessEntityTypes"
            :key="businessEntityType.id"
            :label="businessEntityType.name"
            :value="businessEntityType.id"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="mb-4">
        <h4 class="subtitle-1 font-weight-medium">
          {{ $t("company-formation.resident.step-1.form.state") }}
        </h4>
        <v-radio-group
          row
          filled
          hide-details="auto"
          class="mt-0"
          v-model="form.stateId"
          :rules="rules.state"
        >
          <v-radio
            v-for="state in states"
            :key="state.id"
            :label="state.name"
            :value="state.id"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col md="6">
            <div class="d-flex">
              <v-text-field
                :label="$t('company-formation.resident.step-1.form.legal-name')"
                hide-details="auto"
                filled
                type="text"
                v-model="form.legalName"
                :rules="rules.legalName"
              ></v-text-field>
              <div class="company-formation__form--label ml-2">
                <v-text-field
                  :value="businessEntityDesignator"
                  hide-details="auto"
                  filled
                  type="text"
                  disabled
                >
                </v-text-field>
              </div>
            </div>
            <div class="d-flex">
              <a
                v-if="form.stateId && checkBusinessNameAvailabilityUrl"
                @click.prevent="openURL(checkBusinessNameAvailabilityUrl)"
                href="#"
                class="body-2 font-weight-medium primary--text mb-0 mt-2 px-2 text-decoration-none"
                >{{
                  $t(
                    "company-formation.resident.step-1.form.check-business-name-availability-url"
                  )
                }}</a
              >
              <p
                v-if="form.stateId && !checkBusinessNameAvailabilityUrl"
                class="body-2 font-weight-medium primary--text mb-0 mt-2 px-2"
              >
                {{
                  $t(
                    "company-formation.resident.step-1.form.check-business-name-availability-empty"
                  )
                }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col md="6" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.resident.step-1.form.doing-business-as')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.doingBusinessAs"
            ></v-text-field>
          </v-col>
          <v-col md="6" class="mb-4">
            <v-autocomplete
              filled
              :items="industries"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              :label="$t('company-formation.resident.step-1.form.industry')"
              v-model="form.industryId"
              :rules="rules.industry"
            ></v-autocomplete>
          </v-col>
          <v-col
            class="mb-4"
            md="6"
            v-if="form.industryId == defaultIndustryList.value"
          >
            <v-text-field
              :label="
                $t('company-formation.resident.step-1.form.industry-name')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.otherIndustryName"
              :rules="rules.otherIndustryName"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="pb-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{ $t("company-formation.resident.step-1.form.contact-details") }}
          </h4>
        </div>

        <v-row>
          <v-col md="6" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.resident.step-1.form.contact-first-name')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactFirstName"
              :rules="rules.contactFirstName"
            ></v-text-field>
          </v-col>
          <v-col md="6" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.resident.step-1.form.contact-last-name')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactLastName"
              :rules="rules.contactLastName"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.resident.step-1.form.contact-email-address'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactEmailAddress"
              :rules="rules.contactEmailAddress"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.resident.step-1.form.contact-phone-number'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactPhoneNumber"
              :rules="rules.contactPhoneNumber"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="$t('company-formation.resident.step-1.form.contact-fax')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactFax"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-end">
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
          >
            {{ $t("company-formation.resident.step-1.actions.submit") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BusinessEntityTypesEnum from "@/shared/enums/BusinessEntityTypes";
import companyFormationMixins from "@/mixins/companyFormationMixins";

const initialForm = {
  businessEntityType: BusinessEntityTypesEnum.LLC,
  stateId: null,
  legalName: null,
  doingBusinessAs: null,
  industryId: null,
  otherIndustryName: null,
  contactFirstName: null,
  contactLastName: null,
  contactEmailAddress: null,
  contactPhoneNumber: null,
  contactFax: null,
};

export default {
  data() {
    return {
      BusinessEntityTypesEnum,
      businessEntityTypes: [],
      industries: [],
      form: { ...initialForm },
      isFormValid: true,
      loading: false,
      rules: {
        state: [(v) => !!v || this.$t("defaults.rule.required")],
        legalName: [(v) => !!v || this.$t("defaults.rule.required")],
        industry: [(v) => !!v || this.$t("defaults.rule.required")],
        otherIndustryName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactLastName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactEmailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t(
              "company-formation.resident.step-1.rule.email-address.invalid"
            ),
        ],
        contactPhoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
      },
    };
  },
  mixins: [companyFormationMixins],
  props: {
    states: {
      type: Array,
      required: true,
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit("formUpdated", this.form);
      },
      deep: true,
    },
  },
  computed: {
    businessEntityDesignator() {
      return this.businessEntityTypes.find(
        (businessEntityType) =>
          businessEntityType.id == this.form.businessEntityType
      )?.designator;
    },
    checkBusinessNameAvailabilityUrl() {
      return this.states.find((state) => state.id == this.form.stateId)
        ?.checkBusinessNameAvailabilityUrl;
    },
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitStep", this.form);
      }
    },
    async fetchAllCompanyBusinessEntityTypes() {
      this.businessEntityTypes =
        await window.API.fetchAllCompanyBusinessEntityTypes();
    },
    async fetchAllCompanyIndustries() {
      this.industries = await window.API.fetchAllCompanyIndustries();
      this.industries.push({ ...this.defaultIndustryList });
    },
    openURL(url) {
      var left = window.innerWidth / 2 - 600 / 2,
        top = window.innerHeight / 2 - 600 / 2;

      window.open(
        url,
        "popup",
        "width=600, height=600, top=" + top + ", left=" + left
      );
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchAllCompanyBusinessEntityTypes();
      await this.fetchAllCompanyIndustries();
    } finally {
      this.loading = false;
    }
  },
};
</script>
