export function fetchBookkeeping(params) {
  return window.axios
    .get("/document/bookkeeping", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function insertBookkeeping(data) {
  return window.axios
    .post(`/document/bookkeeping`, data)
    .then((response) => response);
}

export function editBookkeeping(id, data) {
  return window.axios
    .put(`/document/bookkeeping/${id}`, data)
    .then((response) => response);
}
