const qs = require("qs");
import { downloadFile } from "@/utils/file.js";

export function fetchLeads(params) {
  return window.axios
    .get("/document/leads", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchAllLeadStatuses() {
  return window.axios
    .get("/document/lead-statuses/all")
    .then((response) => response.data);
}

export function getLeadDetailsById(id) {
  return window.axios
    .get(`/document/leads/${id}/details`)
    .then((response) => response.data);
}

export function deleteLeads(ids) {
  return window.axios
    .delete("/document/leads", {
      params: { ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { skipNulls: true });
      },
    })
    .then((response) => response);
}

export function exportLeads(params) {
  return window.axios
    .get("/document/leads/export", {
      responseType: "blob",
      params: params,
    })
    .then((response) => downloadFile(response));
}
