const Months = [
  { id: 1, value: "Jan", displayText: "January" },
  { id: 2, value: "Feb", displayText: "February" },
  { id: 3, value: "Mar", displayText: "March" },
  { id: 4, value: "Apr", displayText: "April" },
  { id: 5, value: "May", displayText: "May" },
  { id: 6, value: "Jun", displayText: "June" },
  { id: 7, value: "Jul", displayText: "July" },
  { id: 8, value: "Aug", displayText: "August" },
  { id: 9, value: "Sep", displayText: "September" },
  { id: 10, value: "Oct", displayText: "October" },
  { id: 11, value: "Nov", displayText: "November" },
  { id: 12, value: "Dec", displayText: "December" },
];

export default Months;
