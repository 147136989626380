<template>
  <div class="d-image">
    <div
      class="d-image__selected"
      v-if="
        (imageData && imageData.length > 0) || (imageURL && imageURL.length > 0)
      "
    >
      <img
        class="d-image__selected--image"
        v-if="imageData"
        :src="imageData"
        alt="Docuport"
      />
      <d-auth-image
        v-if="!imageData && imageURL"
        :url="imageURL"
        :width="192"
        :height="192"
      ></d-auth-image>
      <v-btn
        icon
        @click.prevent="removeFile()"
        class="d-image__selected--delete"
      >
        <v-icon color="white" size="18">mdi-delete</v-icon>
      </v-btn>
    </div>
    <div class="d-image__select" @click.prevent="chooseFile()" v-else>
      <v-btn icon><v-icon size="24">mdi-plus</v-icon></v-btn>
      <span class="d-block caption">Upload</span>
    </div>

    <input
      id="fileInput"
      name="fileInput"
      type="file"
      ref="fileInput"
      style="display: none"
      @change="previewImage"
      @click="$refs.fileInput.value = null"
      accept="image/png,image/jpeg,image/jpg"
    />
  </div>
</template>

<style lang="scss">
.d-image {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--v-gray-lighten4);

  &__select {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px dashed var(--v-gray-lighten2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    & .v-icon,
    & span {
      color: var(--v-gray-darken1);
    }

    &:hover {
      border-color: var(--v-primary-base);
      cursor: pointer;

      & .v-icon,
      & span {
        color: var(--v-primary-base);
      }
    }
  }

  &__selected {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;

    $this: &;

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--delete {
      display: none !important;
      position: absolute !important;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    &:hover {
      #{$this} {
        &--image {
          filter: brightness(50%);
        }

        &--delete {
          display: inline-flex !important;
        }
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      imageFile: "",
      imageData: "",
      imageURL: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    chooseFile() {
      this.$refs.fileInput.click();
    },
    removeFile() {
      this.imageData = "";
      this.imageFile = "";
      this.imageURL = "";

      this.$emit("change", this.imageFile);
      this.$emit("add", this.imageData);
    },
    previewImage: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        if (
          input.files[0].type == "image/png" ||
          input.files[0].type == "image/jpg" ||
          input.files[0].type == "image/jpeg"
        ) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.imageData = e.target.result;
            this.$emit("add", this.imageData);
          };
          reader.readAsDataURL(input.files[0]);
          this.imageFile = input.files[0];
        }
      }
      this.$emit("change", this.imageFile);
    },
  },
  mounted() {
    if (this.value) {
      this.imageURL = this.value;
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.imageURL = this.value;
        }
      },
      immediate: true,
    },
  },
};
</script>
