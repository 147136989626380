<template>
  <d-modal-confirmation
    :title="modalTitle"
    :text="modalConfirmationText"
    :modal="modal"
    :submitButtonText="modalSubmitButtonText"
    @closeModal="handleCloseModal"
    @submitModal="handleSubmitModal"
    :isSubmitting="isSubmitting"
  >
  </d-modal-confirmation>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    collaborator: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    modalTitle() {
      return this.$t("accounts.owner.change-collaborator-status.title", {
        action: this.collaborator.isActive
          ? this.$t(
              `accounts.owner.change-collaborator-status.button.deactivate`
            )
          : this.$t(
              `accounts.owner.change-collaborator-status.button.activate`
            ),
      });
    },
    modalConfirmationText() {
      return this.$t("accounts.owner.change-collaborator-status.text", {
        action: this.collaborator.isActive
          ? this.$t(
              `accounts.owner.change-collaborator-status.button.deactivate`
            ).toLowerCase()
          : this.$t(
              `accounts.owner.change-collaborator-status.button.activate`
            ).toLowerCase(),
        fullName: this.collaborator.collaboratorUser,
      });
    },
    modalSubmitButtonText() {
      return this.collaborator.isActive
        ? this.$t(`accounts.owner.change-collaborator-status.button.deactivate`)
        : this.$t(`accounts.owner.change-collaborator-status.button.activate`);
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.collaborator.isActive
        ? this.deactivateClientCollaborator()
        : this.activateClientCollaborator();
    },
    activateClientCollaborator() {
      this.isSubmitting = true;
      window.API.activateClientCollaborator(this.item.id, this.collaborator.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t(
              "accounts.owner.change-collaborator-status.alert.activated",
              {
                fullName: this.collaborator.collaboratorUser,
              }
            ),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "accounts.owner.change-collaborator-status.alert.could-not-activate"
            ),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    deactivateClientCollaborator() {
      this.isSubmitting = true;
      window.API.deactivateClientCollaborator(
        this.item.id,
        this.collaborator.id
      )
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t(
              "accounts.owner.change-collaborator-status.alert.deactivated",
              {
                fullName: this.collaborator.collaboratorUser,
              }
            ),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "accounts.owner.change-collaborator-status.alert.could-not-deactivate"
            ),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
