<template>
  <d-loading v-if="loading.page"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="mb-4">
            <v-text-field
              class="d-required"
              :label="
                $t(
                  'onboarding.create-account.form.company-employer-identification-number'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.companyEmployerIdentificationNumber"
              :rules="rules.companyEmployerIdentificationNumber"
              v-mask="'##-#######'"
              placeholder="##-#######"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('onboarding.create-account.form.company-website')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.companyWebsite"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              class="d-required"
              :label="$t('onboarding.create-account.form.company-phone-number')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.companyPhoneNumber"
              :rules="rules.companyPhoneNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              class="d-required"
              :label="$t('onboarding.create-account.form.company-address')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.companyAddress"
              :rules="rules.companyAddress"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="mb-4">
              <h4 class="subtitle-1 font-weight-medium">
                {{
                  $t("onboarding.create-account.form.login-credentials.heading")
                }}
              </h4>
            </div>
            <v-row>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="
                    $t(
                      'onboarding.create-account.form.login-credentials.email-address'
                    )
                  "
                  v-model.trim="form.emailAddress"
                  hide-details="auto"
                  filled
                  type="text"
                  :rules="rules.emailAddress"
                  :error="errorMessages.emailAddress.length > 0"
                  :error-messages="errorMessages.emailAddress"
                  @blur="checkEmailAddressAvailability"
                  :loading="loading.emailAddress"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="
                    $t(
                      'onboarding.create-account.form.login-credentials.password'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="password"
                  v-model="form.password"
                  :rules="rules.password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="
                    $t(
                      'onboarding.create-account.form.login-credentials.confirm-password'
                    )
                  "
                  hide-details="auto"
                  filled
                  type="password"
                  v-model="form.confirmPassword"
                  :rules="rules.confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <v-btn
                type="submit"
                color="success"
                class="text-none body-2 font-weight-medium"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.submit") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const initialForm = {};
const initialErrorMessages = {
  emailAddress: [],
};

export default {
  data() {
    return {
      loading: {
        page: false,
        emailAddress: false,
      },
      form: { ...initialForm },
      rules: {
        companyEmployerIdentificationNumber: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /\b\d{2}-\d{7}\b/g.test(v) ||
            this.$t(
              "onboarding.create-account.rule.company-employer-identification-number.invalid"
            ),
        ],
        companyPhoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        companyAddress: [(v) => !!v || this.$t("defaults.rule.required")],
        emailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("onboarding.create-account.rule.email-address.invalid"),
        ],
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("onboarding.create-account.rule.password.invalid"),
        ],
        confirmPassword: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            v === this.form.password ||
            this.$t(
              "onboarding.create-account.rule.confirm-password.not-match"
            ),
        ],
      },
      isFormValid: true,
      errorMessages: { ...initialErrorMessages },
    };
  },
  props: {
    isSubmitting: Boolean,
    formData: Object,
  },
  methods: {
    async submitStep() {
      await this.checkEmailAddressAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        this.$emit("submitStep", this.form);
      }
    },
    previousStep() {
      this.$emit("previousStep");
    },
    setInitialForm() {
      this.form = {
        companyEmployerIdentificationNumber:
          this.formData.companyEmployerIdentificationNumber,
        companyWebsite: this.formData.companyWebsite,
        companyPhoneNumber: this.formData.contactPhoneNumber,
        companyAddress: this.formData.companyAddress,
        emailAddress: this.formData.contactEmailAddress,
        password: null,
        confirmPassword: null,
      };
    },
    async checkEmailAddressAvailability() {
      const emailAddress = this.form.emailAddress;

      if (!emailAddress) return;

      this.loading.emailAddress = true;
      await window.API.checkEmailAddressAvailability(emailAddress)
        .then((response) => {
          if (!response) {
            this.errorMessages.emailAddress = [
              this.$t("onboarding.create-account.rule.email-address.is-taken", {
                emailAddress: emailAddress,
              }),
            ];
            return;
          }
          this.errorMessages.emailAddress = [];
        })
        .finally(() => (this.loading.emailAddress = false));
    },
  },
  async created() {
    try {
      this.loading.page = true;
      await this.setInitialForm();
    } finally {
      this.loading.page = false;
    }
  },
};
</script>
