<template>
  <v-row>
    <v-col
      v-if="!authorizationUtils.isClient()"
      cols="12"
      sm="6"
      md="4"
      class="mb-3"
    >
      <v-autocomplete
        filled
        v-model="searchParams.clientId"
        :items="clients"
        item-text="name"
        item-value="id"
        hide-details="auto"
        clearable
        :loading="loading.clients"
        :search-input.sync="search.clients"
        :placeholder="$t('defaults.remote-dropdown-placeholder')"
        hide-no-data
        :label="$t('my-uploads.search.form.client')"
      >
        <template v-slot:item="data">
          <v-icon
            :color="data.item.isActive ? 'success' : 'error'"
            size="12"
          >
            mdi-circle
          </v-icon>
          <span class="ms-2">
            {{ data.item.name }}
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('my-uploads.search.form.document-name')"
        v-model.trim="searchParams.displayName"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <BaseTagsDropdown
        v-model="searchParams.tags"
        :items="tags"
        :label="$t('my-uploads.search.form.tags')"
        :loading="loading.others"
      ></BaseTagsDropdown>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.serviceId"
        :items="services"
        item-text="displayName"
        item-value="id"
        hide-details="auto"
        clearable
        :label="$t('my-uploads.search.form.service')"
      ></v-autocomplete>
    </v-col>
    <v-col
      v-if="authorizationUtils.isClient()"
      cols="12"
      sm="6"
      md="4"
      class="mb-3"
    >
      <v-select
        filled
        v-model="searchParams.status"
        :items="statuses"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        clearable
        :label="$t('my-uploads.search.form.status.title')"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="selectedDateText"
            :label="$t('my-uploads.search.form.upload-date')"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details="auto"
            filled
            clearable
            @click:clear="clearSelectedDate"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="selectedDateRange"
          range
          no-title
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.creatorUserId"
        :items="users"
        item-text="displayText"
        item-value="value"
        hide-details="auto"
        :loading="loading.others"
        clearable
        :label="$t('my-uploads.search.form.uploaded-by')"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import BaseTagsDropdown from "@/components/BaseTagsDropdown.vue";

export default {
  components: {
    BaseTagsDropdown
  },
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      authorizationUtils,
      search: {
        clients: null
      },
      loading: {
        clients: false,
        others: false
      },
      selectedDateText: null,
      selectedDateRange: null,
      clients: [],
      tags: [],
      users: [],
      statuses: [
        {
          value: 1,
          displayText: this.$t("my-uploads.search.form.status.data.open")
        },
        {
          value: 2,
          displayText: this.$t(
            "my-uploads.search.form.status.data.in-progress"
          )
        },
        {
          value: 3,
          displayText: this.$t("my-uploads.search.form.status.data.done")
        },
        {
          value: null,
          displayText: this.$t("my-uploads.search.form.status.data.all")
        }
      ]
    };
  },
  watch: {
    selectedDateRange: {
      handler(range) {
        if (range) {
          const sortedRange = range
            .map((element) => {
              var d = new Date(element);
              return `${
                d.getUTCMonth() + 1
              }-${d.getUTCDate()}-${d.getUTCFullYear()}`;
            })
            .sort(function(a, b) {
              return new Date(a).getTime() - new Date(b).getTime();
            });

          [
            this.searchParams.creationTimeFrom,
            this.searchParams.creationTimeTo
          ] = sortedRange;

          this.selectedDateText = sortedRange.join(" ~ ");
        }
      },
      immediate: true
    },
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
      deep: true
    },
    "searchParams.creationTimeFrom": {
      handler(val) {
        if (
          !val &&
          !this.setSearchParams.creationTimeTo &&
          this.selectedDateRange != null
        ) {
          this.selectedDateRange = null;
          this.selectedDateText = null;
        }
      },
      immediate: true,
      deep: true
    },
    "search.clients": {
      handler(val) {
        if (val?.length > 2) {
          this.fetchAllClients(val);
        } else {
          this.clients = [];
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
      myCurrentClient: "Auth/getMyCurrentClient"
    })
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams"
    }),
    fetchAllTags() {
      return window.API.fetchAllTags()
        .then((response) => {
          this.tags = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllNonClientUsers() {
      return window.API.fetchAllNonClientUsers()
        .then((response) => {
          this.users = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchClientAllMembers(clientId) {
      return window.API.fetchClientAllMembers(clientId)
        .then((response) => {
          this.users = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllClients(searchTerm) {
      this.loading.clients = true;

      window.API.fetchAllClients(searchTerm)
        .then((response) => {
          this.clients = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.clients = false));
    },
    clearSelectedDate() {
      this.selectedDateRange = null;
      this.searchParams.creationTimeFrom = null;
      this.searchParams.creationTimeTo = null;
    }
  },
  async mounted() {
    this.loading.others = true;

    try {
      await this.fetchAllTags();

      if (authorizationUtils.isClient()) {
        await this.fetchClientAllMembers(this.myCurrentClient.id);
      } else {
        await this.fetchAllNonClientUsers();
      }
    } finally {
      this.loading.others = false;
    }
  }
};
</script>
