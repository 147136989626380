<template>
  <d-modal
    :title="editMode ? $t('tags.tag.edit.title') : $t('tags.tag.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-text-field
              :label="$t('tags.tag.create.form.name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.name"
              :error="errorMessages.name.length > 0"
              :error-messages="errorMessages.name"
              @blur="checkNameAvailability"
              :loading="loading.name"
              :rules="rules.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-3">
            <v-text-field
              :label="$t('tags.tag.create.form.display-name')"
              hide-details="auto"
              filled
              type="text"
              v-model.trim="form.displayName"
              :error="errorMessages.displayName.length > 0"
              :error-messages="errorMessages.displayName"
              @blur="checkDisplayNameAvailability"
              :loading="loading.displayName"
              :rules="rules.displayName"
            ></v-text-field>
          </v-col>
          <template v-if="tagGroup.isMainTagGroup">
            <v-col cols="12" sm="12" md="12" class="mb-3">
              <v-autocomplete
                :items="services"
                v-model="form.servicesItDepends"
                item-text="displayName"
                item-value="id"
                :label="$t('tags.tag.create.form.services')"
                filled
                hide-details="auto"
                multiple
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mb-3">
              <v-autocomplete
                :items="tagGroups"
                v-model="form.tagGroupsItActivates"
                item-text="displayText"
                item-value="value"
                :label="$t('tags.tag.create.form.tag-groups')"
                filled
                hide-details="auto"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  tagGroupId: null,
  name: null,
  displayName: null,
  servicesItDepends: [],
  tagGroupsItActivates: [],
};
const initialTempValues = {
  name: null,
  displayName: null,
};
const initialErrorMessages = {
  name: [],
  displayName: [],
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    tagGroup: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      loading: {
        name: false,
        displayName: false,
        page: false,
      },
      errorMessages: { ...initialErrorMessages },
      tempValues: { ...initialTempValues },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        displayName: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      editMode: false,
      services: [],
      tagGroups: [],
    };
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.errorMessages = { ...initialErrorMessages };
      this.tempValues = { ...initialTempValues };
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    createItem() {
      this.isSubmitting = true;
      window.API.insertTag(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("tags.tag.create.alert.created", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("tags.tag.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    editItem() {
      this.isSubmitting = true;
      window.API.editTag(this.form.id, this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("tags.tag.edit.alert.updated", {
              displayName: this.form.displayName,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("tags.tag.edit.alert.could-not-update"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async handleSubmitModal() {
      await this.checkNameAvailability();
      await this.checkDisplayNameAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        if (this.form.id) {
          await this.editItem();
        } else {
          await this.createItem();
        }
      }
    },
    async checkNameAvailability() {
      const { name } = this.form;

      if (!name) return;

      if (this.tempValues.name == name && this.editMode) {
        this.errorMessages.name = [];
        return;
      }

      this.loading.name = true;
      await window.API.checkTagNameAvailability(name, this.form.tagGroupId)
        .then((response) => {
          if (!response) {
            this.errorMessages.name = [
              this.$t("tags.tag.create.rule.name-is-taken", {
                name: this.form.name,
              }),
            ];
            return;
          }
          this.errorMessages.name = [];
        })
        .finally(() => (this.loading.name = false));
    },
    async checkDisplayNameAvailability() {
      const { displayName } = this.form;

      if (!displayName) return;

      if (this.tempValues.displayName == displayName && this.editMode) {
        this.errorMessages.displayName = [];
        return;
      }

      this.loading.displayName = true;
      await window.API.checkTagDisplayNameAvailability(
        displayName,
        this.form.tagGroupId
      )
        .then((response) => {
          if (!response) {
            this.errorMessages.displayName = [
              this.$t("tags.tag.create.rule.name-is-taken", {
                name: this.form.displayName,
              }),
            ];
            return;
          }
          this.errorMessages.displayName = [];
        })
        .finally(() => (this.loading.displayName = false));
    },
    async getItem(item) {
      this.form.tagGroupId = this.tagGroup.id;
      if (item && item.id > 0) {
        this.loading.page = true;
        this.editMode = true;
        await window.API.getTagById(item.id)
          .then((response) => {
            this.form = response;
            this.form.servicesItDepends = response.servicesItDepends.map(
              (item) => item
            );
            this.form.tagGroupsItActivates = response.tagGroupsItActivates.map(
              (item) => item.toString()
            );
            this.tempValues.name = response.name;
            this.tempValues.displayName = response.displayName;
          })
          .catch(() => this.showFetchRequestErrorMessage())
          .finally(() => (this.loading.page = false));
      }
    },
    fetchAllServices() {
      window.API.fetchAllServices()
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllTagGroupsExceptSelected() {
      window.API.fetchAllTagGroups()
        .then((response) => {
          this.tagGroups = response.filter((tagGroup) => {
            return tagGroup.value != this.tagGroup.id;
          });
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async mounted() {
    await this.fetchAllServices();
    await this.fetchAllTagGroupsExceptSelected();
    await this.getItem(this.item);
  },
};
</script>
