<template>
  <d-page :title="$t('coupons.page-heading')" :hasSearch="false">
    <template slot="actions">
      <v-btn
        color="success"
        depressed
        large
        class="mb-1 mb-sm-0"
        @click="handleOpenAddModal()"
        v-granted="'coupon:add'"
      >
        <v-icon left> mdi-plus </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("coupons.button.create-item")
        }}</span>
      </v-btn>
    </template>
    <template slot="content">
      <List
        @addItem="handleOpenAddModal"
        @changeStatus="handleOpenChangeStatusModal"
      ></List>
      <Add
        v-if="openAddModal"
        :modal="openAddModal"
        @closeModal="handleCloseAddModal"
        @submitModal="handleSubmitAddModal"
      ></Add>
      <ChangeStatus
        v-if="selectedItem && openChangeStatusModal"
        :modal="openChangeStatusModal"
        :item="selectedItem"
        @closeModal="handleCloseChangeStatusModal"
        @submitModal="handleSubmitChangeStatusModal"
      ></ChangeStatus>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import Add from "./Add.vue";
import ChangeStatus from "./ChangeStatus.vue";

export default {
  components: { List, Add, ChangeStatus },
  data() {
    return {
      openAddModal: false,
      openChangeStatusModal: false,
      selectedItem: null,
    };
  },
  methods: {
    handleOpenAddModal() {
      this.openAddModal = true;
    },
    handleCloseAddModal() {
      this.openAddModal = false;
    },
    handleSubmitAddModal() {
      this.$eventBus.$emit("fetchCoupons");
    },
    handleOpenChangeStatusModal(item = null) {
      this.selectedItem = item;
      this.openChangeStatusModal = true;
    },
    handleCloseChangeStatusModal() {
      this.selectedItem = null;
      this.openChangeStatusModal = false;
    },
    async handleSubmitChangeStatusModal() {
      this.$eventBus.$emit("fetchCoupons");
    },
  },
};
</script>
