<template>
  <v-row>
    <v-col cols="12" md="6" lg="5">
      <d-loading v-if="loading.data"></d-loading>
      <template v-else-if="!loading.data && client">
        <div class="mb-4">
          <v-row align="center">
            <v-col cols="12" md="6" lg="7">
              <div class="d-flex align-center">
                <v-avatar color="primary" size="24" class="mr-2">
                  <v-icon small dark> mdi-briefcase </v-icon>
                </v-avatar>

                <span class="body-1 font-weight-medium text-none">
                  {{ client.name }}
                </span>

                <v-tooltip
                  top
                  color="gray darken-2"
                  v-if="!authorizationUtils.isClient()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-1"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="$emit('changeAccount')"
                    >
                      <v-icon size="20"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("reconciliations.list.actions.change-client")
                  }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="5">
              <div
                v-if="
                  (checkingAccounts.length || creditCardAccounts.length) &&
                  client.currentlyUsingBookkeepingService
                "
                class="d-flex align-center justify-end"
                v-granted="'reconciliation:sync'"
              >
                <span class="body-2 text--secondary d-inline-block">{{
                  formattedPlaidLastSyncTime
                }}</span>
                <v-tooltip top color="gray darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-1"
                      color="gray darken-2"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="syncNow"
                    >
                      <v-icon size="20"> mdi-sync </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("reconciliations.list.actions.sync") }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <div class="border-bottom pb-2">
              <div class="d-flex align-items-center justify-space-between">
                <h3 class="subtitle-1 font-weight-medium">
                  {{ $t("reconciliations.list.checking-accounts") }}
                </h3>

                <AddInstitutionAccounts
                  v-if="client.currentlyUsingBookkeepingService"
                  v-granted="'reconciliation:add-account'"
                  @onSuccess="onSuccess"
                  @isLoading="isLoading"
                  :subtype="PlaidAccountSubtypes.CHECKING"
                  :clientId="client.id"
                  :isDisabled="loading.add"
                >
                </AddInstitutionAccounts>
              </div>

              <div class="mt-1">
                <template v-for="item in checkingAccounts">
                  <InstitutionAccountItem
                    :item="item"
                    :key="item.id"
                    :clientId="client.id"
                  ></InstitutionAccountItem>
                </template>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="border-bottom py-2">
              <div class="d-flex align-items-center justify-space-between">
                <h3 class="subtitle-1 font-weight-medium">
                  {{ $t("reconciliations.list.credit-cards") }}
                </h3>
                <AddInstitutionAccounts
                  v-if="client.currentlyUsingBookkeepingService"
                  v-granted="'reconciliation:add-account'"
                  @onSuccess="onSuccess"
                  @isLoading="isLoading"
                  :subtype="PlaidAccountSubtypes.CREDIT_CARD"
                  :clientId="client.id"
                  :isDisabled="loading.add"
                >
                </AddInstitutionAccounts>
              </div>

              <div class="mt-1">
                <template v-for="item in creditCardAccounts">
                  <InstitutionAccountItem
                    :item="item"
                    :key="item.id"
                    :clientId="client.id"
                  ></InstitutionAccountItem>
                </template>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="py-2">
              <v-row align="start">
                <v-col cols="5">
                  <h3 class="subtitle-1 font-weight-medium">
                    {{ $t("reconciliations.list.net-cash") }}
                  </h3>
                </v-col>
                <v-col cols="7">
                  <p
                    class="px-2 body-2 text--primary text-right mb-1 font-weight-medium"
                    v-for="cash in netCash"
                    :key="cash.isoCurrencyCode"
                  >
                    {{
                      `${formatCurrency(
                        cash.value,
                        cash.isoCurrencyCode.toUpperCase()
                      )}`
                    }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import authorizationUtils from "@/utils/authorization.js";
import PlaidAccountSubtypes from "@/shared/enums/PlaidAccountSubtypes.js";
import InstitutionAccountItem from "./InstitutionAccountItem.vue";
import isoCountryCurrency from "@/utils/isoCountryCurrency";
import AddInstitutionAccounts from "./AddInstitutionAccounts.vue";

export default {
  props: {
    clientId: {
      type: [Number, String],
    },
  },
  components: { InstitutionAccountItem, AddInstitutionAccounts },
  data() {
    return {
      authorizationUtils,
      client: null,
      checkingAccounts: [],
      creditCardAccounts: [],
      netCash: [],
      loading: {
        data: false,
        add: false,
      },
      PlaidAccountSubtypes,
    };
  },
  computed: {
    formattedPlaidLastSyncTime() {
      let _diff = this.$moment().diff(this.client.plaidLastSyncTime, "hours");

      if (_diff < 24) {
        return this.$moment
          .utc(this.client.plaidLastSyncTime)
          .local()
          .fromNow();
      }

      return this.UTCToLocalTime(this.client.plaidLastSyncTime, "MM.DD.YYYY");
    },
  },
  watch: {
    clientId: {
      async handler() {
        await this.getClientPlaidInstitutionAccounts();
      },
      deep: true,
    },
  },
  methods: {
    async getClientPlaidInstitutionAccounts() {
      try {
        this.loading.data = true;
        const response = await window.API.getClientPlaidInstitutionAccounts(
          this.clientId
        );

        this.client = response.client;

        this.checkingAccounts = response.institutionAccounts.filter(
          (item) => item.subtype === PlaidAccountSubtypes.CHECKING
        );
        this.creditCardAccounts = response.institutionAccounts.filter(
          (item) => item.subtype === PlaidAccountSubtypes.CREDIT_CARD
        );
        this.netCash = response.netCash;
      } catch {
        this.$router.push({
          name: "Reconciliations",
        });
      } finally {
        this.loading.data = false;
      }
    },
    formatCurrency(amount, isoCurrencyCode) {
      return isoCountryCurrency.formatCurrency(amount, isoCurrencyCode);
    },
    async createInstitutionAccountsByPublicToken(public_token) {
      try {
        await window.API.createInstitutionAccountsByPublicToken({
          clientId: this.client.id,
          publicToken: public_token,
        });
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("reconciliations.list.alert.could-not-add"),
        });
      }
    },
    async onSuccess(public_token) {
      try {
        this.loading.data = true;
        await this.createInstitutionAccountsByPublicToken(public_token);
        await this.getClientPlaidInstitutionAccounts();
      } finally {
        this.loading.data = false;
      }
    },
    isLoading(val) {
      this.loading.add = val;
    },
    async syncNow() {
      try {
        await window.API.synchronizeClientPlaidInstitutionAccounts(
          this.client.id
        );

        this.$eventBus.$emit("notification", {
          type: "success",
          message: this.$t("reconciliations.list.alert.synchronized"),
        });
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("reconciliations.list.alert.could-not-synchronize"),
        });
      }
    },
  },
  async mounted() {
    await this.getClientPlaidInstitutionAccounts();
  },
};
</script>
