<template>
  <d-modal
    :title="$t('received-docs.change-status.title')"
    :modal="modal"
    @closeModal="handleCloseModal"
    @submitModal="handleSubmitModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitModal">
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-radio-group
              row
              filled
              v-model="form.status"
              hide-details="auto"
              class="mt-0 ml-2"
              :rules="rules.status"
            >
              <v-radio
                :label="$t('received-docs.list.table.status.open')"
                :value="DocumentStatuses.OPEN"
              ></v-radio>
              <v-radio
                :label="$t('received-docs.list.table.status.in-progress')"
                :value="DocumentStatuses.IN_PROGRESS"
              ></v-radio>
              <v-radio
                :label="$t('received-docs.list.table.status.done')"
                :value="DocumentStatuses.DONE"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('received-docs.change-status.form.comment.label')"
              v-model.trim="form.commentText"
              hide-details="auto"
              filled
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>
<script>
import DocumentStatuses from "@/shared/enums/DocumentStatuses";

const initialForm = {
  documentIds: null,
  status: null,
  commentText: null,
};
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      isSubmitting: false,
      DocumentStatuses,
      rules: {
        status: [(v) => !!v || this.$t("defaults.rule.required")],
      },
    };
  },
  methods: {
    async handleSubmitModal() {
      if (!this.$refs.form.validate()) return;
      this.isSubmitting = true;

      this.form.documentIds = this.selectedItems;
      await window.API.changeDocumentStatus(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("received-docs.change-status.alert.updated"),
          });
          this.handleCloseModal();
          this.$eventBus.$emit("fetchReceivedDocs");
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "received-docs.change-status.alert.could-not-update"
            ),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
  },
};
</script>
