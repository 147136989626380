<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row>
      <v-col cols="12" class="mb-4">
        <div class="py-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{
              $t(
                "company-formation.resident.step-2.form.registered-agent.registered-agent-type"
              )
            }}
          </h4>
        </div>

        <div>
          <v-radio-group
            row
            filled
            hide-details="auto"
            class="mt-0"
            v-model="form.registeredAgent.registeredAgentType"
            :rules="rules.registeredAgentType"
          >
            <v-radio
              v-for="registeredAgentType in registeredAgentTypes"
              :key="registeredAgentType.value"
              :label="registeredAgentType.displayText"
              :value="registeredAgentType.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-col>

      <v-col cols="12">
        <v-row>
          <template
            v-if="
              form.registeredAgent.registeredAgentType ==
              RegisteredAgentTypes.INDIVIDUAL
            "
          >
            <v-col md="4" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.registered-agent.first-name'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="form.registeredAgent.firstName"
                :rules="rules.registeredAgentFirstName"
              ></v-text-field>
            </v-col>

            <v-col md="4" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.registered-agent.last-name'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="form.registeredAgent.lastName"
                :rules="rules.registeredAgentLastName"
              ></v-text-field>
            </v-col>

            <v-col md="4" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.registered-agent.middle-name'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="form.registeredAgent.middleName"
              ></v-text-field>
            </v-col>
          </template>
          <template
            v-if="
              form.registeredAgent.registeredAgentType ==
              RegisteredAgentTypes.COMPANY
            "
          >
            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.registered-agent.company-name'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="form.registeredAgent.companyName"
                :rules="rules.registeredAgentCompanyName"
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-col>

      <v-col md="6" class="mb-4">
        <v-text-field
          :label="
            $t(
              'company-formation.resident.step-2.form.registered-agent.address-line-1'
            )
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.addressLine1"
          :rules="rules.registeredAgentAddressLine1"
        ></v-text-field>
      </v-col>

      <v-col md="6" class="mb-4">
        <v-text-field
          :label="
            $t(
              'company-formation.resident.step-2.form.registered-agent.address-line-2'
            )
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.addressLine2"
        ></v-text-field>
      </v-col>

      <v-col md="4" class="mb-4">
        <v-text-field
          :label="
            $t('company-formation.resident.step-2.form.registered-agent.city')
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.city"
          :rules="rules.registeredAgentCity"
        ></v-text-field>
      </v-col>
      <v-col md="4" class="mb-4">
        <v-autocomplete
          filled
          :items="filteredCounties"
          item-text="name"
          item-value="id"
          hide-details="auto"
          clearable
          :label="
            $t('company-formation.resident.step-2.form.registered-agent.county')
          "
          v-model="form.registeredAgent.countyId"
          :rules="rules.registeredAgentCounty"
        ></v-autocomplete>
      </v-col>
      <v-col md="4" class="mb-4">
        <v-text-field
          :label="
            $t(
              'company-formation.resident.step-2.form.registered-agent.zip-code'
            )
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.zipCode"
          :rules="rules.registeredAgentZipCode"
          v-mask="'#####'"
        ></v-text-field>
      </v-col>

      <v-col md="6" class="mb-4">
        <v-text-field
          :label="
            $t(
              'company-formation.resident.step-2.form.registered-agent.email-address'
            )
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.emailAddress"
          :rules="rules.registeredAgentEmailAddress"
        ></v-text-field>
      </v-col>

      <v-col md="6" class="mb-4">
        <v-text-field
          :label="
            $t(
              'company-formation.resident.step-2.form.registered-agent.phone-number'
            )
          "
          hide-details="auto"
          filled
          type="text"
          v-model="form.registeredAgent.phoneNumber"
          :rules="rules.registeredAgentPhoneNumber"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="mb-4">
        <div class="py-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{
              $t(
                "company-formation.resident.step-2.form.business-address.section-heading"
              )
            }}
          </h4>
        </div>

        <div class="mb-4">
          <v-radio-group
            row
            filled
            hide-details="auto"
            class="mt-0"
            v-model="form.businessAddressType"
          >
            <v-radio
              :label="
                $t(
                  'company-formation.resident.step-2.business-address-types.predefined-address'
                )
              "
              :value="BusinessAddressTypes.PREDEFINED_ADDRESS"
            ></v-radio>
            <v-radio
              :label="
                $t(
                  'company-formation.resident.step-2.business-address-types.new-address'
                )
              "
              :value="BusinessAddressTypes.NEW_ADDRESS"
            ></v-radio>
          </v-radio-group>
        </div>

        <template
          v-if="form.businessAddressType == BusinessAddressTypes.NEW_ADDRESS"
        >
          <v-row>
            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.address-line-1'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.addressLine1"
                :rules="rules.businessAddressAddressLine1"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.address-line-2'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.addressLine2"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.city'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.city"
                :rules="rules.businessAddressCity"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.zip-code'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.zipCode"
                :rules="rules.businessAddressZipCode"
                v-mask="'#####'"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.email-address'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.emailAddress"
                :rules="rules.businessAddressEmailAddress"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.resident.step-2.form.business-address.phone-number'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-end">
          <v-btn
            @click="cancelStep"
            type="button"
            class="text-none body-2 font-weight-medium mr-2"
            depressed
          >
            {{ $t("company-formation.resident.step-2.actions.cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
          >
            {{ $t("company-formation.resident.step-2.actions.submit") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BusinessAddressTypes from "@/shared/enums/BusinessAddressTypes";
import RegisteredAgentTypes from "@/shared/enums/RegisteredAgentTypes";

const initialForm = {
  registeredAgent: {
    registeredAgentType: RegisteredAgentTypes.INDIVIDUAL,
    companyName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    zipCode: null,
    emailAddress: null,
    phoneNumber: null,
    countyId: null,
  },
  businessAddress: null,
  businessAddressType: BusinessAddressTypes.PREDEFINED_ADDRESS,
};

const initialBusinessAddress = {
  addressLine1: null,
  addressLine2: null,
  city: null,
  zipCode: null,
  emailAddress: null,
  phoneNumber: null,
};

export default {
  props: {
    selectedStateId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      BusinessAddressTypes,
      RegisteredAgentTypes,
      registeredAgentTypes: [],
      counties: [],
      loading: false,
      form: { ...initialForm },
      businessAddress: { ...initialBusinessAddress },
      isFormValid: true,
      rules: {
        registeredAgentType: [(v) => !!v || this.$t("defaults.rule.required")],
        registeredAgentCompanyName: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        registeredAgentFirstName: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        registeredAgentLastName: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        registeredAgentAddressLine1: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        registeredAgentCity: [(v) => !!v || this.$t("defaults.rule.required")],
        registeredAgentZipCode: [
          (v) => {
            if (!v) return this.$t("defaults.rule.required");

            if (v && /\b\d{5}\b/g.test(v)) return true;
            return this.$t(
              "company-formation.resident.step-2.rule.zip-code.invalid"
            );
          },
        ],
        registeredAgentEmailAddress: [
          (v) => {
            if (!v) return true;

            if (v && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v)) return true;

            return this.$t(
              "company-formation.resident.step-2.rule.email-address.invalid"
            );
          },
        ],
        registeredAgentPhoneNumber: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        registeredAgentCounty: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        businessAddressAddressLine1: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        businessAddressCity: [(v) => !!v || this.$t("defaults.rule.required")],
        businessAddressZipCode: [
          (v) => {
            if (!v) return this.$t("defaults.rule.required");

            if (v && /\b\d{5}\b/g.test(v)) return true;
            return this.$t(
              "company-formation.resident.step-2.rule.zip-code.invalid"
            );
          },
        ],
        businessAddressEmailAddress: [
          (v) => {
            if (!v) return true;

            if (v && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v)) return true;

            return this.$t(
              "company-formation.resident.step-2.rule.email-address.invalid"
            );
          },
        ],
      },
    };
  },
  computed: {
    filteredCounties() {
      return this.counties.filter(
        (county) => county.stateId == this.selectedStateId
      );
    },
  },
  watch: {
    businessAddress: {
      handler() {
        this.form.businessAddress = { ...this.businessAddress };
      },
      deep: true,
    },
    form: {
      handler() {
        this.$emit("formUpdated", this.form);
      },
      deep: true,
    },
    "form.businessAddressType": {
      handler() {
        if (
          this.form.businessAddressType ==
          BusinessAddressTypes.PREDEFINED_ADDRESS
        ) {
          this.form.businessAddress = null;
        }

        if (this.form.businessAddressType == BusinessAddressTypes.NEW_ADDRESS) {
          this.form.businessAddress = this.businessAddress;
        }
      },
      immediate: true,
    },
    selectedStateId: {
      handler() {
        this.form.registeredAgent.countyId = null;
      },
    },
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitStep");
      }
    },
    cancelStep() {
      this.$emit("cancelStep");
    },
    async fetchAllCompanyRegisteredAgentTypes() {
      this.registeredAgentTypes =
        await window.API.fetchAllCompanyRegisteredAgentTypes();
    },
    async fetchAllCompanyCounties() {
      this.counties = await window.API.fetchAllCompanyCounties();
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchAllCompanyRegisteredAgentTypes();
      await this.fetchAllCompanyCounties();
    } finally {
      this.loading = false;
    }
  },
};
</script>
