<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("leads.details.create-account.title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{
                $t(
                  "leads.details.create-account.company-employer-identification-number"
                )
              }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyEmployerIdentificationNumber) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.create-account.company-website") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyWebsite) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.create-account.company-phone-number") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyPhoneNumber) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.create-account.company-address") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.companyAddress) }}
            </span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("leads.details.create-account.email-address") }}:
            </span>
            <span class="body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(item.linkedUser?.emailAddress) }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
