<template>
  <v-img v-if="imgURL" :src="imgURL"> </v-img>
</template>

<script>
export default {
  data() {
    return {
      imgURL: null,
    };
  },
  props: {
    url: { type: String, required: true },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    id: { type: Number, default: 0 },
  },
  mounted() {
    this.getImage();
  },
  methods: {
    getImage() {
      window
        .axios({
          method: "get",
          url: this.height
            ? `${this.url}?height=${this.height * 2}`
            : `${this.url}?width=${this.width * 2}`,
          responseType: "arraybuffer",
        })
        .then((resp) => {
          let mimeType = resp.headers["content-type"].toLowerCase();
          let imgBase64 = new Buffer(resp.data, "binary").toString("base64");
          this.imgURL = "data:" + mimeType + ";base64," + imgBase64;
        })
        .catch(() => {
          if (this.id > 0) {
            this.$emit("error", this.id);
          } else {
            this.$emit("error", true);
          }
        });
    },
  },
};
</script>
