<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="mb-4">
            <v-autocomplete
              class="d-required"
              :label="$t('onboarding.intake-questions.form.industry')"
              filled
              :items="industries"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              v-model="industryId"
              :rules="rules.industry"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="mb-4"
            v-if="industryId == otherIndustry.value"
          >
            <v-text-field
              class="d-required"
              :label="$t('onboarding.intake-questions.form.industry-name')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.otherIndustryName"
              :rules="rules.otherIndustryName"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="mb-4">
            <v-autocomplete
              class="d-required"
              :label="$t('onboarding.intake-questions.form.annual-sales-range')"
              filled
              :items="annualSalesRanges"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              v-model="form.annualSalesRangeId"
              :rules="rules.annualSalesRange"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="mb-4">
            <v-autocomplete
              class="d-required"
              :label="
                $t('onboarding.intake-questions.form.employee-count-range')
              "
              filled
              :items="employeeCountRanges"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              v-model="form.employeeCountRangeId"
              :rules="rules.employeeCountRange"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="mb-4">
            <v-autocomplete
              class="d-required"
              :label="$t('onboarding.intake-questions.form.services')"
              filled
              :items="services"
              item-text="displayName"
              item-value="id"
              hide-details="auto"
              clearable
              v-model="selectedServices"
              :rules="rules.services"
              multiple
              return-object
            >
              <template v-slot:selection="{ item }">
                <v-chip small>
                  <span>{{ item.displayName }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

          <template v-if="bookkeepingService">
            <v-col cols="12">
              <div class="border-all px-3 pt-3 pb-1 rounded mb-4">
                <h4 class="subtitle-2 mt-n6 mb-1">
                  <span class="white px-1">{{
                    bookkeepingService.displayName
                  }}</span>
                </h4>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <p class="font-weight-medium mb-0 d-required--label">
                      {{
                        $t(
                          "onboarding.intake-questions.form.currently-doing-bookkeeping-service.label"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      v-model="currentlyDoingBookkeeping"
                      hide-details="auto"
                      class="mt-0"
                      :rules="rules.currentlyDoingBookkeeping"
                    >
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.currently-doing-bookkeeping-service.yes'
                          )
                        "
                        :value="yesNoOptions.yes"
                      ></v-radio>
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.currently-doing-bookkeeping-service.no'
                          )
                        "
                        :value="yesNoOptions.no"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="12"
                    class="mb-4"
                    v-if="currentlyDoingBookkeeping === yesNoOptions.yes"
                  >
                    <v-autocomplete
                      class="d-required"
                      :label="
                        $t(
                          'onboarding.intake-questions.form.accounting-software-tool'
                        )
                      "
                      filled
                      :items="accountingSoftwareTools"
                      item-text="displayText"
                      item-value="value"
                      hide-details="auto"
                      clearable
                      v-model="form.accountingSoftwareToolId"
                      :rules="rules.accountingSoftwareTool"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" class="mb-4">
                    <v-autocomplete
                      class="d-required"
                      :label="
                        $t(
                          'onboarding.intake-questions.form.year-to-get-bookkeeping-service'
                        )
                      "
                      filled
                      :items="yearListToGetBookkeepingService"
                      hide-details="auto"
                      clearable
                      v-model="form.yearToGetBookkeepingService"
                      :rules="rules.yearToGetBookkeepingService"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </template>

          <template v-if="payrollService">
            <v-col cols="12">
              <div class="border-all px-3 pt-3 pb-1 rounded mb-4">
                <h4 class="subtitle-2 mt-n6 mb-1">
                  <span class="white px-1">{{
                    payrollService.displayName
                  }}</span>
                </h4>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <p class="font-weight-medium mb-0 d-required--label">
                      {{
                        $t(
                          "onboarding.intake-questions.form.currently-getting-payroll-service.label"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      v-model="currentlyGettingPayrollService"
                      hide-details="auto"
                      class="mt-0"
                      :rules="rules.currentlyGettingPayrollService"
                    >
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.currently-getting-payroll-service.yes'
                          )
                        "
                        :value="yesNoOptions.yes"
                      ></v-radio>
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.currently-getting-payroll-service.no'
                          )
                        "
                        :value="yesNoOptions.no"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </template>

          <template v-if="taxService">
            <v-col cols="12">
              <div class="border-all px-3 pt-3 pb-1 rounded mb-4">
                <h4 class="subtitle-2 mt-n6 mb-1">
                  <span class="white px-1">{{ taxService.displayName }}</span>
                </h4>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <p class="font-weight-medium mb-0 d-required--label">
                      {{
                        $t(
                          "onboarding.intake-questions.form.ready-financial-report.label"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      v-model="hasAnyReadyFinancialReport"
                      hide-details="auto"
                      class="mt-0"
                      :rules="rules.hasAnyReadyFinancialReport"
                    >
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.ready-financial-report.yes'
                          )
                        "
                        :value="yesNoOptions.yes"
                      ></v-radio>
                      <v-radio
                        :label="
                          $t(
                            'onboarding.intake-questions.form.ready-financial-report.no'
                          )
                        "
                        :value="yesNoOptions.no"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="mb-4">
                    <v-autocomplete
                      class="d-required"
                      filled
                      :items="yearListToGetTaxService"
                      hide-details="auto"
                      clearable
                      :label="
                        $t(
                          'onboarding.intake-questions.form.year-to-get-tax-service'
                        )
                      "
                      v-model="form.yearToGetTaxService"
                      :rules="rules.yearToGetTaxService"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </template>

          <v-col cols="12" class="mb-4">
            <v-autocomplete
              class="d-required"
              :label="
                $t('onboarding.intake-questions.form.acquisition-channel')
              "
              filled
              :items="acquisitionChannels"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              v-model="form.acquisitionChannelId"
              :rules="rules.acquisitionChannel"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <v-btn
                @click="previousStep"
                type="button"
                class="text-none body-2 font-weight-medium mr-2"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.previous") }}
              </v-btn>
              <v-btn
                type="submit"
                color="success"
                class="text-none body-2 font-weight-medium"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.next") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ServiceNames from "@/shared/enums/ServiceNames";

const initialForm = {
  industryId: null,
  otherIndustryName: null,
  annualSalesRangeId: null,
  employeeCountRangeId: null,
  accountingSoftwareToolId: null,
  acquisitionChannelId: null,
  hasAnyReadyFinancialReport: null,
  yearToGetTaxService: null,
  yearToGetBookkeepingService: null,
  currentlyDoingBookkeeping: null,
  currentlyGettingPayrollService: null,
  services: [],
};

export default {
  data() {
    return {
      loading: false,
      form: { ...initialForm },
      isFormValid: true,
      rules: {
        industry: [(v) => !!v || this.$t("defaults.rule.required")],
        otherIndustryName: [(v) => !!v || this.$t("defaults.rule.required")],
        annualSalesRange: [(v) => !!v || this.$t("defaults.rule.required")],
        employeeCountRange: [(v) => !!v || this.$t("defaults.rule.required")],
        services: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => (!!v && v.length >= 1) || this.$t("defaults.rule.required"),
        ],
        hasAnyReadyFinancialReport: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        yearToGetTaxService: [(v) => !!v || this.$t("defaults.rule.required")],
        currentlyDoingBookkeeping: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        yearToGetBookkeepingService: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        accountingSoftwareTool: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        currentlyGettingPayrollService: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
      },
      industries: [],
      industryId: null,
      annualSalesRanges: [],
      employeeCountRanges: [],
      accountingSoftwareTools: [],
      acquisitionChannels: [],
      otherIndustry: {
        value: "other",
        displayText: "Other",
      },
      services: [],
      selectedServices: null,
      ServiceNames,
      hasAnyReadyFinancialReport: null,
      currentlyDoingBookkeeping: null,
      currentlyGettingPayrollService: null,
      yesNoOptions: {
        yes: "yes",
        no: "no",
      },
    };
  },
  props: {
    formData: {
      type: Object,
    },
    isSubmitting: Boolean,
  },
  computed: {
    taxService() {
      return this.selectedServices?.find(
        (service) => service.name == ServiceNames.TAX
      );
    },
    payrollService() {
      return this.selectedServices?.find(
        (service) => service.name == ServiceNames.PAYROLL
      );
    },
    bookkeepingService() {
      return this.selectedServices?.find(
        (service) => service.name == ServiceNames.BOOKKEEPING
      );
    },
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        if (this.industryId == this.otherIndustry.value) {
          this.form.industryId = null;
        } else {
          this.form.industryId = this.industryId;
          this.form.otherIndustryName = null;
        }

        this.form.services = this.selectedServices.map((service) => service.id);

        if (this.hasAnyReadyFinancialReport === this.yesNoOptions.yes) {
          this.form.hasAnyReadyFinancialReport = true;
        } else if (this.hasAnyReadyFinancialReport === this.yesNoOptions.no) {
          this.form.hasAnyReadyFinancialReport = false;
        }

        if (this.currentlyDoingBookkeeping === this.yesNoOptions.yes) {
          this.form.currentlyDoingBookkeeping = true;
        } else if (this.currentlyDoingBookkeeping === this.yesNoOptions.no) {
          this.form.currentlyDoingBookkeeping = false;
          this.form.accountingSoftwareToolId = null;
        }

        if (this.currentlyGettingPayrollService === this.yesNoOptions.yes) {
          this.form.currentlyGettingPayrollService = true;
        } else if (
          this.currentlyGettingPayrollService === this.yesNoOptions.no
        ) {
          this.form.currentlyGettingPayrollService = false;
        }

        if (!this.taxService) {
          this.form.hasAnyReadyFinancialReport = null;
          this.form.yearToGetTaxService = null;
        }

        if (!this.payrollService) {
          this.form.currentlyGettingPayrollService = null;
        }

        if (!this.bookkeepingService) {
          this.form.currentlyDoingBookkeeping = null;
          this.form.yearToGetBookkeepingService = null;
          this.form.accountingSoftwareToolId = null;
        }

        this.$emit("submitStep", this.form);
      }
    },
    previousStep() {
      this.$emit("previousStep");
    },
    async getAllLeadIndustries() {
      this.industries = await window.API.getAllLeadIndustries();
      this.industries.push({ ...this.otherIndustry });
    },
    async getAllAnnualSalesRanges() {
      this.annualSalesRanges = await window.API.getAllAnnualSalesRanges();
    },
    async getAllEmployeeCountRanges() {
      this.employeeCountRanges = await window.API.getAllEmployeeCountRanges();
    },
    async getAllAccountingSoftwareTools() {
      this.accountingSoftwareTools =
        await window.API.getAllAccountingSoftwareTools();
    },
    async getAllAcquisitionChannels() {
      this.acquisitionChannels = await window.API.getAllAcquisitionChannels();
    },
    async getAllClientOnboardingProcessRelatedServices() {
      this.services =
        await window.API.getAllClientOnboardingProcessRelatedServices();
    },
    getYearList(whatYearAgo) {
      let currentYear = this.$moment().format("YYYY");
      let yearAgo = Number(currentYear) - whatYearAgo;
      let yearList = [];

      for (let year = yearAgo; year <= currentYear; year++) {
        yearList.push(year);
      }

      return yearList;
    },
    setInitialForm() {
      this.form = {
        industryId: this.formData.industryId?.toString(),
        otherIndustryName: this.formData.otherIndustryName,
        annualSalesRangeId: this.formData.annualSalesRangeId?.toString(),
        employeeCountRangeId: this.formData.employeeCountRangeId?.toString(),
        accountingSoftwareToolId:
          this.formData.accountingSoftwareToolId?.toString(),
        acquisitionChannelId: this.formData.acquisitionChannelId?.toString(),
        hasAnyReadyFinancialReport: this.formData.hasAnyReadyFinancialReport,
        yearToGetTaxService: this.formData.yearToGetTaxService,
        yearToGetBookkeepingService: this.formData.yearToGetBookkeepingService,
        currentlyDoingBookkeeping: this.formData.currentlyDoingBookkeeping,
        currentlyGettingPayrollService:
          this.formData.currentlyGettingPayrollService,
        services: this.formData.services,
      };

      this.industryId =
        !this.formData.industryId && this.formData.otherIndustryName
          ? this.otherIndustry.value
          : this.formData.industryId?.toString();

      this.selectedServices = this.services.filter((service) =>
        this.formData.services.some(
          (selectedService) => selectedService == service.id
        )
      );

      if (this.form.hasAnyReadyFinancialReport === true) {
        this.hasAnyReadyFinancialReport = this.yesNoOptions.yes;
      } else if (this.form.hasAnyReadyFinancialReport === false) {
        this.hasAnyReadyFinancialReport = this.yesNoOptions.no;
      }

      if (this.form.currentlyDoingBookkeeping === true) {
        this.currentlyDoingBookkeeping = this.yesNoOptions.yes;
      } else if (this.form.currentlyDoingBookkeeping === false) {
        this.currentlyDoingBookkeeping = this.yesNoOptions.no;
      }

      if (this.form.currentlyGettingPayrollService === true) {
        this.currentlyGettingPayrollService = this.yesNoOptions.yes;
      } else if (this.form.currentlyGettingPayrollService === false) {
        this.currentlyGettingPayrollService = this.yesNoOptions.no;
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getAllLeadIndustries();
      await this.getAllAnnualSalesRanges();
      await this.getAllEmployeeCountRanges();
      await this.getAllClientOnboardingProcessRelatedServices();
      await this.getAllAcquisitionChannels();
      await this.getAllAccountingSoftwareTools();
      this.yearListToGetTaxService = this.getYearList(6);
      this.yearListToGetBookkeepingService = this.getYearList(6);
      await this.setInitialForm();
    } finally {
      this.loading = false;
    }
  },
};
</script>
