<template>
  <d-page :title="$t('announcements.page-heading')" :hasSearch="false">
    <template slot="actions">
      <v-btn
        color="success"
        depressed
        large
        @click="handleOpenAddModal()"
        v-granted="'announcement:add'"
        class="mb-1 mb-sm-0"
      >
        <v-icon left> mdi-plus </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("announcements.button.create-item")
        }}</span>
      </v-btn>
    </template>
    <template slot="content">
      <List
        @addItem="handleOpenAddModal"
        @viewItem="handleOpenDetailsModal"
        @changeStatus="handleOpenChangeStatusModal"
      ></List>
      <Add
        v-if="openAddModal"
        :modal="openAddModal"
        @closeModal="handleCloseAddModal"
        @submitModal="handleSubmitAddModal"
      ></Add>
      <Details
        v-if="selectedItem && openDetailsModal"
        :modal="openDetailsModal"
        :item="selectedItem"
        @submitModal="handleCloseDetailsModal"
      ></Details>
      <ChangeStatus
        v-if="selectedItem && openChangeStatusModal"
        :modal="openChangeStatusModal"
        :item="selectedItem"
        @closeModal="handleCloseChangeStatusModal"
        @submitModal="handleSubmitChangeStatusModal"
      ></ChangeStatus>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import Add from "./Add.vue";
import Details from "./Details.vue";
import ChangeStatus from "./ChangeStatus.vue";

export default {
  components: { List, Add, Details, ChangeStatus },
  data() {
    return {
      openAddModal: false,
      openDetailsModal: false,
      openChangeStatusModal: false,
      selectedItem: null,
    };
  },
  methods: {
    handleOpenAddModal() {
      this.openAddModal = true;
    },
    handleCloseAddModal() {
      this.openAddModal = false;
    },
    handleSubmitAddModal() {
      this.$eventBus.$emit("fetchAnnouncements");
    },
    handleOpenDetailsModal(item = null) {
      this.selectedItem = item;
      this.openDetailsModal = true;
    },
    handleCloseDetailsModal() {
      this.selectedItem = null;
      this.openDetailsModal = false;
    },
    handleOpenChangeStatusModal(item = null) {
      this.selectedItem = item;
      this.openChangeStatusModal = true;
    },
    handleCloseChangeStatusModal() {
      this.selectedItem = null;
      this.openChangeStatusModal = false;
    },
    async handleSubmitChangeStatusModal() {
      this.$eventBus.$emit("fetchAnnouncements");
    },
  },
};
</script>
