import fileDownload from "js-file-download";
import { getFilenameFromContentDispositionHeader } from "../utils/getFilenameFromContentDispositionHeader";

export default {
  data() {
    return {
      loading: {
        download: false,
        preview: false,
      },
    };
  },
  methods: {
    downloadSelectedDocuments(ids) {
      ids.forEach(async (id) => {
        this.loading.download = true;
        try {
          const response = await window.API.downloadDocument(id);

          if (response.data) {
            const fileName = getFilenameFromContentDispositionHeader(
              response.headers["content-disposition"]
            );
            fileDownload(response.data, `${fileName}`);
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.loading.download = false;
        }
      });
    },
    async previewDocument(id) {
      try {
        this.loading.preview = true;
        const response = await window.API.previewDocument(id);

        if (response.data) {
          if (
            response.data.type.startsWith("image/") ||
            response.data.type == "application/pdf"
          ) {
            const objectUrl = window.URL.createObjectURL(response.data);
            window.open(objectUrl);

            return;
          }

          const fileName = getFilenameFromContentDispositionHeader(
            response.headers["content-disposition"]
          );
          fileDownload(response.data, `${fileName}`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading.preview = false;
      }
    },
  },
};
