<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-6">
        <h4 class="subtitle-2 font-weight-medium black--text">
          {{ $t("companies.details.company-info.section-title") }}
        </h4>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.busines-entity-type") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.businessEntityType.name }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.formation-state") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.state }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.legal-company-name") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.legalName }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.doing-business-as") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ fillEmptyData(company.doingBusinessAs) }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.industry") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{
                company.industry
                  ? company.industry.name
                  : company.otherIndustryName
              }}
            </span>
          </v-col>
          <v-col md="6">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.registration-date") }}:
            </span>
            <span
              class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
              >{{ company.creationTime | moment("MM.DD.YYYY") }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="border-bottom py-3">
        <v-row>
          <v-col md="12">
            <span class="subtitle-2 font-weight-medium grey--text"
              >{{ $t("companies.details.company-info.payment-status") }}:
            </span>
            <v-chip
              v-if="company.registrationPayment.paymentStatus"
              class="ml-4"
              small
              :color="
                getPaymentStatus(company.registrationPayment.paymentStatus.id)
                  .bgColor
              "
            >
              <span
                :class="`${
                  getPaymentStatus(company.registrationPayment.paymentStatus.id)
                    .color
                }--text`"
                >{{ company.registrationPayment.paymentStatus.name }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PaymentStatuses from "@/shared/enums/PaymentStatuses";

export default {
  props: {
    company: {
      type: Object,
      require: true,
    },
  },
  methods: {
    getPaymentStatus(status) {
      switch (status) {
        case PaymentStatuses.PAID:
          return { bgColor: "light-green lighten-5", color: "green" };
        case PaymentStatuses.PENDING:
          return { bgColor: "orange lighten-5", color: "warning" };
        case PaymentStatuses.FAILED:
          return { bgColor: "red lighten-5", color: "red" };
        case PaymentStatuses.CANCELED:
          return { bgColor: "red lighten-5", color: "red" };
        default:
          return null;
      }
    },
  },
};
</script>
