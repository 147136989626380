export function downloadFile(response) {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );

  const fileName = response.headers["content-disposition"]
    .split("filename=")[1]
    .split(";")[0];

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}