<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        <div class="d-flex flex-nowrap">
          <v-tooltip right :color="item.isActive ? 'success' : 'error'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.isActive ? 'success' : 'error'"
                size="12"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-circle
              </v-icon>
            </template>
            <span>{{
              item.isActive
                ? $t("users.list.table.status.active")
                : $t("users.list.table.status.inactive")
            }}</span>
          </v-tooltip>
          <span class="ml-2">{{ item.fullName }}</span>
        </div>
      </template>
      <template v-slot:[`item.phoneNumber`]="{ item }">
        <span>{{
          `${item.phoneNumber}${item.extension ? ` / ${item.extension}` : ""}`
        }}</span>
      </template>
      <template v-slot:[`item.roles`]="{ item }">
        <template v-for="(role, index) in item.roles">
          <v-chip
            v-if="role && role.displayName"
            small
            :key="index"
            class="ma-1"
            color="primary"
            outlined
          >
            {{ getRoleDisplayText(role, item.department) }}
          </v-chip>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'user:change-status'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                @click="changeStatus(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ item.isActive ? "mdi-lock" : "mdi-lock-open" }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.list.table.actions.change-status") }}</span>
          </v-tooltip>

          <v-tooltip top color="gray darken-2" v-granted="'user:edit'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                @click="addOrEditItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.list.table.actions.edit") }}</span>
          </v-tooltip>

          <v-tooltip
            top
            color="gray darken-2"
            v-granted="'user:reset-password'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="resetPassword(item)"
              >
                <v-icon size="18"> mdi-key </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.list.table.actions.reset-password") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :createButtonPermission="'user:add'"
      @actionClick="addOrEditItem()"
      :text="$t('users.list.empty-page')"
      :createButtonText="$t('users.button.create-item')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import StaticRoles from "@/shared/enums/StaticRoles";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("users.list.table.headers.fullname"),
          value: "fullName",
          width: "20%",
        },
        {
          text: vm.$t("users.list.table.headers.email-address"),
          value: "emailAddress",
        },
        {
          text: vm.$t("users.list.table.headers.username"),
          value: "username",
        },
        {
          text: vm.$t("users.list.table.headers.inviter"),
          value: "inviter",
        },
        {
          text: vm.$t("users.list.table.headers.phone-number"),
          value: "phoneNumber",
        },
        {
          text: vm.$t("users.list.table.headers.role"),
          value: "roles",
        },
        {
          text: vm.$t("users.list.table.headers.advisor"),
          value: "advisor",
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {
        isActive: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    addOrEditItem(item = null) {
      this.$emit("editItem", item);
    },
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    changeStatus(item = null) {
      this.$emit("changeStatus", item);
    },
    resetPassword(item = null) {
      this.$emit("resetPassword", item);
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchUsers({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getRoleDisplayText(role, department) {
      if (role.name && role.name == StaticRoles.EMPLOYEE && department)
        return `${role.displayName}-${department}`;

      return `${role.displayName}`;
    },
    handleFetchUsersAfterStatusChange() {
      if (
        Math.ceil(this.table.totalCount / this.table.itemsPerPage) ===
          this.table.page &&
        this.table.totalCount % this.table.itemsPerPage === 1 &&
        this.table.page != 1 &&
        this.searchParams.isActive !== null
      ) {
        this.table.options.page = this.table.options.page - 1;
        this.table.page = this.table.page - 1;
      }

      this.fetchItems();
    },
  },
  created() {
    this.$eventBus.$on("fetchUsers", this.handleFetchItems);
    this.$eventBus.$on(
      "fetchUsersAfterStatusChange",
      this.handleFetchUsersAfterStatusChange
    );
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchUsers", this.handleFetchItems);
    this.$eventBus.$off(
      "fetchUsersAfterStatusChange",
      this.handleFetchUsersAfterStatusChange
    );
  },
};
</script>
