import { downloadFile } from "@/utils/file.js";

export function fetchClients(params) {
  return window.axios
    .get("/document/clients", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchAllClients(searchTerm) {
  return window.axios
    .get("/document/clients/all", { params: { searchTerm } })
    .then((response) => response.data);
}

export function fetchAllBusinessClients(searchTerm) {
  return window.axios
    .get("/document/clients/business/all", { params: { searchTerm } })
    .then((response) => response.data);
}

export function getPersonalClientById(id) {
  return window.axios
    .get(`/document/clients/personal/${id}`)
    .then((response) => response.data);
}

export function getBusinessClientById(id) {
  return window.axios
    .get(`/document/clients/business/${id}`)
    .then((response) => response.data);
}

export function getPersonalClientDetailsById(id) {
  return window.axios
    .get(`/document/clients/personal/${id}/details`)
    .then((response) => response.data);
}

export function getBusinessClientDetailsById(id) {
  return window.axios
    .get(`/document/clients/business/${id}/details`)
    .then((response) => response.data);
}

export function insertClient(data) {
  return window.axios
    .post("/document/clients", data)
    .then((response) => response.data);
}

export function insertMyClient(data) {
  return window.axios
    .post("/document/users/me/clients", data)
    .then((response) => response.data);
}

export function editPersonalClient(id, data) {
  return window.axios
    .put(`/document/clients/personal/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response.data);
}

export function editBusinessClient(id, data) {
  return window.axios
    .put(`/document/clients/business/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response.data);
}

export function deleteClient(id) {
  return window.axios
    .delete(`/document/clients/${id}`)
    .then((response) => response);
}

export function activateClient(id) {
  return window.axios
    .patch(`/document/clients/${id}/activate`)
    .then((response) => response.data);
}

export function deactivateClient(id) {
  return window.axios
    .patch(`/document/clients/${id}/deactivate`)
    .then((response) => response.data);
}

export function exportClients(params) {
  return window.axios
    .get("/document/clients/export", {
      responseType: "blob",
      params: params,
    })
    .then((response) => downloadFile(response));
}

export function sendEmail(data, params) {
  return window.axios
    .post("/document/clients/send-email", data, {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function getAllMyClients() {
  return window.axios
    .get(`/document/users/me/clients`)
    .then((response) => response.data);
}

export function getAllMyOwnerClients() {
  return window.axios
    .get(`/document/users/me/clients/owner`)
    .then((response) => response.data);
}

export function getAllMyCollaboratorClients() {
  return window.axios
    .get(`/document/users/me/clients/collaborator`)
    .then((response) => response.data);
}

export function updateMyCurrentClient(data) {
  return window.axios
    .put(`/document/users/me/clients/current`, data)
    .then((response) => response.data);
}

export function activateClientCollaborator(clientId, clientCollaboratorId) {
  return window.axios
    .patch(
      `document/clients/${clientId}/collaborators/${clientCollaboratorId}/activate`
    )
    .then((response) => response.data);
}

export function deactivateClientCollaborator(clientId, clientCollaboratorId) {
  return window.axios
    .patch(
      `document/clients/${clientId}/collaborators/${clientCollaboratorId}/deactivate`
    )
    .then((response) => response.data);
}

export function deleteMyCollaboration(clientId) {
  return window.axios
    .patch(`/document/clients/${clientId}/collaborators/me/deactivate`)
    .then((response) => response);
}

export function getClientProfilePicture(id, width = null, height = null) {
  return window.axios
    .get(`/document/clients/${id}/profile-picture`, {
      params: {
        width,
        height,
      },
    })
    .then((response) => response.data);
}

export function getClientActiveServices(id) {
  return window.axios
    .get(`/document/clients/${id}/services/active`)
    .then((response) => response.data);
}

export function fetchAllActiveClients() {
  return window.axios
    .get(`/document/clients/active`)
    .then((response) => response.data);
}

export function fetchClientAllServices(id, params) {
  return window.axios
    .get(`/document/clients/${id}/services`, { params })
    .then((response) => response.data);
}

export function fetchClientAllMembers(id) {
  return window.axios
    .get(`/document/clients/${id}/members`)
    .then((response) => response.data);
}
