const LeadStatuses = Object.freeze({
  NEW_LEAD: 1,
  CONTACT_ATTEMPT: 2,
  PURGE: 3,
  ASSIGNED: 4,
  APPOINTMENT: 5,
  CLOSED: 6,
  GENERAL_INFORMATION: 7,
  INTAKE_QUESTIONS: 8,
  QUOTE: 9,
  CONTRACT: 10,
  CREATE_ACCOUNT: 11,
  BILLING_INFO: 12,
  COMPLETED: 13,
});

export default LeadStatuses;
