<template>
  <v-row>
    <v-col cols="12" class="mb-4">
      <div class="border-bottom py-3">
        <p class="mb-0 body-2 gray--text text--darken-1">
          {{ $t("company-formation.payment.page.text") }}
        </p>
      </div>
    </v-col>

    <v-col cols="12" md="5">
      <v-row>
        <v-col cols="12" class="mb-3">
          <div class="stripe--input" id="card-number"></div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <div class="stripe--input" id="card-expiry"></div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <div class="stripe--input" id="card-cvc"></div>
        </v-col>

        <v-col cols="12">
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
            block
            large
            @click="handleSubmit"
            :loading="loading"
          >
            {{ $t("company-formation.payment.page.button.pay") }}
            {{
              $t("defaults.currency", {
                amount: fee,
              })
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      stripe: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    };
  },
  props: {
    fee: {
      type: [Number, String],
      required: true,
    },
    clientSecret: {
      type: String,
      required: true,
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {},
          },
        })
        .then((result) => {
          if (result.error) {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: result.error.message,
            });
            this.loading = false;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              this.$emit("submitPayment");
            }
          }
        });
    },
    initializeStripe() {
      const style = {
        base: {
          color: "#424242",
          fontFamily: '"Roboto", sans-serif',
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.54)",
          },
        },
        invalid: {
          color: "#FF5252",
          iconColor: "#fa755a",
        },
      };

      this.stripe = window.Stripe(process.env.VUE_APP_COMPANY_STRIPE_PUBLISHABLE_KEY);

      const elements = this.stripe.elements();

      this.cardNumber = elements.create("cardNumber", {
        style,
        showIcon: true,
        placeholder: this.$t("company-formation.payment.page.form.card-number"),
      });

      this.cardNumber.mount("#card-number");

      this.cardExpiry = elements.create("cardExpiry", {
        style,
        placeholder: this.$t("company-formation.payment.page.form.card-expiry"),
      });

      this.cardExpiry.mount("#card-expiry");

      this.cardCvc = elements.create("cardCvc", {
        style,
        placeholder: this.$t("company-formation.payment.page.form.card-cvc"),
      });
      
      this.cardCvc.mount("#card-cvc");
    },
    destroyStripeElements() {
      if (this.cardNumber) {
        this.cardNumber.destroy();
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy();
      }
      if (this.cardCvc) {
        this.cardCvc.destroy();
      }
    },
  },
  mounted() {
    this.initializeStripe();
  },
  beforeDestroy() {
    this.destroyStripeElements();
  },
};
</script>
