<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
      class="mb-3"
      v-if="!authorizationUtils.isClient()"
    >
      <v-autocomplete
        filled
        v-model="searchParams.clientId"
        :items="businessClients"
        item-text="name"
        item-value="id"
        hide-details="auto"
        clearable
        :loading="loading.businessClients"
        :search-input.sync="search.businessClients"
        :placeholder="$t('defaults.remote-dropdown-placeholder')"
        hide-no-data
        :label="$t('contractors.search.form.client')"
      >
        <template v-slot:item="data">
          <v-icon
            :color="data.item.isActive ? 'success' : 'error'"
            size="12"
          >
            mdi-circle
          </v-icon>
          <span class="ms-2">
            {{ data.item.name }}
          </span>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      :md="authorizationUtils.isClient() ? 4 : 6"
      class="mb-3"
    >
      <v-text-field
        :label="$t('contractors.search.form.business-name-or-full-name')"
        v-model.trim="searchParams.businessNameOrFullName"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-radio-group
        row
        filled
        v-model="searchParams.contractorType"
        hide-details="auto"
        :class="authorizationUtils.isClient() ? 'mt-3' : 'mt-0'"
      >
        <v-radio
          :label="$t('contractors.search.form.contractor-type.business')"
          :value="ContractorTypes.BUSINESS"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.contractor-type.individual')"
          :value="ContractorTypes.INDIVIDUAL"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.contractor-type.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-radio-group
        row
        filled
        v-model="searchParams.isPaid"
        hide-details="auto"
        :class="authorizationUtils.isClient() ? 'mt-3' : 'mt-0'"
      >
        <v-radio
          :label="$t('contractors.search.form.service-fee.paid')"
          :value="true"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.service-fee.unpaid')"
          :value="false"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.service-fee.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
      class="mb-3"
      v-if="!authorizationUtils.isClient()"
    >
      <v-radio-group
        row
        filled
        v-model="searchParams.isCompleted"
        hide-details="auto"
        class="mt-0"
      >
        <v-radio
          :label="$t('contractors.search.form.complete.completed')"
          :value="true"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.complete.uncompleted')"
          :value="false"
        ></v-radio>
        <v-radio
          :label="$t('contractors.search.form.complete.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContractorTypes from "@/shared/enums/ContractorTypes";
import authorizationUtils from "@/utils/authorization.js";

export default {
  data() {
    return {
      search: {
        businessClients: null
      },
      loading: {
        businessClients: false
      },
      businessClients: [],
      ContractorTypes,
      authorizationUtils
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true
    },
    "search.businessClients": {
      handler(val) {
        if (val && val.length > 2) {
          this.fetchAllBusinessClients(val);
        } else {
          this.businessClients = [];
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams"
    })
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams"
    }),
    fetchAllBusinessClients(searchTerm) {
      this.loading.businessClients = true;

      window.API.fetchAllBusinessClients(searchTerm)
        .then((response) => {
          this.businessClients = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.businessClients = false));
    }
  }
};
</script>
