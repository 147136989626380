<template>
  <div>
    <v-row class="mb-4" align="center">
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="6"
            ><span class="font-weight-bold"
              >{{
                $t("reconciliations.transactions.list.starting-balance")
              }}:</span
            >
            <span
              class="text--secondary ml-1"
              v-if="accountBalance && accountBalance.startingBalanceDate"
              >{{
                `${UTCToLocalTime(
                  accountBalance.startingBalanceDate,
                  "MM.DD.YY HH:mm"
                )} ${formatCurrency(
                  accountBalance.startingBalance,
                  accountBalance.isoCurrencyCode
                )}`
              }}</span
            ></v-col
          >
          <v-col cols="6">
            <span class="font-weight-bold"
              >{{
                $t("reconciliations.transactions.list.ending-balance")
              }}:</span
            >
            <span
              class="text--secondary ml-1"
              v-if="accountBalance && accountBalance.endingBalanceDate"
              >{{
                `${UTCToLocalTime(
                  accountBalance.endingBalanceDate,
                  "MM.DD.YY HH:mm"
                )} ${formatCurrency(
                  accountBalance.endingBalance,
                  accountBalance.isoCurrencyCode
                )}`
              }}</span
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <div class="d-flex justify-end" v-if="selectedMonth || selectedYear">
          <div class="d-flex align-center">
            <v-btn
              small
              fab
              text
              depressed
              dense
              value="left"
              @click="handlePrevMonth"
              :disabled="isDisabledPrevMonth"
              :loading="loading.prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>

            <span class="mx-2 text-align-center"
              >{{ selectedMonth.displayText }}
              {{ selectedYear.displayText }}</span
            >

            <v-btn
              small
              fab
              text
              depressed
              dense
              value="right"
              @click="handleNextMonth"
              :disabled="isDisabledNextMonth"
              :loading="loading.next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.name.slice(0, 20) }}</span>
          </template>
          <span> {{ item.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ $moment(item.date).format("MM.DD.YY") }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{
          `${item.amount >= 0 ? "+" : ""}${formatCurrency(
            item.amount,
            item.isoCurrencyCode
          )}`
        }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span v-if="item.amount >= 0">{{
          $t("reconciliations.transactions.list.types.deposit")
        }}</span>
        <span v-else>{{
          $t("reconciliations.transactions.list.types.withdrawal")
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import isoCountryCurrency from "@/utils/isoCountryCurrency";

export default {
  mixins: [tableMixins],
  props: {
    months: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    startedYear: {
      type: Number,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    institutionAccountId: {
      type: [Number, String],
      required: true,
    },
  },
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("reconciliations.transactions.list.table.headers.date"),
          value: "date",
          sortable: false,
        },
        {
          text: vm.$t("reconciliations.transactions.list.table.headers.name"),
          value: "name",
          sortable: false,
        },
        {
          text: vm.$t(
            "reconciliations.transactions.list.table.headers.category"
          ),
          value: "category",
          sortable: false,
        },
        {
          text: vm.$t("reconciliations.transactions.list.table.headers.amount"),
          value: "amount",
          sortable: false,
        },
        {
          text: vm.$t("reconciliations.transactions.list.table.headers.type"),
          value: "type",
          sortable: false,
        },
      ],
      initialSearchParams: {
        name: null,
        amount: null,
        isDeposit: null,
        year: null,
        month: null,
        categoryId: null,
      },
      isDisabledNextMonth: false,
      isDisabledPrevMonth: false,
      accountBalance: null,
      prevMonth: null,
      prevYear: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    selectedMonth() {
      return this.months.find((month) => month.id == this.searchParams.month);
    },
    selectedYear() {
      return this.years.find((year) => year.value == this.searchParams.year);
    },
    currentYear() {
      return this.$moment().format("YYYY");
    },
    currentMonth() {
      return this.$moment().month() + 1;
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.isDisabledPrevMonth = false;
        this.isDisabledNextMonth = false;

        if (
          this.selectedMonth.id == 1 &&
          this.selectedYear.value == this.startedYear
        ) {
          this.isDisabledPrevMonth = true;
        }

        if (
          this.selectedMonth.id == 12 &&
          this.selectedYear.value == this.currentYear
        ) {
          this.isDisabledNextMonth = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({
        ...this.initialSearchParams,
        year: this.currentYear,
        month: this.currentMonth,
      });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;

      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.getAllPlaidInstitutionAccountTransactions(
        this.clientId,
        this.institutionAccountId,
        {
          limit: this.table.itemsPerPage,
          offset: (this.table.page - 1) * this.table.itemsPerPage,
          ...this.searchParams,
        }
      )
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });

      this.getPlaidInstitutionAccountBalance();
    },
    async handlePrevMonth() {
      if (
        this.selectedMonth.id == 1 &&
        this.selectedYear.value == this.startedYear
      )
        return;

      try {
        this.loading.prev = true;
        let month, year;

        if (this.selectedMonth.id == 1) {
          month = this.months.find((month) => month.id == 12).id;
          year = parseInt(this.selectedYear.value) - 1;
        } else {
          month = this.months.find(
            (month) => month.id == this.selectedMonth.id - 1
          ).id;
          year = parseInt(this.selectedYear.value);
        }
        await this.setSearchParams({
          ...this.searchParams,
          month: month,
          year: year.toString(),
        });
        await this.fetchItems();
      } finally {
        this.loading.prev = false;
      }
    },
    async handleNextMonth() {
      if (
        this.selectedMonth.id == 12 &&
        this.selectedYear.value == this.currentYear
      )
        return;

      try {
        this.loading.next = true;
        let month, year;

        if (this.selectedMonth.id == 12) {
          month = this.months.find((month) => month.id == 1).id;
          year = parseInt(this.selectedYear.value) + 1;
        } else {
          month = this.months.find(
            (month) => month.id == this.selectedMonth.id + 1
          ).id;
          year = parseInt(this.selectedYear.value);
        }
        await this.setSearchParams({
          ...this.searchParams,
          month: month,
          year: year.toString(),
        });
        await this.fetchItems();
      } finally {
        this.loading.next = false;
      }
    },
    async getPlaidInstitutionAccountBalance() {
      if (
        this.prevMonth != this.searchParams.month ||
        this.prevYear != this.searchParams.year
      ) {
        try {
          const { balance } =
            await window.API.getPlaidInstitutionAccountBalance(
              this.clientId,
              this.institutionAccountId,
              {
                year: this.searchParams.year,
                month: this.searchParams.month,
              }
            );

          this.accountBalance = balance;
        } catch {
          this.accountBalance = null;
        } finally {
          this.prevMonth = this.searchParams.month;
          this.prevYear = this.searchParams.year;
        }
      }
    },
    formatCurrency(amount, isoCurrencyCode) {
      return isoCountryCurrency.formatCurrency(amount, isoCurrencyCode);
    },
  },
  created() {
    this.$eventBus.$on("fetchTransactions", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchTransactions", this.handleFetchItems);
  },
};
</script>
