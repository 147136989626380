<template>
  <d-modal
    :title="$t('announcements.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('announcements.create.form.title')"
              v-model.trim="form.title"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.title"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <p class="font-weight-regular body-2 text--secondary mb-1">
              {{ $t("announcements.create.form.body") }}
            </p>
            <d-editor
              id="bodyEditor"
              :height="250"
              v-model="form.body"
              :isValid="validation.body"
            ></d-editor>
          </v-col>
          <v-col cols="12" class="mb-3">
            <p class="font-weight-regular body-2 text--secondary mb-1">
              {{ $t("announcements.create.form.acknowledgment-text") }}
            </p>
            <d-editor
              id="acknowledgmentTextEditor"
              :height="250"
              v-model="form.acknowledgmentText"
              :isValid="validation.acknowledgmentText"
            ></d-editor>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-menu
              v-model="menu.expirationDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedExpirationDate"
                  :label="$t('announcements.create.form.expiration-date')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  filled
                  clearable
                  @click:clear="form.expirationDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.expirationDate"
                @input="menu.expirationDate = false"
                no-title
                :min="minDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import BaseEditor from "@/components/BaseEditor.vue";
const initialForm = {
  title: null,
  body: null,
  acknowledgmentText: null,
  expirationDate: null,
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    "d-editor": BaseEditor,
  },
  data() {
    return {
      form: { ...initialForm },
      loading: {
        page: false,
      },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        title: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      validation: {
        body: true,
        acknowledgmentText: true,
      },
      menu: {
        expirationDate: false,
      },
      minDate: this.$moment().format(),
    };
  },
  computed: {
    formattedExpirationDate() {
      return this.form.expirationDate
        ? this.$moment(this.form.expirationDate).format("MM.DD.YYYY")
        : null;
    },
  },
  watch: {
    "form.body": {
      handler() {
        this.validation.body = !!this.form.body;
      },
    },
    "form.acknowledgmentText": {
      handler() {
        this.validation.acknowledgmentText = !!this.form.acknowledgmentText;
      },
    },
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      this.validation.body = !!this.form.body;
      this.validation.acknowledgmentText = !!this.form.acknowledgmentText;
      if (
        this.$refs.form.validate() &&
        this.isFormValid &&
        this.validation.body &&
        this.validation.acknowledgmentText
      ) {
        await this.createItem();
      }
    },
    async createItem() {
      this.isSubmitting = true;

      await window.API.insertAnnouncement(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("announcements.create.alert.created"),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("announcements.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
