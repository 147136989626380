<template>
  <div class="pt-6">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row class="text-center">
          <v-col cols="12" class="mb-4">
            <v-avatar tile size="32">
              <img src="@/assets/images/mdi-success.svg" alt="Completed" />
            </v-avatar>
          </v-col>
          <v-col cols="12" class="mb-2">
            <h1 class="text-h5 font-weight-medium mb-0">
              {{ $t("company-formation.payment.success.title") }}
            </h1>
          </v-col>
          <v-col cols="12" class="mb-4">
            <p
              class="mb-0 body-1 gray--text"
              v-html="$t('company-formation.payment.success.text')"
            ></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {};
</script>
