<template>
  <div class="mx-2 my-2">
    <v-row v-if="account && !loading">
      <v-col cols="12" class="mb-3">
        <v-avatar color="gray lighten-1" size="64">
          <d-auth-image
            :url="`/document/clients/${item.id}/profile-picture`"
            :width="196"
            :height="196"
            @error="handleImageError"
          ></d-auth-image>
          <span class="body-1 gray--text text--lighten-4" v-if="imageError">{{
            profileAvatar
          }}</span>
        </v-avatar>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters align="center">
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{
                isBusiness
                  ? $t("accounts.owner.details.name")
                  : $t("accounts.owner.details.full-name")
              }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <div class="d-flex flex-nowrap align-center">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="primary" dark v-bind="attrs" v-on="on">
                    {{
                      item.clientType === ClientTypes.BUSINESS
                        ? "mdi-briefcase"
                        : "mdi-account"
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    item.clientType === ClientTypes.BUSINESS
                      ? $t("accounts.client-type.business")
                      : $t("accounts.client-type.personal")
                  }}
                </span>
              </v-tooltip>
              <span class="caption black--text ml-1">
                {{
                  item.clientType === ClientTypes.BUSINESS
                    ? fillEmptyData(account.name)
                    : fillEmptyData(account.fullName)
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.ssn") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.socialSecurityNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.trade-name") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.tradeName) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.tin") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.taxpayerIdentificationNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isBusiness">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.website") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.website) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.birth-date") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.birthDate) | moment("MM.DD.YYYY") }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.email-address") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.emailAddress) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.phone-number") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.phoneNumber) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.address") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text" v-if="account.address">
              {{ generateAddress(account.address) }}
            </span>
            <span class="caption black--text" v-else>—</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.services") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <template v-if="account.services && account.services.length > 0">
              <v-chip
                v-for="(service, index) in account.services"
                small
                :key="index"
                class="ma-1"
                color="primary"
                outlined
              >
                {{ service }}
              </v-chip>
            </template>
            <template v-else>
              <span class="caption black--text"> — </span>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.owner") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.owner) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <span class="caption gray--text text--darken-1">
              {{ $t("accounts.owner.details.advisor") }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <span class="caption black--text">
              {{ fillEmptyData(account.advisor) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-3">
        <div class="border-bottom">
          <v-row no-gutters class="mb-3">
            <span class="caption black--text font-weight-medium">
              {{ $t("accounts.owner.details.collaborators") }}
            </span>
          </v-row>
          <template
            v-if="account.collaborators && account.collaborators.length > 0"
          >
            <v-row
              no-gutters
              v-for="(item, index) in account.collaborators"
              :key="index"
              class="mb-3"
            >
              <v-col cols="12" md="4">
                <span class="caption gray--text text--darken-1">
                  {{ item.collaborator }}
                </span>
              </v-col>
              <v-col cols="12" md="8">
                <template v-if="item.services && item.services.length > 0">
                  <v-chip
                    v-for="(service, index) in item.services"
                    small
                    :key="index"
                    class="ma-1"
                    color="primary"
                    outlined
                  >
                    {{ service }}
                  </v-chip>
                </template>
                <template v-else>
                  <span class="caption black--text"> — </span>
                </template>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span class="caption black--text"> — </span>
          </template>
        </div>
      </v-col>
      <v-col cols="12" class="mb-3" v-if="isPersonal">
        <v-row no-gutters class="mb-3">
          <span class="caption black--text font-weight-medium">
            {{ $t("accounts.owner.details.marriage-info") }}
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="mb-3">
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <span class="caption gray--text text--darken-1">
                  {{ $t("accounts.owner.details.marital-status.title") }}
                </span>
              </v-col>
              <v-col cols="12" md="8">
                <span class="caption black--text">
                  {{
                    account.maritalStatus === MaritalStatuses.MARRIED
                      ? $t("accounts.owner.details.marital-status.married")
                      : $t("accounts.owner.details.marital-status.single")
                  }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <template v-if="account.maritalStatus === MaritalStatuses.MARRIED">
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <span class="caption gray--text text--darken-1">
                    {{ $t("accounts.owner.details.spouse.full-name") }}
                  </span>
                </v-col>
                <v-col cols="12" md="8">
                  <span class="caption black--text">
                    {{ fillEmptyData(account.spouseInfo.fullName) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-3">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <span class="caption gray--text text--darken-1">
                    {{ $t("accounts.owner.details.spouse.ssn") }}
                  </span>
                </v-col>
                <v-col cols="12" md="8">
                  <span class="caption black--text">
                    {{ fillEmptyData(account.spouseInfo.socialSecurityNumber) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-3">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <span class="caption gray--text text--darken-1">
                    {{ $t("accounts.owner.details.spouse.birth-date") }}
                  </span>
                </v-col>
                <v-col cols="12" md="8">
                  <span class="caption black--text">
                    {{
                      fillEmptyData(account.spouseInfo.birthDate)
                        | moment("MM.DD.YYYY")
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-3">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <span class="caption gray--text text--darken-1">
                    {{ $t("accounts.owner.details.spouse.phone-number") }}
                  </span>
                </v-col>
                <v-col cols="12" md="8">
                  <span class="caption black--text">
                    {{ fillEmptyData(account.spouseInfo.phoneNumber) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-3">
              <div class="border-bottom">
                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <span class="caption gray--text text--darken-1">
                      {{ $t("accounts.owner.details.spouse.address") }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span
                      class="caption black--text"
                      v-if="account.spouseInfo.address"
                    >
                      {{ generateAddress(account.spouseInfo.address) }}
                    </span>
                    <span class="caption black--text" v-else>—</span>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <d-loading v-else></d-loading>
  </div>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";
import MaritalStatuses from "@/shared/enums/MaritalStatuses";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      account: null,
      imageError: false,
      ClientTypes,
      MaritalStatuses,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.getItem(val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    profileAvatar() {
      if (this.isPersonal) {
        return (
          this.account.firstName.charAt(0).toUpperCase() +
          this.account.lastName.charAt(0).toUpperCase()
        );
      } else {
        return this.account.name.charAt(0).toUpperCase();
      }
    },
    isPersonal() {
      return this.item.clientType === ClientTypes.PERSONAL;
    },
    isBusiness() {
      return this.item.clientType === ClientTypes.BUSINESS;
    },
  },
  methods: {
    getPersonalAccountDetailsById(id) {
      this.loading = true;
      window.API.getPersonalClientDetailsById(id)
        .then((response) => {
          this.account = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
    getBusinessAccountDetailsById(id) {
      this.loading = true;
      window.API.getBusinessClientDetailsById(id)
        .then((response) => {
          this.account = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
    getItem(item) {
      if (item) {
        if (item.clientType === ClientTypes.PERSONAL) {
          this.getPersonalAccountDetailsById(item.id);
        } else if (item.clientType === ClientTypes.BUSINESS) {
          this.getBusinessAccountDetailsById(item.id);
        }
      }
    },
    handleImageError(error) {
      this.imageError = error;
    },
    generateAddress(adress) {
      return `${adress.addressLine1 ? adress.addressLine1 + ", " : ""}
      ${adress.addressLine2 ? adress.addressLine2 + ", " : ""}
      ${adress.city ? adress.city + ", " : ""}
      ${adress.state ? adress.state + ", " : ""}
      ${adress.zipCode ? adress.zipCode : ""}`
        .trim()
        .replace(/(^,)|(,$)/g, "");
    },
  },
};
</script>
