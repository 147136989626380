<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('leads.search.form.owner-full-name')"
        v-model.trim="searchParams.ownerFullName"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-autocomplete
        filled
        v-model="searchParams.status"
        :items="statuses"
        item-text="displayName"
        item-value="id"
        hide-details="auto"
        clearable
        :label="$t('leads.search.form.status')"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
  },
};
</script>
