import * as authServices from "./auth";
import * as departmentServices from "./department";
import * as profileServices from "./profile";
import * as accountServices from "./account";
import * as permissionServices from "./permission";
import * as roleServices from "./role";
import * as userServices from "./user";
import * as tagGroupServices from "./tag-group";
import * as serviceServices from "./service";
import * as tagServices from "./tag";
import * as clientServices from "./client";
import * as stateServices from "./state";
import * as leadServices from "./lead";
import * as invitationServices from "./invitation";
import * as documentServices from "./document";
import * as myUploadServices from "./my-upload";
import * as receivedDocServices from "./received-doc";
import * as bookkeepingServices from "./bookkeeping";
import * as companyServices from "./company";
import * as contractorServices from "./contractor";
import * as customerOnboardingServices from "./customer-onboarding";
import * as announcementServices from "./announcement";
import * as reconciliationServices from "./reconciliation";
import * as couponServices from "./coupon";

export default {
  ...authServices,
  ...departmentServices,
  ...profileServices,
  ...accountServices,
  ...permissionServices,
  ...roleServices,
  ...userServices,
  ...tagGroupServices,
  ...serviceServices,
  ...tagServices,
  ...clientServices,
  ...stateServices,
  ...leadServices,
  ...invitationServices,
  ...documentServices,
  ...myUploadServices,
  ...receivedDocServices,
  ...documentServices,
  ...bookkeepingServices,
  ...companyServices,
  ...contractorServices,
  ...customerOnboardingServices,
  ...announcementServices,
  ...reconciliationServices,
  ...couponServices,
};
