<template>
  <div>
    <v-row class="mb-6" v-if="loading.table || table.items.length > 0">
      <v-col cols="12">
        <v-text-field
          :label="$t('tags.list.search')"
          hide-details="auto"
          filled
          type="text"
          v-model="searchText"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="searchText ? filteredItems : table.items"
      :loading="loading.table"
      hide-default-footer
      v-if="
        loading.table ||
        (searchText ? filteredItems.length > 0 : table.items.length > 0)
      "
      class="d-table"
    >
      <template v-slot:progress v-if="loading.table">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>

      <template v-slot:body="props">
        <draggable
          v-model="table.items"
          tag="tbody"
          handle=".tag-handler"
          @update="handleTableReorder"
        >
          <tr v-for="item in props.items" :key="item.ordinal">
            <td>
              <v-tooltip top color="gray darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="gray darken-2"
                    icon
                    small
                    :disabled="searchText ? true : false"
                    v-bind="attrs"
                    v-on="on"
                    class="tag-handler"
                  >
                    <v-icon size="18"> mdi-swap-vertical </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tags.list.table.actions.swap") }}</span>
              </v-tooltip>
            </td>
            <td>
              <div class="d-flex flex-nowrap">
                <span class="mr-2">{{ item.displayName }}</span>
                <v-tooltip top color="primary" v-if="item.isMainTagGroup">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="22"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-folder-star
                    </v-icon>
                  </template>
                  <span>{{ $t("tags.list.table.main-tag-group") }}</span>
                </v-tooltip>
              </div>
            </td>
            <td>
              <div class="py-1">
                <template v-for="tag in item.tags">
                  <v-chip
                    @click="selectTag(tag)"
                    :key="tag.id"
                    :dark="tag.isActive"
                    small
                    label
                    class="ma-1"
                    :color="tag.isActive ? item.color : 'gray lighten-3'"
                  >
                    <span
                      :class="tag.isActive ? 'white--text' : 'gray--text'"
                      >{{ tag.displayName }}</span
                    >

                    <template v-if="selectedTag && selectedTag.id === tag.id">
                      <template v-if="tag.isActive">
                        <v-tooltip
                          top
                          small
                          color="gray darken-2"
                          v-granted="'tag:change-status'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-2"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              @click="changeTagStatus(tag)"
                            >
                              <v-icon x-small> mdi-lock </v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("tags.list.table.tag.deactivate")
                          }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip
                          top
                          small
                          color="gray darken-2"
                          v-granted="'tag:change-status'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-2"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              @click="changeTagStatus(tag)"
                            >
                              <v-icon x-small> mdi-lock-open </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tags.list.table.tag.activate") }}</span>
                        </v-tooltip>
                      </template>
                      <v-tooltip
                        top
                        small
                        color="gray darken-2"
                        v-granted="'tag:edit'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            @click="editTag(tag, item)"
                          >
                            <v-icon x-small> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tags.list.table.tag.edit") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        top
                        small
                        color="gray darken-2"
                        v-granted="'tag:delete'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteTag(tag)"
                          >
                            <v-icon x-small> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tags.list.table.tag.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </v-chip>
                </template>
              </div>
            </td>
            <td>
              <div class="d-flex align-center justify-end">
                <v-tooltip top color="gray darken-2" v-granted="'tag:add'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="gray darken-2"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addTag(null, item)"
                    >
                      <v-icon size="18"> mdi-tag-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tags.list.table.actions.add-tag") }}</span>
                </v-tooltip>
                <v-tooltip top color="gray darken-2" v-granted="'tag:delete'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="gray darken-2"
                      icon
                      small
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tags.list.table.actions.delete") }}</span>
                </v-tooltip>
                <v-tooltip top color="gray darken-2" v-granted="'tag:edit'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="gray darken-2"
                      icon
                      small
                      @click="addOrEditItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tags.list.table.actions.edit") }}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <d-page-empty
      :createButtonPermission="'tag:add'"
      @actionClick="addOrEditItem()"
      :text="$t('tags.list.empty-page')"
      :createButtonText="$t('tags.button.create-item')"
      v-if="!searchText && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="searchText && filteredItems.length == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import Draggable from "vuedraggable";

export default {
  mixins: [tableMixins],
  components: { Draggable },
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("tags.list.table.headers.order"),
          value: "ordinal",
          sortable: false,
        },
        {
          text: vm.$t("tags.list.table.headers.display-name"),
          value: "displayName",
          sortable: false,
          width: "150",
        },
        {
          text: vm.$t("tags.list.table.headers.tags"),
          value: "tags",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      searchText: null,
      selectedTag: null,
    };
  },
  computed: {
    filteredItems() {
      return this.table.items.filter(
        (item) =>
          item.displayName
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          item.tags.some((tag) =>
            tag.displayName
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          )
      );
    },
  },
  methods: {
    handleTableReorder() {
      let orderedItemIds = this.table.items.map((item) => item.id);
      this.reorderItems(orderedItemIds);
    },
    reorderItems(itemIds) {
      this.loading.table = true;

      window.API.reorderTagGroups(itemIds)
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    addOrEditItem(item = null) {
      this.$emit("editItem", item);
    },
    deleteItem(item = null) {
      this.$emit("deleteItem", item);
    },
    selectTag(tag) {
      this.selectedTag = tag;
    },
    addTag(tag = null, tagGroup) {
      this.$emit("addTag", tag, tagGroup);
    },
    editTag(tag = null, tagGroup) {
      this.$emit("editTag", tag, tagGroup);
    },
    deleteTag(tag = null) {
      this.$emit("deleteTag", tag);
    },
    changeTagStatus(tag) {
      this.$emit("changeTagStatus", tag);
    },
    fetchItems() {
      this.loading.table = true;
      window.API.fetchTagGroups({
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
  created() {
    this.$eventBus.$on("fetchTagGroups", this.fetchItems);
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchTagGroups", this.fetchItems);
  },
};
</script>

<style lang="scss">
.v-data-table__progress > th {
  padding: 0 !important;
}
</style>
