const accessToken = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
const refreshToken = localStorage.getItem(
  process.env.VUE_APP_REFRESH_TOKEN_KEY
);
const loggedInUser = JSON.parse(
  localStorage.getItem(process.env.VUE_APP_USER_DATA_KEY)
);

const permissions = JSON.parse(
  localStorage.getItem(process.env.VUE_APP_USER_PERMISSIONS_KEY)
);

const myCurrentClient = JSON.parse(
  localStorage.getItem(process.env.VUE_APP_MY_CURRENT_CLIENT_KEY)
);

const state = {
  accessToken,
  refreshToken,
  permissions,
  loggedInUser,
  myCurrentClient,
};

const getters = {
  isLoggedIn: (state) => !!state.accessToken,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getUserPermissions: (state) => state.permissions,
  getLoggedInUser: (state) => state.loggedInUser,
  getMyCurrentClient: (state) => state.myCurrentClient,
  isLeadOnboardingCompleted: (state) =>
    state.loggedInUser ? state.loggedInUser.isLeadOnboardingCompleted : null,
};

const actions = {
  setAccessToken({ commit }, accessToken) {
    commit("SET_ACCESS_TOKEN", accessToken);
  },
  setRefreshToken({ commit }, refreshToken) {
    commit("SET_REFRESH_TOKEN", refreshToken);
  },
  updateTokens({ commit }, { refreshToken, accessToken }) {
    commit("SET_ACCESS_TOKEN", accessToken);
    commit("SET_REFRESH_TOKEN", refreshToken);
  },
  logOut({ commit }) {
    commit("LOG_OUT");
  },
  setUserPermissions({ commit }, permissions) {
    commit("SET_USER_PERMISSIONS", permissions);
  },
  setLoggedInUser({ commit }, loggedInUser) {
    commit("SET_LOGGED_IN_USER", loggedInUser);
  },
  setMyCurrentClient({ commit }, myCurrentClient) {
    commit("SET_MY_CURRENT_CLIENT", myCurrentClient);
  },
  setIsLeadOnboardingCompleted({ commit }, isLeadOnboardingCompleted) {
    commit("SET_IS_LEAD_ONBOARDING_COMPLETED", isLeadOnboardingCompleted);
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
    localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_KEY, token);
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
    localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN_KEY, token);
  },
  LOG_OUT(state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.permissions = [];
    state.loggedInUser = null;
    state.myCurrentClient = null;
    localStorage.clear();
  },
  SET_USER_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
    localStorage.setItem(
      process.env.VUE_APP_USER_PERMISSIONS_KEY,
      JSON.stringify(permissions)
    );
  },
  SET_LOGGED_IN_USER(state, loggedInUser) {
    state.loggedInUser = loggedInUser;
    localStorage.setItem(
      process.env.VUE_APP_USER_DATA_KEY,
      JSON.stringify(loggedInUser)
    );
  },
  SET_MY_CURRENT_CLIENT(state, myCurrentClient) {
    state.myCurrentClient = myCurrentClient;
    localStorage.setItem(
      process.env.VUE_APP_MY_CURRENT_CLIENT_KEY,
      JSON.stringify(myCurrentClient)
    );
  },
  SET_IS_LEAD_ONBOARDING_COMPLETED(state, isLeadOnboardingCompleted) {
    const loggedInUser = {
      ...state.loggedInUser,
      isLeadOnboardingCompleted: isLeadOnboardingCompleted,
    };

    localStorage.setItem(
      process.env.VUE_APP_USER_DATA_KEY,
      JSON.stringify(loggedInUser)
    );

    state.loggedInUser = loggedInUser;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
