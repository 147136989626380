import Vue from "vue";
import Vuex from "vuex";

import Common from "./modules/common";
import Auth from "./modules/auth";
import SearchParams from "./modules/searchParams";
import MyUpload from "./modules/myUpload";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Common,
    Auth,
    SearchParams,
    MyUpload,
  },
});
