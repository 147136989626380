<template>
  <d-page
    :title="$t('leads.page-heading')"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("leads.button.search-toggle")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="error"
        v-granted="'lead:delete'"
        @click="handleOpenDeleteModal"
        :disabled="!hasSelectedItems"
      >
        <v-icon left> mdi-delete </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("leads.button.delete")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        v-granted="'lead:export'"
        @click="exportLeads"
      >
        <v-icon left> mdi-file-excel </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("leads.button.export-data")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search :statuses="statuses"></Search>
    </template>
    <template slot="content">
      <List
        @getTable="handleGetTable"
        @updateSelectedItems="handleUpdateSelectedItems"
      ></List>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :selectedItems="selectedItems"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
      ></Delete>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";
import Delete from "./Delete.vue";

export default {
  components: {
    List,
    Search,
    Delete,
  },
  data() {
    return {
      searchToggle: false,
      openDeleteModal: false,
      selectedItems: [],
      selectedItem: null,
      statuses: [],
      table: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    hasSelectedItems() {
      return this.selectedItems && this.selectedItems.length > 0;
    },
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchLeads", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchLeads");
    },
    handleUpdateSelectedItems(ids = []) {
      this.selectedItems = ids;
    },
    handleOpenDeleteModal() {
      this.openDeleteModal = true;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchLeads");
    },
    handleGetTable(value) {
      this.table = value;
    },
    exportLeads() {
      window.API.exportLeads({
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("leads.export.alert.exported"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("leads.export.alert.could-not-export"),
          });
        });
    },
    fetchAllLeadStatuses() {
      window.API.fetchAllLeadStatuses()
        .then((response) => {
          this.statuses = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async mounted() {
    await this.fetchAllLeadStatuses();
  },
};
</script>
