<template>
  <v-row>
    <v-col v-if="isAdmin" cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('invitations.search.form.sender')"
        v-model.trim="searchParams.sender"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="mb-3">
      <v-text-field
        :label="$t('invitations.search.form.recipient')"
        v-model.trim="searchParams.recipient"
        hide-details="auto"
        filled
        type="text"
      ></v-text-field>
    </v-col>
    <v-col
      v-if="isAdmin"
      cols="12"
      sm="6"
      md="4"
      class="mb-4"
      align-self="center"
    >
      <v-radio-group
        row
        filled
        v-model="searchParams.type"
        hide-details="auto"
        class="mt-0"
      >
        <v-radio
          :label="$t('invitations.search.form.type.advisor-to-client')"
          :value="InvitationTypes.ADVISOR_TO_CLIENT"
        ></v-radio>
        <v-radio
          :label="$t('invitations.search.form.type.client-to-collaborator')"
          :value="InvitationTypes.CLIENT_TO_COLLABORATOR"
        ></v-radio>
        <v-radio
          :label="$t('invitations.search.form.type.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="6" md="8" class="mb-3" align-self="center">
      <v-radio-group
        row
        filled
        v-model="searchParams.status"
        hide-details="auto"
        class="mt-0"
      >
        <v-radio
          :label="$t('invitations.search.form.status.sent')"
          :value="InvitationStatuses.SENT"
        ></v-radio>
        <v-radio
          :label="$t('invitations.search.form.status.received')"
          :value="InvitationStatuses.RECEIVED"
        ></v-radio>
        <v-radio
          :label="$t('invitations.search.form.status.registered')"
          :value="InvitationStatuses.REGISTERED"
        ></v-radio>
        <v-radio
          :label="$t('invitations.search.form.status.all')"
          :value="null"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvitationStatuses from "@/shared/enums/InvitationStatuses";
import InvitationTypes from "@/shared/enums/InvitationTypes";
import authorizationUtils from "@/utils/authorization.js";

export default {
  data() {
    return {
      InvitationStatuses,
      InvitationTypes,
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    isAdmin() {
      return authorizationUtils.isAdmin();
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
  },
};
</script>
