<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.title`]="{ item }">
        <div class="d-flex flex-nowrap">
          <v-tooltip right :color="item.isActive ? 'success' : 'error'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.isActive ? 'success' : 'error'"
                size="12"
                dark
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                mdi-circle
              </v-icon>
            </template>
            <span>{{
              item.isActive
                ? $t("announcements.list.table.status.active")
                : $t("announcements.list.table.status.inactive")
            }}</span>
          </v-tooltip>
          <span class="ml-2">{{ item.title }}</span>
        </div>
      </template>
      <template v-slot:[`item.expirationDate`]="{ item }">
        {{ UTCToLocalTime(item.expirationDate, "MMM D, YYYY") }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="gray darken-2" v-granted="'announcement:view'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="viewItem(item)"
            >
              <v-icon size="18"> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("announcements.list.table.actions.preview") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          color="gray darken-2"
          v-granted="'announcement:change-status'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-2"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="changeStatus(item)"
            >
              <v-icon size="18">
                {{ item.isActive ? "mdi-lock" : "mdi-lock-open" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{
            item.isActive
              ? $t("announcements.list.table.actions.deactivate")
              : $t("announcements.list.table.actions.activate")
          }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <d-page-empty
      :createButtonPermission="'announcement:add'"
      :text="$t('announcements.list.empty-page')"
      :createButtonText="$t('announcements.button.create-item')"
      @actionClick="addItem()"
      v-if="table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("announcements.list.table.headers.title"),
          value: "title",
          sortable: false,
        },
        {
          text: vm.$t("announcements.list.table.headers.expiration-date"),
          value: "expirationDate",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  methods: {
    handleFetchItems() {
      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;
      window.API.fetchAnnouncements({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    addItem() {
      this.$emit("addItem");
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    changeStatus(item = null) {
      this.$emit("changeStatus", item);
    },
  },
  created() {
    this.$eventBus.$on("fetchAnnouncements", this.handleFetchItems);
  },
  mounted() {
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchAnnouncements", this.handleFetchItems);
  },
};
</script>
