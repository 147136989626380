export function logIn(usernameOrEmailAddress, password) {
  return window.axios
    .post("/authentication/account/authenticate", {
      usernameOrEmailAddress,
      password,
    })
    .then((response) => response.data);
}

export function refreshToken(refreshToken) {
  return window.axios
    .get("/authentication/account/refresh-jwt-tokens", {
      params: { refreshToken },
    })
    .then((response) => response.data)
    .catch(() => null);
}

export function logOut() {
  return window.axios.post("/authentication/account/logout");
}
