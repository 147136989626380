<template>
  <section class="home-page">
    <d-loading v-if="loading.services"></d-loading>

    <template v-else>
      <template v-if="!selectedService">
        <div class="home-page--documents mt-4 px-4">
          <v-row>
            <v-col cols="12" md="4" class="mb-4">
              <router-link class="doc-card style-1" to="/received-docs">
                <div class="doc-card--text">
                  <h2
                    class="text-h6 gray--text text--darken-3 text-decoration-none"
                    v-html="$t('dashboard.received-documents')"
                  ></h2>
                </div>
                <div class="doc-card--icon d-flex justify-end">
                  <img src="@/assets/images/document-icon.svg" />
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" md="4" class="mb-4">
              <router-link class="doc-card style-1" to="/my-uploads">
                <div class="doc-card--text">
                  <h2
                    class="text-h6 gray--text text--darken-3 text-decoration-none"
                    v-html="$t('dashboard.my-uploads')"
                  ></h2>
                </div>
                <div class="doc-card--icon d-flex justify-end">
                  <img src="@/assets/images/document-icon.svg" />
                </div>
              </router-link>
            </v-col>
          </v-row>
        </div>
        <div class="home-page--services" v-if="services && services.length > 0">
          <div class="px-4 py-3 mb-4 border-bottom">
            <h3 class="text-h6">{{ $t("dashboard.services") }}</h3>
          </div>
          <div class="px-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
                class="mb-4"
                v-for="service in services"
                :key="service.id"
              >
                <router-link
                  class="service-card"
                  :to="{ path: '/', query: { service: service.id } }"
                  :style="{
                    'background-color': service.color
                      ? service.color
                      : defaults.serviceColor,
                  }"
                >
                  <div class="service-card--icon">
                    <img
                      :src="`data:image/png;base64,${
                        service.listIcon
                          ? service.listIcon
                          : defaults.serviceIcon
                      }`"
                    />
                  </div>
                  <div class="service-card--text">
                    <span class="subtitle-1 white--text font-weight-bold">{{
                      service.displayName
                    }}</span>
                  </div>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="home-page--appointment"
          v-if="authorizationUtils.isClient()"
        >
          <div class="px-4 py-3 mb-4 border-bottom">
            <h3 class="text-h6">
              {{ $t("dashboard.make-an-appointment.title") }}
            </h3>
          </div>
          <div class="px-4">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="mb-4"
                v-for="(appointmentLink, index) in appointmentLinks"
                :key="index"
              >
                <v-tooltip top color="gray darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      :href="appointmentLink.url"
                      target="_blank"
                      class="service-card"
                      v-bind="attrs"
                      v-on="on"
                      :style="{
                        'background-color': appointmentLink.color
                          ? appointmentLink.color
                          : defaults.serviceColor,
                      }"
                    >
                      <div class="service-card--icon">
                        <img
                          :src="`data:image/png;base64,${
                            appointmentLink.listIcon
                              ? appointmentLink.listIcon
                              : defaults.serviceIcon
                          }`"
                        />
                      </div>
                      <div class="service-card--text">
                        <span class="subtitle-1 white--text font-weight-bold">{{
                          appointmentLink.name
                        }}</span>
                      </div>
                    </a>
                  </template>
                  <span>{{
                    $t("dashboard.make-an-appointment.tooltip", {
                      department: appointmentLink.name,
                    })
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>

      <template v-else>
        <d-loading v-if="loading.tags"></d-loading>

        <div class="home-page--tags" v-else>
          <div>
            <v-breadcrumbs :items="items">
              <v-breadcrumbs-item
                slot="item"
                slot-scope="{ item }"
                :to="!item.disabled ? '/' : null"
                exact
                :class="{ 'primary--text cursor-pointer': !item.disabled }"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </div>
          <div class="px-4">
            <v-row>
              <v-col cols="12" md="3" class="mb-4">
                <div class="tag-card" @click="selectTag">
                  <div class="tag-card--icon">
                    <v-icon color="gray darken-3" size="20"
                      >mdi-format-list-bulleted</v-icon
                    >
                  </div>
                  <div class="tag-card--text">
                    <span
                      class="body-1 gray--text text--darken-3 font-weight-medium"
                      >{{ $t("dashboard.all-tags") }}</span
                    >
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-for="tag in tags"
                :key="tag.id"
                class="mb-4"
              >
                <div class="tag-card" @click="selectTag(tag)">
                  <div class="tag-card--icon">
                    <img
                      v-if="tag.listIcon"
                      :src="`data:image/png;base64,${tag.listIcon}`"
                    />
                  </div>
                  <div class="tag-card--text">
                    <span
                      class="body-1 gray--text text--darken-3 font-weight-medium"
                      >{{ tag.displayName }}</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import ServiceNames from "@/shared/enums/ServiceNames";

export default {
  data() {
    return {
      authorizationUtils,
      loading: {
        services: false,
        tags: false,
      },
      services: [],
      tags: [],
      selectedService: null,
      selectedTag: null,
      items: [
        {
          text: "Home",
          disabled: false,
          to: "/",
        },
        {
          text: "Service name",
          disabled: true,
          to: "#",
        },
      ],
      defaults: {
        serviceColor: "#78909C",
        serviceIcon:
          "iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFASURBVHgBzVTdbYMwGPwMHYDHPpYNOgJ/76UTQCeoOkHIBt2gdAMGAJJO0GxANmjFO7hnhCOEwJikkXISsn06n78fY+b7fsQ5fycii9RIyrLcLmjItG07w3hPy3CgpaqqvlQi5nkeFxOczuZEUqMTqUHrkeCAzX8aKk3PNZw1vcSwMx0Td6SBqYaNGnXCpRFe31Ar5SnM3dvbiDAIAqdt252YG4bh5nm+P8sQD0kIgyPMogH9FIbhoa7rEGVItf5lAehiDB8qjYhWu4aMsWhEpXj2PocE1hvtlJumeUYE35g+CDNk9CJ413W7w/AdOo2uoWVZYuNvH+2JxyG8j87qNKjNDylea1lbNGSHTY7kYZr2RvGA24sI3/AdaQET9YqHZlLDaAVEp3FlMqSZYPna0ym4rWmaj0VRZKsMJVQX+w9Zp4nmaNQ92wAAAABJRU5ErkJggg==",
      },
      allServices: [],
    };
  },
  computed: {
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
      loggedInUser: "Auth/getLoggedInUser",
    }),
    taxService() {
      return this.allServices.find(
        (service) => service.name == ServiceNames.TAX
      );
    },
    bookkeepingService() {
      return this.allServices.find(
        (service) => service.name == ServiceNames.BOOKKEEPING
      );
    },
    appointmentLinks() {
      return [
        {
          name: this.$t("dashboard.make-an-appointment.bookkeeping-department"),
          url: "https://calendly.com/nara-bookkeeping",
          color: this.bookkeepingService?.color,
          listIcon: this.bookkeepingService?.listIcon,
        },
        {
          name: this.$t("dashboard.make-an-appointment.tax-department"),
          url: "https://calendly.com/oyuka/tax-prep-appointment",
          color: this.taxService?.color,
          listIcon: this.taxService?.listIcon,
        },
      ];
    },
  },
  watch: {
    "$route.query": {
      handler() {
        this.selectService();
      },
      deep: true,
    },
  },
  methods: {
    fetchAllServices() {
      return window.API.fetchAllServices({ includeIcons: true });
    },
    fetchClientAllServices(clientId) {
      return window.API.fetchClientAllServices(clientId, {
        includeIcons: true,
      });
    },
    getMainTagGroupTagsByServiceId(serviceId) {
      this.loading.tags = true;
      window.API.getMainTagGroupTagsByServiceId(serviceId, true)
        .then((response) => {
          this.tags = response;
        })
        .finally(() => {
          this.loading.tags = false;
        });
    },
    async selectService() {
      this.tags = null;

      if (this.$route.query && this.$route.query.service) {
        this.selectedService = this.services.find(
          (service) => service.id == this.$route.query.service
        );

        if (this.selectedService) {
          await this.getMainTagGroupTagsByServiceId(this.selectedService.id);
          this.items[1].text = this.selectedService.displayName;

          return;
        }

        this.$router.push("/");
      }

      this.selectedService = null;
    },
    selectTag(tag = null) {
      this.selectedTag = tag;
      this.$router.push({
        name: "ReceivedDocs",
        params: {
          serviceId: this.selectedService.id,
          tagId: this.selectedTag ? [this.selectedTag.id] : null,
        },
      });
    },
  },
  async mounted() {
    try {
      this.loading.services = true;
      const _services = await this.fetchAllServices();
      this.allServices = _services;

      if (authorizationUtils.isClient()) {
        if (this.myCurrentClient) {
          this.services = await this.fetchClientAllServices(
            this.myCurrentClient.id
          );
        }
      } else {
        this.services = _services;
      }

      await this.selectService();
    } finally {
      this.loading.services = false;
    }
  },
};
</script>
