<template>
  <div class="border-bottom pb-2">
    <v-row>
      <v-col cols="12">
        <div class="pt-6">
          <h4 class="subtitle-2 font-weight-medium black--text">
            {{ $t("companies.details.social-security.section-title") }}
          </h4>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="py-3">
          <v-row>
            <v-col md="4">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.social-security.ssn") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(company.socialSecurityNumber) }}
              </span>
            </v-col>
            <v-col md="4">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.social-security.first-name") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(company.socialSecurityFirstName) }}
              </span>
            </v-col>
            <v-col md="4">
              <span class="subtitle-2 font-weight-medium grey--text"
                >{{ $t("companies.details.social-security.last-name") }}:
              </span>
              <span
                class="ml-4 body-2 font-weight-regular grey--text text--darken-3"
                >{{ fillEmptyData(company.socialSecurityLastName) }}
              </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      require: true,
    },
  },
};
</script>
