import fileDownload from "js-file-download";
import { getFilenameFromContentDispositionHeader } from "@/utils/getFilenameFromContentDispositionHeader";

export default {
  methods: {
    isEqualObjects(source, target) {
      const sourceKeys = Object.keys(source);
      const targetKeys = Object.keys(target);

      if (sourceKeys.length !== targetKeys.length) {
        return false;
      }

      for (let key of sourceKeys) {
        if (source[key] !== target[key]) {
          return false;
        }
      }

      return true;
    },
    isObjectEmpty(obj) {
      if (!obj) return true;
      return (
        Object.keys(obj).filter((key) => !!obj[key]).length === 0 &&
        obj.constructor === Object
      );
    },
    showFetchRequestErrorMessage() {
      this.$eventBus.$emit("notification", {
        type: "error",
        message: this.$t("defaults.alert.could-not-fetch"),
      });
    },
    fillEmptyData(val) {
      return val ? val : "—";
    },
    UTCToLocalTime(date, format) {
      if (!date) return;
      return this.$moment.utc(date).local().format(format);
    },
    formatPrice(val) {
      if (val && !Number.isInteger(val)) {
        return Number(val).toFixed(2);
      }

      return val;
    },
    previewFile(response) {
      if (
        response.data.type.startsWith("image/") ||
        response.data.type == "application/pdf"
      ) {
        const objectUrl = window.URL.createObjectURL(response.data);
        window.open(objectUrl);

        return;
      }

      const fileName = getFilenameFromContentDispositionHeader(
        response.headers["content-disposition"]
      );
      fileDownload(response.data, `${fileName}`);
    },
    allowOnlyNumbers: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
