<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="mb-4">
            <v-text-field
              class="d-required"
              :label="$t('onboarding.general-info.form.company-legal-name')"
              hide-details="auto"
              filled
              type="text"
              v-model="form.legalCompanyName"
              :rules="rules.legalCompanyName"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="mb-4">
              <h4 class="subtitle-1 font-weight-medium">
                {{ $t("onboarding.general-info.form.contact-section-heading") }}
              </h4>
            </div>

            <v-row>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="$t('onboarding.general-info.form.contact-first-name')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.contactFirstName"
                  :rules="rules.contactFirstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="$t('onboarding.general-info.form.contact-last-name')"
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.contactLastName"
                  :rules="rules.contactLastName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="
                    $t('onboarding.general-info.form.contact-email-address')
                  "
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.contactEmailAddress"
                  :rules="rules.contactEmailAddress"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  class="d-required"
                  :label="
                    $t('onboarding.general-info.form.contact-phone-number')
                  "
                  hide-details="auto"
                  filled
                  type="text"
                  v-model="form.contactPhoneNumber"
                  :rules="rules.contactPhoneNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-autocomplete
                  class="d-required"
                  :label="$t('onboarding.general-info.form.contact-position')"
                  filled
                  :items="contactPositions"
                  item-text="displayText"
                  item-value="value"
                  hide-details="auto"
                  clearable
                  v-model="form.contactPosition"
                  :rules="rules.contactPosition"
                ></v-autocomplete>
              </v-col>
              <template
                v-if="
                  form.contactPosition &&
                  ContactPositions.OWNER != form.contactPosition
                "
              >
                <v-col cols="12" class="mb-4">
                  <v-text-field
                    class="d-required"
                    :label="$t('onboarding.general-info.form.owner-first-name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.ownerFirstName"
                    :rules="rules.ownerFirstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mb-4">
                  <v-text-field
                    class="d-required"
                    :label="$t('onboarding.general-info.form.owner-last-name')"
                    hide-details="auto"
                    filled
                    type="text"
                    v-model="form.ownerLastName"
                    :rules="rules.ownerLastName"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-input v-if="!editMode" :error-messages="errorMessages.captcha">
              <VueRecaptcha
                @verify="handleCaptchaVerified"
                @expired="handleCaptchaExpired"
                :sitekey="recaptchaSitekey"
                ref="recaptcha"
              />
            </v-input>

            <div class="d-flex align-center justify-end">
              <v-btn
                type="submit"
                color="success"
                class="text-none body-2 font-weight-medium"
                depressed
                :loading="isSubmitting"
              >
                {{ $t("onboarding.step-actions.next") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ContactPositions from "@/shared/enums/ContactPositions";
import { VueRecaptcha } from "vue-recaptcha";
const initialErrorMessages = {
  captcha: [],
};

const initialForm = {
  legalCompanyName: null,
  contactFirstName: null,
  contactLastName: null,
  contactEmailAddress: null,
  contactPhoneNumber: null,
  contactPosition: null,
  ownerFirstName: null,
  ownerLastName: null,
};

export default {
  data() {
    return {
      form: { ...initialForm },
      isFormValid: true,
      loading: false,
      rules: {
        legalCompanyName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactLastName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactEmailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("onboarding.general-info.rule.email-address.invalid"),
        ],
        contactPhoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        contactPosition: [(v) => !!v || this.$t("defaults.rule.required")],
        ownerFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        ownerLastName: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      contactPositions: [],
      ContactPositions,
      errorMessages: { ...initialErrorMessages },
      googleRecaptchaToken: null,
      recaptchaSitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    };
  },
  components: { VueRecaptcha },
  props: {
    isSubmitting: Boolean,
    formData: Object,
    editMode: Boolean,
  },
  methods: {
    async submitStep() {
      if (!this.editMode && !this.googleRecaptchaToken) {
        await this.handleCaptchaVerified();
      }

      const isFormValid = this.$refs.form.validate();

      if (isFormValid && this.errorMessages.captcha.length === 0) {
        if (!this.editMode) {
          this.form.googleRecaptchaToken = this.googleRecaptchaToken;
        }

        this.$emit("submitStep", this.form);
      }
    },
    async getAllContactPositions() {
      const response = await window.API.getAllContactPositions();
      this.contactPositions = response.map((item) => {
        return {
          displayText: item.displayText,
          value: +item.value,
        };
      });
    },
    handleCaptchaVerified(response) {
      if (!response) {
        this.handleCaptchaExpired();
        return;
      }

      this.googleRecaptchaToken = response;
      this.errorMessages.captcha = [];
    },
    handleCaptchaExpired() {
      this.googleRecaptchaToken = null;
      this.errorMessages.captcha = [this.$t("defaults.rule.required")];
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getAllContactPositions();

      if (this.editMode) {
        this.form = {
          legalCompanyName: this.formData.legalCompanyName,
          contactFirstName: this.formData.contactFirstName,
          contactLastName: this.formData.contactLastName,
          contactEmailAddress: this.formData.contactEmailAddress,
          contactPhoneNumber: this.formData.contactPhoneNumber,
          contactPosition: this.formData.contactPosition,
          ownerFirstName: this.formData.ownerFirstName,
          ownerLastName: this.formData.ownerLastName,
        };
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>
