const isRedirectedFromLogin = JSON.parse(
  localStorage.getItem(process.env.VUE_APP_IS_REDIRECTED_FROM_LOGIN)
);

const state = {
  navigationDrawer: true,
  isRedirectedFromLogin,
};

const getters = {
  getNavigationDrawer: (state) => state.navigationDrawer,
  isRedirectedFromLogin: (state) => state.isRedirectedFromLogin,
};

const actions = {
  setNavigationDrawer({ commit }) {
    commit("SET_NAVIGATION_DRAWER");
  },
  setIsRedirectedFromLogin({ commit }, isRedirectedFromLogin) {
    commit("SET_IS_REDIRECTED_FROM_LOGIN", isRedirectedFromLogin);
  },
};

const mutations = {
  SET_NAVIGATION_DRAWER(state) {
    state.navigationDrawer = !state.navigationDrawer;
  },
  SET_IS_REDIRECTED_FROM_LOGIN(state, isRedirectedFromLogin) {
    state.isRedirectedFromLogin = isRedirectedFromLogin;
    localStorage.setItem(
      process.env.VUE_APP_IS_REDIRECTED_FROM_LOGIN,
      JSON.stringify(isRedirectedFromLogin)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
