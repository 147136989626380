import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import authorizationUtils from "@/utils/authorization.js";
import ClientTypes from "@/shared/enums/ClientTypes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isRequireAuth = to.matched.some((record) => record.meta.requiresAuth);
  const permission = to.meta.permission;
  const isSigned = store.getters["Auth/isLoggedIn"];
  const myCurrentClient = store.getters["Auth/getMyCurrentClient"];
  const isLeadOnboardingCompleted =
    store.getters["Auth/isLeadOnboardingCompleted"];

  function canAccess1099Form() {
    return (
      authorizationUtils.isGranted("contractor:list") &&
      (!authorizationUtils.isClient() ||
        (myCurrentClient && myCurrentClient.clientType == ClientTypes.BUSINESS))
    );
  }

  function canAccessReconciliations() {
    return (
      authorizationUtils.isGranted("reconciliation:list") &&
      (!authorizationUtils.isClient() ||
        (myCurrentClient &&
          myCurrentClient.clientType == ClientTypes.BUSINESS &&
          myCurrentClient.usedBookkeepingServiceAtLeastOnce))
    );
  }

  if (
    from.name === "Login" ||
    from.name === "Register" ||
    from.name === "ContinueCustomerOnboarding"
  ) {
    store.dispatch("Common/setIsRedirectedFromLogin", true);
  }

  if (!to.name) {
    return next("/");
  }

  if (isRequireAuth && !isSigned) {
    return next({
      path: "/login",
      query: {
        redirect: to.path,
      },
    });
  }

  if (isSigned && to.name === "Login") {
    return next("/");
  }

  if (isRequireAuth && isSigned) {
    if (permission && !authorizationUtils.isGranted(permission)) {
      return next("/");
    }

    if (to.name === "1099Form" && !canAccess1099Form()) {
      return next("/");
    }

    if (
      (to.name === "Reconciliations" ||
        to.name === "ReconciliationTransactions") &&
      !canAccessReconciliations()
    ) {
      return next("/");
    }

    if (
      authorizationUtils.isClient() &&
      isLeadOnboardingCompleted === false &&
      to.name !== "Dashboard"
    ) {
      return next("/");
    }
  }

  next();
});

export default router;
