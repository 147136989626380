<template>
  <d-modal
    :title="$t('documents.edit.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.document"></d-loading>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
        v-if="!loading.document"
      >
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              filled
              hide-details="auto"
              :label="$t('documents.edit.form.displayName')"
              v-model="form.displayName"
              :rules="rules.displayName"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="mb-3"
            v-if="!authorizationUtils.isClient()"
          >
            <v-autocomplete
              filled
              hide-details="auto"
              :label="$t('documents.edit.form.client')"
              v-model="form.clientId"
              :items="clients"
              :rules="rules.client"
              item-text="name"
              item-value="id"
              @change="clientChanged"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            :md="authorizationUtils.isClient() ? 12 : 6"
            class="mb-3"
          >
            <v-autocomplete
              filled
              hide-details="auto"
              :label="$t('documents.edit.form.service')"
              v-model="form.serviceId"
              :items="services"
              :rules="rules.service"
              item-text="displayName"
              item-value="id"
              :loading="loading.services"
              clearable
              @change="serviceChanged"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :class="{ 'mb-3': form.serviceId }">
            <BaseTagsSelect
              :title="$t('documents.edit.form.tags')"
              :serviceItDepends="form.serviceId"
              :initialValues="initalTagValues"
              v-model="form.tags"
            />
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-textarea
              rows="1"
              filled
              hide-details="auto"
              :label="$t('documents.edit.form.description')"
              v-model="form.description"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import BaseTagsSelect from "@/components/BaseTagsSelect.vue";
import authorizationUtils from "@/utils/authorization.js";
import { mapGetters } from "vuex";
import DocumentTypes from "@/shared/enums/DocumentTypes";

const initialForm = {
  displayName: null,
  clientId: null,
  serviceId: null,
  tags: null,
  description: null,
};
export default {
  components: { BaseTagsSelect },
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      default: () => DocumentTypes.MY_UPLOAD,
    },
  },
  computed: {
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
    }),
  },
  data() {
    return {
      form: { ...initialForm },
      rules: {
        displayName: [(v) => !!v || this.$t("defaults.rule.required")],
        client: [(v) => !!v || this.$t("defaults.rule.required")],
        service: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      isSubmitting: false,
      clients: [],
      services: [],
      isFormValid: false,
      loading: {
        document: false,
        services: false,
      },
      authorizationUtils,
      initalTagValues: [],
      DocumentTypes,
    };
  },
  methods: {
    async handleSubmitModal() {
      if (this.$refs.form.validate() && this.isFormValid) {
        await this.updateDocument();
      }
    },
    handleCloseModal() {
      this.$emit("closeModal");
      this.form = { ...initialForm };
      this.$refs.form.reset();
    },
    getClientActiveServices(id) {
      this.loading.services = true;
      this.services = [];
      window.API.getClientActiveServices(id)
        .then((response) => {
          this.services = response;
        })
        .finally(() => (this.loading.services = false));
    },
    clientChanged() {
      this.form.serviceId = null;
      this.getClientActiveServices(this.form.clientId);
    },
    serviceChanged() {
      this.initalTagValues = [];
    },
    async fetchAllActiveClients() {
      this.clients = await window.API.fetchAllActiveClients();
    },
    async updateDocument() {
      this.isSubmitting = true;

      (this.type === this.DocumentTypes.RECEIVED
        ? window.API.editRecievedDocument(this.item.id, this.form)
        : window.API.editMyUploadDocument(this.item.id, this.form)
      )
        .then(async () => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("documents.edit.alert.updated"),
          });

          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("documents.edit.alert.could-not-update"),
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    getDocument(id) {
      this.loading.document = true;
      this.services = [];
      window.API.getDocumentById(id)
        .then(async ({ clientId, tags, ...rest }) => {
          await this.getClientActiveServices(clientId);
          this.initalTagValues = tags;
          this.form = {
            ...rest,
            clientId: clientId,
            tags: tags.map((item) => item.id),
          };
        })
        .finally(() => (this.loading.document = false));
    },
  },
  mounted() {
    this.getDocument(this.item.id);
    if (authorizationUtils.isClient()) {
      this.getClientActiveServices(this.myCurrentClient.id);
    } else {
      this.fetchAllActiveClients();
    }
  },
};
</script>
