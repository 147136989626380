export function fetchTagGroups({ sortedBy, sortedDesc }) {
  return window.axios
    .get("/document/tag-groups", {
      params: {
        sortedBy,
        sortedDesc,
      },
    })
    .then((response) => response.data);
}

export function fetchAllTagGroups() {
  return window.axios
    .get("/document/tag-groups/all")
    .then((response) => response.data);
}

export function reorderTagGroups(data) {
  return window.axios
    .post("/document/tag-groups/reorder", data)
    .then((response) => response.data);
}

export function getTagGroupById(id) {
  return window.axios
    .get(`/document/tag-groups/${id}`)
    .then((response) => response.data);
}

export function insertTagGroup(data) {
  return window.axios
    .post("/document/tag-groups", data)
    .then((response) => response.data);
}

export function editTagGroup(id, data) {
  return window.axios
    .put(`/document/tag-groups/${id}`, data)
    .then((response) => response.data);
}

export function checkTagGroupNameAvailability(name) {
  return window.axios
    .get(`document/tag-groups/name/${name}/is-available`)
    .then((response) => response.data);
}

export function checkTagGroupDisplayNameAvailability(displayName) {
  return window.axios
    .get(`document/tag-groups/display-name/${displayName}/is-available`)
    .then((response) => response.data);
}

export function deleteTagGroup(id) {
  return window.axios
    .delete(`/document/tag-groups/${id}`)
    .then((response) => response);
}
