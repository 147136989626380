<template>
  <div class="d-comments pa-2">
    <d-loading v-if="loading"></d-loading>

    <div
      class="d-comments__top d-flex"
      :class="{ 'align-center justify-center': comments.length == 0 }"
    >
      <d-no-comment v-if="comments.length == 0 && !loading"></d-no-comment>

      <template v-if="comments.length > 0 && !loading">
        <div class="pr-1 w-100">
          <v-row no-gutters>
            <v-col
              cols="12"
              v-for="(comment, index) in comments"
              :key="index"
              class="my-2"
            >
              <div
                class="comment"
                v-if="loggedInUser.id != comment.creatorUser.id"
              >
                <div
                  class="body-2 gray--text text--darken-3 pt-3 pb-3 pr-4 pl-4 rounded-lg rounded-bl-0 gray lighten-3"
                >
                  {{ comment.commentText }}
                </div>
                <div class="d-flex mt-2 align-center justify-start">
                  <v-avatar color="gray lighten-1" size="28">
                    <d-auth-image
                      :url="`/identity/users/${comment.creatorUser.id}/profile-picture`"
                      :width="28"
                      :height="28"
                      :id="comment.creatorUser.id"
                      @error="handleImageError"
                    ></d-auth-image>
                    <span
                      class="body-2 gray--text text--lighten-4"
                      v-if="imageError.includes(comment.creatorUser.id)"
                      >{{ profileAvatar(comment.creatorUser) }}</span
                    >
                  </v-avatar>
                  <div class="ml-3">
                    <span
                      class="d-block body-2 gray--text text--darken-3 font-weight-medium"
                    >
                      {{ comment.creatorUser.fullName }}
                    </span>
                    <span class="d-block caption grey--text">
                      {{
                        UTCToLocalTime(
                          comment.creationTime,
                          "MM.DD.YYYY, hh:mm A"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="comment" v-else>
                <div
                  class="body-2 gray--text text--darken-3 pt-3 pb-3 pr-4 pl-4 rounded-lg rounded-br-0 light-blue lighten-5"
                >
                  {{ comment.commentText }}
                </div>
                <div class="d-flex mt-2 align-center justify-end">
                  <div class="mr-3">
                    <span
                      class="d-block body-2 gray--text text--darken-3 font-weight-medium"
                    >
                      {{ comment.creatorUser.fullName }}
                    </span>
                    <span class="d-block caption grey--text">
                      {{
                        UTCToLocalTime(
                          comment.creationTime,
                          "MM.DD.YYYY, hh:mm A"
                        )
                      }}
                    </span>
                  </div>
                  <v-avatar color="gray lighten-1" size="28">
                    <d-auth-image
                      :url="`/identity/users/me/profile-picture`"
                      :width="28"
                      :height="28"
                      :id="comment.creatorUser.id"
                      @error="handleImageError"
                    ></d-auth-image>
                    <span
                      class="body-2 gray--text text--lighten-4"
                      v-if="imageError.includes(comment.creatorUser.id)"
                      >{{ profileAvatar(comment.creatorUser) }}</span
                    >
                  </v-avatar>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
    <div class="d-comments__bottom">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitForm">
        <v-row>
          <v-col cols="12" class="mb-2">
            <v-textarea
              outlined
              no-resize
              rows="2"
              :label="this.$t('documents.comments.label')"
              v-model.trim="form.commentText"
              :rules="rules.commentText"
              hide-details="auto"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-btn
                type="submit"
                depressed
                color="primary"
                class="text-none body-2 font-weight-medium"
                :loading="isSubmitting"
                >Send</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import NoCommentSection from "@/components/BaseNoCommentSection.vue";
import { mapGetters } from "vuex";

const initialForm = {
  documentId: null,
  commentText: null,
};

export default {
  components: {
    "d-no-comment": NoCommentSection,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      comments: [],
      imageError: [],
      form: { ...initialForm },
      isSubmitting: false,
      currentDocumentId: null,
      rules: {
        commentText: [
          (v) => (!!v && !!v.trim()) || this.$t("defaults.rule.required"),
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "Auth/getLoggedInUser",
    }),
  },
  watch: {
    item: {
      handler(val) {
        this.fetchComments(val.id);
        this.currentDocumentId = val.id;
        this.form = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchComments(id) {
      this.loading = true;
      window.API.fetchDocumentComments(id)
        .then((response) => {
          this.comments = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
    async handleSubmitForm() {
      if (!this.$refs.form.validate()) return;
      this.isSubmitting = true;
      this.form.documentId = this.currentDocumentId;

      window.API.insertDocumentComment(this.form.documentId, this.form)
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t(
              "documents.comments.create.alert.could-not-create"
            ),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    handleImageError(error) {
      if (!this.imageError.includes(error)) {
        this.imageError.push(error);
      }
    },
    profileAvatar(item) {
      return (
        item.firstName.charAt(0).toUpperCase() +
        item.lastName.charAt(0).toUpperCase()
      );
    },
    close() {
      this.fetchComments(this.form.documentId);
      this.form = {};
      this.$refs.form.reset();
      this.item.numberOfComments = this.comments.length + 1;
    },
  },
};
</script>

<style lang="scss">
.d-comments {
  height: calc(100vh - 109px);

  &__top {
    height: calc(100% - 130px);

    overflow: auto;
  }

  &__bottom {
    padding-top: 2px;
    height: 130px;
  }
}
</style>
