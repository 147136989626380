var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitStep.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"onboarding-modal--body px-4"},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"8"}},[_c('v-file-input',{staticClass:"d-required",attrs:{"hide-details":"auto","filled":"","label":_vm.$t('onboarding.document-uploads.form.tax-return-attachment'),"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","rules":_vm.form.uploadTaxReturnAttachmentLater
              ? []
              : _vm.rules.taxReturnAttachment},model:{value:(_vm.form.taxReturnAttachment),callback:function ($$v) {_vm.$set(_vm.form, "taxReturnAttachment", $$v)},expression:"form.taxReturnAttachment"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('onboarding.document-uploads.form.upload-later'),"filled":"","hide-details":"auto"},model:{value:(_vm.form.uploadTaxReturnAttachmentLater),callback:function ($$v) {_vm.$set(_vm.form, "uploadTaxReturnAttachmentLater", $$v)},expression:"form.uploadTaxReturnAttachmentLater"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"8"}},[_c('v-file-input',{staticClass:"d-required",attrs:{"hide-details":"auto","filled":"","label":_vm.$t(
              'onboarding.document-uploads.form.employer-identification-number-attachment'
            ),"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","rules":_vm.form.uploadEmployerIdentificationNumberAttachmentLater
              ? []
              : _vm.rules.employerIdentificationNumberAttachment},model:{value:(_vm.form.employerIdentificationNumberAttachment),callback:function ($$v) {_vm.$set(_vm.form, "employerIdentificationNumberAttachment", $$v)},expression:"form.employerIdentificationNumberAttachment"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('onboarding.document-uploads.form.upload-later'),"filled":"","hide-details":"auto"},model:{value:(_vm.form.uploadEmployerIdentificationNumberAttachmentLater),callback:function ($$v) {_vm.$set(_vm.form, "uploadEmployerIdentificationNumberAttachmentLater", $$v)},expression:"form.uploadEmployerIdentificationNumberAttachmentLater"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"8"}},[_c('v-file-input',{staticClass:"d-required",attrs:{"hide-details":"auto","filled":"","label":_vm.$t(
              'onboarding.document-uploads.form.article-of-incorporation-attachment'
            ),"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","rules":_vm.form.uploadArticleOfIncorporationAttachmentLater
              ? []
              : _vm.rules.articleOfIncorporationAttachment},model:{value:(_vm.form.articleOfIncorporationAttachment),callback:function ($$v) {_vm.$set(_vm.form, "articleOfIncorporationAttachment", $$v)},expression:"form.articleOfIncorporationAttachment"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('onboarding.document-uploads.form.upload-later'),"filled":"","hide-details":"auto"},model:{value:(_vm.form.uploadArticleOfIncorporationAttachmentLater),callback:function ($$v) {_vm.$set(_vm.form, "uploadArticleOfIncorporationAttachmentLater", $$v)},expression:"form.uploadArticleOfIncorporationAttachmentLater"}})],1)],1)],1),_c('div',{staticClass:"onboarding--modal--footer"},[_c('div',{staticClass:"border-top py-3 px-4"},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"text-none body-2 font-weight-medium mr-2",attrs:{"type":"button","depressed":"","loading":_vm.isSubmitting},on:{"click":_vm.previousStep}},[_vm._v(" "+_vm._s(_vm.$t("onboarding.step-actions.previous"))+" ")]),_c('v-btn',{staticClass:"text-none body-2 font-weight-medium",attrs:{"type":"submit","color":"success","depressed":"","loading":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.$t("onboarding.step-actions.submit"))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }