<template>
  <d-loading v-if="loading"></d-loading>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
    v-else
  >
    <v-row>
      <v-col cols="12">
        <div class="pb-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{
              $t("company-formation.non-resident.step-2.form.contact-details")
            }}
          </h4>
        </div>

        <v-row>
          <v-col md="6" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.non-resident.step-2.form.contact-first-name'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactFirstName"
              :rules="rules.contactFirstName"
            ></v-text-field>
          </v-col>
          <v-col md="6" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.non-resident.step-2.form.contact-last-name'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactLastName"
              :rules="rules.contactLastName"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.non-resident.step-2.form.contact-email-address'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactEmailAddress"
              :rules="rules.contactEmailAddress"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t(
                  'company-formation.non-resident.step-2.form.contact-phone-number'
                )
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactPhoneNumber"
              :rules="rules.contactPhoneNumber"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="mb-4">
            <v-text-field
              :label="
                $t('company-formation.non-resident.step-2.form.contact-fax')
              "
              hide-details="auto"
              filled
              type="text"
              v-model="form.contactFax"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mb-4">
        <div class="py-3 border-bottom mb-4">
          <h4 class="subtitle-1 font-weight-medium">
            {{
              $t(
                "company-formation.non-resident.step-2.form.business-address.section-heading"
              )
            }}
          </h4>
        </div>

        <div class="mb-4">
          <v-radio-group
            row
            filled
            hide-details="auto"
            class="mt-0"
            v-model="form.businessAddressType"
          >
            <v-radio
              :label="
                $t(
                  'company-formation.non-resident.step-2.business-address-types.predefined-address'
                )
              "
              :value="BusinessAddressTypes.PREDEFINED_ADDRESS"
            ></v-radio>
            <v-radio
              :label="
                $t(
                  'company-formation.non-resident.step-2.business-address-types.new-address'
                )
              "
              :value="BusinessAddressTypes.NEW_ADDRESS"
            ></v-radio>
          </v-radio-group>
        </div>

        <template
          v-if="form.businessAddressType == BusinessAddressTypes.NEW_ADDRESS"
        >
          <v-row>
            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.address-line-1'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.addressLine1"
                :rules="rules.businessAddressAddressLine1"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.address-line-2'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.addressLine2"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.city'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.city"
                :rules="rules.businessAddressCity"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.zip-code'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.zipCode"
                :rules="rules.businessAddressZipCode"
                v-mask="'#####'"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.email-address'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.emailAddress"
                :rules="rules.businessAddressEmailAddress"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="mb-4">
              <v-text-field
                :label="
                  $t(
                    'company-formation.non-resident.step-2.form.business-address.phone-number'
                  )
                "
                hide-details="auto"
                filled
                type="text"
                v-model="businessAddress.phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-end">
          <v-btn
            @click="cancelStep"
            type="button"
            class="text-none body-2 font-weight-medium mr-2"
            depressed
          >
            {{ $t("company-formation.non-resident.step-2.actions.cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
          >
            {{ $t("company-formation.non-resident.step-2.actions.submit") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BusinessAddressTypes from "@/shared/enums/BusinessAddressTypes";

const initialForm = {
  contactFirstName: null,
  contactLastName: null,
  contactEmailAddress: null,
  contactPhoneNumber: null,
  contactFax: null,
  businessAddress: null,
  businessAddressType: BusinessAddressTypes.PREDEFINED_ADDRESS,
};

const initialBusinessAddress = {
  addressLine1: null,
  addressLine2: null,
  city: null,
  zipCode: null,
  emailAddress: null,
  phoneNumber: null,
};

export default {
  data() {
    return {
      BusinessAddressTypes,
      loading: false,
      form: { ...initialForm },
      businessAddress: { ...initialBusinessAddress },
      isFormValid: true,
      rules: {
        contactFirstName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactLastName: [(v) => !!v || this.$t("defaults.rule.required")],
        contactEmailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t(
              "company-formation.non-resident.step-2.rule.email-address.invalid"
            ),
        ],
        contactPhoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        businessAddressAddressLine1: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        businessAddressCity: [(v) => !!v || this.$t("defaults.rule.required")],
        businessAddressZipCode: [
          (v) => {
            if (!v) return this.$t("defaults.rule.required");

            if (v && /\b\d{5}\b/g.test(v)) return true;
            return this.$t(
              "company-formation.non-resident.step-2.rule.zip-code.invalid"
            );
          },
        ],
        businessAddressEmailAddress: [
          (v) => {
            if (!v) return true;

            if (v && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v)) return true;

            return this.$t(
              "company-formation.non-resident.step-2.rule.email-address.invalid"
            );
          },
        ],
      },
    };
  },
  watch: {
    businessAddress: {
      handler() {
        this.form.businessAddress = this.businessAddress;
      },
      deep: true,
    },
    form: {
      handler() {
        this.$emit("formUpdated", this.form);
      },
      deep: true,
    },
    "form.businessAddressType": {
      handler() {
        if (
          this.form.businessAddressType ==
          BusinessAddressTypes.PREDEFINED_ADDRESS
        ) {
          this.form.businessAddress = null;
          this.businessAddress = { ...initialBusinessAddress };
        }

        if (this.form.businessAddressType == BusinessAddressTypes.NEW_ADDRESS) {
          this.form.businessAddress = this.businessAddress;
        }
      },
    },
  },
  methods: {
    submitStep() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitStep");
      }
    },
    cancelStep() {
      this.$emit("cancelStep");
    },
  },
};
</script>
