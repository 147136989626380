<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.contactDetails`]="{ item }">
        <div class="d-flex flex-column">
          <span>{{ item.contactFullName }}</span>
          <span>{{ item.contactEmailAddress }}</span>
          <span>{{ item.contactPhoneNumber }}</span>
        </div>
      </template>
      <template v-slot:[`item.isResident`]="{ item }">
        <v-chip small :color="getCompanyType(item.isResident).color" outlined
          >{{ getCompanyType(item.isResident).text }}
        </v-chip>
      </template>
      <template v-slot:[`item.registrationPayment`]="{ item }">
        <v-chip
          v-if="item.registrationPayment.paymentStatus"
          small
          :color="
            getPaymentStatus(item.registrationPayment.paymentStatus.id).bgColor
          "
        >
          <span
            :class="`${
              getPaymentStatus(item.registrationPayment.paymentStatus.id).color
            }--text`"
          >
            {{ item.registrationPayment.paymentStatus.name }}
          </span>
        </v-chip>
      </template>
      <template v-slot:[`item.creationTime`]="{ item }">
        <span class="caption black--text">
          {{ item.creationTime | moment("MM/DD/YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'company:view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                small
                icon
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'CompanyDetails', params: { id: item.id } }"
              >
                <v-icon size="18"> mdi-information-outline </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("companies.list.table.actions.view") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :text="$t('companies.list.empty-page')"
      :hasCreateButton="false"
      v-if="table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import PaymentStatuses from "@/shared/enums/PaymentStatuses";

export default {
  mixins: [tableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("companies.list.table.headers.legal-name"),
          value: "legalName",
          sortable: false,
        },
        {
          text: vm.$t("companies.list.table.headers.business-entity-type"),
          value: "businessEntityType",
          sortable: false,
        },
        {
          text: vm.$t("companies.list.table.headers.contact-details"),
          value: "contactDetails",
          sortable: false,
        },
        {
          text: vm.$t("companies.list.table.headers.company-type"),
          value: "isResident",
          sortable: false,
        },
        {
          text: vm.$t("companies.list.table.headers.payment-status"),
          value: "registrationPayment",
          sortable: false,
        },
        {
          text: vm.$t("companies.list.table.headers.creation-time"),
          value: "creationTime",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  methods: {
    getCompanyType(isResident) {
      if (isResident) {
        return {
          color: "success",
          text: this.$t("companies.company-type.resident"),
        };
      }

      return {
        color: "primary",
        text: this.$t("companies.company-type.non-resident"),
      };
    },
    getPaymentStatus(status) {
      switch (status) {
        case PaymentStatuses.PAID:
          return { bgColor: "light-green lighten-5", color: "green" };
        case PaymentStatuses.PENDING:
          return { bgColor: "orange lighten-5", color: "warning" };
        case PaymentStatuses.FAILED:
          return { bgColor: "red lighten-5", color: "red" };
        case PaymentStatuses.CANCELED:
          return { bgColor: "red lighten-5", color: "red" };
        default:
          return null;
      }
    },
    handleFetchItems() {
      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;
      window.API.fetchCompanies({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>
