<template>
  <d-modal
    :title="$t('clients.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="prepend-title">
      <div class="mr-2">
        <v-tooltip right color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              {{ isBusiness ? "mdi-briefcase" : "mdi-account" }}
            </v-icon>
          </template>
          <span>
            {{
              isBusiness
                ? $t("clients.client-type.business")
                : $t("clients.client-type.personal")
            }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <template v-if="isBusiness">
            <v-col cols="12" class="mb-3">
              <v-text-field
                :label="$t('clients.create.form.name')"
                v-model.trim="form.name"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
          </template>
          <template v-if="isPersonal">
            <v-col cols="12" sm="6" md="6" class="mb-3">
              <v-text-field
                :label="$t('clients.create.form.first-name')"
                v-model.trim="form.firstName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mb-3">
              <v-text-field
                :label="$t('clients.create.form.last-name')"
                v-model.trim="form.lastName"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.lastName"
              ></v-text-field>
            </v-col>
          </template>
          <v-col cols="12">
            <v-autocomplete
              filled
              v-model="form.ownerUserId"
              :items="clientUsers"
              item-text="displayText"
              item-value="value"
              hide-details="auto"
              clearable
              :loading="loading.clientUsers"
              :search-input.sync="search.clientUsers"
              :placeholder="$t('defaults.remote-dropdown-placeholder')"
              hide-no-data
              :label="$t('clients.create.form.owner')"
              :disabled="isNewUser"
              :rules="isNewUser ? [] : rules.ownerUser"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :class="isNewUser ? 'mb-4' : ''">
            <v-checkbox
              v-model="isNewUser"
              :label="$t('clients.create.form.create-new-user')"
              filled
              hide-details="auto"
            ></v-checkbox>
          </v-col>

          <template v-if="isNewUser">
            <template v-if="isBusiness">
              <v-col cols="12" sm="6" md="6" class="mb-3">
                <v-text-field
                  :label="$t('clients.create.form.owner-user.first-name')"
                  v-model.trim="form.ownerUser.firstName"
                  hide-details="auto"
                  filled
                  type="text"
                  :rules="rules.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mb-3">
                <v-text-field
                  :label="$t('clients.create.form.owner-user.last-name')"
                  v-model.trim="form.ownerUser.lastName"
                  hide-details="auto"
                  filled
                  type="text"
                  :rules="rules.lastName"
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="12" sm="6" md="6" class="mb-3">
              <v-text-field
                :label="$t('clients.create.form.owner-user.email-address')"
                v-model.trim="form.ownerUser.emailAddress"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.emailAddress"
                :error="errorMessages.emailAddress.length > 0"
                :error-messages="errorMessages.emailAddress"
                @blur="checkEmailAddressAvailability"
                :loading="loading.emailAddress"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mb-3">
              <v-autocomplete
                :label="$t('clients.create.form.owner-user.advisor')"
                v-model="form.ownerUser.advisorUserId"
                :items="advisors"
                item-text="displayText"
                item-value="value"
                filled
                hide-details="auto"
                :rules="rules.advisorUser"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="mb-3">
              <v-text-field
                :label="$t('clients.create.form.owner-user.phone-number')"
                v-model.trim="form.ownerUser.phoneNumber"
                hide-details="auto"
                filled
                type="text"
                :rules="rules.phoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mb-3 mb-sm-0">
              <v-text-field
                :label="$t('clients.create.form.owner-user.password.label')"
                v-model.trim="form.ownerUser.password"
                hide-details="auto"
                filled
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="getPasswordInputIcon(isPasswordVisible)"
                @click:append="isPasswordVisible = !isPasswordVisible"
                :rules="rules.password"
                :hint="$t('clients.create.form.owner-user.password.hint')"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :label="$t('clients.create.form.owner-user.confirm-password')"
                v-model.trim="form.ownerUser.confirmPassword"
                hide-details="auto"
                filled
                :type="isConfirmPasswordVisible ? 'text' : 'password'"
                :append-icon="getPasswordInputIcon(isConfirmPasswordVisible)"
                @click:append="
                  isConfirmPasswordVisible = !isConfirmPasswordVisible
                "
                :rules="rules.confirmPassword"
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  clientType: null,
  name: null,
  firstName: null,
  lastName: null,
  ownerUserId: null,
  ownerUser: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    phoneNumber: null,
    advisorUserId: null,
    password: null,
    confirmPassword: null,
  },
};
const initialErrorMessages = {
  emailAddress: [],
};

import ClientTypes from "@/shared/enums/ClientTypes";
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    clientType: {
      type: Number || String,
      required: true,
      default: ClientTypes.PERSONAL,
    },
  },
  data() {
    return {
      ClientTypes,
      isNewUser: false,
      form: { ...initialForm },
      search: {
        clientUsers: null,
      },
      clientUsers: [],
      loading: {
        emailAddress: false,
        page: false,
        clientUsers: false,
      },
      errorMessages: { ...initialErrorMessages },
      isFormValid: false,
      isSubmitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        firstName: [(v) => !!v || this.$t("defaults.rule.required")],
        lastName: [(v) => !!v || this.$t("defaults.rule.required")],
        advisorUser: [(v) => !!v || this.$t("defaults.rule.required")],
        ownerUser: [(v) => !!v || this.$t("defaults.rule.required")],
        phoneNumber: [(v) => !!v || this.$t("defaults.rule.required")],
        emailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("clients.create.rule.email-address.invalid"),
        ],
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("clients.create.rule.password.invalid"),
        ],
        confirmPassword: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) => {
            if (!this.form.ownerUser.password) return true;

            if (v === this.form.ownerUser.password) return true;

            return this.$t("clients.create.rule.confirm-password.not-match");
          },
        ],
      },
      advisors: [],
      owners: [],
    };
  },
  computed: {
    isPersonal() {
      return this.clientType === ClientTypes.PERSONAL;
    },
    isBusiness() {
      return this.clientType === ClientTypes.BUSINESS;
    },
  },
  watch: {
    "search.clientUsers": {
      handler(val) {
        if (val && val.length > 2) {
          this.fetchClientUsers(val);
        } else {
          this.clientUsers = [];
        }
      },
    },
    isNewUser: {
      handler(val) {
        if (val) this.form.ownerUserId = null;
      },
    },
  },
  methods: {
    getPasswordInputIcon(isVisible) {
      return isVisible ? "mdi-eye-off" : "mdi-eye";
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.errorMessages = { ...initialErrorMessages };
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    createItem(newItem) {
      this.isSubmitting = true;

      window.API.insertClient(newItem)
        .then((id) => {
          if (id) {
            this.$router.push({
              name: "EditClient",
              params: {
                id: id,
                clientType: this.isBusiness ? "business" : "personal",
              },
            });
          }

          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.create.alert.created", {
              fullName: this.isPersonal
                ? `${newItem.firstName} ${newItem.lastName}`
                : `${newItem.name}`,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    insertClientUser(newUser) {
      this.isSubmitting = true;

      return window.API.insertClientUser(newUser)
        .then((response) => response)
        .finally(() => (this.isSubmitting = false));
    },
    fetchClientUsers(searchTerm) {
      this.loading.clientUsers = true;

      window.API.fetchClientUsers(searchTerm)
        .then((response) => {
          this.clientUsers = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading.clientUsers = false));
    },
    async handleSubmitModal() {
      if (this.isNewUser) {
        await this.checkEmailAddressAvailability();
      }

      if (this.$refs.form.validate() && this.isFormValid) {
        const newItem = { ...this.form };
        newItem.clientType = this.clientType;

        if (this.isNewUser) {
          newItem.ownerUser = { ...this.form.ownerUser };
          newItem.ownerUser.advisorUserId = +this.form.ownerUser.advisorUserId;

          if (this.isPersonal) {
            newItem.ownerUser.firstName = this.form.firstName;
            newItem.ownerUser.lastName = this.form.lastName;
          }

          await this.insertClientUser(newItem.ownerUser)
            .then((id) => {
              newItem.ownerUserId = id;
              this.createItem(newItem);
            })
            .catch(() => {
              this.$eventBus.$emit("notification", {
                type: "error",
                message: this.$t("clients.create.alert.could-not-create"),
              });
            });
        } else {
          newItem.ownerUserId = +this.form.ownerUserId;
          newItem.ownerUser = null;

          this.createItem(newItem);
        }
      }
    },
    async checkEmailAddressAvailability() {
      const emailAddress = this.form.ownerUser.emailAddress;

      if (!emailAddress) return;

      this.loading.emailAddress = true;
      window.API.checkEmailAddressAvailability(emailAddress)
        .then((response) => {
          if (!response) {
            this.errorMessages.emailAddress = [
              this.$t("clients.create.rule.email-address.is-taken", {
                emailAddress: emailAddress,
              }),
            ];
            return;
          }
          this.errorMessages.emailAddress = [];
        })
        .finally(() => (this.loading.emailAddress = false));
    },
    fetchAllAdvisors() {
      window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async mounted() {
    await this.fetchAllAdvisors();
  },
};
</script>
