<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-stepper elevation="0" v-model="currentStep" flat alt-labels>
          <v-stepper-items class="mt-3">
            <v-stepper-content step="1" class="ma-0 pa-0">
              <div class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <div class="text-h6 mb-2">
                      <span class="d-block mb-1">
                        {{
                          $t(
                            "tax-planning.questions.your-business-one-of-the-following.title"
                          )
                        }}:
                      </span>
                      <p>
                        <span class="body-1 d-block mb-1">
                          <span class="font-weight-medium">
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.sole-proprietorship.title"
                              )
                            }}
                          </span>

                          <span>
                            -
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.sole-proprietorship.description"
                              )
                            }}
                          </span>
                        </span>
                        <span class="body-1 d-block mb-1">
                          <span class="font-weight-medium">
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.single-member-llc.title"
                              )
                            }}
                          </span>

                          <span>
                            -
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.single-member-llc.description"
                              )
                            }}
                          </span>
                        </span>
                        <span class="body-1 d-block mb-1">
                          <span class="font-weight-medium">
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.1099-contractor.title"
                              )
                            }}
                          </span>

                          <span>
                            -
                            {{
                              $t(
                                "tax-planning.questions.your-business-one-of-the-following.1099-contractor.description"
                              )
                            }}
                          </span>
                        </span>
                      </p>
                    </div>

                    <v-radio-group
                      row
                      filled
                      hide-details="auto"
                      class="mt-0"
                      v-model="form.isBusinessOneOfTheFollowing"
                      @change="handleIsBusinessOneOfTheFollowing"
                    >
                      <v-radio
                        :label="$t('tax-planning.answers.yes')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('tax-planning.answers.no')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <div class="pa-4">
                <v-form
                  ref="annualSalesAndIncomeStep"
                  v-model="isAnnualSalesAndIncomeStepValid"
                  lazy-validation
                  @submit.prevent="submitAnnualSalesAndIncomeStep"
                >
                  <v-row>
                    <v-col cols="12" class="mb-3">
                      <p class="text-h6 mb-0">
                        {{
                          $t("tax-planning.questions.total-annual-sales.title")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <v-text-field
                        hide-details="auto"
                        filled
                        type="text"
                        :rules="rules.required"
                        v-model="form.totalAnnualSales"
                        @keypress="allowOnlyNumbers()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <p class="text-h6 mb-0">
                        {{
                          $t("tax-planning.questions.annual-net-income.title")
                        }}

                        <span class="d-block body-2">
                          {{
                            $t(
                              "tax-planning.questions.annual-net-income.description"
                            )
                          }}
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12" class="mb-3">
                      <v-text-field
                        hide-details="auto"
                        filled
                        type="text"
                        :rules="rules.required"
                        v-model="form.annualNetIncome"
                        @keypress="allowOnlyNumbers()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex align-center justify-end">
                        <v-btn
                          type="submit"
                          color="success"
                          class="text-none body-2 font-weight-medium"
                          depressed
                        >
                          {{ $t("tax-planning.button.next") }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="ma-0 pa-0">
              <div class="pa-4">
                <v-row>
                  <v-col cols="12" class="mb-3">
                    <p class="text-h6 mb-2">
                      {{
                        $t(
                          "tax-planning.questions.business-incorporated-as-s-corp.title"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      hide-details="auto"
                      class="mt-0"
                      v-model="form.isBusinessIncorporatedAsSCorp"
                      @change="submitNextStep"
                    >
                      <v-radio
                        :label="$t('tax-planning.answers.yes')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('tax-planning.answers.no')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        @click="submitPreviousStep"
                        class="text-none body-2 font-weight-medium"
                        depressed
                      >
                        {{ $t("tax-planning.button.previous") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4" class="ma-0 pa-0">
              <div class="pa-4">
                <v-row>
                  <v-col cols="12" class="mb-3">
                    <p class="text-h6 mb-2">
                      {{
                        $t(
                          "tax-planning.questions.own-or-rent-your-house.title"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      hide-details="auto"
                      class="mt-0"
                      v-model="form.ownsHouse"
                      @change="submitNextStep"
                    >
                      <v-radio
                        :label="
                          $t(
                            'tax-planning.questions.own-or-rent-your-house.answers.own'
                          )
                        "
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="
                          $t(
                            'tax-planning.questions.own-or-rent-your-house.answers.rent'
                          )
                        "
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        @click="handlePreviousOwnOrRentHouseStep"
                        class="text-none body-2 font-weight-medium"
                        depressed
                      >
                        {{ $t("tax-planning.button.previous") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="5" class="ma-0 pa-0">
              <div class="pa-4">
                <v-row>
                  <v-col cols="12" class="mb-3">
                    <p class="text-h6 mb-2">
                      {{
                        $t("tax-planning.questions.number-of-children.title")
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      hide-details="auto"
                      class="mt-0"
                      v-model="form.numberOfChildren"
                      @change="submitNextStep"
                    >
                      <v-radio
                        v-for="(i, index) in 6"
                        :key="index"
                        :label="index.toString()"
                        :value="index"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        @click="handlePreviousNumberOfChildrenStep"
                        class="text-none body-2 font-weight-medium"
                        depressed
                      >
                        {{ $t("tax-planning.button.previous") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="6" class="ma-0 pa-0">
              <div class="pa-4">
                <v-row>
                  <v-col cols="12" class="mb-3">
                    <p class="text-h6 mb-2">
                      {{
                        $t(
                          "tax-planning.questions.drive-for-your-business.title"
                        )
                      }}
                    </p>

                    <v-radio-group
                      row
                      filled
                      hide-details="auto"
                      class="mt-0"
                      v-model="form.drivesForBusiness"
                      @change="submitNextStep"
                    >
                      <v-radio
                        :label="$t('tax-planning.answers.yes')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('tax-planning.answers.no')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        @click="handlePreviousDriveForYourBusinessStep"
                        class="text-none body-2 font-weight-medium"
                        depressed
                      >
                        {{ $t("tax-planning.button.previous") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="7" class="ma-0 pa-0">
              <div class="pa-4" v-if="currentStep == 7">
                <v-row align="center">
                  <v-col cols="12" md="8">
                    <p class="text-h4">
                      {{ $t("tax-planning.total-potential-tax-savings") }}:
                    </p>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="text-h4 text-right">
                      <v-progress-circular
                        :rotate="-90"
                        :size="150"
                        :width="5"
                        :value="value"
                        color="primary"
                        class="text-h5"
                      >
                        <CountUp
                          :endVal="totalPotentialTaxSavings"
                          :options="countUpOptions"
                        />
                      </v-progress-circular>
                    </div>
                  </v-col>
                </v-row>

                <div class="mt-10" v-if="showPlans">
                  <v-row>
                    <v-col cols="12" md="6" class="mb-3">
                      <div class="border-all pa-4 rounded">
                        <h5 class="text-h6 text-center mb-3">
                          {{
                            $t("tax-planning.plans.tax-planning-session.title")
                          }}
                        </h5>
                        <div class="text-center mb-3">
                          <p class="mb-2">
                            <v-chip class="mb-2">
                              {{
                                $t(
                                  "tax-planning.plans.tax-planning-session.payment-frequency.one-time"
                                )
                              }}
                            </v-chip>
                            <br />
                            <span class="font-weight-medium text-h6">
                              {{
                                $t("defaults.currency", {
                                  amount: 475,
                                })
                              }}
                            </span>
                          </p>
                          <v-btn
                            color="primary"
                            depressed
                            class="body-2 text-none"
                            href="https://freedomplan.mypaysimple.com/s/tax-planning"
                            target="_blank"
                            @click="sendAnalyticsEventForPaymentPages('TaxPlanningSession', 'tax-planning-session')"
                          >
                            {{
                              $t(
                                "tax-planning.plans.tax-planning-session.action"
                              )
                            }}
                          </v-btn>
                        </div>
                        <ul>
                          <li
                            v-for="(
                              service, index
                            ) in taxPlanningSessionServices"
                            :key="index"
                          >
                            {{ service }}
                          </li>
                        </ul>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="mb-3">
                      <div class="border-all pa-4 rounded">
                        <h5 class="text-h6 text-center mb-3">
                          {{ $t("tax-planning.plans.freedom-plan.title") }}
                        </h5>

                        <v-row>
                          <v-col cols="12" md="6">
                            <div class="text-center mb-3">
                              <p class="mb-2">
                                <v-chip class="mb-2">{{
                                  $t(
                                    "tax-planning.plans.freedom-plan.payment-frequency.monthly"
                                  )
                                }}</v-chip>
                                <br />
                                <span class="font-weight-medium text-h6">{{
                                  $t("defaults.currency", {
                                    amount: 200,
                                  })
                                }}</span
                                >/month
                              </p>

                              <v-btn
                                color="primary"
                                depressed
                                class="body-2 text-none"
                                href="https://freedomplan.mypaysimple.com/s/monthly"
                                target="_blank"
                                @click="sendAnalyticsEventForPaymentPages('FreedomPlanMonthly', 'freedom-plan-monthly')"
                                >{{
                                  $t("tax-planning.plans.freedom-plan.action")
                                }}</v-btn
                              >
                            </div>
                          </v-col>
                          <v-col cols="12" md="6">
                            <div class="text-center mb-3">
                              <p class="mb-2">
                                <v-chip class="mb-2">{{
                                  $t(
                                    "tax-planning.plans.freedom-plan.payment-frequency.annually"
                                  )
                                }}</v-chip>
                                <br />
                                <span class="font-weight-medium text-h6">{{
                                  $t("defaults.currency", {
                                    amount: 175,
                                  })
                                }}</span
                                >/month
                              </p>
                              <v-btn
                                color="primary"
                                depressed
                                class="body-2 text-none"
                                href="https://freedomplan.mypaysimple.com/s/yearly"
                                target="_blank"
                                @click="sendAnalyticsEventForPaymentPages('FreedomPlanAnnually', 'freedom-plan-annually')"
                                >{{
                                  $t("tax-planning.plans.freedom-plan.action")
                                }}</v-btn
                              >
                            </div>
                          </v-col>
                        </v-row>

                        <ul>
                          <li
                            v-for="(service, index) in freedomPlanServices"
                            :key="index"
                          >
                            {{ service }}
                          </li>
                        </ul>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>

    <d-modal
      :title="$t('tax-planning.out-of-scope.title')"
      :modal="outOfScopeModal"
      @closeModal="handleCloseOutOfScopeModal"
      :hasSaveButton="false"
      :cancelButtonText="$t('tax-planning.button.back')"
    >
      <template slot="body">
        <p class="mb-0">
          {{ $t("tax-planning.out-of-scope.text") }}
        </p>
      </template>
    </d-modal>
  </div>
</template>

<script>
import CountUp from "vue-countup-v2";

const initialForm = {
  isBusinessOneOfTheFollowing: null,
  totalAnnualSales: null,
  annualNetIncome: null,
  isBusinessIncorporatedAsSCorp: null,
  ownsHouse: null,
  numberOfChildren: null,
  drivesForBusiness: null,
};

export default {
  components: { CountUp },
  data() {
    return {
      currentStep: 1,
      form: { ...initialForm },
      outOfScopeModal: false,
      isAnnualSalesAndIncomeStepValid: true,
      rules: {
        required: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      countUpOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "$",
        suffix: "",
        duration: 4,
      },
      taxPlanningSessionServices: [
        "In person / online planning session",
        "Tax Return review by our tax expert",
        "Business tax structure analysis",
        "Potential tax deductions report",
        "Tax credit identification",
        "Retirement plan analysis",
        "Implementation guide",
      ],
      freedomPlanServices: [
        "Everything from Tax Planning Session",
        "Company Formation",
        "S-Corp Election",
        "Bookkeeping",
        "Payroll",
        "Quarterly Estimated Tax Payment",
        "Business Tax Return",
      ],
      interval: {},
      value: 10,
      showPlans: false,
    };
  },
  watch: {
    currentStep: {
      handler(val) {
        if (val === 7) {
          this.interval = setInterval(() => {
            if (this.value === 100) {
              clearInterval(this.interval);
              this.showPlans = true;
              return;
            }
            this.value += 30;
          }, 1000);
        }
      },
    },
  },
  computed: {
    totalPotentialTaxSavings() {
      let PS1 = 0;
      let PS2 = 0;
      let PS3 = 0;
      let PS4 = 0;

      if (!this.form.isBusinessIncorporatedAsSCorp) {
        if (this.form.annualNetIncome <= 160000) {
          PS1 = this.form.annualNetIncome * 0.1071;
        } else {
          PS1 = 17136;
        }
      }

      if (this.form.ownsHouse) {
        PS2 = 2100;
      }

      if (this.form.numberOfChildren != null) {
        PS3 = this.form.numberOfChildren * 3600;
      }

      if (this.form.drivesForBusiness) {
        PS4 = 2000;
      }

      return PS1 + PS2 + PS3 + PS4;
    },
  },
  methods: {
    sendAnalyticsEventForPaymentPages(title, path) {
      const page_root = location.protocol + '//' + location.hostname;
      const page_path = '/tax-planning/' + path;

      this.$gtag.pageview({ 
        page_title: 'TaxPlanning - ' + title,
        page_location: page_root + page_path,
        page_path,
      });
    },
    submitNextStep() {
      this.currentStep++;

      const page_root = location.protocol + '//' + location.hostname;
      const page_path = '/tax-planning/step-' + this.currentStep;

      this.$gtag.pageview({ 
        page_title: 'TaxPlanning - Step ' + this.currentStep,
        page_location: page_root + page_path,
        page_path,
      });
    },
    submitPreviousStep() {
      this.currentStep--;
    },
    handleIsBusinessOneOfTheFollowing(val) {
      if (!val) {
        this.outOfScopeModal = true;
        return;
      }
      this.submitNextStep();
    },
    handleCloseOutOfScopeModal() {
      this.outOfScopeModal = false;
      this.form.isBusinessOneOfTheFollowing = null;
    },
    submitAnnualSalesAndIncomeStep() {
      if (
        this.$refs.annualSalesAndIncomeStep.validate() &&
        this.isAnnualSalesAndIncomeStepValid
      ) {
        if (
          this.form.annualNetIncome < 50000 ||
          this.form.annualNetIncome > 300000
        ) {
          this.outOfScopeModal = true;
          return;
        }
        this.submitNextStep();
      }
    },
    handlePreviousOwnOrRentHouseStep() {
      this.form.isBusinessIncorporatedAsSCorp = null;
      this.submitPreviousStep();
    },
    handlePreviousNumberOfChildrenStep() {
      this.form.ownsHouse = null;
      this.submitPreviousStep();
    },
    handlePreviousDriveForYourBusinessStep() {
      this.form.numberOfChildren = null;
      this.submitPreviousStep();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
