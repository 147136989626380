<template>
  <d-modal
    :title="$t('reconciliations.choose-client.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
    :saveButtonText="$t('reconciliations.choose-client.actions.continue')"
  >
    <template slot="body">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-autocomplete
              :items="clients"
              v-model="form.client"
              item-text="name"
              item-value="id"
              filled
              hide-details="auto"
              :rules="rules.client"
              :loading="loading.clients"
              :placeholder="
                $t('reconciliations.choose-client.form.client-placeholder')
              "
              return-object
              clearable
              :readonly="!!form.client"
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex">
                  <span v-text="item.name"></span>
                  <template v-if="!item.currentlyUsingBookkeepingService">
                    <span class="px-1">-</span>
                    <span>
                      {{ $t("reconciliations.choose-client.inactive") }}
                    </span>
                  </template>
                </div>
              </template>

              <template v-slot:item="{ item }">
                <p class="d-flex align-center mb-0 body-2">
                  <span>
                    {{ item.name }}
                  </span>
                  <template v-if="!item.currentlyUsingBookkeepingService">
                    <span class="px-1">-</span>
                    <span>
                      {{ $t("reconciliations.choose-client.inactive") }}
                    </span>
                  </template>
                </p>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-checkbox
              class="mt-0"
              :label="
                $t(
                  'reconciliations.choose-client.form.currently-using-bookkeeping-service'
                )
              "
              filled
              hide-details="auto"
              v-model="form.onlyCurrentlyUsingBookkeepingService"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="mb-3">
            <p
              class="mb-0 body-2 text--secondary"
              v-html="$t('reconciliations.choose-client.form.inactive-client')"
            ></p>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    selectedClient: {
      type: [Object, null],
    },
    bookkeepingBusinessClients: {
      type: Array,
    },
  },
  data() {
    return {
      ClientTypes,
      rules: {
        client: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      isFormValid: false,
      isSubmitting: false,
      form: {
        client: null,
        onlyCurrentlyUsingBookkeepingService: true,
      },
      allClients: [],
      clients: [],
      loading: {
        clients: false,
      },
    };
  },
  watch: {
    "form.onlyCurrentlyUsingBookkeepingService": {
      handler(val) {
        if (
          val &&
          this.form.client &&
          !this.form.client.currentlyUsingBookkeepingService
        ) {
          this.form.client = null;
        }

        this.getClients();
      },
    },
  },
  methods: {
    handleSubmitModal() {
      if (this.$refs.form.validate() && this.isFormValid) {
        this.$emit("submitModal", this.form.client);
      }
    },
    handleCloseModal() {
      this.$emit("closeModal");
    },
    async getAllBookkeepingBusinessClients() {
      return await window.API.getAllBookkeepingBusinessClients({
        onlyCurrentlyUsingBookkeepingService: false,
      });
    },
    getClients() {
      try {
        this.loading.clients = true;

        if (this.form.onlyCurrentlyUsingBookkeepingService) {
          this.clients = this.allClients.filter(
            (client) => client.currentlyUsingBookkeepingService
          );
          return;
        }
        this.clients = this.allClients;
      } finally {
        this.loading.clients = false;
      }
    },
  },
  async mounted() {
    if (this.selectedClient) {
      this.form.client = this.selectedClient.id;
      this.form.onlyCurrentlyUsingBookkeepingService =
        this.selectedClient.currentlyUsingBookkeepingService;

      this.allClients = await this.getAllBookkeepingBusinessClients();
    } else {
      this.allClients = this.bookkeepingBusinessClients;
    }

    await this.getClients();
  },
};
</script>
