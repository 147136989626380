export function fetchDocumentComments(id) {
  return window.axios
    .get(`/document/documents/${id}/comments`)
    .then((response) => response.data);
}

export function insertDocumentComment(id, data) {
  return window.axios
    .post(`/document/documents/${id}/comments`, data)
    .then((response) => response);
}

export function shareDocument(id, data) {
  return window.axios
    .post(`/document/documents/${id}/share`, data)
    .then((response) => response);
}

export function downloadDocument(id) {
  return window.axios
    .get(`/document/documents/${id}/download`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response);
}

export function previewDocument(id) {
  return window.axios
    .get(`/document/documents/${id}/preview`, {
      responseType: "blob",
      timeout: 10 * 60 * 1000, // 10 minutes
    })
    .then((response) => response);
}

export function getDocumentById(id) {
  return window.axios
    .get(`/document/documents/${id}`)
    .then((response) => response.data);
}
