<template>
  <d-page
    :title="$t('users.page-heading')"
    :searchToggle="searchToggle"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("users.button.search-toggle")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        v-granted="'user:export'"
        @click="exportUsers"
      >
        <v-icon left> mdi-file-excel </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("users.button.export-data")
        }}</span>
      </v-btn>
      <v-btn
        color="success"
        depressed
        large
        @click="handleOpenAddOrEditModal()"
        v-granted="'user:add'"
        class="mb-1 mb-sm-0"
      >
        <v-icon left> mdi-plus </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("users.button.create-item")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search
        :roles="roles"
        :advisors="advisors"
        :departments="departments"
      ></Search>
    </template>
    <template slot="content">
      <List
        @editItem="handleOpenAddOrEditModal"
        @changeStatus="handleOpenChangeStatusModal"
        @resetPassword="handleOpenResetPasswordModal"
        @getTable="handleGetTable"
      ></List>
      <AddOrEdit
        v-if="openAddOrEditModal"
        :modal="openAddOrEditModal"
        :item="selectedItem"
        @closeModal="handleCloseAddOrEditModal"
        @submitModal="handleSubmitAddOrEditModal"
      ></AddOrEdit>
      <ChangeStatus
        v-if="openChangeStatusModal"
        :modal="openChangeStatusModal"
        :item="selectedItem"
        @closeModal="handleCloseChangeStatusModal"
        @submitModal="handleSubmitChangeStatusModal"
      ></ChangeStatus>
      <ResetPassword
        v-if="openResetPasswordModal"
        :modal="openResetPasswordModal"
        :item="selectedItem"
        @closeModal="handleCloseResetPasswordModal"
      ></ResetPassword>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";
import AddOrEdit from "./AddOrEdit.vue";
import ChangeStatus from "./ChangeStatus.vue";
import ResetPassword from "./ResetPassword.vue";

export default {
  components: {
    List,
    Search,
    AddOrEdit,
    ChangeStatus,
    ResetPassword,
  },
  data() {
    return {
      searchToggle: false,
      openAddOrEditModal: false,
      openChangeStatusModal: false,
      openResetPasswordModal: false,
      selectedItem: null,
      roles: [],
      advisors: [],
      departments: [],
      table: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchUsers", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchUsers");
    },
    handleOpenAddOrEditModal(item = null) {
      this.openAddOrEditModal = true;
      this.selectedItem = item;
    },
    handleCloseAddOrEditModal() {
      this.openAddOrEditModal = false;
    },
    async handleSubmitAddOrEditModal() {
      this.$eventBus.$emit("fetchUsers");
      await this.fetchAllAdvisors();
    },
    handleOpenChangeStatusModal(item = null) {
      this.selectedItem = item;
      this.openChangeStatusModal = true;
    },
    handleCloseChangeStatusModal() {
      this.openChangeStatusModal = false;
    },
    async handleSubmitChangeStatusModal() {
      this.$eventBus.$emit("fetchUsersAfterStatusChange");
    },
    handleOpenResetPasswordModal(item = null) {
      this.openResetPasswordModal = true;
      this.selectedItem = item;
    },
    handleCloseResetPasswordModal() {
      this.openResetPasswordModal = false;
    },
    fetchAllRoles() {
      window.API.fetchAllRoles()
        .then((response) => {
          this.roles = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllAdvisors() {
      window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    fetchAllDepartments() {
      window.API.fetchAllDepartments()
        .then((response) => {
          this.departments = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    handleGetTable(value) {
      this.table = value;
    },
    exportUsers() {
      window.API.exportUsers({
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("users.export.alert.exported"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("users.export.alert.could-not-export"),
          });
        });
    },
  },
  async mounted() {
    await this.fetchAllRoles();
    await this.fetchAllAdvisors();
    await this.fetchAllDepartments();
  },
};
</script>
