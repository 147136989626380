import { Translations } from "../plugins/translations";

import LoginLayout from "@/layouts/LoginLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import RegistrationLayout from "@/layouts/RegistrationLayout.vue";
import CustomerOnboardingLayout from "@/layouts/CustomerOnboardingLayout.vue";
import TaxPlanningLayout from "@/layouts/TaxPlanningLayout.vue";
import Login from "@/views/login/Login.vue";
import Register from "@/views/login/Register.vue";
import ResetPassword from "@/views/login/ResetPassword.vue";
import ChangePassword from "@/views/login/ChangePassword.vue";
import Dashboard from "@/views/dashboard/index.vue";
import Profile from "@/views/profile/Profile.vue";
import Roles from "@/views/roles/index.vue";
import Users from "@/views/users/index.vue";
import Tags from "@/views/tags/index.vue";
import Services from "@/views/services/index.vue";
import Clients from "@/views/clients/index.vue";
import EditClient from "@/views/clients/Edit.vue";
import Invitations from "@/views/invitations/index.vue";
import Leads from "@/views/leads/index.vue";
import LeadDetails from "@/views/leads/Details/index.vue";
import Accounts from "@/views/accounts/index.vue";
import MyUploads from "@/views/my-uploads/index.vue";
import ReceivedDocs from "@/views/received-docs/index.vue";
import Bookkeeping from "@/views/bookkeeping/index.vue";
import Companies from "@/views/companies/index.vue";
import CompanyDetails from "@/views/companies/Details/index.vue";
import CompanyFormationResident from "@/views/company-formation/resident/index.vue";
import CompanyFormationNonResident from "@/views/company-formation/non-resident/index.vue";
import Contractors from "@/views/contractors/index.vue";
import CustomerOnboarding from "@/views/customer-onboarding/index.vue";
import Announcements from "@/views/announcements/index.vue";
import Reconciliations from "@/views/reconciliations/index.vue";
import ReconciliationTransactions from "@/views/reconciliations/Transactions.vue";
import Coupons from "@/views/coupons/index.vue";
import TaxPlanning from "@/views/tax-planning/index.vue";

const routes = [
  {
    path: "/",
    component: AppLayout,
    meta: {
      requiresAuth: true,
      permission: "*",
    },
    children: [
      {
        path: "/",
        component: Dashboard,
        name: "Dashboard",
        meta: {
          requiresAuth: true,
          permission: "*",
        },
      },
      {
        path: "/profile",
        component: Profile,
        name: "Profile",
        meta: {
          requiresAuth: true,
          permission: "*",
        },
      },
      {
        path: "/roles",
        component: Roles,
        name: "Roles",
        meta: {
          requiresAuth: true,
          permission: "role:list",
        },
      },
      {
        path: "/users",
        component: Users,
        name: "Users",
        meta: {
          requiresAuth: true,
          permission: "user:list",
        },
      },
      {
        path: "/tags",
        component: Tags,
        name: "Tags",
        meta: {
          requiresAuth: true,
          permission: "tag:list",
        },
      },
      {
        path: "/services",
        component: Services,
        name: "Services",
        meta: {
          requiresAuth: true,
          permission: "service:list",
        },
      },
      {
        path: "/clients",
        component: Clients,
        name: "Clients",
        meta: {
          requiresAuth: true,
          permission: "client:list",
        },
      },
      {
        path: "/clients/:clientType/:id/edit",
        component: EditClient,
        name: "EditClient",
        meta: {
          requiresAuth: true,
          permission: "client:edit",
        },
      },
      {
        path: "/invitations",
        component: Invitations,
        name: "Invitations",
        meta: {
          requiresAuth: true,
          permission: "invitation:list",
        },
      },
      {
        path: "/leads",
        component: Leads,
        name: "Leads",
        meta: {
          requiresAuth: true,
          permission: "lead:list",
        },
      },
      {
        path: "/leads/:id/details",
        component: LeadDetails,
        name: "LeadDetails",
        meta: {
          requiresAuth: true,
          permission: "lead:list",
        },
      },
      {
        path: "/accounts",
        component: Accounts,
        name: "Accounts",
        meta: {
          requiresAuth: true,
          permission: "account:list",
        },
      },
      {
        path: "/received-docs",
        component: ReceivedDocs,
        name: "ReceivedDocs",
        meta: {
          requiresAuth: true,
          permission: "received:list",
        },
      },
      {
        path: "/my-uploads",
        component: MyUploads,
        name: "MyUploads",
        meta: {
          requiresAuth: true,
          permission: "my-upload:list",
        },
      },
      {
        path: "/bookkeeping",
        component: Bookkeeping,
        name: "Bookkeeping",
        meta: {
          requiresAuth: true,
          permission: "bookkeeping:list",
        },
      },
      {
        path: "/companies",
        name: "Companies",
        component: Companies,
        meta: {
          requiresAuth: true,
          permission: "company:list",
        },
      },
      {
        path: "/companies/:id/details",
        name: "CompanyDetails",
        component: CompanyDetails,
        meta: {
          requiresAuth: true,
          permission: "company:view",
        },
      },
      {
        path: "/1099-form",
        name: "1099Form",
        component: Contractors,
        meta: {
          requiresAuth: true,
          permission: "contractor:list",
        },
      },
      {
        path: "/announcements",
        name: "Announcements",
        component: Announcements,
        meta: {
          requiresAuth: true,
          permission: "announcement:list",
        },
      },
      {
        path: "/reconciliations",
        name: "Reconciliations",
        component: Reconciliations,
        meta: {
          requiresAuth: true,
          permission: "reconciliation:list",
        },
      },
      {
        path: "/reconciliations/:institutionAccountId/transactions",
        name: "ReconciliationTransactions",
        component: ReconciliationTransactions,
        meta: {
          requiresAuth: true,
          permission: "reconciliation:transaction:list",
        },
      },
      {
        path: "/coupons",
        name: "Coupons",
        component: Coupons,
        meta: {
          requiresAuth: true,
          permission: "coupon:list",
        },
      },
    ],
  },
  {
    path: "",
    component: RegistrationLayout,
    meta: {
      requiresAuth: false,
      permission: "*",
    },
    // beforeEnter: Translations.routeMiddleware,
    children: [
      {
        path: "/company-formation/resident",
        name: "CompanyFormationResident",
        component: CompanyFormationResident,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
      {
        path: "/company-formation/non-resident",
        name: "CompanyFormationNonResident",
        component: CompanyFormationNonResident,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
    ],
  },
  {
    path: "",
    component: CustomerOnboardingLayout,
    meta: {
      requiresAuth: false,
      permission: "*",
    },
    children: [
      {
        path: "/customer-onboarding",
        name: "StartCustomerOnboarding",
        component: CustomerOnboarding,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
      {
        path: "/customer-onboarding/:idHash",
        name: "ContinueCustomerOnboarding",
        component: CustomerOnboarding,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
    ],
  },
  {
    path: "",
    component: TaxPlanningLayout,
    meta: {
      requiresAuth: false,
      permission: "*",
    },
    children: [
      {
        path: "/tax-planning",
        name: "TaxPlanning",
        component: TaxPlanning,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
    ],
  },
  {
    path: "",
    component: LoginLayout,
    meta: {
      requiresAuth: false,
      permission: null,
    },
    beforeEnter: Translations.routeMiddleware,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
      {
        path: "/forgot-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          requiresAuth: false,
          permission: "*",
        },
      },
    ],
  },
];

export default routes;
