<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    app
    color="#FAFAFA"
    width="296"
  >
    <div class="app-layout--logo pa-4">
      <router-link to="/" class="d-inline-block">
        <img src="@/assets/images/logo.svg" alt="Docuport" />
      </router-link>
    </div>
    <div class="menu mr-2" v-if="!loading.menu">
      <v-btn
        depressed
        large
        class="mt-2 ml-2 white--text"
        color="#0093FF"
        v-granted="'my-upload:add'"
        @click="openUploadDocumentModal"
      >
        <v-icon left> mdi-tray-arrow-up </v-icon>
        <span class="subtitle-2 text-uppercase text-none">{{
          $t("my-uploads.button.upload")
        }}</span>
      </v-btn>

      <v-list shaped dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <template v-for="(item, i) in filteredItems">
            <v-list-group
              :value="checkSubmenuRouteChange(item)"
              :key="i"
              v-if="item.children && item.children.length > 0"
              v-granted="item.permission"
              class="py-x-2"
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="body-1" v-text="item.text"></span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                class="pl-14"
                v-for="(subitem, index) in item.children"
                :key="index"
                link
                :to="subitem.link"
                v-granted="subitem.permission"
              >
                <v-list-item-title>
                  <span class="body-1" v-text="subitem.text"></span>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item
              :key="i"
              v-else
              class="py-x-2"
              :to="item.link"
              v-granted="item.permission"
            >
              <v-list-item-icon class="mr-4">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="body-1" v-text="item.text"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <div style="margin-top: 40px; margin-left: 20px;" v-if="authorizationUtils.isClient()">
        <a style="color: rgba(0,0,0,.87); text-decoration: none;" target="_blank" href="https://numbersquad.com/service-terms-and-conditions">
          Terms and conditions
        </a>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  data: (vm) => ({
    authorizationUtils,
    selectedItem: 1,
    items: [
      {
        link: "/",
        text: vm.$t("menu.home"),
        icon: "mdi-home",
        permission: "*",
      },
      {
        link: "/received-docs",
        text: vm.$t("menu.received-docs"),
        icon: "mdi-folder-download",
        permission: "received:list",
      },
      {
        link: "/my-uploads",
        text: vm.$t("menu.my-uploads"),
        icon: "mdi-folder-upload",
        permission: "my-upload:list",
      },
      {
        link: "/clients",
        text: vm.$t("menu.clients"),
        icon: "mdi-account-group",
        permission: "client:list",
      },
      {
        link: "/invitations",
        text: vm.$t("menu.invitations"),
        icon: "mdi-email-open",
        permission: "invitation:list",
      },
      {
        link: "/users",
        text: vm.$t("menu.users"),
        icon: "mdi-account-multiple",
        permission: "user:list",
      },
      {
        link: "/leads",
        text: vm.$t("menu.leads"),
        icon: "mdi-account-star",
        permission: "lead:list",
      },
      {
        link: "/bookkeeping",
        text: vm.$t("menu.bookkeeping"),
        icon: "mdi-finance",
        permission: "bookkeeping:list",
      },
      {
        key: "companies",
        link: "/companies",
        text: vm.$t("menu.companies"),
        icon: "mdi-domain",
        permission: "company:list",
      },
      {
        text: vm.$t("menu.administration"),
        icon: "mdi-account-cog",
        permission: "role:list|service:list|tag:list|announcement:list",
        children: [
          {
            link: "/roles",
            text: vm.$t("menu.roles"),
            permission: "role:list",
          },
          {
            link: "/services",
            text: vm.$t("menu.services"),
            permission: "service:list",
          },
          {
            link: "/tags",
            text: vm.$t("menu.tags"),
            permission: "tag:list",
          },
          {
            link: "/announcements",
            text: vm.$t("menu.announcements"),
            permission: "announcement:list",
          },
          {
            link: "/coupons",
            text: vm.$t("menu.coupons"),
            permission: "coupon:list",
          },
        ],
      },
    ],
    form1099MenuItem: {
      link: "/1099-form",
      text: vm.$t("menu.1099-form"),
      icon: "mdi-list-box-outline",
      permission: "contractor:list",
    },
    reconciliationMenuItem: {
      link: "/reconciliations",
      text: vm.$t("menu.reconciliations"),
      icon: "mdi-briefcase-check",
      permission: "reconciliation:list",
    },
    filteredItems: [],
    loading: {
      menu: false,
    },
  }),
  computed: {
    navigationDrawer: {
      get: function () {
        return this.$store.getters["Common/getNavigationDrawer"];
      },
      set: function (val) {
        if (val != this.$store.getters["Common/getNavigationDrawer"]) {
          this.setNavigationDrawer();
        }
      },
    },
    ...mapGetters({
      myCurrentClient: "Auth/getMyCurrentClient",
      userPermissions: "Auth/getUserPermissions",
    }),
    canAccess1099Form() {
      return (
        authorizationUtils.isGranted("contractor:list") &&
        (!authorizationUtils.isClient() ||
          (this.myCurrentClient &&
            this.myCurrentClient.clientType == ClientTypes.BUSINESS))
      );
    },
    canAccessReconciliations() {
      return (
        authorizationUtils.isGranted("reconciliation:list") &&
        (!authorizationUtils.isClient() ||
          (this.myCurrentClient &&
            this.myCurrentClient.clientType == ClientTypes.BUSINESS &&
            this.myCurrentClient.usedBookkeepingServiceAtLeastOnce))
      );
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.checkSubmenuRouteChange();
      },
    },
    myCurrentClient: {
      handler() {
        this.generateMenu();
      },
      deep: true,
    },
    userPermissions: {
      handler() {
        this.generateMenu();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setNavigationDrawer: "Common/setNavigationDrawer",
      setOpenUploadDocumentModal: "MyUpload/setOpenUploadDocumentModal",
    }),
    openUploadDocumentModal() {
      this.$router
        .push({
          name: "MyUploads",
        })
        .catch(() => {})
        .finally(() => {
          this.setOpenUploadDocumentModal(true);
        });
    },
    checkSubmenuRouteChange(item) {
      if (!item) return;

      return item.children.some((i) => i.link === this.$route.path);
    },
    generateMenu() {
      this.loading.menu = true;
      let _items = [...this.items];

      if (this.canAccessReconciliations) {
        this.items.forEach((item, index) => {
          if (item.key === "companies") {
            _items.splice(index + 1, 0, this.reconciliationMenuItem);
          }
        });
      }

      if (this.canAccess1099Form) {
        this.items.forEach((item, index) => {
          if (item.key === "companies") {
            _items.splice(index + 1, 0, this.form1099MenuItem);
          }
        });
      }

      this.filteredItems = _items;
      this.$nextTick(() => {
        this.loading.menu = false;
      });
    },
  },
  mounted() {
    this.generateMenu();
  },
};
</script>
