<template>
  <d-modal
    :title="$t('coupons.create.title')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
  >
    <template slot="body">
      <d-loading v-if="loading.page"></d-loading>
      <v-form
        v-else
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('coupons.create.form.title')"
              v-model.trim="form.title"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.title"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('coupons.create.form.code')"
              v-model.trim="form.code"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.code"
              :error="errorMessages.code.length > 0"
              :error-messages="errorMessages.code"
              @blur="checkCouponCodeAvailability"
              :loading="loading.code"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-radio-group
              row
              filled
              v-model="form.discountType"
              hide-details="auto"
              class="mt-0"
              :rules="rules.discountType"
            >
              <v-radio
                :label="$t('coupons.create.discount-types.percentage')"
                :value="DiscountTypes.PERCENTAGE"
              ></v-radio>
              <v-radio
                :label="$t('coupons.create.discount-types.fixed')"
                :value="DiscountTypes.FIXED"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('coupons.create.form.discount-value')"
              hide-details="auto"
              filled
              type="number"
              v-model="form.discountValue"
              :rules="rules.discountValue"
              @keypress="allowOnlyNumbers()"
              min="0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-menu
              v-model="menu.expirationDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedExpirationDate"
                  :label="$t('coupons.create.form.expiration-date')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  filled
                  clearable
                  @click:clear="form.expirationDate = null"
                  :rules="rules.expirationDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.expirationDate"
                @input="menu.expirationDate = false"
                no-title
                :min="minDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import DiscountTypes from "@/shared/enums/DiscountTypes";

const initialForm = {
  title: null,
  code: null,
  discountType: 2,
  discountValue: null,
  expirationDate: null,
};

const initialErrorMessages = {
  code: [],
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      DiscountTypes,
      form: { ...initialForm },
      loading: {
        page: false,
        code: false,
      },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        title: [(v) => !!v || this.$t("defaults.rule.required")],
        code: [(v) => !!v || this.$t("defaults.rule.required")],
        discountType: [(v) => !!v || this.$t("defaults.rule.required")],
        discountValue: [(v) => !!v || this.$t("defaults.rule.required")],
        expirationDate: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      menu: {
        expirationDate: false,
      },
      minDate: this.$moment().format(),
      errorMessages: { ...initialErrorMessages },
    };
  },
  computed: {
    formattedExpirationDate() {
      return this.form.expirationDate
        ? this.$moment(this.form.expirationDate).format("MM.DD.YYYY")
        : null;
    },
  },

  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.errorMessages = { ...initialErrorMessages };
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      await this.checkCouponCodeAvailability();

      if (this.$refs.form.validate() && this.isFormValid) {
        await this.createItem();
      }
    },
    async createItem() {
      this.isSubmitting = true;

      await window.API.insertCoupon(this.form)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("coupons.create.alert.created", {
              title: this.form.title,
            }),
          });
          this.$emit("submitModal");
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("coupons.create.alert.could-not-create"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async checkCouponCodeAvailability() {
      const code = this.form.code;

      if (!code) return;

      this.loading.code = true;
      window.API.checkCouponCodeAvailability(code)
        .then((response) => {
          if (!response) {
            this.errorMessages.code = [
              this.$t("coupons.create.rule.code-is-taken", {
                code: code,
              }),
            ];
            return;
          }
          this.errorMessages.code = [];
        })
        .finally(() => (this.loading.code = false));
    },
  },
};
</script>
