<template>
  <div class="login-layout__form">
    <h1 class="text-h5 font-weight-medium mb-6">
      {{ $t("change-password.page-heading") }}
    </h1>

    <v-form
      ref="form"
      v-model="isValidForm"
      lazy-validation
      @submit.prevent="handleFormSubmit"
    >
      <v-row>
        <template v-if="!loading.usableToken && isUsableToken">
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('change-password.form.password')"
              hide-details="auto"
              filled
              type="password"
              :rules="rules.password"
              :hint="$t('change-password.hint.password')"
              persistent-hint
              v-model.trim="form.password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-4">
            <v-text-field
              :label="$t('change-password.form.confirm-password')"
              hide-details="auto"
              filled
              type="password"
              :rules="rules[`confirm-password`]"
              persistent-hint
              v-model.trim="form.rePassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="d-flex align-center justify-end">
              <v-btn
                :to="{ name: 'Login' }"
                class="text-none body-2 font-weight-medium mr-3"
                depressed
                large
                type="button"
                :disabled="loading.changePassword"
              >
                {{ $t("change-password.button.cancel") }}
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                class="text-none body-2 font-weight-medium"
                depressed
                large
                :loading="loading.changePassword"
              >
                {{ $t("change-password.button.change") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isValidForm: true,
      form: {
        password: null,
        rePassword: null,
      },
      rules: {
        password: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            (!!v && v.length >= 6) || this.$t("change-password.rule.password"),
          (v) =>
            this.$constants.PASSWORD_REGEX.test(v) ||
            this.$t("change-password.rule.password"),
        ],
        "confirm-password": [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            v === this.form.password ||
            this.$t("change-password.rule.confirm-password"),
        ],
      },
      isUsableToken: null,
      loading: {
        usableToken: false,
        changePassword: false,
      },
    };
  },
  methods: {
    isForgotPasswordTokenUsable() {
      this.loading.usableToken = true;
      window.API.isForgotPasswordTokenUsable(
        this.$route.query.token,
        this.$route.query.userId
      )
        .then((res) => {
          if (res) {
            this.isUsableToken = res;
          } else {
            this.$router.push({
              name: "ResetPassword",
              params: { isUsableToken: res },
            });
          }
        })
        .finally(() => {
          this.loading.usableToken = false;
        });
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();

      if (isFormValid) {
        this.loading.changePassword = true;
        window.API.resetPassword({
          userId: this.$route.query.userId,
          password: this.form.password,
          confirmPassword: this.form.rePassword,
          token: this.$route.query.token,
        })
          .then(() => {
            this.$eventBus.$emit("notification", {
              type: "success",
              message: this.$t("change-password.alert.password-changed"),
            });

            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.$router.push({
              name: "ResetPassword",
              params: { isUsableToken: false },
            });
          })
          .finally(() => {
            this.loading.changePassword = false;
          });
      }
    },
  },
  created() {
    this.isForgotPasswordTokenUsable();
  },
};
</script>
