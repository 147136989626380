<template>
  <d-page :title="$t('reconciliations.page-heading')" :hasSearch="false">
    <template slot="content">
      <d-loading v-if="loading.page"></d-loading>
      <template v-else>
        <p
          class="body-1"
          v-if="
            bookkeepingBusinessClients.length == 0 &&
            !authorizationUtils.isClient()
          "
          v-text="$t('reconciliations.no-business-clients')"
        ></p>
        <List
          v-if="selectedClient"
          :clientId="selectedClient.id"
          @changeAccount="openChooseBookkeepingBusinessClientModal = true"
        ></List>
        <ChooseBookkeepingBusinessClient
          v-if="openChooseBookkeepingBusinessClientModal"
          :modal="openChooseBookkeepingBusinessClientModal"
          @submitModal="handleSubmitChooseBookkeepingBusinessClientModal"
          @closeModal="handleCloseChooseBookkeepingBusinessClientModal"
          :bookkeepingBusinessClients="bookkeepingBusinessClients"
          :selectedClient="selectedClient"
        />
      </template>
    </template>
  </d-page>
</template>

<script>
import List from "./List.vue";
import ChooseBookkeepingBusinessClient from "./ChooseBookkeepingBusinessClient.vue";
import authorizationUtils from "@/utils/authorization.js";
import { mapGetters } from "vuex";

export default {
  components: { List, ChooseBookkeepingBusinessClient },
  data() {
    return {
      openChooseBookkeepingBusinessClientModal: false,
      bookkeepingBusinessClients: [],
      selectedClient: null,
      authorizationUtils,
      loading: {
        page: false,
      },
    };
  },
  computed: {
    ...mapGetters({ myCurrentClient: "Auth/getMyCurrentClient" }),
  },
  methods: {
    async handleSubmitChooseBookkeepingBusinessClientModal(client) {
      this.selectedClient = client;

      if (this.$route.query.clientId != this.selectedClient.id) {
        this.$router.push({
          name: "Reconciliations",
          query: { clientId: this.selectedClient.id },
        });
      }

      this.handleCloseChooseBookkeepingBusinessClientModal();
    },
    handleCloseChooseBookkeepingBusinessClientModal() {
      if (!this.selectedClient) {
        this.$router.push({
          name: "Dashboard",
        });
      }
      this.openChooseBookkeepingBusinessClientModal = false;
    },
    async setSelectedClient() {
      try {
        this.loading.page = true;

        const response = await window.API.getAllBookkeepingBusinessClients({
          onlyCurrentlyUsingBookkeepingService: false,
        });

        if (response.length) {
          this.bookkeepingBusinessClients = response;

          this.selectedClient = this.bookkeepingBusinessClients.find(
            (client) => client.id == this.$route.query.clientId
          );

          if (!this.selectedClient) {
            if (this.$route.query.clientId) {
              this.$router.push({
                name: "Reconciliations",
              });
            }

            this.openChooseBookkeepingBusinessClientModal = true;
          }
        }
      } finally {
        this.loading.page = false;
      }
    },
  },
  async mounted() {
    if (authorizationUtils.isClient()) {
      this.selectedClient = {
        id: this.myCurrentClient.id,
      };
      this.openChooseBookkeepingBusinessClientModal = false;
    } else {
      await this.setSelectedClient();
    }
  },
};
</script>
