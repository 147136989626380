<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    lazy-validation
    @submit.prevent="submitStep"
  >
    <div class="onboarding-modal--body px-4">
      <v-row>
        <v-col cols="8" class="mb-4">
          <v-file-input
            class="d-required"
            hide-details="auto"
            filled
            :label="
              $t('onboarding.document-uploads.form.tax-return-attachment')
            "
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            v-model="form.taxReturnAttachment"
            :rules="
              form.uploadTaxReturnAttachmentLater
                ? []
                : rules.taxReturnAttachment
            "
          ></v-file-input>
        </v-col>
        <v-col cols="4" class="mb-4">
          <v-checkbox
            :label="$t('onboarding.document-uploads.form.upload-later')"
            filled
            hide-details="auto"
            v-model="form.uploadTaxReturnAttachmentLater"
          ></v-checkbox>
        </v-col>

        <v-col cols="8" class="mb-4">
          <v-file-input
            class="d-required"
            hide-details="auto"
            filled
            :label="
              $t(
                'onboarding.document-uploads.form.employer-identification-number-attachment'
              )
            "
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            v-model="form.employerIdentificationNumberAttachment"
            :rules="
              form.uploadEmployerIdentificationNumberAttachmentLater
                ? []
                : rules.employerIdentificationNumberAttachment
            "
          ></v-file-input>
        </v-col>
        <v-col cols="4" class="mb-4">
          <v-checkbox
            :label="$t('onboarding.document-uploads.form.upload-later')"
            filled
            hide-details="auto"
            v-model="form.uploadEmployerIdentificationNumberAttachmentLater"
          ></v-checkbox>
        </v-col>

        <v-col cols="8" class="mb-4">
          <v-file-input
            class="d-required"
            hide-details="auto"
            filled
            :label="
              $t(
                'onboarding.document-uploads.form.article-of-incorporation-attachment'
              )
            "
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            v-model="form.articleOfIncorporationAttachment"
            :rules="
              form.uploadArticleOfIncorporationAttachmentLater
                ? []
                : rules.articleOfIncorporationAttachment
            "
          ></v-file-input>
        </v-col>
        <v-col cols="4" class="mb-4">
          <v-checkbox
            :label="$t('onboarding.document-uploads.form.upload-later')"
            filled
            hide-details="auto"
            v-model="form.uploadArticleOfIncorporationAttachmentLater"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div class="onboarding--modal--footer">
      <div class="border-top py-3 px-4">
        <div class="d-flex align-center justify-end">
          <v-btn
            @click="previousStep"
            type="button"
            class="text-none body-2 font-weight-medium mr-2"
            depressed
            :loading="isSubmitting"
          >
            {{ $t("onboarding.step-actions.previous") }}
          </v-btn>

          <v-btn
            type="submit"
            color="success"
            class="text-none body-2 font-weight-medium"
            depressed
            :loading="isSubmitting"
          >
            {{ $t("onboarding.step-actions.submit") }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
const initialForm = {
  taxReturnAttachment: null,
  employerIdentificationNumberAttachment: null,
  articleOfIncorporationAttachment: null,
  uploadTaxReturnAttachmentLater: null,
  uploadEmployerIdentificationNumberAttachmentLater: null,
  uploadArticleOfIncorporationAttachmentLater: null,
};
export default {
  props: {
    isSubmitting: Boolean,
  },
  data() {
    return {
      form: { ...initialForm },
      isFormValid: true,
      rules: {
        taxReturnAttachment: [(v) => !!v || this.$t("defaults.rule.required")],
        employerIdentificationNumberAttachment: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
        articleOfIncorporationAttachment: [
          (v) => !!v || this.$t("defaults.rule.required"),
        ],
      },
    };
  },
  watch: {
    "form.taxReturnAttachment": {
      handler(val) {
        if (val) {
          this.form.uploadTaxReturnAttachmentLater = false;
        }
      },
    },
    "form.employerIdentificationNumberAttachment": {
      handler(val) {
        if (val) {
          this.form.uploadEmployerIdentificationNumberAttachmentLater = false;
        }
      },
    },
    "form.articleOfIncorporationAttachment": {
      handler(val) {
        if (val) {
          this.form.uploadArticleOfIncorporationAttachmentLater = false;
        }
      },
    },
    "form.uploadTaxReturnAttachmentLater": {
      handler(val) {
        if (val) {
          this.form.taxReturnAttachment = null;
        }
      },
    },
    "form.uploadEmployerIdentificationNumberAttachmentLater": {
      handler(val) {
        if (val) {
          this.form.employerIdentificationNumberAttachment = null;
        }
      },
    },
    "form.uploadArticleOfIncorporationAttachmentLater": {
      handler(val) {
        if (val) {
          this.form.articleOfIncorporationAttachment = null;
        }
      },
    },
  },
  methods: {
    previousStep() {
      this.$emit("previousStep");
    },
    async submitStep() {
      if (this.$refs.form.validate() && this.isFormValid) {
        this.$emit("submitStep", this.form);
      }
    },
  },
};
</script>
