<template>
  <d-page
    :title="$t('clients.page-heading')"
    :searchToggle="searchToggle"
    :openDetails="openDetails"
    @closeSearch="handleCloseSearch"
    @submitSearch="handleSubmitSearch"
    @closeDetails="handleCloseDetails"
  >
    <template slot="actions">
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        :class="searchToggle ? 'primary--text' : ''"
        @click="searchToggle = !searchToggle"
      >
        <v-icon left> mdi-tune </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("clients.button.search-toggle")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        @click="handleOpenSendEmailModal"
        v-granted="'client:send-email'"
      >
        <v-icon left> mdi-email </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("clients.button.send-email")
        }}</span>
      </v-btn>
      <v-btn
        depressed
        large
        class="mr-3 mb-1 mb-sm-0"
        color="primary"
        v-granted="'client:export'"
        @click="exportClients"
      >
        <v-icon left> mdi-file-excel </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("clients.button.export-data")
        }}</span>
      </v-btn>
      <v-menu
        v-granted="'client:add'"
        bottom
        offset-y
        content-class="elevation-0 mt-1 rounded"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            depressed
            large
            class="mb-1 mb-sm-0"
            v-bind="attrs"
            v-on="on"
          >
            <span class="subtitle-2 text-none">{{
              $t("clients.button.create-item")
            }}</span>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list outlined class="rounded" dense>
          <v-list-item @click="handleOpenAddModal(ClientTypes.BUSINESS)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-briefcase</v-icon>
                <span class="body-2">{{
                  $t("clients.client-type.business")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleOpenAddModal(ClientTypes.PERSONAL)">
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-account</v-icon>
                <span class="body-2">{{
                  $t("clients.client-type.personal")
                }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template slot="search">
      <Search
        :advisors="advisors"
        :services="services"
        :states="states"
      ></Search>
    </template>
    <template slot="content">
      <List
        @addItem="handleOpenAddModal(ClientTypes.PERSONAL)"
        @getTable="handleGetTable"
        @viewItem="handleOpenDetails"
        @deleteItem="handleOpenDeleteModal"
        @changeStatus="handleOpenChangeStatusModal"
        :services="services"
        v-if="!loading.page"
      ></List>
      <Add
        v-if="openAddModal"
        :modal="openAddModal"
        @closeModal="handleCloseAddModal"
        @submitModal="handleSubmitAddModal"
        :clientType="selectedClientType"
      ></Add>
      <Delete
        v-if="openDeleteModal"
        :modal="openDeleteModal"
        :item="selectedItem"
        @closeModal="handleCloseDeleteModal"
        @submitModal="handleSubmitDeleteModal"
      ></Delete>
      <ChangeStatus
        v-if="openChangeStatusModal"
        :modal="openChangeStatusModal"
        :item="selectedItem"
        @closeModal="handleCloseChangeStatusModal"
        @submitModal="handleSubmitChangeStatusModal"
      ></ChangeStatus>
      <SendEmail
        v-if="openSendEmailModal"
        :modal="openSendEmailModal"
        :table="table"
        @closeModal="handleCloseSendEmailModal"
      ></SendEmail>
    </template>
    <template slot="details">
      <Details v-if="openDetails" :item="selectedItem"></Details>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";
import Add from "./Add.vue";
import Details from "./Details.vue";
import Delete from "./Delete.vue";
import ChangeStatus from "./ChangeStatus.vue";
import SendEmail from "./SendEmail.vue";
import ClientTypes from "@/shared/enums/ClientTypes";

export default {
  components: {
    List,
    Search,
    Add,
    Details,
    Delete,
    ChangeStatus,
    SendEmail,
  },
  data() {
    return {
      searchToggle: false,
      openAddModal: false,
      openDetails: false,
      openDeleteModal: false,
      openChangeStatusModal: false,
      openSendEmailModal: false,
      selectedItem: null,
      advisors: [],
      services: [],
      states: [],
      table: null,
      ClientTypes,
      selectedClientType: null,
      loading: { page: false },
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchClients", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchClients");
    },
    handleOpenAddModal(clientType) {
      this.openAddModal = true;
      this.selectedClientType = clientType;
    },
    handleCloseAddModal() {
      this.openAddModal = false;
    },
    handleSubmitAddModal() {
      this.$eventBus.$emit("fetchClients");
    },
    handleOpenDetails(item = null) {
      this.selectedItem = item;
      this.openDetails = true;
    },
    handleCloseDetails() {
      this.openDetails = false;
    },
    handleOpenDeleteModal(item = null) {
      this.openDeleteModal = true;
      this.selectedItem = item;
    },
    handleCloseDeleteModal() {
      this.openDeleteModal = false;
    },
    handleSubmitDeleteModal() {
      this.$eventBus.$emit("fetchClientsAfterDelete");
    },
    handleOpenChangeStatusModal(item = null) {
      this.selectedItem = item;
      this.openChangeStatusModal = true;
    },
    handleCloseChangeStatusModal() {
      this.openChangeStatusModal = false;
    },
    async handleSubmitChangeStatusModal() {
      this.$eventBus.$emit("fetchClientsAfterStatusChange");
    },
    handleOpenSendEmailModal() {
      this.openSendEmailModal = true;
    },
    handleCloseSendEmailModal() {
      this.openSendEmailModal = false;
    },
    handleGetTable(value) {
      this.table = value;
    },
    exportClients() {
      window.API.exportClients({
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.export.alert.exported"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.export.alert.could-not-export"),
          });
        });
    },
    async fetchAllAdvisors() {
      await window.API.fetchAllAdvisors()
        .then((response) => {
          this.advisors = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    async fetchAllServices() {
      await window.API.fetchAllServices()
        .then((response) => {
          this.services = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
    async fetchAllStates() {
      await window.API.fetchAllStates()
        .then((response) => {
          this.states = response;
        })
        .catch(() => this.showFetchRequestErrorMessage());
    },
  },
  async created() {
    try {
      this.loading.page = true;
      await this.fetchAllServices();
      await this.fetchAllAdvisors();
      await this.fetchAllStates();
    } finally {
      this.loading.page = false;
    }
  },
};
</script>
