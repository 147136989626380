<template>
  <d-page :title="$t('profile.page-heading')">
    <template slot="actions">
      <v-btn
        color="primary"
        depressed
        large
        @click="editProfile"
        v-if="!isEdit"
      >
        <v-icon left> mdi-pencil </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("profile.button.edit")
        }}</span>
      </v-btn>
    </template>
    <template slot="content">
      <template v-if="isEdit">
        <EditProfile
          :hasImage="!imageError"
          :data="form"
          @cancelEdit="isEdit = false"
          @saveEdit="isEdit = false"
        />
      </template>
      <template v-else>
        <ViewProfile
          @imageError="handleImageError"
          @viewProfile="handleViewProfile"
        />
      </template>
    </template>
  </d-page>
</template>

<script>
import EditProfile from "./EditProfile.vue";
import ViewProfile from "./ViewProfile.vue";

export default {
  components: {
    EditProfile,
    ViewProfile,
  },
  data() {
    return {
      isEdit: false,
      form: null,
      profile: null,
      imageError: false,
    };
  },
  methods: {
    editProfile() {
      this.form = { ...this.profile };
      this.isEdit = true;
    },
    handleViewProfile(profile) {
      this.profile = profile;
    },
    handleImageError(error) {
      this.imageError = error;
    },
  },
};
</script>
