export default {
  data() {
    return {
      loading: {
        table: false,
      },
      table: {
        items: [],
        totalCount: 0,
        page: 1,
        itemsPerPage: 10,
        options: {
          sortBy: [],
          sortDesc: [],
        },
        isSearched: false,
        itemsPerPageOptions: [5, 10, 15, 50],
        sortedBy: null,
        sortedDesc: null,
      },
    };
  },
  watch: {
    "loading.table": {
      handler(val) {
        if (val) this.$eventBus.$emit("searchStarted");
        else this.$eventBus.$emit("searchFinished");
      },
    },
  },
  methods: {
    optionsUpdated(value) {
      const { page, itemsPerPage, sortBy, sortDesc } = value;
      if (
        (sortBy.length > 0 && sortDesc.length > 0) ||
        page > 0 ||
        itemsPerPage > 0
      ) {
        this.handleOptionsChanged({
          page: page,
          itemsPerPage: itemsPerPage,
          sortedBy: sortBy[0],
          sortedDesc: sortDesc[0],
        });
        this.$emit("getTable", this.table);
      }
    },
    handleOptionsChanged(item) {
      if (
        this.table.sortedBy != item.sortedBy ||
        this.table.sortedDesc != item.sortedDesc ||
        this.table.page != item.page ||
        this.table.itemsPerPage != item.itemsPerPage
      ) {
        this.table.page = item.page;
        this.table.itemsPerPage = item.itemsPerPage;
        this.table.sortedBy = item.sortedBy;
        this.table.sortedDesc = item.sortedDesc;
        this.fetchItems();
      }
    },
  },
};
