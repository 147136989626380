<template>
  <d-page :title="$t('companies.details.title')">
    <template slot="append-heading">
      <v-chip
        v-if="company && !loading"
        class="ml-6"
        small
        :color="getCompanyType(company.isResident).color"
        outlined
        >{{ getCompanyType(company.isResident).text }}
      </v-chip>
    </template>
    <template slot="content">
      <d-loading v-if="loading"></d-loading>
      <div
        v-if="company && !loading"
        class="company-details border-all rounded"
      >
        <CompanyInformation :company="company" />
        <ContactDetails :company="company" />
        <RegisteredAgent
          v-if="company.registeredAgent && company.isResident"
          :registeredAgent="company.registeredAgent"
        />
        <BusinessAddress
          :businessAddress="company.businessAddress"
          :isResident="company.isResident"
        />
        <ManagementStructure
          v-if="company.managementStructure && !isCorporation"
          :company="company"
        />
        <Organizers
          v-if="company.organizers"
          :organizers="company.organizers"
          :isCorporation="isCorporation"
          :isResident="company.isResident"
          :isLLC="isLLC"
        />
        <SosialSecurity :company="company" />
        <OrderSummary :company="company" v-if="company.registrationPayment" />
      </div>
    </template>
  </d-page>
</template>

<script>
import BusinessEntityTypes from "@/shared/enums/BusinessEntityTypes";

import CompanyInformation from "./CompanyInformation.vue";
import ContactDetails from "./ContactDetails.vue";
import RegisteredAgent from "./RegisteredAgent.vue";
import BusinessAddress from "./BusinessAddress.vue";
import ManagementStructure from "./ManagementStructure.vue";
import SosialSecurity from "./SosialSecurity.vue";
import Organizers from "./Organizers.vue";
import OrderSummary from "./OrderSummary.vue";

export default {
  components: {
    CompanyInformation,
    ContactDetails,
    RegisteredAgent,
    BusinessAddress,
    ManagementStructure,
    SosialSecurity,
    Organizers,
    OrderSummary,
  },
  data() {
    return {
      loading: false,
      company: null,
    };
  },
  computed: {
    isCorporation() {
      return (
        this.company.businessEntityType.id === BusinessEntityTypes.Corporation
      );
    },
    isLLC() {
      return this.company.businessEntityType.id === BusinessEntityTypes.LLC;
    },
  },
  methods: {
    getCompanyType(isResident) {
      if (isResident) {
        return {
          color: "success",
          text: this.$t("companies.company-type.resident"),
        };
      }

      return {
        color: "primary",
        text: this.$t("companies.company-type.non-resident"),
      };
    },
    getCompanyDetailsById(id) {
      this.loading = true;

      window.API.getCompanyDetailsById(id)
        .then((response) => {
          this.company = response;
        })
        .catch(() => this.showFetchRequestErrorMessage())
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getCompanyDetailsById(this.$route.params.id);
  },
};
</script>
