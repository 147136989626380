<template>
  <div>
    <v-data-table
      v-model="selectedItems"
      :single-select="false"
      show-select
      :page="table.page"
      :headers="filteredHeaders"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.displayName`]="{ item }">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.displayName }}</span>
          </template>
          <span
            v-html="
              $t('my-uploads.list.table.document-name', {
                uploaderFullName: item.creatorUser || '',
                uploadedDate: getFormattedUploadedDate(item.creationTime),
              })
            "
          ></span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <template v-if="item.tags.length > 0">
          <v-chip
            v-for="(tag, index) in item.tags"
            small
            :key="index"
            class="ma-1 white--text"
            :color="tag.tagGroup.color"
            label
          >
            {{ tag.displayName }}
          </v-chip>
        </template>
      </template>
      <template v-slot:[`item.service`]="{ item }">
        <v-chip small color="primary" outlined>
          {{ item.service.displayName }}
        </v-chip>
      </template>
      <template v-slot:[`item.creationTime`]="{ item }">
        <span>
          {{ getUploadInfo(item) }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="getDocumentStatusText(item.status)"
          small
          :color="getDocumentStatus(item.status).color"
          :text-color="getDocumentStatus(item.status).textColor"
        >
          {{ getDocumentStatusText(item.status) }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top color="gray darken-2" v-granted="'my-upload:edit'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="editDocument(item)"
              >
                <v-icon
                  size="18"
                  :color="highlightEditedItem(item) ? 'primary' : ''"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("my-uploads.list.table.actions.edit") }}</span>
          </v-tooltip>
          <v-tooltip top color="gray darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                v-bind="attrs"
                v-on="on"
                icon
                small
                :loading="loadPreview(item)"
                @click="viewDocument(item)"
              >
                <v-icon size="18"> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("my-uploads.list.table.actions.view") }}</span>
          </v-tooltip>
          <v-tooltip top color="gray darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                class="ml-1"
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="viewComments(item)"
              >
                <v-badge
                  color="error"
                  left
                  :value="item.numberOfComments != 0"
                  overlap
                  offset-x="15"
                  :content="item.numberOfComments"
                >
                  <v-icon
                    size="18"
                    :color="highlightCommentedItem(item) ? 'primary' : ''"
                  >
                    mdi-comment-text-multiple
                  </v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>{{ $t("my-uploads.list.table.actions.comment") }}</span>
          </v-tooltip>
          <v-tooltip top color="gray darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray darken-2"
                icon
                v-bind="attrs"
                v-on="on"
                small
                @click="shareDocument(item)"
              >
                <v-icon
                  size="18"
                  :color="highlightSharedItem(item) ? 'primary' : ''"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("my-uploads.list.table.actions.share") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('my-uploads.list.empty-page')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";
import DocumentStatuses from "@/shared/enums/DocumentStatuses";

export default {
  mixins: [tableMixins],
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isViewing: {
      type: Boolean,
      default: false,
    },
    isSharing: {
      type: Boolean,
      default: false,
    },
    isCommenting: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      required: true,
    },
  },
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("my-uploads.list.table.headers.client"),
          value: "client",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.list.table.headers.document-name"),
          value: "displayName",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.list.table.headers.tags"),
          value: "tags",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.list.table.headers.service"),
          value: "service",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.list.table.headers.uploaded"),
          value: "creationTime",
          sortable: false,
        },
        {
          text: vm.$t("my-uploads.list.table.headers.status"),
          value: "status",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      initialSearchParams: {
        status: null,
      },
      selectedItems: [],
      DocumentStatuses,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
      loggedInUser: "Auth/getLoggedInUser",
    }),
    filteredHeaders() {
      if (authorizationUtils.isClient()) {
        return this.headers.filter(
          (header) => !["client"].includes(header.value)
        );
      }
      if (!authorizationUtils.isClient()) {
        return this.headers.filter(
          (header) => !["status"].includes(header.value)
        );
      }
      return this.headers;
    },
  },
  watch: {
    selectedItems: {
      handler(val) {
        this.updateSelectedItems(val.map((item) => item.id));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      let serviceId = null;

      if (authorizationUtils.isEmployee()) {
        const selectedServices = this.services
          .filter((item) => item.departmentId == this.loggedInUser.departmentId)
          .sort(function (a, b) {
            return a.id - b.id;
          });

        if (selectedServices && selectedServices.length > 0) {
          serviceId = selectedServices[0]?.id;
        }
      }

      this.setSearchParams({
        ...this.initialSearchParams,
        serviceId: serviceId,
      });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;
      this.clearSelectedItems();
      this.table.isSearched = !this.isObjectEmpty(this.searchParams);
      window.API.fetchMyUploads({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;
          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    editDocument(item = null) {
      this.$emit("editDocument", item);
    },
    viewDocument(item = null) {
      this.$emit("viewDocument", item);
    },
    viewComments(item = null) {
      this.$emit("viewComments", item);
    },
    shareDocument(item = null) {
      this.$emit("shareDocument", item);
    },
    getUploadInfo(item) {
      if (!item) return "";

      const uploadedDate = this.getFormattedUploadedDate(item.creationTime);

      return [uploadedDate, item.creatorUser].filter((x) => x).join(" / ");
    },
    getFormattedUploadedDate(date) {
      return date ? this.UTCToLocalTime(date, "MMM D,  YYYY") : "";
    },
    getDocumentStatus(status) {
      switch (status) {
        case DocumentStatuses.OPEN:
          return {
            color: "light-blue lighten-5",
            textColor: "primary",
          };
        case DocumentStatuses.IN_PROGRESS:
          return {
            color: "orange lighten-5",
            textColor: "warning",
          };
        case DocumentStatuses.DONE:
          return {
            color: "teal lighten-5",
            textColor: "success",
          };
        default:
          return null;
      }
    },
    getDocumentStatusText(status) {
      switch (status) {
        case DocumentStatuses.OPEN:
          return this.$t("my-uploads.list.table.status.open");
        case DocumentStatuses.IN_PROGRESS:
          return this.$t("my-uploads.list.table.status.in-progress");
        case DocumentStatuses.DONE:
          return this.$t("my-uploads.list.table.status.done");
        default:
          return null;
      }
    },
    clearSelectedItems() {
      this.selectedItems = [];
    },
    updateSelectedItems(selectedItems) {
      this.$emit("updateSelectedItems", selectedItems);
    },
    highlightEditedItem(item) {
      return (
        this.selectedItem && this.selectedItem.id == item.id && this.isEditing
      );
    },
    loadPreview(item) {
      return (
        this.selectedItem && this.selectedItem.id == item.id && this.isViewing
      );
    },
    highlightSharedItem(item) {
      return (
        this.selectedItem && this.selectedItem.id == item.id && this.isSharing
      );
    },
    highlightCommentedItem(item) {
      return (
        this.selectedItem &&
        this.selectedItem.id == item.id &&
        this.isCommenting
      );
    },
  },
  created() {
    this.$eventBus.$on("fetchMyUploads", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchMyUploads", this.handleFetchItems);
  },
};
</script>
