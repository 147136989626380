<template>
  <div>
    <div class="d-flex justify-end mb-4" v-if="selectedMonth || selectedYear">
      <div class="d-flex align-center">
        <v-btn
          small
          fab
          text
          depressed
          dense
          value="left"
          @click="handlePrevMonth"
          :disabled="isDisabledPrevMonth"
          :loading="loading.prev"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>

        <span class="mx-2 text-align-center"
          >{{ selectedMonth.displayText }} {{ selectedYear.displayText }}</span
        >

        <v-btn
          small
          fab
          text
          depressed
          dense
          value="right"
          @click="handleNextMonth"
          :disabled="isDisabledNextMonth"
          :loading="loading.next"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table || isSubmitting"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      :options.sync="table.options"
      v-if="table.items.length > 0 || loading.table"
      class="d-table"
      @update:options="optionsUpdated"
      item-key="clientId"
    >
      <template v-slot:progress v-if="loading.table">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            :class="
              item.isBankStatementsUploaded &&
              item.isQuickBooksDraftDone &&
              item.isQuickBooksReviewDone &&
              item.isFinancialStatementsUploaded
                ? 'light-green lighten-5'
                : ''
            "
            v-for="item in items"
            :key="item.client.id"
          >
            <td>
              <div class="d-flex flex-nowrap">
                <v-tooltip
                  right
                  :color="item.client.isActive ? 'success' : 'error'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="item.client.isActive ? 'success' : 'error'"
                      size="16"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <span>{{
                    item.client.isActive
                      ? $t("bookkeeping.list.table.status.active")
                      : $t("bookkeeping.list.table.status.inactive")
                  }}</span>
                </v-tooltip>

                <span class="ml-2">{{ item.client.name }}</span>
              </div>
            </td>
            <td>
              <span>{{ item.client.owner }}</span>
            </td>
            <td>
              <span>{{ item.client.advisor }}</span>
            </td>
            <td>
              <v-tooltip
                top
                color="primary"
                :disabled="item.numberOfAccounts > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model.number="item.numberOfAccounts"
                    hide-details="auto"
                    @change="handleBookkeepingChange(item)"
                    type="number"
                    min="0"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!canEditBookkeeping"
                  ></v-text-field>
                </template>
                <span>{{
                  $t("bookkeeping.list.table.headers.number-of-accounts.hint")
                }}</span>
              </v-tooltip>
            </td>
            <td>
              <span>{{ item.numberOfBankStatements }}</span>
            </td>
            <td>
              <v-checkbox
                dense
                class="mt-0"
                v-model="item.isBankStatementsUploaded"
                hide-details="auto"
                disabled
              ></v-checkbox>
            </td>
            <td>
              <v-checkbox
                dense
                class="mt-0"
                v-model="item.isQuickBooksDraftDone"
                hide-details="auto"
                @change="handleBookkeepingChange(item)"
                :disabled="!canEditBookkeeping"
              ></v-checkbox>
            </td>
            <td>
              <v-checkbox
                dense
                class="mt-0"
                v-model="item.isQuickBooksReviewDone"
                hide-details="auto"
                @change="handleBookkeepingChange(item)"
                :disabled="!canEditBookkeeping"
              ></v-checkbox>
            </td>
            <td>
              <v-checkbox
                dense
                class="mt-0"
                v-model="item.isFinancialStatementsUploaded"
                hide-details="auto"
                disabled
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <d-page-empty
      :hasCreateButton="false"
      :text="$t('bookkeeping.list.empty-page')"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import { mapActions, mapGetters } from "vuex";
import authorizationUtils from "@/utils/authorization.js";

export default {
  mixins: [tableMixins],
  props: {
    months: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    startedYear: {
      type: Number,
      required: true,
    },
  },
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("bookkeeping.list.table.headers.client"),
          value: "client.name",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.owner"),
          value: "client.owner",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.advisor"),
          value: "client.advisor",
          sortable: false,
        },
        {
          text: vm.$t(
            "bookkeeping.list.table.headers.number-of-accounts.label"
          ),
          value: "numberOfAccounts",
          sortable: false,
          width: "120px",
        },
        {
          text: vm.$t(
            "bookkeeping.list.table.headers.number-of-bank-statements"
          ),
          value: "numberOfBankStatements",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.bank-statement"),
          value: "isBankStatementsUploaded",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.quickbooks-draft"),
          value: "isQuickBooksDraftDone",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.quickbooks-review"),
          value: "isQuickBooksReviewDone",
          sortable: false,
        },
        {
          text: vm.$t("bookkeeping.list.table.headers.financials"),
          value: "isFinancialStatementsUploaded",
          sortable: false,
        },
      ],
      initialSearchParams: {
        isActiveClient: null,
        year: null,
        month: null,
      },
      isSubmitting: false,
      isDisabledNextMonth: false,
      isDisabledPrevMonth: false,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    selectedMonth() {
      return this.months.find(
        (month) => month.value == this.searchParams.month
      );
    },
    selectedYear() {
      return this.years.find((year) => year.value == this.searchParams.year);
    },
    currentYear() {
      return this.$moment().format("YYYY");
    },
    currentMonth() {
      return this.$moment().format("MMM");
    },
    canEditBookkeeping() {
      return authorizationUtils.isGranted("bookkeeping:edit");
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.isDisabledPrevMonth = false;
        this.isDisabledNextMonth = false;

        if (
          this.selectedMonth.id == 1 &&
          this.selectedYear.value == this.startedYear
        ) {
          this.isDisabledPrevMonth = true;
        }

        if (
          this.selectedMonth.id == 12 &&
          this.selectedYear.value == this.currentYear
        ) {
          this.isDisabledNextMonth = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({
        ...this.initialSearchParams,
        year: this.currentYear,
        month: this.currentMonth,
      });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    fetchItems() {
      this.loading.table = true;

      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );
      window.API.fetchBookkeeping({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items = response.items;
          this.table.totalCount = response.totalCount;

          if (
            response.totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    handleBookkeepingChange(item) {
      if (item.numberOfAccounts < 0) return;

      if (item.id > 0) {
        this.editBookkeeping(item);
        return;
      }

      this.insertBookkeeping(item);
    },
    async insertBookkeeping({
      client,
      numberOfAccounts,
      isQuickBooksDraftDone,
      isQuickBooksReviewDone,
      year,
      month,
    }) {
      let bookkeeping = {
        clientId: client.id,
        numberOfAccounts: numberOfAccounts,
        isQuickBooksDraftDone: isQuickBooksDraftDone,
        isQuickBooksReviewDone: isQuickBooksReviewDone,
        year: year,
        month: month,
      };

      try {
        this.isSubmitting = true;

        await window.API.insertBookkeeping(bookkeeping);
        this.$eventBus.$emit("notification", {
          type: "success",
          message: this.$t("bookkeeping.edit.alert.updated", {
            clientName: client.name,
          }),
        });
        this.fetchItems();
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("bookkeeping.edit.alert.could-not-update"),
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async editBookkeeping({
      client,
      id,
      numberOfAccounts,
      isQuickBooksDraftDone,
      isQuickBooksReviewDone,
    }) {
      let bookkeeping = {
        id: id,
        numberOfAccounts: numberOfAccounts,
        isQuickBooksDraftDone: isQuickBooksDraftDone,
        isQuickBooksReviewDone: isQuickBooksReviewDone,
      };

      try {
        this.isSubmitting = true;

        await window.API.editBookkeeping(id, bookkeeping);
        this.$eventBus.$emit("notification", {
          type: "success",
          message: this.$t("bookkeeping.edit.alert.updated", {
            clientName: client.name,
          }),
        });
        this.fetchItems();
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("bookkeeping.edit.alert.could-not-update"),
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async handlePrevMonth() {
      if (
        this.selectedMonth.id == 1 &&
        this.selectedYear.value == this.startedYear
      )
        return;

      try {
        this.loading.prev = true;
        let month, year;

        if (this.selectedMonth.id == 1) {
          month = this.months.find((month) => month.id == 12).value;
          year = parseInt(this.selectedYear.value) - 1;
        } else {
          month = this.months.find(
            (month) => month.id == this.selectedMonth.id - 1
          ).value;
          year = parseInt(this.selectedYear.value);
        }
        await this.setSearchParams({
          ...this.searchParams,
          month: month,
          year: year.toString(),
        });
        await this.fetchItems();
      } finally {
        this.loading.prev = false;
      }
    },
    async handleNextMonth() {
      if (
        this.selectedMonth.id == 12 &&
        this.selectedYear.value == this.currentYear
      )
        return;

      try {
        this.loading.next = true;
        let month, year;

        if (this.selectedMonth.id == 12) {
          month = this.months.find((month) => month.id == 1).value;
          year = parseInt(this.selectedYear.value) + 1;
        } else {
          month = this.months.find(
            (month) => month.id == this.selectedMonth.id + 1
          ).value;
          year = parseInt(this.selectedYear.value);
        }
        await this.setSearchParams({
          ...this.searchParams,
          month: month,
          year: year.toString(),
        });
        await this.fetchItems();
      } finally {
        this.loading.next = false;
      }
    },
  },
  created() {
    this.$eventBus.$on("fetchBookkeeping", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchBookkeeping", this.handleFetchItems);
  },
};
</script>
