<template>
  <d-modal
    :title="$t('clients.send-email.title')"
    :saveButtonText="$t('clients.send-email.button.send')"
    :modal="modal"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
    :isSubmitting="isSubmitting"
    :maxWidth="960"
  >
    <template slot="body">
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-text-field
              :label="$t('clients.send-email.form.subject')"
              v-model.trim="form.subject"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.subject"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <d-editor v-model="form.body" :isValid="isBodyValid"></d-editor>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import BaseEditor from "@/components/BaseEditor.vue";
import { mapGetters } from "vuex";

const initialForm = {
  subject: null,
  body: "",
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    table: {
      type: Object,
      required: true,
    },
  },
  components: {
    "d-editor": BaseEditor,
  },
  data() {
    return {
      form: { ...initialForm },
      rules: {
        subject: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      isBodyValid: true,
      isFormValid: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    "form.body": {
      handler() {
        this.isBodyValid = !!this.form.body;
      },
    },
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    handleSubmitModal() {
      this.isBodyValid = !!this.form.body;
      if (this.$refs.form.validate() && this.isFormValid && this.isBodyValid) {
        this.sendEmail();
      }
    },
    sendEmail() {
      this.isSubmitting = true;

      window.API.sendEmail(this.form, {
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        sortedBy: this.table.sortedBy,
        sortedDesc: this.table.sortedDesc,
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("clients.send-email.alert.sent"),
          });
          this.handleCloseModal();
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("clients.send-email.alert.could-not-send"),
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style>
.editor .tox-tinymce {
  border-radius: 4px;
}

.editor--invalid .tox-tinymce {
  border-color: var(--v-error-base) !important;
}

.editor--invalid .tox-edit-area__iframe {
  background-color: rgba(255, 82, 82, 0.12) !important;
}
</style>
