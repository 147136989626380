<template>
  <d-modal
    :title="$t('profile.confirm-announcement.title')"
    :modal="modal"
    :saveButtonText="$t('defaults.modal-confirm')"
    :isSaveButtonDisabled="!form.isAcknowledged"
    :hasCancelButton="false"
    @submitModal="handleSubmitModal"
    :isSubmitting="loading.confirm"
  >
    <template slot="body">
      <div v-if="item" class="announcement-details">
        <v-row>
          <v-col cols="12" class="mb-3">
            <h4 v-text="item.announcement.title"></h4>
          </v-col>
          <v-col cols="12" class="mb-3">
            <div v-html="item.announcement.body"></div>
          </v-col>
          <v-col cols="12" class="mb-3">
            <div>
              {{ item.clients.map((client) => client.name).join(", ") }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              class="mt-0"
              hide-details="auto"
              v-model="form.isAcknowledged"
            >
              <template v-slot:label>
                <div v-html="item.announcement.acknowledgmentText"></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
    </template>
  </d-modal>
</template>

<script>
const initialForm = {
  isAcknowledged: false,
};
export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    item: Object,
  },
  data() {
    return {
      form: {
        ...initialForm,
      },
      loading: {
        confirm: false,
      },
    };
  },
  watch: {
    item: {
      handler() {
        this.form = { ...initialForm };
      },
      deep: true,
    },
  },
  methods: {
    async handleSubmitModal() {
      try {
        this.loading.confirm = true;
        await window.API.confirmMyAnnouncement(this.item.announcement.id, {
          clients: this.item.clients.map((client) => client.id),
        });
        this.$emit("submitModal");
      } catch {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t(
            "profile.confirm-announcement.alert.could-not-confirm"
          ),
        });
      } finally {
        this.loading.confirm = false;
      }
    },
  },
};
</script>
