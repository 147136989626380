<template>
  <v-row v-if="profile">
    <v-col cols="12" sm="12" md="4" class="mb-4 mb-md-0">
      <div
        class="border-all pa-4 rounded d-flex align-center justify-center h-100"
      >
        <v-row class="my-0">
          <v-col cols="12" align="center">
            <v-avatar color="gray lighten-1" size="96">
              <d-auth-image
                :url="`/identity/users/me/profile-picture`"
                :width="192"
                :height="192"
                @error="handleImageError"
              ></d-auth-image>

              <span
                class="body-1 gray--text text--lighten-4"
                v-if="imageError"
                >{{ profileAvatar }}</span
              >
            </v-avatar>
          </v-col>
          <v-col cols="12" class="mt-2" align="center">
            <span class="subtitle-1 gray--text text--darken-3">{{
              `${profile.fullName}`
            }}</span>
          </v-col>
          <v-col cols="12" align="center">
            <template v-if="profile.roles.length > 0">
              <span
                class="body-2 gray--text"
                v-for="(role, index) in profile.roles"
                :key="index"
                >{{ `${role}${profile.roles.length > index + 1 ? "," : ""}` }}
              </span>
            </template>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="8">
      <div class="border-all px-4 py-1 rounded h-100">
        <div class="border-bottom py-3">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.first-name")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.firstName)"
              ></span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.last-name")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.lastName)"
              ></span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.phone-number")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.phoneNumber)"
              ></span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3" v-if="!isClient">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.extension")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.extension)"
              ></span>
            </v-col>
          </v-row>
        </div>
        <div class="border-bottom py-3">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.email")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.emailAddress)"
              ></span>
            </v-col>
          </v-row>
        </div>
        <div class="py-3">
          <v-row class="my-0">
            <v-col md="3">
              <span class="subtitle-2 gray--text">{{
                $t("profile.form.username")
              }}</span>
            </v-col>
            <v-col md="9">
              <span
                class="body-2 gray--text"
                v-html="fillEmptyData(profile.username)"
              ></span>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import authorizationUtils from "@/utils/authorization.js";

export default {
  data() {
    return {
      imageError: false,
      profile: null,
    };
  },
  methods: {
    getUserProfile() {
      window.API.getUserProfile()
        .then((res) => {
          this.profile = res;
          this.$emit("viewProfile", res);
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("defaults.alert.could-not-fetch"),
          });
        });
    },
    handleImageError(error) {
      this.imageError = error;
    },
  },
  watch: {
    imageError: {
      handler(val) {
        this.$emit("imageError", val);
      },
      immediate: true,
    },
  },
  mounted() {
    this.getUserProfile();
    this.imageError = false;
  },
  computed: {
    profileAvatar() {
      return (
        this.profile.firstName.charAt(0).toUpperCase() +
        this.profile.lastName.charAt(0).toUpperCase()
      );
    },
    isClient() {
      return authorizationUtils.isClient();
    },
  },
};
</script>
