<template>
  <d-modal
    :title="$t('profile.send-invitation.title')"
    :modal="modal"
    :isSubmitting="isSubmitting"
    :saveButtonText="$t('profile.send-invitation.button.send')"
    @submitModal="handleSubmitModal"
    @closeModal="handleCloseModal"
  >
    <template slot="body">
      <v-form ref="form" lazy-validation @submit.prevent="handleSubmitModal">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :label="$t('profile.send-invitation.form.email-address')"
              v-model.trim="form.emailAddress"
              hide-details="auto"
              filled
              type="text"
              :rules="rules.emailAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </d-modal>
</template>

<script>
import SendInvitationResultTypes from "@/shared/enums/SendInvitationResultTypes";

const initialForm = {
  emailAddress: null,
};

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      SendInvitationResultTypes,
      isSubmitting: false,
      form: { ...initialForm },
      rules: {
        emailAddress: [
          (v) => !!v || this.$t("defaults.rule.required"),
          (v) =>
            /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
            this.$t("profile.send-invitation.rule.email-address.invalid"),
        ],
      },
    };
  },
  methods: {
    handleCloseModal() {
      this.$refs.form.reset();
      this.form = { ...initialForm };
      this.$emit("closeModal");
    },
    async handleSubmitModal() {
      if (this.$refs.form.validate()) {
        this.isSubmitting = true;
        window.API.sendInvitation({
          recipientEmailAddress: this.form.emailAddress,
        })
          .then((response) => {
            switch (response.resultType) {
              case this.SendInvitationResultTypes
                .RECIPIENT_IS_OWNER_OR_COLLABORATOR:
                this.$eventBus.$emit("notification", {
                  type: "info",
                  message: this.$t(
                    "profile.send-invitation.alert.recipient-is-owner-or-collaborator",
                    { emailAddress: this.form.emailAddress }
                  ),
                });
                break;
              case this.SendInvitationResultTypes.RECIPIENT_IS_REGISTERED_USER:
                this.$eventBus.$emit("notification", {
                  type: "info",
                  message: this.$t(
                    "profile.send-invitation.alert.recipient-is-registered-user",
                    { emailAddress: this.form.emailAddress }
                  ),
                });
                break;
              default:
                this.$eventBus.$emit("notification", {
                  type: "success",
                  message: this.$t("profile.send-invitation.alert.sent", {
                    emailAddress: this.form.emailAddress,
                  }),
                });
            }

            this.handleCloseModal();
          })
          .catch(() => {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("profile.send-invitation.alert.could-not-send"),
            });

            this.$refs.form.reset();
            this.form = { ...initialForm };
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
  },
};
</script>
